import React, { useState, useEffect } from 'react';
import CFATable from './Components/CertifiedApplicatorTable';
import NJ_ACT_C112 from '../cfa-files/NJ Act P.L. 2010 c.112 (C58 10A-64).pdf';


import {get_cfa_public} from '../Api/api';
//import { Link } from 'react-router-dom';

//import cfaList from  '../JsonApi/cfa-list.js';

const CfaListPage = ( props ) => {

	document.title = props.title;
	const [cfaList, setCfaList] = useState([]);

	useEffect( () => {
		get_cfa_public()
			.then( (res) => {
				//console.log('Retrieving CFA Public List', res);
				
				if( res.status && res.status !== 200 ) {
					throw res; 
				}

				if( res.data.code === 'PUBLIC_CFAS' ) {
					if( res.data.data ) {
						setCfaList( res.data.data );
					}
				}

			} )
			.catch( error => {
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch CFA Public listing.... ');
				}
				setCfaList(null);
			});
	}, [] );

	

	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h1>Certified Applica​​​​tors List​</h1>
						<h3>
							All professional applicators listed below have met the requirements for applying fertilizer in the state of 
								New Jersey according to <a href={NJ_ACT_C112} target="_blank" rel="noopener noreferrer">New Jersey Act, ​P.L. 2010, c. 112 (C.58:10A-64)</a>​.​​​​​​​​​​
						</h3>
						<hr />
						<CFATable CfaList={cfaList} />
					</div>
				</div>
			</div>
		</main>
	);

}

export default CfaListPage;