import React, {
	//useEffect,
	useState, 
	//useEffect
	//createRef
} from 'react';

import { 
	//AUTO_update_admin_list,
	//get_tfas_by_cfas,
	//delete_tfa_to_cfa,
	AUTO_tfa_to_cfa,

	DECODE_TOKEN 
} from '../../../Api/api';
import {FormatPhoneNumber, FormatZipcode} from '../../../Config';

import ProfactSelect from '../../../HelperComponents/ProfactSelect';
//import {FormatPhoneNumber, FormatZipcode} from '../../../Config';

const EditTFARecord = (props) => {
	
	//these items are functions to the parent page.
	const [
		cancelEditing,
		confirmEditing
	] = [
		props.cancelEditing, 
		props.confirmEditing 
	];

	//const userId = props.cfaId;

	const [tfaAlert, SetTfaAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	/*
	
	const STOCK_TFA = {

		tfaId: null,

		firstName: '',
		lastName: '',
		email:'',
		homeAddressId: '',
		cfaId: userId,
		spanishPref: 0,
		payId: null,
		activeYear: null, //new Date().getFullYear(),

		//Address 
		homeStreet: '',
		homeCity: '',
		homeState: 'NJ',
		homeZip: '',
		homePhone: '',
		homeCounty: '',

		confirmTfa: false,
	};
*/
	//console.log('STOCK TFA', props.UserState, STOCK_TFA);
	const [tfaFormData, UpdateTfaFormData] = useState({
		...props.selectedUser,
		
		homePhone: FormatPhoneNumber(props.selectedUser.homePhone),
		homeZip: FormatZipcode(props.selectedUser.homeZip),
	});
	const [disableAddTfaBtn, setDisableTfaBtn] = useState(false);
	const [requireTfaHomeCounty, setRequireTfaHomeCounty] = useState( (tfaFormData.homeState && tfaFormData.homeState.toLowerCase() === 'nj') );
	

	//console.log('Currently selected user? ', selectedUser );

	const submitTfaInfo = (e) => {
		e.preventDefault();
		/*
		if( !tfaFormData.confirmTfa ) {
			console.error('Please confirm the checkbox.');
			return false;
		}
		*/
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		setDisableTfaBtn(true);
		console.log(tfaFormData);

		AUTO_tfa_to_cfa( tokenInfo.getAuthToken, {
			...tfaFormData,
			
			homeZip: (''+ tfaFormData.homeZip).replace(/[^0-9]/g, '').substr(0,9),
			homePhone: (''+ tfaFormData.homePhone).replace(/[^0-9]/g, '').substr(0,11),
		})
			.then( (res) => {
				console.log('ADD_NEW_TFA: ', res);
				
				SetTfaAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully updated!',
					message: 'We have saved this TFAs information.'	
				});
				if( !tfaFormData.homeAddressId ) {
					//attempt to get the home address Id
					try{
						const decodeData = JSON.parse(res[0].config.data);
						UpdateTfaFormData({
							...tfaFormData,
							homeAddressId: decodeData.homeAddressId
						});
					} catch (error) {

					}
				}

				document.getElementById('tfa-details').scrollIntoView();
				setDisableTfaBtn(false);
			} )
			.catch( error => {
				console.error('Getting [ADD_NEW_TFA] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add TFA for this CFA.');
				}
				
				SetTfaAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				setDisableTfaBtn(false);
			});
	}
	const tfaForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		//console.log(e_target, );
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            [name]: value
		}));
	}
	const tfaForm_checkBox_HandleChange = (e_target) => {
		const { name, checked } = e_target;
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            [name]: (checked ? 1 : 0)
		}));
		//setSpanishText( checked );
	}

	const handleHomeStateChange = (e_target) => {
		
		tfaForm_HandleChange(e_target);
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            homeCounty: ''
		}));
		setRequireTfaHomeCounty( e_target.value.toLowerCase() === 'nj' );
	};

	const SetUpForm = () => {
		return (
			<div id="tfa-details">
				<h3> Editing a TFA </h3>
				<div className={"callout " + tfaAlert.type} style={{display:tfaAlert.display}} data-closable>
					<h5>{tfaAlert.title}</h5>
					<p>{tfaAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							SetTfaAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				
				<div className="margin-top-3" style={{marginTop: 1 + 'em'}}>
					<form action="" method="post" onSubmit={submitTfaInfo}>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="firstName" className="label-style-1">Name: </label>
							</div>
							<div className="large-4 medium-6 small-12  columns">
								<input type="text" id="firstName" name="firstName" placeholder="First Name" required="required" 
									value={tfaFormData.firstName}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
							<div className="large-5 medium-6 small-12  columns">
								<input type="text" id="lastName" name="lastName" placeholder="Last Name" required="required" 
									value={tfaFormData.lastName}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="small-12 columns">
								<label htmlFor="spanishPref" className="label-style-1">
									<input type="checkbox" 
										name="spanishPref" id="spanishPref" 
										defaultChecked={tfaFormData.spanishPref}
										onChange={(e)=>{ tfaForm_checkBox_HandleChange(e.target); }}
									/>
									This TFA prefers Spanish when available
								</label>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="email" className="label-style-1">Email</label>
							</div>
							
							<div className="large-9 medium-12 small-12 columns">
								<input type="email" id="email" name="email" placeholder="name@example.com" 
									value={tfaFormData.email}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homePhone" className="label-style-1">Home Phone Number</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homePhone" name="homePhone" placeholder="" 
								 
								minLength="7"
								maxLength="21"
									value={tfaFormData.homePhone}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>

						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeStreet" className="label-style-1">Home Address</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeStreet" name="homeStreet" placeholder="" 
								
									value={tfaFormData.homeStreet}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeCity" className="label-style-1">Home City</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeCity" name="homeCity" placeholder="" 
								 
									value={tfaFormData.homeCity}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeState" className="label-style-1">Home State</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<ProfactSelect
									name="homeState" id="homeState" 
									StateDropdown={true}  
									IncludeBlank={true} 
									
									value={tfaFormData.homeState}
									onChange={e => {
										handleHomeStateChange(e.target);
										//homeForm_requireCounty(e.target);
										//homeForm_HandleChange(e.target);
									} } />
							</div>
						</div>
						<div className="row" style={{display: (requireTfaHomeCounty ? 'block': 'none')}}>
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeCounty" className="label-style-1">Home NJ County</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<ProfactSelect
									name="homeCounty" id="homeCounty" NjCountyDropdown={true}
									required={requireTfaHomeCounty}
									value={tfaFormData.homeCounty}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeZip" className="label-style-1">Home Zipcode</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeZip" name="homeZip" placeholder="" 
								 
									minLength="5"
									maxLength="10"
									value={tfaFormData.homeZip}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-12 medium-12 small-12 columns text-right">
								<button 
									onClick={e => {cancelEditing('tfa-only')}} 
									className="button alert" type="button">
									Cancel</button>
								<button className="button" type="submit" disabled={disableAddTfaBtn}>
									Update This TFA</button>
							</div>
						</div>
					</form>
				</div>
			</div>

);
	}

	/*
						<div className="row">
							<div className="callout alert large-12 medium-12 small-12 columns">
								<p>By clicking the checkbox below, I verify that I am a Certified Fertilizer Applicator (CFA) in the state of New Jersey, and that the Trained Fertilizer Applicator (TFA) listed above has received training using the TFA training materials found on the ProFACT web site (profact.rutgers.edu) or an approved alternate, on the application, sale, and use of fertilizer. I also attest that the Trained Fertilizer Applicator understands:</p>
								<ul className="large-margin-left">
									<li>how to use fertilizer application equipment</li>
									<li>the best management practices for applying fertilizer to turf</li>
									<li>the environmental risks associated with improper application of fertilizer</li>
									<li>procedures for preventing as well as responding to spills of fertilizers</li>
									<li>all the laws, rules, and regulations pertaining to the application of fertilizer to turf</li>
								</ul>
								<p>I agree that the Trained Fertilizer Applicator will be in direct supervision by me or a CFA from at all times during which the TFA is applying fertilizer in the state of New Jersey.</p>
							
								<div className="large-4 medium-12 small-12 columns">
									<label htmlFor="confirmTfa" className="label-style-1">I verify, attest, and agree to the statements above.   *</label>
								</div>
								<div className="large-8 medium-12 small-12  columns">
									<input type="checkbox" id="confirmTfa" name="confirmTfa" placeholder="" required="required" 
										value={tfaFormData.confirmTfa}
										checked={tfaFormData.confirmTfa}
										onChange={e => tfaForm_checkBox_HandleChange(e.target)}
									/>
								</div>
							</div>
						</div>
	*/

	return (
		<>
			<div className="medium-10 large-10 large-offset-1 medium-offset-1 columns">
				<div>
					<hr className="slim-hr" />
				</div>
				<div>
					{SetUpForm()}
				</div>
			</div>
			<div className="small-12 columns">
				<hr />
				<div>
					<button className="no-bottom-margin button primary small"
						onClick={ (e) => { confirmEditing('tfa-only') }}
					><i className="fas fa-angle-left"></i> Back To List</button>
				</div>
			</div>
		</>
	);
};

export default EditTFARecord;