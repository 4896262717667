import React, {
	useEffect,
	useState, 
	//useEffect
} from 'react';
import { 
	DECODE_TOKEN 
} from '../../../Api/api';
import { 
	//Redirect 
	useLocation
} from 'react-router-dom';




const ProcessPaymentFailed = (props) => {

	const [paymentId, paymentKey, summary]= [props.paymentId, props.paymentToken, props.summary];
	const query = new URLSearchParams(useLocation().search);
	console.log(paymentId, paymentKey, summary);



	const WARNING_REMINDER_NOTIFICATION = (
		<div className={"callout warning"}>
			<h5>As a reminder:</h5>	
			<p>
				If you see any errors during the payment process, please contact the ProFACT office (848-932-6373) immediately for support, 
					preferably while any error message is still on your screen.
			</p>
		</div>
	);

	const [processPayment, SetProcessPayment] = useState({
		isProcessing: true,
		hasError: false,
		errorCode: '',
		errorMsg: '',
		summary: {},
		setPaymentId: null,
		setPaymentConf: null
	});

	//Should only do this once.
	useEffect( () => {
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}
		
	}, [] );


	





	
	const GetProcessingState = () => {
		if( processPayment.isProcessing ) {
			const processPaymentItem = {
				...processPayment,
				isProcessing: false
			};
		  
			const transactionResultCode = query.get('transactionResultCode');
			const transactionResultMessage = query.get('transactionResultMessage');
			const orderNumber = query.get('orderNumber');
			const transactionId = query.get('transactionId');
			const extraInformation = [];

			if( orderNumber ){ extraInformation.push(`Order Number: ${orderNumber}`);}
			if( transactionId ){ extraInformation.push(`Transaction Id: ${transactionId}`);}

			if( extraInformation.length > 0 ) {
				processPaymentItem.extraInformation = <div>Please provide the additional information: <ul>{extraInformation.map( (extraInfo)=>{return <li>{extraInfo}</li>})}</ul></div>;
			}else{
				processPaymentItem.extraInformation =null;
			}

			
			console.log({transactionResultCode, transactionResultMessage});

			if( transactionResultCode ) {
				if( transactionResultCode === '2' ) {
					processPaymentItem.errorCode = 'Credit Card Rejection Code';
				}else if( transactionResultCode === '3' ) {
					processPaymentItem.errorCode = 'Credit Card Processing Error Code (3)';
				}else if( transactionResultCode === '4' ) {
					processPaymentItem.errorCode = 'Credit Card Processing Error Code (4)';
				}
			}else{
				processPaymentItem.errorCode = 'Payment Processing Error';
			}

			if( transactionResultMessage ) {
				processPaymentItem.errorMsg = transactionResultMessage;
			}else{
				processPaymentItem.errorMsg = 'There was an unknown problem processing your payment.';
			}

			SetProcessPayment(processPaymentItem);
		}

		return (<>
			{WARNING_REMINDER_NOTIFICATION}
			<div className={"callout alert "}>
				<h3> Error! </h3>
				<h4> {processPayment.errorCode} </h4>
				<p> {processPayment.errorMsg} </p>
				{processPayment.extraInformation}
			</div>
		</>);

	}


	return ( GetProcessingState() );
	/*
	return (<>
		<h3> Processing! </h3>
		<p><em>{paymentToken}</em></p>
	</>)
	*/
};

export default ProcessPaymentFailed;




