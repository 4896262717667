//
import React, { useRef, useState, useEffect } from "react";
import { DECODE_TOKEN, admin_update_single_credit } from "../../../../Api/api";



import { AXIOS_ERR, AXIOS_LOADING, AXIOS_OK, AXIOS_WAIT  } from "../../../NewYearsTasksPage";


/*
//this isn't correct; ignore for now
type CourseInfoType = 
{
creditId	5500
courseId	121
payId	null
examId	null
creditEarned	null
courseAvailable	"2024-07-10T14:14:41.000Z"
beginDate	"2024-07-10T14:14:41.000Z"
course	Object { title: "2022 ProFACT Recertification Webinar", description: "James Murphy, Brad Park", cost: 0, … }
}
*/

export default function UpdateCreditsInCourse ({courseInfo, callback=()=>{}}/*:{courseInfo: CourseInfoType, callback?:()=>void} */) {
	const popupRef = useRef/*<HTMLDivElement>*/(null);
	const [myPopup, setMyPopup] = useState(null);

	const [formInput, setFormInput] = useState/*<{creditEarned: number}> */({
		creditEarned: null
	})
	
	const [axiosState, setAxiosState] = useState/*<{isLoading: boolean, status: AXIOS_WAIT|AXIOS_OK|AXIOS_ERR|AXIOS_LOADING|> */({
		isLoading: false,
		state: AXIOS_WAIT
	});

	useEffect( () => { 
		setFormInput({
			creditEarned: courseInfo.creditEarned
		})
	}, [courseInfo])

	useEffect( () => {
		if( axiosState.state === AXIOS_LOADING ){
			const tokenInfo = DECODE_TOKEN();
			if( tokenInfo.code !== 'TOKEN_FOUND' ) {
				console.error('Did not find appropriate token.');
				setAxiosState({isLoading:false, state: AXIOS_ERR});
				return;
			}

			admin_update_single_credit( tokenInfo.getAuthToken, courseInfo.creditId, formInput.creditEarned ) 
			.then( (res) => {		
				if( res && res.data && res.data.code && res.data.code ==='OK') {
					setAxiosState({isLoading:false, state: AXIOS_OK});
				}else{
					setAxiosState({isLoading:false, state: AXIOS_ERR});
				}
			} )
			.catch( error => {

				console.error('Getting [admin_update_single_credit] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not update course.');
				}
				setAxiosState({isLoading:false, state: AXIOS_ERR});
			});
		}
	}, [axiosState, courseInfo.creditId, formInput.creditEarned] );

	
	useEffect( () => {
		if( popupRef.current ){
			const myA = new window.Foundation.Reveal( window.jQuery(popupRef.current) );
			setMyPopup(myA)
			//new Foundation.Reveal(myPopup.current)
		}
	}, [popupRef] );



	return <div>
		<div><button type="button" className="button small warning" onClick={()=>{
			myPopup.open()
		}}> <i className="fas fa-edit"></i>  </button></div>
		<div ref={popupRef} className="reveal" id="disable-tfas" data-reveal>
			{
				axiosState.state !== AXIOS_OK
				?
				<div>
					<h1>Update Course Credits for CFA</h1>
					
					<div>
						<label>
							Credits
							<input type="number" step={1} min={0}  
								value={formInput.creditEarned || ''} onChange={(event)=>{
									console.log('Changed....');
									setFormInput({creditEarned: event.target.value});
								}}
							/>
						</label>
						<p> Adding credits will place this course in the CFA&rsquo;s <em><strong>COMPLETED</strong></em> list. </p>
					</div>
					
					<p><em><strong>This action cannot be undone.</strong></em></p>
					{axiosState.state === AXIOS_ERR ? <p><em> There was a problem adding the course to the CFA. </em></p> : <></>}
					<button className="close-button" data-close aria-label="Close modal" type="button"
						onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							
						}}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div>
						<button className="button" onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							}}>
							Cancel
						</button> <button type="submit" className="button "
							disabled={axiosState.isLoading || undefined}
							onClick={()=>{
								setAxiosState({isLoading:true, state: AXIOS_LOADING});
							}}>
								{axiosState.isLoading ? 'Updating...'
								:"Update Course Info for CFA"
								}
						</button>
					</div>
				</div>
				:
				<div>
					<h1>Course updated successfully.</h1>
					<p className="lead">The course info was updated for the CFA.</p>
					<div>
						<button className="button" onClick={()=>{
							if( callback) { callback() }
							if( myPopup ) { myPopup.close(); }
							setAxiosState({isLoading:false, state: AXIOS_WAIT});
						}}>
							OK
						</button>
					</div>
				</div>
			}
		</div>	
	</div>
}

