import React from 'react';
import { Link } from 'react-router-dom';

import NJ_ACT_20 from '../cfa-files/NJ Act P.L. 2010 c.112 (C58 10A-64).pdf';

const BackgroundPage = (props) => {
//class BackgroundPage extends React.Component {
		//console.log('Background page props', props);
		return (
			<main id="main-wrapper" className="">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content" className="">
							<div id="primary-sub-col" className="large-12 columns">
								<h2>Back​​​​ground</h2>
<p>On January 5, 2011 the State of New Jersey enacted <a href={NJ_ACT_20} target="_blank"  rel="noopener noreferrer">New Jersey Act, ​P.L. 2010, c. 112 (C.58:10A-64)</a>. The Act ​was conceived to protect all New Jersey surface and groundwaters from impairment by minimizing nitrogen and phosphorus loading that may be derived from fertilizer.</p>
<p>The Act addresses the application, sale, and use of fertilizer for turf and distinguishes between retail fertilizer and professional applications. The Act does not apply to the application of fertilizer to commercial farms.</p>
<p>The Act also requires that a certification program for professional fertilizer applicators be established by the New Jersey Agricultural Experiment Station (NJAES) at Rutgers University in consultation with the Department of Environmental Protection (DEP). An outcome of that effort is this website, an online program for <strong>Professional Fertilizer Applicator Certification Training</strong> (<Link to="/">ProFACT.rutgers.edu</Link>).</p>

<p>A “professional fertilizer applicator” is defined as any individual who applies fertilizer for hire, including any employee of a government entity who applies fertilizer within the scope of employment. Professional fertilizer applicators are required to be trained in the following subject areas:</p>
<ol>
	<li>the proper use and calibration of fertilizer application equipment;</li>
    <li>the hazards involved in, and the environmental impact of, applying fertilizer, including nutrient pollution to the State’s waterbodies;</li>
    <li>all applicable State and federal laws, rules and regulations;</li>
    <li>the correct interpretation of fertilizer labeling information; and</li>
    <li>the best management practices developed by the NJAES for nutrient management in turf.</li>
</ol>	

<p>The Act allows two general classifications for professional fertilizer applicators: <strong>certified</strong> and <strong>trained</strong>.</p>
<p>For a professional to become <strong>certified</strong>, the individual must pass an exam to certify that they have sufficient knowledge of the laws, rules and regulations, standards and requirements applicable to the use and application of fertilizer. The exam is offered through this website. No person may take the certification exam until the applicant meets the established training standards; once registered with ProFACT.rutgers.edu, the website will document and track each user's progress in training modules and certification status.</p>
<p>A $90 fee will be charged to take the certification exam. Upon passing the exam, an annual certification card will be issued. Certification will be valid for 5 years as long as the professional registers annually ($40 fee) through the website. It will be required to re-certify as a <strong>Certified Fertilizer Applicator</strong> at the end of the 5 year certification cycle by one of two methods:</p>
<ol>
	<li>If an individual obtains <strong>8 continuing education credits</strong> during the 5 year cycle, the individual will simply move to the next cycle. Certified Fertilizer Applicators may receive continuing education credits for participating in NJAES-approved courses offered online or at industry meetings. These credits can be used to renew certification at the end of the 5-year certification cycle. Continuing education will address the subject areas described above. Qualified organizations and persons may provide continuing education courses if the materials meet the training and education standards of the certification program and are approved by NJAES.</li>
	<li>Those individuals that choose not to participate in continuing education during the 5 year cycle will be required to take the training and exam to be re-certified.</li>
</ol>

<p>For a professional to become <strong>trained</strong>, the individual must receive training on the laws, rules and regulations, standards and requirements applicable to the use and application of fertilizer by a Trained Fertilizer Applicator. <strong>Trained Fertilizer Applicators</strong> can only apply fertilizer under the direct supervision of a Certified Fertilizer Applicator. Annual training and registration with the ProFACT program ($40) will be required for Trained Fertilizer Applicators. Records for Trained Fertilizer Applicators will be deleted from the ProFACT website at the end of each calendar year (31 December). Therefore, Trained Fertilizer Applicators must be registered annually with the program.</p>
<p>By State law, Rutgers NJAES is required to maintain a list of all <strong>certified</strong> and <strong>trained</strong> fertilizer applicators and make the <Link to="/certified-applicators">list available on its internet website</Link>.</p>
<p>More details on the ProFACT program will follow as these become available. The New Jersey Fertilizer Law allows NJAES to:</p>
<ol>
    <li>charge reasonable fees, including, but not limited to, an annual re-certification fee, to cover costs associated with the certification and training programs;</li>
    <li>require continuing education or training for certified fertilizer applicators or for trained applicators who apply fertilizer only under the direct supervision of a certified professional fertilizer applicator;</li>
    <li>designate one or more qualified organizations to train, certify, and recertify professional fertilizer applicators and provide that a designated organization may charge fees to cover reasonable costs associated with the certification training and education;</li>
    <li>designate one or more qualified organizations to train professional fertilizer applicators who will apply fertilizer only under the direct supervision of a professional fertilizer applicator certified pursuant to this section and provide that a designated organization may charge fees to cover reasonable costs associated with the training process;</li>
    <li>recognize the training program of any person employing professional fertilizer applicators if it meets the certification and recertification training and education standards established by the program.</li>
</ol>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
}

export default BackgroundPage;