import React,  
	{ useState, useEffect } 
from 'react';
//import { cfaList } from '../Api/Json/static';
//import { Link } from 'react-router-dom';
//import { get_cfa_public_profile } from '../Api/api';
import {get_tfa_public} from '../Api/api';
//import moment from 'moment-timezone';

import {FormatPhoneNumber, FormatZipcode} from '../Config';
//import cfaList from  '../JsonApi/cfa-list.js';

const CFAPublicProfilePage = ( props ) => {

	document.title = props.title;
	const [tfa, SetPublicTFA] = useState({
		isLoading:true,
		isError:false,
		name: '',
		tId: props.match.params.T_CODE,
		business: '',
		street: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
		//certDate: '',
		//lastModif: ''
	});

	useEffect(() => {
		if( !props.match.params.T_CODE ) {
			SetPublicTFA({
				isLoading:false,
				isError:true
			});
		}else{
			let tId = Number( props.match.params.T_CODE.substr(1));
			get_tfa_public()
				.then( (res) => {
					console.log('Retrieving TFA Public List', res);
					if( res.status && res.status !== 200 ) {
						throw res; 
					}
					const myTfa = res.data.rawTfas.find( (tfaInfo) => { return tfaInfo['tfas.id'] === tId;});
					SetPublicTFA({
						isLoading:false,
						isError:false,
						name: myTfa['tfas.firstName'] + ' ' + myTfa['tfas.lastName'],
						tId: props.match.params.T_CODE,
						business: myTfa.businessName,
						street: myTfa['businessAddress.street'],
						city: myTfa['businessAddress.city'],
						state: myTfa['businessAddress.state'],
						zip: myTfa['businessAddress.zip'],
						phone: myTfa['businessAddress.phone'],
						/*
						certDate:  moment.tz(myCfa.certDate,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
							.tz( moment.tz.guess() )
							.format('LLL')
						*/
						
					});
				} )
				.catch( error => {
					if( error.statusCode && error.statusCode === 500) {
						console.error('Could not fetch TFA Public List. ');
					}
					SetPublicTFA({
						isLoading:false,
						isError:true
					});
				});
				
		}
		

	}, [props.match.params.T_CODE]);


	const GetProfile = () => {
		if( tfa.isError ) {
			return (
				<p>We're sorry, but we could not find the Training Number. Please refresh the page and try again. If this is an error, please contact an administrator.</p>
			);
		}
		return ( 
			<>
				<h3><strong>{tfa.name}</strong>, under the direct supervision of a Certified Fertilizer Applicator (CFA), may apply fertilizer to turf. </h3>
​​​​​​​​​​​​​​​​​​​​				<dl>
					<dt>Name</dt>
						<dd>{tfa.name}</dd>
					<dt>Training ID</dt>
						<dd>{tfa.tId}</dd>
					<dt>Business</dt>
						<dd>{tfa.business}</dd>
					<dt>Business Address</dt>
						<dd>
							<p className="no-bottom-margin">{tfa.street}</p>
							<p className="no-bottom-margin">{tfa.city}, {tfa.state} {FormatZipcode(tfa.zip)} </p>
						</dd>
					<dt>Business Phone</dt>
						<dd>{FormatPhoneNumber(tfa.phone)}</dd>
				</dl>
			</>
		);

	}
	/*
					<dt>Date Certified</dt>
						<dd>{cfa.certDate}</dd>
	*/

	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h1>Trained Fertilizer Applicator Details​​</h1>
						{GetProfile()}
					</div>
				</div>
			</div>
		</main>
	);

}

export default CFAPublicProfilePage;