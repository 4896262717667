import React from 'react';
import QRCode from 'qrcode.react';

import PRINT_CARD_BKGRND_IMG from '../cfa-files/RU_SIG_NJAES_864x338.png';
import Config from '../Config';
import {FormatPhoneNumber, FormatZipcode} from '../Config';

const CFACertificate = (props) => {
	document.getElementsByTagName( 'html' )[0].classList.add('has-certificate');

	//Not completly working when opening with a new tab
	//const currentYear = new Date().getFullYear();
	
	//Waiting to check login
	if( !props.UserState || !props.UserState.user ) {
		//console.log(props);
		return (<></>);
	}
	//console.log(props);
	const [CFA_ID, firstName, lastName, businessName, 
		street,
		city,
		state,
		zip,
		phone,
		activeYear] = [
		//('C' + props.UserState.user.id.toString().padStart(6, '0'))
		props.UserState.user.profactId
	, props.UserState.user.firstName
	, props.UserState.user.lastName
	, props.UserState.user.businessName


	, props.UserState.user.businessAddress.street
	, props.UserState.user.businessAddress.city
	, props.UserState.user.businessAddress.state
	, FormatZipcode(props.UserState.user.businessAddress.zip)
	, FormatPhoneNumber(props.UserState.user.businessAddress.phone)

	, props.UserState.user.activeYear
];

	return(
		<>
			<div style={
				{
					width:'3.375in',
					height:'2.125in',
					border:'thin black dotted',
					fontSize:'8pt',
					fontFamily:'Arial, Helvetica, sans-serif',
					backgroundRepeat:'no-repeat',
					backgroundPosition:'.05in 1.2in',
					backgroundSize:'2.25in .875in',
					backgroundImage:`url(${PRINT_CARD_BKGRND_IMG})`,
					margin:'1em'
				}
			}	
			
				
			>
				<p style={
					{
						backgroundColor:'black',
						color:'white',
						textAlign:'center',
						padding:'0in .1in .05in .1 in',
						fontWeight:'bold',
						textTransform:'uppercase',
						marginTop:'0'
					}
				}>
					New Jersey<br />
					{activeYear} Certified Fertilizer Applicator
				</p>
				<p style={
					{
						margin:'.1in',
						textTransform:'uppercase'
					}
				}>
					<QRCode style={{float:'right'}} renderAs={'svg'} size={115} 
						value={`${Config.WEBSITE_URL}${Config.BASENAME}cfa/${props.UserState.user.id}`} 
						alt="ProFACT CFA QR Code" />
					{firstName} {lastName}
					<br />
					{businessName}
					<br />
					<span style={
						{fontSize:'6pt'}
					}>
						{street}
						<br />
						{city}, {state} {zip}
					</span>
					<br />
					<span style={
						{fontSize:'6pt'}
					}>
						Phone: {phone}
					</span>
					<br />
				</p>
				<div style={{
					position:'absolute',
					top:'1.875in',
					left:'.2in'
				}}>
					Certified ID#: {CFA_ID}
					<br />
					Expires: 12/31/{activeYear}
				</div>
				<div style={
					{
						position:'absolute',
						top:'1.88in',
						left:'2.1in',
						textAlign:'right'
					}
				}>
					ProFACT.Rutgers.edu
					<br />
					<span style={{
						fontSize:'6pt'
					}}>
						New Jersey Act, P.L. 2010, c.112
					</span>
				</div>
			</div>
		</>
	);
	
};

export default CFACertificate;



//Trash zone
	/*xmlns:x="http://www.w3.org/2001/XMLSchema" 
				xmlns:d="http://schemas.microsoft.com/sharepoint/dsp"
				xmlns:asp="http://schemas.microsoft.com/ASPNET/20" 
				xmlns:__designer="http://schemas.microsoft.com/WebParts/v2/DataView/designer" 
				xmlns:sharepoint="Microsoft.SharePoint.WebControls" 
				xmlns:ddwrt2="urn:frontpage:internal"
				*/

				//<div style={"width:3.375in;height:2.125in;border:thin black dotted;font-size:8pt;font-family:Arial, Helvetica, sans-serif;background-image:url('../PublishingImages/RU_SIG_NJAES_864x338.png');background-repeat:no-repeat;background-position:.05in 1.2in;background-size:2.25in .875in;" 
				/*
<div style="
	width:3.375in;height:2.125in;border:thin black dotted;font-size:8pt;
	font-family:Arial, Helvetica, sans-serif;background-image:url('../PublishingImages/RU_SIG_NJAES_864x338.png');
	background-repeat:no-repeat;background-position:.05in 1.2in;background-size:2.25in .875in;" 
	xmlns:x="http://www.w3.org/2001/XMLSchema" xmlns:d="http://schemas.microsoft.com/sharepoint/dsp"
	xmlns:asp="http://schemas.microsoft.com/ASPNET/20" xmlns:__designer="http://schemas.microsoft.com/WebParts/v2/DataView/designer" 
	xmlns:sharepoint="Microsoft.SharePoint.WebControls" xmlns:ddwrt2="urn:frontpage:internal">

	<p style="background-color:black;color:white;text-align:center;padding: 0in .1in .05in .1 in;font-weight:bold;text-transform:uppercase;margin-top:0">
		New Jersey <br />
		2020 Certified Fertilizer Applicator
	</p>
	<p style="margin:.1in;text-transform:uppercase">
		<img src="http://qrcode.kaywa.com/img.php?s=3&amp;d=http%3A%2F%2Fprofact.rutgers.edu%2FPages%2FCFA.aspx%3FCID%3D7221" 
			alt="ProFACT qrcode" style="float: right" />
		Lucas&nbsp;Marxen<br>
		Rutgers University<br>
		<span style="font-size:6pt">
			88 Lipman Drive<br>New Brunswick&nbsp;NJ&nbsp;08901
			<br />
		</span>
		<br />
	</p>
	<div style="position:absolute;top:1.875in;left:.2in">
		Certified ID#: C007221
		<br />
		Expires: 12/31/2020
	</div>
	<div style="position:absolute;top:1.88in; left:2.1in; text-align:right">
		ProFACT.Rutgers.edu
		<br>
		<span style="font-size:6pt">
			New Jersey Act, P.L. 2010, c.112
		</span>
	</div>
</div>
				*/
/*			
	return (
		<>
			<h3>NYI_CERT</h3>
			{PRINT_CARD_BKGRND_IMG}
			<p>{firstName} {lastName}</p>
			<QRCode value="http://profact.rutgers.edu/Pages/CFA.aspx?CID=7221" />
		</>
	);	
	*/