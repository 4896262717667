//
import React, { useRef, useState, useEffect } from "react";
import { DECODE_TOKEN, admin_remove_single_credit } from "../../../../Api/api";



import { AXIOS_ERR, AXIOS_LOADING, AXIOS_OK, AXIOS_WAIT  } from "../../../NewYearsTasksPage";


/*
type CourseList = {
	courseId	6
	courseTitle	"Previous Profact System"
	description	"This user had valid credits on the old profact system (pre-2021)."
	cost	0
	credits	0
	numSections	0
	numExamQuestions	0
	examTimeLimit	0
	passingScore	70
	beginDate	"2020-12-14T05:00:00.000Z"
}
*/

/*
//send data
type CourseDataPacket = {
	id: int,
	courseId: int, required,
	userId: int, required,
	creditEarned: int,
	examId: int,
	payId: int
}

*/

export default function DeleteCourseFromCfa ({creditId, title="No Title", callback=()=>{}}/*:{creditId: number, title?: string, callback?:()=>void} */) {
	const popupRef = useRef/*<HTMLDivElement>*/(null);
	const [myPopup, setMyPopup] = useState(null);


	
	const [axiosState, setAxiosState] = useState/*<{isLoading: boolean, status: AXIOS_WAIT|AXIOS_OK|AXIOS_ERR|AXIOS_LOADING|> */({
		isLoading: false,
		state: AXIOS_WAIT
	});


	useEffect( () => {
		if( axiosState.state === AXIOS_LOADING ){
			const tokenInfo = DECODE_TOKEN();
			if( tokenInfo.code !== 'TOKEN_FOUND' ) {
				console.error('Did not find appropriate token.');
				setAxiosState({isLoading:false, state: AXIOS_ERR});
				return;
			}

			
			admin_remove_single_credit( tokenInfo.getAuthToken, creditId ) 
			.then( (res) => {		
				if( res && res.data && res.data.code && res.data.code ==='OK') {
					setAxiosState({isLoading:false, state: AXIOS_OK});
				}else{
					setAxiosState({isLoading:false, state: AXIOS_ERR});
				}
			} )
			.catch( error => {

				console.error('Getting [admin_get_all_courses] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not revoke cfas.');
				}
				setAxiosState({isLoading:false, state: AXIOS_ERR});
			});
		}
	}, [axiosState, creditId] );

	
	useEffect( () => {
		if( popupRef.current ){
			const myA = new window.Foundation.Reveal( window.jQuery(popupRef.current) );
			setMyPopup(myA)
			//new Foundation.Reveal(myPopup.current)
		}
	}, [popupRef] );



	return <div>
		<div><button type="button" className="button small alert" onClick={()=>{
			myPopup.open()
		}}> <i className="fas fa-trash"></i>  </button></div>
		<div ref={popupRef} className="reveal" id="disable-tfas" data-reveal>
			{
				axiosState.state !== AXIOS_OK
				?
				<div>
					<h1>Remove Course from CFA</h1>
					<p className="lead">This will remove the course [{title}] from the CFA.</p>
					<p><em><strong>This action cannot be undone.</strong></em></p>
					{axiosState.state === AXIOS_ERR ? <p><em> There was a problem adding the course to the CFA. </em></p> : <></>}
					<button className="close-button" data-close aria-label="Close modal" type="button"
						onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							
						}}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div>
						<button className="button" onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							setAxiosState({isLoading:false, state: AXIOS_WAIT});
							}}>
							Cancel
						</button> <button type="submit" className="button "
							disabled={axiosState.isLoading || undefined}
							onClick={()=>{
								setAxiosState({isLoading:true, state: AXIOS_LOADING});
							}}>
								{axiosState.isLoading ? 'Removing...'
								:"Remove Course and Credits from CFA"
								}
						</button>
					</div>
				</div>
				:
				<div>
					<h1>Course removed successfully.</h1>
					<p className="lead">The course was removed from the CFA.</p>
					<div>
						<button className="button" onClick={()=>{
							if( callback) { callback() }
							if( myPopup ) { myPopup.close(); }
							setAxiosState({isLoading:false, state: AXIOS_WAIT});
						}}>
							OK
						</button>
					</div>
				</div>
			}
		</div>	
	</div>
}

