import React, {
	useEffect,
	useState, 
	//useEffect
} from 'react';
import { 
	retrieve_pending_online_payment_by_payid,
	//payment_cfa_history,
	//payment_cfa_exam,
	//payment_cfa_tfas,
	//payment_cfa_course,
	//payment_cfa_renewal,

	DECODE_TOKEN 
} from '../../../Api/api';

/*
import { Link
} from 'react-router-dom';
*/
import moment from 'moment-timezone';
/*
import { Redirect } from 'react-router-dom';
import { VERIFY_TOKEN } from '../../../Api/schoolpay.api';
*/


const Receipt = (props) => {

	const [payId, confNumber]= [props.paymentId, props.confNumber];
	//const [paymentHistoryTable, setPaymentHistoryTable] = useState([]);
	const [contentInfo, SetContentInfo] = useState( {
		isLoading: true,
		hasError: false,
		paymentInfo: {}
	})
	
	const WARNING_REMINDER_NOTIFICATION = (
		<div className={"callout warning"}>
			<h5>As a reminder:</h5>	
			<p>
				If you see any errors during the payment process, please contact the ProFACT office (848-932-6373) immediately for support, 
					preferably while any error message is still on your screen.
			</p>
		</div>
	);

	
	const GetPaymentHistory = (payId) => {
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		console.log('Getting payment history.');
		retrieve_pending_online_payment_by_payid(tokenInfo.getAuthToken,  payId)
		.then( (res) => {

			if( res.data.code==='PAYMENT_BY_ID') {
				SetContentInfo({
					isLoading: false,
					hasError: false,
					paymentInfo: res.data.data
				});
			}else{
				throw new Error(res.data);
			}
			//console.log(res);
			/*
			if( res.data.history && res.data.code === 'CFA_PAYMENT_HISTORY') {
				//create book
				SetContentInfo({
					isLoading: false,
					hasError: false,
					paymentInfo: res.data.history.find( (payInfo) => {
						return payInfo.payConf === confNumber;
					})
				});
				//setPaymentHistoryTable(res.data.history);
			}else{
				throw new Error(res.data);
			}
			*/
		} )
		.catch( error => {
			console.error('Getting CFA\'s history failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Payment History. ');
			}
				SetContentInfo({
					isLoading: false,
					hasError: true,
					paymentInfo: null
				});
			//setPaymentHistoryTable(null);
		});
	}

	useEffect( () => {
		GetPaymentHistory(payId/*confNumber*/)
	}, [payId/*confNumber*/] );

	const GET_RECEIPT = () => {
		if( contentInfo.isLoading ) {
			return ( <h3> Loading... </h3>)
		}
		if( contentInfo.hasError ) {
			return ( <h3> There was a problem loading this receipt. </h3>)
		}

		console.log('Got some payment info! ', contentInfo.paymentInfo);
		
		const myShoppingCart = JSON.parse(contentInfo.paymentInfo.cart);
		const myCart = (
			myShoppingCart.items ?
			myShoppingCart.items
			: myShoppingCart
		);
		

		if( contentInfo.paymentInfo.payPurpose === 'exam' ) {
			
			return (<>
				<h4>Confirmation Number: {confNumber}</h4>
				<h5>Placed: {moment(contentInfo.paymentInfo.createdAt,'YYYY-MM-DDTHH:mm:ss.SSSZ').format('LLLL')} </h5>
				{
					contentInfo.paymentInfo.schoolPayReturn ? 
					<></>: 
					<p>As soon as we get confirmation from school pay, you will be able to take the CFA Exam</p>
				}
				<ul>
					<li>{myCart[0].title}: {myCart[0].amount.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})}</li>
				</ul>
			</>);
		}else if( contentInfo.paymentInfo.payPurpose === 'tfa' ) {
			let total=0.0;
			return (<>
				<h4>Confirmation Number: {confNumber}</h4>
				{
					new Date().getMonth() === 11 //December, Jan =0
					? <p><em>Note: These TFAs will be appear on the public TFA List starting next year.</em></p>
					: <></>
				}
				<h5>Placed: {moment(contentInfo.paymentInfo.createdAt,'YYYY-MM-DDTHH:mm:ss.SSSZ').format('LLLL')} </h5>
				{
					contentInfo.paymentInfo.schoolPayReturn ? 
					<></>: 
					<p>As soon as we get confirmation from school pay, your TFAs will appear in the Trained Applicators List on the public page. </p>
				}
				<ul>
					{myCart.map( (tfaInfo) => {
						//console.log(tfaInfo);
						total+=Number(tfaInfo.amount);
						return (<li>{tfaInfo.title}: {tfaInfo.amount.toLocaleString('en-US', {
							style: 'currency',
							currency: 'USD',
							})}</li>)
					})}
				</ul>
				<p><strong> Total: {total.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})} </strong></p>
			</>);
		}else if( contentInfo.paymentInfo.payPurpose === 'renewal' ) {
			let total=0.0;
			return (<>
				<h4>Confirmation Number: {confNumber}</h4>
				<h5>Placed: {moment(contentInfo.paymentInfo.createdAt,'YYYY-MM-DDTHH:mm:ss.SSSZ').format('LLLL')} </h5>
				{
					contentInfo.paymentInfo.schoolPayReturn ? 
					<></>: 
					<p>As soon as we get confirmation from school pay, your renewal year will be active. </p>
				}
				<ul>
					{myCart.map( (renewInfo) => {
						//console.log(renewInfo);
						total+=Number(renewInfo.amount);
						return (<li>{renewInfo.title}: {renewInfo.amount.toLocaleString('en-US', {
							style: 'currency',
							currency: 'USD',
							})}</li>)
					})}
				</ul>
				<p><strong> Total: {total.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})} </strong></p>
			</>);
		}else if( contentInfo.paymentInfo.payPurpose === 'course' ) {
			return (<>
				<h4>Confirmation Number: {confNumber}</h4>
				<h5>Placed: {moment(contentInfo.paymentInfo.createdAt,'YYYY-MM-DDTHH:mm:ss.SSSZ').format('LLLL')} </h5>
				{
					contentInfo.paymentInfo.schoolPayReturn ? 
					<></>: 
					<p>As soon as we get confirmation from school pay, your course exam and / or credits will be available. </p>
				}
				<ul>
					<li>{myCart[0].title}: {myCart[0].amount.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})}</li>
				</ul>
			</>);
		}


		//console.log(contentInfo.paymentInfo);

		return (<p><em>{confNumber}</em></p>);
	}
	return (<>
		<h3> Receipt </h3>
		{WARNING_REMINDER_NOTIFICATION}
		{GET_RECEIPT()}
		
	</>);
};

export default Receipt;