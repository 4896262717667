
import React, { useRef, useState } from "react";
import JoditEditor from 'jodit-react';
import sanitizeHtml from 'sanitize-html';
import { DECODE_TOKEN, MASS_MAILER_JSON } from "../../../Api/api";






export default function NyeEmailUsers ({cfaIds=[], callback=(refresh=false)=>{}}/*:{cfaIds:Array<number>, callback?:(refresh:boolean)=>void}*/) {
	
	const [disableMailBtn, SetDisableMailBtn] = useState(false);
	const [emailAlert, SetEmailAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
    
	const [emailInfo, SetEmailInfo] = useState({
		recipients: 'SPECIFIC_PEOPLE',
		subject: '',
		message: '',
		attachment: null,
		cfaIds,
	});
	//const fileInput = useRef(null);
	const formElement = useRef(null);

	//JODIT config
	const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

	const joditEditor = useRef(null);
	const joditConfig = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
		, 
		uploader: {
		  "insertImageAsBase64URI": true
		},
        toolbarAdaptive: false,
		buttons: [
			'source',
			'|',
			'bold',
			'strikethrough',
			'underline',
			'italic',
			'eraser',
			'|',
			'superscript',
			'subscript',
			'|',
			'ul',
			'ol',
			'|',
			'outdent',
			'indent',
			'|',
			'font',
			'fontsize',
			'brush',
			'paragraph',
			'|',
			'align',
			'undo',
			'redo',
			'\n',
			'selectall',
			'cut',
			'copy',
			'paste',
			'copyformat',
			'|',
			'link',
			'hr',
			'symbol',
			'fullsize',
			'print',
			'preview',
			'find',
			'about'
		],
		askBeforePasteFromWord: false,
		askBeforePasteHTML: false,
		events: {
			//change: () => {}, 
			//change: (a,b) => { console.log('CHANGE', a,b); setPrepHtml(a); },
			blur: (event) => {
				console.log('Blur event: ', event); 
				if( event.relatedTarget ) {
					return;
				}
			 	SetEmailContent(event.target.innerHTML); /* console.log(joditEditor);*/ 
			}
		}
	}



	const SetEmailContent = ( emailContent ) => {
		console.log(emailContent);
		SetEmailInfo({
			...emailInfo,
			message: sanitizeHtml(emailContent,{
				allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'span','p', 'img', 'table' ]),
				transformTags: {
					'a': function(tagName, attribs) {
						return {
							tagName: tagName,
							attribs: {
								...attribs,
								href: withHttp(attribs.href)
							}
						}
					}
				},        
				allowedAttributes: {
					...sanitizeHtml.defaults.allowedAttributes,
					'p': ["style"],
					'span': ["style"],
					'h1': ["style"],
					'h2': ["style"],
					'h3': ["style"],
					'h4': ["style"],
					'h5': ["style"],
					a: [ 'href', 'name', 'target', 'rel' ]
				},
				allowedSchemesByTag: {
					...sanitizeHtml.defaults.allowedSchemesByTag,
					img: [ 'data' ],
				},
				allowedStyles: {
					'*': {
						// Match HEX and RGB
						'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
						'text-align': [/^left$/, /^right$/, /^center$/],
						// Match any number with px, em, or %
						'font-size': [/^\d+(?:px|em|%|rem)$/]
					},
				}
			})
		});
	}

	const ResetEmailForm = ( ) => {
		ClearAllControls();
		formElement.current.reset();
	}
	const ClearAllControls = () => {
		SetEmailInfo({
			...emailInfo,
			message: '',
            subject:'',
		});
        joditEditor.current.value='';
	}
	const HandleEmailChange = (e_target) => {
		const { name, value } = e_target;
		//console.log('Updating {name, value}', { name, value })
		SetEmailInfo({
			...emailInfo,
            [name]: value
		})
	}

	const submitEmailInformation = (e) => {
		e.preventDefault();
        
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		console.log('SUBMITTING EMAIL: ', e);
        

		const myMessage = sanitizeHtml(joditEditor.current.value, {
			allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'span','p', 'img', 'table' ]),
			transformTags: {
				'a': function(tagName, attribs) {
					return {
						tagName: tagName,
						attribs: {
							...attribs,
							href: withHttp(attribs.href)
						}
					}
				}
			},        
			allowedAttributes: {
				...sanitizeHtml.defaults.allowedAttributes,
				'p': ["style"],
				'span': ["style"],
				'h1': ["style"],
				'h2': ["style"],
				'h3': ["style"],
				'h4': ["style"],
				'h5': ["style"],
				a: [ 'href', 'name', 'target', 'rel' ]
			},
			allowedSchemesByTag: {
				...sanitizeHtml.defaults.allowedSchemesByTag,
				img: [ 'data' ],
			},
			allowedStyles: {
				'*': {
					// Match HEX and RGB
					'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
					'text-align': [/^left$/, /^right$/, /^center$/],
					// Match any number with px, em, or %
					'font-size': [/^\d+(?:px|em|%|rem)$/]
				},
			}
		});
        console.log(
            'email',
            {
				...emailInfo,
				message: myMessage
			}
        );
        
        
		MASS_MAILER_JSON( 
			tokenInfo.getAuthToken, 
			{
				...emailInfo,
				message: myMessage
			}
		)
		.then( (res) => {
			console.log('Sending some emails: ', res.data);

			if(res.data.code === 'OK' ) {
				SetEmailAlert({
					type: 'success',
					display: 'block',
					title: 'Email sent',
					message: <div>The email has been sent out. A copy of the email you have sent will be sent to profact@sebs.rutgers.edu.
<div><button type="button" 
                        className="button primary"
                        onClick={ (e) => {
                            if(callback){callback(true)};
                        }}
                        >Go Back </button> 
</div>
                    </div>
				});
				ResetEmailForm();
			}else{
				SetEmailAlert({
					type: 'alert',
					display: 'block',
					title: 'Problem sending out an email',
					message: `There was a problem sending this email out: ${res.data.message}`
				});
			}
			SetDisableMailBtn(false);
		} )
		.catch( error => {
			console.error('Auto (Adding / Updating News Item)', error);
			SetDisableMailBtn(false);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Auto (Adding / Updating News Item)');
			}
		});


	}


    if( cfaIds.length < 1 ) { 
        return <p><em>No CFAs selected.</em> <button type="button" onClick={()=>{if(callback){callback(false)}}}>Go Back</button></p>
    }
	return <form ref={formElement} action="post" encType="multipart/form-data"
	 onSubmit={submitEmailInformation}>
        <div className={"callout " + emailAlert.type} style={{display:emailAlert.display}} data-closable>
            <h5>{emailAlert.title}</h5>
            <p>{emailAlert.message}</p>
            <button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
                window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
                    SetEmailAlert({
                        type: 'success',
                        display: 'none',
                        title: '',
                        message: ''
                    })
                });
            }}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <p><strong>Sending Email to {cfaIds.length} CFA(s)</strong></p>
        <hr />
        <h3> Email Message </h3>
        <div>
            <div className="row">
                <div className="medium-3 small-12 columns">
                    <label htmlFor="subject" className="label-style-1">Subject: </label>
                </div>
                <div className="medium-5 small-12 columns no-float">
                    <input type="text" 
                        id="subject" 
                        name="subject"
                        placeholder="Email Subject" 
                        onChange={(e) => {HandleEmailChange(e.target);} }
                        required="required"
                    />
                </div>
            </div>
            <div className="row">
                <div className="medium-3 small-12 columns">
                    <label htmlFor="message" className="label-style-1">Message: </label>
                </div>
                <div className="medium-8 small-12 columns no-float">
                    <JoditEditor
                        required="required"
                        ref={joditEditor}
                        //value={emailInfo.message}
                        config={joditConfig}
                        tabIndex={1} // tabIndex of textarea
                        //onBlur={newContent => setNewsItem(newContent)} // preferred to use only this option to update the content for performance reasons
                        //onChange={newContent => {}}
                    />
                </div>
            </div>
            <div className="row" style={{marginTop:'1em'}}>
                <div className="medium-12 small-12 columns text-align-right">
                <button type="button" 
                        className="button primary"
                        onClick={ (e) => {
                            if(callback){callback()};
                        }}
                        >CANCEL </button> <button type="reset" 
                        className="button primary"
                        onClick={ (e) => {
                            ClearAllControls();
                        }}
                        >Clear </button> <button type="submit" 
                        className="button success override-sebs"
                        disabled={disableMailBtn}
                        
                        ><i className="fas fa-paper-plane"></i> Send Email</button>
                </div>
            </div>
        </div>
	</form>
}

