import React from 'react';
//import Config from '../Config';
import AdminProfilePage from './Components/ProfileComponents/AdminProfilePage';
import CFAProfilePage from './Components/ProfileComponents/CFAProfilePage';


const ProfilePage = (props) =>{

	const userState = props.UserState;
	const setUserState = props.SetUserState;
	document.title = props.title;
	
	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
				{(
						userState.user.userType === 'A' ?
						<AdminProfilePage 

							UserState={userState} 
							SetUserState={setUserState}
						/>
						: <CFAProfilePage UserState={userState} 
						SetUserState={setUserState}/>
				)}
				</div>
			</div>
		</main>
	);
}
export default ProfilePage;