import React from 'react';
import {
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import Config from '../Config';
//import { DECODE_TOKEN, CHECK_LOGIN_STATUS} from '../Api/api';

const AuthRoute = ({component: Component, ...rest }) => {
	const location = useLocation();
	const redirStr = `/login${location.pathname}`;
	
	const isAuthorized = (props) => {
		//console.log('Checking Authorization...', rest.UserState.loggedIn, rest.UserState.refreshData, rest.UserState.lastRefreshPage, window.location.pathname);
		if( 
			rest.UserState.loggedIn === undefined 
			|| rest.UserState.loggedIn === null 
		) {
			console.log('Unknown user status. Waiting...');
			return null;
		}

		if( 
			
			(
				(rest.UserState.lastRefreshPage !== window.location.pathname)
				&& window.location.pathname === `${Config.BASENAME}/dashboard`
			)
			|| rest.UserState.refreshData 
		) {
			console.log('Requiring a refresh (May have just logged back in.) ');
			window.location.reload();
			return ( <>
				<main id="main-wrapper" className="">
					<div id="main" className="">
						<div id="main-col" className="">
							<div id="content">
								<p><i className="fas fa-circle-notch fa-spin" > </i> <em> Please wait...</em></p>
							</div>
						</div>
					</div>
				</main>
			</>)
		}

		//console.log('Authorization okay!', rest.UserState );

		if( rest.UserState.loggedIn ) {
			if( rest.AllowedUsers && Array.isArray( rest.AllowedUsers ) ) {
				if( rest.AllowedUsers.length > 0 ) {
					if( rest.AllowedUsers.find((element) => { return element === rest.UserState.user.userType; }) !== undefined ) {
						return <Component {...props}  UserState={rest.UserState} SetUserState={rest.SetUserState} title={rest.title} />
					}else{
						//Not allowed to view page?
						return <Redirect push to={'/dashboard'} />;
					}
				}else{
					//Is loggged in and there are no other permissions on this page.
					if( Config.ALLOW_EMPTY_PERMISSIONS ) {
						return <Component {...props}  UserState={rest.UserState} SetUserState={rest.SetUserState} title={rest.title} />
					}
				}
			}else{
				if( Config.ALLOW_EMPTY_PERMISSIONS ) {
					return <Component {...props}  UserState={rest.UserState} SetUserState={rest.SetUserState} title={rest.title} />
				}else{

				}
			}
		}
		
		//console.log('AUTH ROUTE', rest, location);
		//console.log('Redirecting to: ', redirStr);
		return <Redirect push to={redirStr} />;
	}

	return(
		<Route {...rest} render={(props) =>  {
				return isAuthorized(props);
			}
		} />
	);
};

export  default AuthRoute;