import React, {useState, useRef} from 'react';
//import Config from '../Config';
import {GET_DEVELOPER_VARS} from '../Api/schoolpay.api';

import { NELNET_TEST_HASH, GO_TO_NELNET_URL } from '../Api/nelnet.api';

import JoditEditor from 'jodit-react';
//import ReactQuill from 'react-quill';
import QRCode from 'qrcode.react';
//import 'react-quill/dist/quill.snow.css';
import sanitizeHtml from 'sanitize-html';
//var QRCode = require('qrcode.react');
import axios from 'axios';
import Config from '../Config';
import {get_cfa_training_completion, GET_API_COURSE} from '../Api/api';

const DeveloperPage = (props) => {
	
	document.title = props.title;

	const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

	const [newsItem, setNewsItem] = useState('');
	const [newsTitleToDelete, setDeleteNewsTitle] = useState('');
	const [schoolPayJson, setSchoolPayJson] = useState({});
	const schoolPayPOST = {
		action: 'https://payserv.scholarchip.com/api/sso/open',
		version:5,
		items:[ 
			{"amount":"0.02","code":"Sdvb8dL","quantity":"1","title":"Test SP Checkout Item (Sdvb8dL) From Document"},
			//{"amount":"1.00","code":"Scj4sth","quantity":"1","title":"Test SP Checkout Item ( Sb9n6Od ) From payserve"},
			//{"amount":"1.00","code":"S9CMPD","quantity":"1","title":"Test SP Checkout Item ( S9CMPD ) From payserve"},  
			//{"amount":"25.00","code":"Sdvb8dL","quantity":"1","title":"Test SP Checkout Item (Sdvb8dL) From Document"},
			//{"amount":"30.00","code":"Sb9n6Od","quantity":"1","title":"Test SP Checkout Item (Sb9n6Od)"},
			//{"amount":"40.00","code":"does_not_exist","quantity":"1","title":"Test SP Checkout Item (DNE)"}
		],
		return_url:'http://requestb.in/1dge8101',
		notification_url:'http://www.google.com/',
		provider:'ruprofact',
		student_id: 'N/A',
	}
	
	const TestNelnetHash = () => {
		if( NELNET_TEST_HASH() ) {
			console.log('Hash works' );
		}else{
			console.error('Something went wrong.');
		}

	}


	const getCfaTrainingApi = () => {
		
		let getAuthToken = null;
		let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(e){
			return false;
		}
		const courseId = 1;
		console.log('[getCfaTrainingApi]', getAuthToken, getId) ;
		get_cfa_training_completion(getAuthToken, getId.id, courseId)
			.then(function(response){
				console.log('Current user CFA Training Completion.', response);
			})
			.catch(function(error){
				console.error('Failed to update this user\'s poassword details.', error);
			});




	}
	const API_COURSE = () => {
		
		let getAuthToken = null;
		let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(e){
			return false;
		}
		const courseId = 5;
		console.log('[API_COURSE]', getAuthToken, getId) ;
		GET_API_COURSE(getAuthToken, courseId)
			.then(function(response){
				console.log('Current user CFA Training Completion.', response);
			})
			.catch(function(error){
				console.error('Failed to update this user\'s poassword details.', error);
			});




	}
    const addNewsItem = () => {
		
		setNewsItem(  sanitizeHtml(newsItem,{
			allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'table' ]),
			transformTags: {
			  'a': function(tagName, attribs) {
				  return {
					  href: withHttp(attribs.href)
				  };
			  }
			},
			allowedSchemesByTag: {
				img: [ 'data' ]
			}
		}) );

		axios.create( Config.axiosConfig )
			.post( '/api/news',
			{
				title: 'Random Title ' + Math.ceil(Math.random()*100),
				content: newsItem
			}
			).then(function(response){
				console.log('Created new NEWS post using Axios', response);
				setNewsItem('');
			})
			.catch(function(){
				console.error('Failed to create a new NEWS post with Axios.');
			//setDisableRegBtn(false);
			});
	};

	/*
    const addNewsItem = async () => {
		//Default options for sanitize are here: https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
		setNewsItem(  sanitizeHtml(newsItem,{
			transformTags: {
			  'a': function(tagName, attribs) {
				  return {
					  href: withHttp(attribs.href)
				  };
			  }
			}
		  }) );
        const result = await fetch(`/news`, {
            method: 'post',
            body: JSON.stringify( {
				title: 'Random Title ' + Math.ceil(Math.random()*100),
				content: newsItem
			} ),
            headers:{
                'Content-Type': 'application/json'
            }
        });

		const body = await result.json();
		console.log(body);
		setNewsItem('');
		
	}
	*/
    const deleteNewsTitleItem = async () => {
		if( newsTitleToDelete === '' ){
			return false;
		}
		
		axios.create( Config.axiosConfig )
			.delete( `/news/${newsTitleToDelete}`, {data:{
				title: newsTitleToDelete
			}}
			).then(function(response){
				console.log('Deleted NEWS post using Axios', response);
				setDeleteNewsTitle('');
			})
			.catch(function(){
				console.error('Failed to delete NEWS post with Axios.');
			//setDisableRegBtn(false);
			});
		/*
		//Default options for sanitize are here: https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
        const result = await fetch(`/news/${newsTitleToDelete}`, {
            method: 'delete',
            body: JSON.stringify( {
				title: newsTitleToDelete
			} ),
            headers:{
                'Content-Type': 'application/json'
            }
        });

		const body = await result.json();
		console.log(body);
		setDeleteNewsTitle('');
		*/
		
	}
	
	const joditEditor = useRef(null)
	const joditConfig = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
		, uploader: {
		  "insertImageAsBase64URI": true
		},
		askBeforePasteFromWord: false,
		askBeforePasteHTML: false,
		events: {
			change: () => {}, 
			//change: (a,b) => { console.log('CHANGE', a,b); setPrepHtml(a); },
			blur: (event) => { setNewsItem(event.target.innerHTML) }
		}
	}

	const getSchoolDevItem = () => {
		setSchoolPayJson( GET_DEVELOPER_VARS(Config.EXTERNAL_SYSTEM.profactPw) );
	}


	const FetchTest = () => {

		axios.create( Config.axiosConfig )
			.post( 'https://stage.schoolpay.com/services/graphql',
				{
					"query":"query txn($id: ID!) {Txn(Search: {ID: [$id]}) { edges { node { Amount AmountRefundable ConvenienceFeeAmount PayeeName Type Status Date CcPanSubstr PaymentMethod}}}}", 
					"variables": {"id":"10157498"}
				},{
				headers: { 
					'Content-Type': 'application/json',
					Authorization: `Basic cnVwcm9mYWN0OmZhMzYzZjExYzY4MWI1MTU4NjA1ZDYzOTc3MDQ4YzAzNTRhN2UxN2Q1Y2EzMWMyOWQ2ZjFhNTUxMjdiNTE3MDk=` 
				}
			 }).then(function(response){
				console.log('Created new NEWS post using Axios', response);
				setNewsItem('');
			})
			.catch(function(){
				console.error('Failed to create a new NEWS post with Axios.');
			//setDisableRegBtn(false);
			});
		return true;
		/*
		fetch('https://stage.schoolpay.com/services/graphql', {
			method: 'POST', 
			headers: {'Authorization': 'Basic cnVwcm9mYWN0OmZhMzYzZjExYzY4MWI1MTU4NjA1ZDYzOTc3MDQ4YzAzNTRhN2UxN2Q1Y2EzMWMyOWQ2ZjFhNTUxMjdiNTE3MDk=',
			  'Content-Type': 'application/json',
			  },
		  
		  body: '{"query":"query txn($id: ID!) {Txn(Search: {ID: [$id]}) { edges { node { Amount AmountRefundable ConvenienceFeeAmount PayeeName Type Status Date CcPanSubstr PaymentMethod}}}}", "variables": {"id":"10157498"}}'	
			  
			  /*
			body: {
			"query":"query txn($id: ID!) {Txn(Search: {ID: [$id]}){edges{node{ID OrderID PayerName Amount Status Date TxnItem {ID TxnID Title CustomFields {Key Value Separator}}}}}}", 
			  "variables": {"id":"10157535"}
			}* /,
		  })
		  .then(response => response.json())
		  .then(data => {
			console.log('Success:', data);
		  })
		  .catch((error) => {
			console.error('Error:', error);
		  });

		return true;
		fetch('https://stage.schoolpay.com/services/graphql', {
			method: 'POST', 
			headers: {
				'Authorization': 'Basic cnVwcm9mYWN0OmZhMzYzZjExYzY4MWI1MTU4NjA1ZDYzOTc3MDQ4YzAzNTRhN2UxN2Q1Y2EzMWMyOWQ2ZjFhNTUxMjdiNTE3MDk=',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			"query":"query txn($id: ID!) {Txn(Search: {ID: [$id]}){edges{node{ID OrderID PayerName Amount Status Date TxnItem {ID TxnID Title CustomFields {Key Value Separator}}}}}}", 
				"variables": {"id":"10157535"}
			}),
			})
			.then(response => response.json())
			.then(data => {
			console.log('Success:', data);
			})
			.catch((error) => {
			console.error('Error:', error);
			});
			*/
	}




	return (
		<main id="main-wrapper" className="grid-container ">
			<div id="main" className="grid-x grid-margin-x">
				<div id="main-col" className="cell">
					<h1>Developer Page</h1>
					<h2 style={{color:'red'}}> You can do damage from here. Be careful. In fact, you should probably not see this page. Go home. </h2>
					<div>
						<div>
							<button className="button blue"  onClick={()=>TestNelnetHash()}>Test NELNET Hash (to console)</button>
							<button className="button blue"  onClick={()=>GO_TO_NELNET_URL('exam')}>Exam: Test NELNET URLs</button>
							<button className="button blue"  onClick={()=>GO_TO_NELNET_URL('renewal')}>Renewal: Test NELNET URLs</button>
						</div>
					

					</div>
					<hr />
					<div>
						<div>
							<button className="button blue"  onClick={()=>getSchoolDevItem()}>Get Vars for Schoolpay Api Test</button>
						</div>
						<div>
							<button className="button blue"  onClick={()=>FetchTest()}>Fetch GraphQL Stuff</button>
						</div>
						<p>
							<h3> I dont think this is the rigth way to do this? </h3>
							<code style={{wordBreak:'break-all'}}>{JSON.stringify(schoolPayJson)}</code>
						</p>

						<div>
							<form 
								action={schoolPayPOST.action} 
								target="_blank"
								method="post" 
							>
								<input type="hidden" name="version" 	value={schoolPayPOST.version} />
								<input type="hidden" name="items" 		value={JSON.stringify(schoolPayPOST.items)} />
								<input type="hidden" name="return_url"		value={schoolPayPOST.return_url} />
								<input type="hidden" name="notification_url" value={schoolPayPOST.notification_url} />
								<input type="hidden" name="provider" value={schoolPayPOST.provider} />
								<input type="hidden" name="student_id" value={schoolPayPOST.student_id} />
								<button type="submit" className="button primary">Submit To new window</button>
							</form>
						</div>

					</div>
					<hr />
					<div>
						<QRCode value="test" />
					</div>
					<hr />
						<button type="button" className="button blue" onClick={()=>getCfaTrainingApi()}>Get Logged in CFA Training</button>
						<button type="button" className="button blue" onClick={()=>API_COURSE()}>Get API Course</button>
					<hr />
					<h3> Delete news item by title. </h3>
					<div>
						<input type="text" value={newsTitleToDelete} onChange={e => setDeleteNewsTitle(e.target.value)} />
					</div>
					<div>
						<button type="button" className="button blue" onClick={()=>deleteNewsTitleItem()}>Delete News item form</button>
					</div>
					<hr />
					<div>
						<button type="button" className="button blue" onClick={()=>addNewsItem()}>Submit form</button>
					</div>
					<div>
						<h3>JODIT EDITOR</h3>
						
						<JoditEditor
							ref={joditEditor}
							value={newsItem}
							config={joditConfig}
							tabIndex={1} // tabIndex of textarea
							//onBlur={newContent => setNewsItem(newContent)} // preferred to use only this option to update the content for performance reasons
							//onChange={newContent => {}}
						/>
						<code>{newsItem}</code>
					</div>
				</div>
			</div>
		</main>
	);

}
/*
					<div>
						<h3> REACT QUILL EDITOR</h3>
						<ReactQuill theme="snow" value={newsItem} onChange={setNewsItem}
							modules={{
								toolbar: [
								  [{ 'header': [1, 2, false] }],
								  ['bold', 'italic', 'underline','strike', 'blockquote'],
								  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
								  ['link', 'image'],
								  ['clean']
								],
							}}
						/>
					</div>*/

export default DeveloperPage;