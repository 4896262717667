import React, {
	useState,
	useEffect
} from 'react';

//import Config from '../Config';
//import axios from 'axios';
import {
	Link
} from 'react-router-dom';
import {
	//get_cfa_training_modules
	get_online_training_content,
	get_credits_by_cfa,
	get_credit_info
	//,get_cfa_training_completion
	,
	DECODE_TOKEN
} from '../Api/api';
import parse from 'html-react-parser';

import CFA_ENG_PDF from '../cfa-files/ProFACT_Training_Manual.pdf';
//import CFA_ESP_PPT from '../cfa-files/PPT-ProFACT-Training-Spanish-translated-by-ARSP-FINAL.pptx';
//import CFA_ESP_DOC from '../cfa-files/Spanish ProFACT Training Manual-translatededited-byARSP-FINAL (clean version).docx';
import CFA_ESP_PPT from '../cfa-files/PPT-ProFACT-Training-Spanish-translated-by-ARSP-FINAL.pdf';
import CFA_ESP_DOC from '../cfa-files/Spanish ProFACT Training Manual-translatededited-byARSP-FINAL (clean version).pdf';




const CFATrainingPage = (props) => {
	//class CFATrainingPage extends React.Component {

	document.title = props.title;
	const [contentLoading, setContentLoading] = useState(true);
	const [cfaTrainingContent, setCfaTrainingContent] = useState([]);
	const [ceExtraInfo, setCeExtraInfo] = useState(null);
	const [creditInfo, setCreditInfo] = useState(null);




	useEffect(() => {
		setContentLoading(true);
		//setCourseRegistration(null);

		const tokenInfo = DECODE_TOKEN();
		if (tokenInfo.code !== 'TOKEN_FOUND') {
			console.error('Did not find appropriate token.');
			setContentLoading(null);
			setCfaTrainingContent(null);

			return () => { };
		}


		const courseId = Number(props.match.params.courseId) || null;

		get_online_training_content(tokenInfo.getAuthToken, courseId)
			.then((res) => {
				//console.log('COURSE CONTENT: ', res.data.contents);
				if (res.data.cfaExamContent && res.data.trainingProgress && res.data.code === 'OK') {
					//create book

					setContentLoading(false);

					//09/22/2020: changed the way we're doing things

					const cfaExamContent = res.data.cfaExamContent;
					const trainingProgress = res.data.trainingProgress;

					cfaExamContent.map((contentObj) => {
						const findTrainingitem = trainingProgress.find((trainingItem) => {
							return trainingItem.contentId === contentObj.contentId
						});
						if (findTrainingitem) {
							contentObj.cfaRead = true;
						} else {
							contentObj.cfaRead = false;
						}

						return contentObj;
					});
					//setCfaTrainingContent(res.data.contents);
					setCfaTrainingContent(cfaExamContent);



				} else if (res.data.contents && res.data.code === 'OK') {


					//I think i'm just going to have to grab this stuff manually
					//get_cfa_specific_training_modules(tokenInfo.getAuthToken, courseId)
					const ceExamContent = res.data.contents.map((contentInfo)=>{
						if( !contentInfo.courseId ){
							contentInfo.courseId = courseId;
						}
						return contentInfo;
					});

					get_credits_by_cfa(tokenInfo.getAuthToken, tokenInfo.getId.id)
						.then((ceCourse) => {

							if (ceCourse.data.credits && ceCourse.data.code === 'OK') {
								//console.log(ceCourse.data.credits);
								const myCeExtraInfo = ceCourse.data.credits.find((courseInfo) => {
									return courseInfo.courseId === courseId;
								});

								//console.log('FOUND COURSE INFO ', myCeExtraInfo);
								if( !myCeExtraInfo ) { 
									throw new Error (ceCourse);
								}
								
								//Just go one deeper and get the rest of the information.
								get_credit_info(tokenInfo.getAuthToken, myCeExtraInfo.creditId)
								.then((creditInfo) => {
									//console.log('Got the credit information so I can put the exam together.', creditInfo.data.credit);

									if (creditInfo.data.credit && creditInfo.data.code === 'OK') {
										setCreditInfo(creditInfo.data.credit);
										setCeExtraInfo(myCeExtraInfo);
										setCfaTrainingContent(ceExamContent);
										setContentLoading(false);
									}
								})
								.catch(error => {
									console.error('Getting CE Information failed.', error);
								});
								

							} else {
								throw new Error(ceCourse);
							}
						})
						.catch(error => {
							console.error('Getting CE Information failed.', error);
						});

				} else {
					throw new Error(res);
				}


			})
			.catch(error => {
				console.error('Getting public CFA modules failed.', error.response);
				if (error.response) {

					if (error.response.data && error.response.data.code === 'AUTH_ERROR') {
						console.log('Please log in :)');
						setContentLoading(null);
						setCfaTrainingContent(null);

					} else if (error.response.status && error.response.status === 500) {
						console.error('Could not fetch CFA Training Content items. ');
						setContentLoading(false);
						setCfaTrainingContent(null);
					}
				} else {
					console.error('???Could not fetch CFA Training Content items. ');
					setContentLoading(false);
					setCfaTrainingContent(null);
				}
			});



	}, [props.match.params.courseId]);

	useEffect(() => {
		const myUser = props.UserState;

		if (myUser.refreshData) {
			window.location.reload();
			return () => { };
		}

		//console.log('ALL MY USER:  ', myUser, myUser.lastRefreshPage, window.location.pathname);
		if (myUser.loggedIn && myUser.lastRefreshPage !== window.location.pathname) {
			return () => {
				//on close.
				myUser.lastRefreshPage = window.location.pathname;
				props.SetUserState(myUser);
			}
		}
	}, [props]);

	const GetCeDescription = () => {

		if (contentLoading) {
			return (
				<p> <i className="fas fa-circle-notch fa-spin" > </i> Loading Description </p>
			);
		}

		if( !creditInfo || !creditInfo.course.description ) {
			return (
				<p> No description given. </p>
			);
		}

		return(
			<>
				<div>
					{parse(creditInfo.course.description)}
				</div>
				<hr />
			</>
		);

	}
	const CheckForExam = () => {
		if (contentLoading) {
			return (
				<p> < i className="fas fa-circle-notch fa-spin" > </i> Loading exam information. </p>
			);
		}

		//console.log( ceExtraInfo,  creditInfo, creditInfo.course.numQuestions, creditInfo.course.examTimeLimit)
		if( !creditInfo || !creditInfo.course.numQuestions || !creditInfo.course.examTimeLimit ) {
			return (<div className="callout primary" >
				<h5> No exam for this course</h5>
				<p> There is currently no required exam for this Continuing Education course. If you read the required sections, you should receive credit for this course. </p>
			</div>
			);
		}
		
		if( creditInfo.exam && creditInfo.exam.passed ) {
			return (<div className="callout success" >
				<h5> You passed!</h5>
				<p> You have passed this exam (exam id: {creditInfo.exam.examId}) scoring a {creditInfo.exam.score}%. Congratulations!</p>
			</div>);
		}

		const numberOfSectionsRead = cfaTrainingContent.filter( (cfaInfo) => { return ( cfaInfo.cfaRead===true ||cfaInfo.cfaRead==='true'); } ).length;

		const numberOfSections = creditInfo.course.sections;
		const isPaid = ( creditInfo.course.cost === 0 ? true : ( creditInfo.payId ? true: false ) );


		//console.log('Sections read? ', numberOfSectionsRead, numberOfSections);
		if( numberOfSectionsRead < numberOfSections ) {
			return (<div className="callout secondary" >
				<h5> Exam Locked</h5>
				<p> In order to take the exam, please read the course material below.</p>
				{
					(!isPaid ? 
						<p><strong>In addition, to be eligible to take the exam, please head over to the <Link to="/payments/ce-course/" className="no-bottom-margin button primary small">payment page</Link> and pay the amount listed. </strong> </p>: <></>)
				}
			</div>);
		}


		//console.log('seeing training content', cfaTrainingContent);

		if( isPaid ) {
			//console.log(creditInfo)
			//Check if in
			if( creditInfo.exam && !creditInfo.exam.score ) {
				return(
					<>
					<div className={"callout primary"}>
						<h5>You have an exam in Progress</h5>	
						<p><Link to={ `/exam-room/${creditInfo.exam.courseId}-${ceExtraInfo.creditId}/${creditInfo.exam.examId}/` } className="button success override-sebs"><i className="fa fa-door-open"></i> 
							Continue Exam </Link></p>
					</div>
					</>
				);
			}

			return(
				<>
				<div className={"callout primary"}>
					<h5>Start your exam</h5>	
					<p><Link to={ `/exam-room/${props.match.params.courseId}-${ceExtraInfo.creditId}/` } className="button success override-sebs">
						<i className="fa fa-door-open"></i> Go to the Online Examination</Link></p>
				</div>
				</>
			);
		}


		return (
			<div className="callout secondary" >
				<h5> Exam Locked</h5>
				<p><strong>To be eligible to take the exam, please head over to the <Link to="/payments/ce-course/" className="no-bottom-margin button primary small">payment page</Link> and pay the amount listed. </strong> </p>
			</div>
		);
	}

	const generateTrainingContentList = () => {

		if (contentLoading) {
			return (
				<p> < i className="fas fa-circle-notch fa-spin" > </i></p>
			);
		} else if (contentLoading === null) {
			return (<div className="callout primary">
				<h5> Make sure to log in! </h5>
				<p> The online manual is only viewable
					while logged in. Please use the menu above to log in or < Link to="/login/cfa-training"
						className="button small primary" > click here </Link>. </p>
			</div>
			);
		}

		if (!cfaTrainingContent) {
			return (<div className="callout alert">
				<h5> We're sorry</h5> <p> There was a problem fetching the live online manual for you.Please refresh the page and try again. </p> </div>
			);
		}

		if (ceExtraInfo && cfaTrainingContent.length < 1) {
			return (<div className="callout primary" >
				<h5> No online content </h5>
				<p> There is currently no online content for this Continuing Education course. </p>
			</div>
			);
		} else if (cfaTrainingContent.length < 1) {
			return (<div className="callout primary" >
				<h5 > Manual Not Ready </h5>
				<p> The manual has not been uploaded to the new site.We 're sorry, please use an offline reference (listed above) in the interim. </p>
			</div>
			);
		}


		const TABLE_OF_CONTENTS = {};
		const TABLE_OF_CONTENTS_ORDER = [];
		const UNSORTED = [];


		for (const i in cfaTrainingContent.sort( (a, b) => {
			//console.log(a,b);
			if( a.module === b.module ) {
				return a.section - b.section;
			}else{
				return a.module-b.module;
			}
			/*
			const aVar = a[ sortedTable.var ] ? a[ sortedTable.var ].toLocaleLowerCase() : '';
			const bVar = b[ sortedTable.var ] ? b[ sortedTable.var ].toLocaleLowerCase() : '';

			if( sortedTable.dir === 'asc' ) {
				return aVar.localeCompare(bVar);
			}else  if( sortedTable.dir === 'desc' ) {
				return (aVar.localeCompare(bVar) * -1);
			}*/

		})
			
		) {
			const sectionItem = cfaTrainingContent[i];
			if (!sectionItem.module) {
				UNSORTED.push(
					sectionItem
				);
				continue;
			}



			if (!TABLE_OF_CONTENTS[sectionItem.module]) {
				TABLE_OF_CONTENTS_ORDER.push(sectionItem.module);
				TABLE_OF_CONTENTS[sectionItem.module] = {
					title: `Module ${sectionItem.module}: ${sectionItem.moduleTitle}`,
					sections: []
				};
			}
			TABLE_OF_CONTENTS[sectionItem.module].sections.push(sectionItem);
		};

		if (UNSORTED.length > 0) {
			TABLE_OF_CONTENTS_ORDER.push('unsorted');
			TABLE_OF_CONTENTS.unsorted = {
				title: 'Unsorted',
				sections: UNSORTED
			};

		}

		return (<> {
			TABLE_OF_CONTENTS_ORDER
				.map((moduleKey, key) => {
					let moduleItem = TABLE_OF_CONTENTS[moduleKey];
					//console.log(moduleItem);
					return (
						<div key={key} >
							<h4 key={key} > {moduleItem.title} </h4> <ul style={
								{
									listStyleType: 'none'
								}
							} > {
									moduleItem.sections
										.sort((a, b) => {
											return Number(a.section) - Number(b.section);
										})
										.map((trainingItem, secKey) => {
											//console.log( trainingItem ) ;
											let myLink = `/cfa-training/${trainingItem.courseId}/${trainingItem.module}/${trainingItem.section}`;
											if (props.match.params.courseId) {
												myLink = `/ce-training/${trainingItem.courseId}/${trainingItem.module}/${trainingItem.section}`;
											}
											return (<li modkey={key}
												key={secKey} >
												<Link to={myLink} className="no-bottom-margin button tiny primary" > < i className="fas fa-link" > </i></Link> 
												{
													((trainingItem.cfaRead === 'true' || trainingItem.cfaRead === true) ?
														<i className="fa fa-check"> </i> : null)
												} Section {trainingItem.section}: {trainingItem.sectionTitle} 
											</li>)
										})
								} </ul> </div>
					)
				})
		}


		</>
		);
	}



	const loadTrainingGuideByType = () => {
		if (contentLoading) {
			return (<p> < i className="fas fa-circle-notch fa-spin" > </i></p>);
		}


		//If this is a CE course, load that information instead
		/*
							<p> Read each training section in the modules below. </p>
							<p> If there is online content, when you complete the readings below, you will either take an exam or be given credit
								for reading. </p> 
							<hr />
							*/
		if (props.match.params.courseId) {
			if (ceExtraInfo) {
				return (<>
						<div className="cell small-12" >
							<div>
								<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
								<hr className="slim-hr" />
							</div>
							<h1> Continuing Education Online Training Material​ </h1> 
							<h2> {ceExtraInfo.course.title} </h2> 
							<h3> Course Material </h3>	
							<h4> Description </h4>
							{GetCeDescription()}  
							<h4> Exam Information</h4>
							{CheckForExam()}  
							<h4> Continuing Education Reading </h4>
							{generateTrainingContentList()} 
						</div>	 
							
					</>
				);
			} else {
				return (<p> <i className="fas fa-circle-notch fa-spin"> </i> Loading Course Content</p>
				);
			}
		}

		//If this is the training page, load it.

		return (<>
			<div className="cell small-12">
				{(contentLoading !== null ? 
					<div>
						<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
						<hr className="slim-hr" />
					</div> : <></>)}
				<h1> Certified Fertilizer Applicator Training Material​ </h1> <p> Read each training section in the modules below. </p>
				<p> Your progress can be viewed on this page when you are logged in .You will see a checked box like this:
								Checked Box in front of the number of any section you have read.​ </p> <p> For your convenience, you can download the <a href={
					CFA_ENG_PDF
				}
					target="_blank"
					rel="noopener noreferrer" > English PDF Training Manual </a> which is an abridged version of the full training material to assist you.</p>
				<p> For Spanish speakers you can download the <a href={
					CFA_ESP_DOC
				}
					target="_blank"
					rel="noopener noreferrer" > Spanish Training Manual </a> and the <a href={CFA_ESP_PPT} target="_blank" rel="noopener noreferrer">Spanish PowerPoint Presentation</a> </p>
				<hr />
			</div> <div className="cell small-12">
				<h3> Online Training </h3>	 {
					generateTrainingContentList()
				} </div>	 </>
		);


	}





	return (<main id="main-wrapper" className="grid-container full" >
		<div id="main" className="grid-x grid-margin-x" >
			< div id="main-col" className="cell" >
				<div id="content"
					className="grid-x grid-margin-x" > {
						loadTrainingGuideByType()
					} </div> </div> </div> </main>
	);
}

export default CFATrainingPage;