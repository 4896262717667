import React from 'react';

import { Link } from 'react-router-dom';


class TFAIntroductionPage extends React.Component {

	constructor( props ) {
		super(props);
		document.title = props.title;
	}

	render() {
		return (
			<main id="main-wrapper" className="">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content" className="">
							<div className="small-12 columns">
								<h1>Instructions</h1>
								<h2>Trained Fertilizer Applicators</h2>
								<p>
									Trained Fertilizer Applicators (TFA) are professionals that may apply fertilizer under the direct supervision of a 
										Certified Fertilizer Applicator (CFA). 
									Direct supervision means that Certified Fertilizer Applicators provide TFAs with written 
										instructions regarding the application of fertilizer and maintain 
										immediate contact via radio or mobile phone (voice) communication.
								</p>
								<p>For any professional to become a Trained Fertilizer Applicator, you must:</p>
							</div>
							<div className="small-12 columns">
								<ol>
									<li>receive annual training on the laws, rules and regulations, standards and requirements applicable to the use and application of fertilizer.
										Training material for TFA is provided below in three basic forms: 
										a PowerPoint slideshow (pps), a printable (pdf) version of the slideshow, and a printable (pdf) manual. 
										See the <Link to="/tfa-training">Trained Applicators Program page</Link> for more details.
									</li> 
									<li>Certified Applicator registers any Trained applicators under their supervision annually.</li>
									<li>have the supervising Certified Fertilizer Applicator attest to their training; and</li>
									<li>pay the registration fee ($40).</li>
								</ol>
							</div>
							<div className="small-12 columns">
								<p>Once this process is complete, the individual's name and affiliation will appear in the Trained Fertilizer Applicator list on the ProFACT website. Additionally, each TFA will find a link on the Status page that takes opens to a personal certificate card. Print this card and store it among other business records that you carry on the job, or cut it out and laminate to carry in your wallet.</p>
								<p>The certificate serves as verification that you are compliant with the certification requirement in the fertilizer law. Each certificate will have a QR code that is unique to you as a Trained Fertilizer Applicator. The QR code can be scanned with smartphone app that will open that individual's Status page on the website. This certificate along with the public listing on the website are the two methods that will be used to verify an applicator's status in the program.</p>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}

}

export default TFAIntroductionPage;