import React from 'react';

class MainLogoWrapper extends React.Component {
	render() {
		return (
			<div id="main-logo-wrapper" className="row">
				<div id="main-logo-row">
					<a href="https://njaes.rutgers.edu" id="main-logo" rel="noreferrer">
						<span className="visually-hide">Rutgers New Jersey Agricultural Experiment Station</span>
					</a>
				</div>
			</div>
		);
	}
}
export default MainLogoWrapper;