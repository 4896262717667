import React,  
	{ useState, useEffect } 
from 'react';
//import { cfaList } from '../Api/Json/static';
//import { Link } from 'react-router-dom';
import { get_cfa_public_profile } from '../Api/api';
import moment from 'moment-timezone';

import {FormatPhoneNumber, FormatZipcode} from '../Config';
//import cfaList from  '../JsonApi/cfa-list.js';

const CFAPublicProfilePage = ( props ) => {

	document.title = props.title;
	const [cfa, SetPublicCFA] = useState({
		isLoading:true,
		isError:false,
		name: '',
		cId: props.match.params.C_CODE,
		profId: props.match.params.PROFACT_CODE,
		business: '',
		street: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
		certDate: '',
		lastModif: ''
	});

	useEffect(() => {
		if( !props.match.params.C_CODE ) {
			SetPublicCFA({
				isLoading:false,
				isError:true
			});
		}else{
			let cId = Number( props.match.params.C_CODE );
			get_cfa_public_profile(cId)
			.then( (res) => {
				//console.log('Retrieving CFA Public Profile', res);
				
				if( res.status !== 200 || res.data.code !== 'ONE_PUBLIC_CFA') {
					throw res; 
				}
				if( res.data.data ) {
					const myCfa = res.data.data;
					console.log(myCfa);
					SetPublicCFA({
						isLoading:false,
						isError:false,
						name: myCfa.firstName + ' ' + myCfa.lastName,
						cId: props.match.params.C_CODE,
						profId:  myCfa.profactId , //props.match.params.PROFACT_CODE,
						business: myCfa.businessName,
						street: myCfa.businessAddress.street,
						city: myCfa.businessAddress.city,
						state: myCfa.businessAddress.state,
						zip: myCfa.businessAddress.zip,
						phone: myCfa.businessAddress.phone,
						certDate:  moment.tz(myCfa.certDate,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
							.tz( moment.tz.guess() )
							.format('LLL')
						
					});
				}
			} )
			.catch( error => {
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch CFA Training Content items. ');
				}
				SetPublicCFA({
					isLoading:false,
					isError:true
				});
			});
		}
		

	}, [props.match.params.C_CODE
		, props.match.params.PROFACT_CODE]);


	const GetProfile = () => {
		if( cfa.isError ) {
			return (
				<p>We're sorry, but we could not find the Certification Number. Please refresh the page and try again. If this is an error, please contact an administrator.</p>
			);
		}
		return ( 
			<>
				<h3><strong>{cfa.name}</strong>  has met all requirements for Certification as a Fertilizer Applicator in the state of New Jersey.</h3>
​​​​​​​​​​​​​​​​​​​​				<dl>
					<dt>Name</dt>
						<dd>{cfa.name}</dd>
					<dt>Certification ID</dt>
						<dd>{cfa.profId}</dd>
					<dt>Business</dt>
						<dd>{cfa.business}</dd>
					<dt>Business Address</dt>
						<dd>
							<p className="no-bottom-margin">{cfa.street}</p>
							<p className="no-bottom-margin">{cfa.city}, {cfa.state} {FormatZipcode(cfa.zip)} </p>
						</dd>
					<dt>Business Phone</dt>
						<dd>{FormatPhoneNumber(cfa.phone)}</dd>
					<dt>Date Certified</dt>
						<dd>{cfa.certDate}</dd>
				</dl>
			</>
		);

	}

	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h1>Certified Fertilizer Applicator Details​​</h1>
						{GetProfile()}
					</div>
				</div>
			</div>
		</main>
	);

}

export default CFAPublicProfilePage;