
const Config = {
	UNIT_NAME: "ProFACT",
	RUTGERS: "Rutgers, The State University of New Jersey",
	STREET_ADDRESS: "59 Dudley Road",
	BUILDING_NAME: "Department of Plant Biology and Pathology",
	CAMPUS: "George H. Cook Campus",
	//SCHOOL_NAME: "School of Environmental and Biological Sciences",
	SCHOOL_NAME: "New Jersey Agricultural Experiment Station",
	CITY_STATE_ZIP: "New Brunswick, NJ 08901-8520",
	PHONE_NUMBER: "848-932-6373",

	WEB_TITLE: 'ProFACT',
	WEB_ADDRESS: "https://rutgers.edu",
	UNIT_EMAIL: "profact@sebs.rutgers.edu",
	WEB_MASTER: "profact@sebs.rutgers.edu",

	//Basename is if this is in a subfolder, e.g. https://server.com/BASENAME , this variable would be `BASENAME/`. Please add the forward slash. 
	//Leave BASENAME to an empty string if this variable is not in a subfolder
	WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL, 
	BASENAME: process.env.REACT_APP_BASENAME,
	
	
	USE_API_LOCATION:`${process.env.REACT_APP_AXIOS_API_LOCATION}${process.env.REACT_APP_AXIOS_BASENAME}`, // process.env.REACT_APP_API_LOCATION,
	ALLOW_EMPTY_PERMISSIONS: true, //If you want any page that doesn't explicity have user permissions to allow anyone to access it

	BASE_WEB_URL: process.env.REACT_APP_WEBSITE_URL || process.env.REACT_APP_BASE_WEB_URL,
	ENABLE_STAGING_SCHOOL_PAY: (
		process.env.REACT_APP_ENABLE_STAGING_SCHOOL_PAY ==='true'
	 ) || false,
	 ENABLE_DEVELOPMENT_PAYMENT_LINKS: (
		 process.env.REACT_APP_ENABLE_DEVELOPMENT_PAYMENT_LINKS ==='true'
	  ) || false,

	IS_DEV_ENV: function () {
		return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
	},
	API_LOCATION: function () {
		//I dont think this is used anywhere (now at least)
		return this.USE_API_LOCATION;
		/*
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			return this.DEV_API_LOCATION;
		}else{
			return this.PROD_API_LOCATION;
		}
		*/

	},
	GET_PAGE_TITLE: function (pageTitle) {
		return pageTitle + ' | ' + this.WEB_TITLE;
	},
	axiosConfig: {
		baseURL: `${process.env.REACT_APP_AXIOS_API_LOCATION}${process.env.REACT_APP_AXIOS_BASENAME}`, //process.env.REACT_APP_AXIOS_BASE_URL,
		proxy: {
			host: process.env.REACT_APP_AXIOS_PROXY_HOST,
			port: process.env.REACT_APP_AXIOS_PROXY_PORT,
			protocol: process.env.REACT_APP_AXIOS_PROXY_PROTOCOL,
		}
	},
	EXTERNAL_SYSTEM: { 
		profactUn: process.env.REACT_APP_PROFACT_UN,
		profactPw: process.env.REACT_APP_PROFACT_PW,
	},
}


export const FormatPhoneNumber = (phNum) => {
	if( !phNum ) { return phNum; }
	const myPhone = phNum.toString();
	if( myPhone.length === 11 ){ 
		return `+${myPhone.substr(0,1)} (${myPhone.substr(1,3)}) ${myPhone.substr(4,3)}-${myPhone.substr(7,4)}`;
	}else if( myPhone.length === 10 ){ 
		return `(${myPhone.substr(0,3)}) ${myPhone.substr(3,3)}-${myPhone.substr(6,4)}`;
	}else if( myPhone.length === 7 ){ 
		return `${myPhone.substr(0,3)}-${myPhone.substr(3,4)}`;
	}
	return phNum;
}
export const FormatZipcode = (zip) => {
	if( zip === null || zip === undefined ) { return zip; }

	const myZip = zip.toString();
	if( myZip.length === 9 ){ 
		return `${myZip.substr(0,5)}-${myZip.substr(5,4)}`;
	}
	if( myZip.length < 5 ){ 
		return `${myZip.padStart(5,'0')}`;
	}

	return zip;
}


export default Config;