import React, {
	//useState, 
//	useEffect, 
//	useRef
} from 'react';
//import Config from '../Config';
//import moment from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
//import parser from 'html-react-parser';
/*
import { 
	//AUTO_update_news,
	//get_specific_news,
	//delete_news,
	DECODE_TOKEN 
} from '../Api/api';
*/

import CourseList from './Components/ContentComponents/CourseList';
import ContentManager from './Components/ContentComponents/ContentManager';
//import ContentManager from './Components/ContentComponents/ContentManager_Forms';

const AdminManageContentsPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	const course = props.match.params.course || null;

	
	


	if( !course ) {
		return ( <CourseList /> );
	}
	

	return <ContentManager course={course}/>
	
}
export default AdminManageContentsPage;