import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import ProfactSelect from '../HelperComponents/ProfactSelect';

import { 
	get_tfas_by_cfas,  
	AUTO_tfa_to_cfa,
	delete_tfa_to_cfa,
	//add_tfa_to_cfa,
	//update_tfa_to_cfa
	//get_tfa_info,
	get_contact_info,
	DECODE_TOKEN 
} from '../Api/api';


import {FormatPhoneNumber, FormatZipcode} from '../Config';
import moment from 'moment-timezone';

//import Config from '../Config';


const CFAAddTFA = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	const currentYear = new Date().getFullYear() ;
	const [showContent, toggleShowContent] = useState( 'tfa-list' );
	const [contentLoading, setContentLoading] = useState(true);
	const [currentTfas, setCurrentTfas] = useState([]);

	const [disableAddTfaBtn, setDisableTfaBtn] = useState(false);
	const [sortedTable, SetSortedVars] = useState({
		var: 'lastName',
		dir: 'asc'
	});



	const STOCK_TFA = {

		tfaId: null,

		firstName: '',
		lastName: '',
		email:'',
		homeAddressId: '',
		cfaId: props.UserState.user.id,
		spanishPref: 0,
		payId: null,
		activeYear: null, //new Date().getFullYear(),

		//Address 
		homeStreet: '',
		homeCity: '',
		homeState: 'NJ',
		homeZip: '',
		homePhone: '',
		homeCounty: '',

		confirmTfa: false,
	};

	//console.log('STOCK TFA', props.UserState, STOCK_TFA);
	const [tfaFormData, UpdateTfaFormData] = useState(STOCK_TFA);
	//Why I don't use tfaFormData for the delete form is because I want to guarentee that each has a seperate entity.
	//Probalby not the best but I'm not in the right mind set.
	const [deleteTfaFormData, UpdateDeleteTfaFormData] = useState(STOCK_TFA);

	//User Table Filter
	const [filterText, filterByText] = useState( '' );
	const [requireHomeCounty, setRequireHomeCounty] = useState( (tfaFormData.homeState.toLowerCase() === 'nj') );

	const refreshTfas = () => {
		
		setContentLoading(true);

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		//console.log(tokenInfo);

		get_tfas_by_cfas( tokenInfo.getAuthToken, tokenInfo.getId.id ) 
		.then( (res) => {
			//console.log('TFAs by CFA: ', res.data);
			if( res.data.data && res.data.code === 'TFA_BY_CFA') {
				//Let's pull out the cfa exam vs. ce courses
				setContentLoading(false);
				setCurrentTfas( 
					res.data.data
				);
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting [TFA_BY_CFA] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch TFAs for this CFA.');
			}
			setContentLoading(false);
			setCurrentTfas( [] );
		});
	}

	useEffect( ()=> {
		moment();
		refreshTfas();
	}, [] );
	
	const editTfa = (contactId, tfaId) => {
		

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		const foundTfaInfo = currentTfas.find( (tfaInfo) => { return tfaInfo.id === tfaId; } );
		if( !foundTfaInfo ) {
			return () => {};
		}
		const myTfaInfo = {
			...STOCK_TFA,
			tfaId: tfaId,
			firstName: foundTfaInfo.firstName,
			lastName: foundTfaInfo.lastName,
			email: foundTfaInfo.email,
			homeAddressId: foundTfaInfo.homeAddressId,
			spanishPref: (foundTfaInfo.spanishPref === true || Number(foundTfaInfo.spanishPref) === 1 ? 1 : 0 ),
			payId: foundTfaInfo.payId,
			activeYear: foundTfaInfo.activeYear,
			
			homeStreet: '',
			homeCity: '',
			homeState: '',
			homeZip: '',
			homePhone: '',
			homeCounty: ''
		};

		//console.log(foundTfaInfo);
		//if contact id is null 
		if( !contactId ) {
			setRequireHomeCounty( myTfaInfo.homeState.toLowerCase() === 'nj' );
			UpdateTfaFormData(myTfaInfo);
			toggleShowContent('tfa-form');
		}else{
			//three independent 
			//console.log(tokenInfo);

			//get_tfa_info( tokenInfo.getAuthToken, tfaId ) 
			get_contact_info( tokenInfo.getAuthToken, contactId )
			.then( (res) => {
				//console.log('Editing a TFA: ', res.data);
				if( res.data.data 
					//&& res.data.code === 'ONE_TFA'
					&& res.data.code === 'ONE_CONTACT'
				) {
					//find tfa in current list
					//console.log('Finding TFAID: `',tfaId,'` in: ', currentTfas)

					myTfaInfo.homeStreet= res.data.data.street;
					myTfaInfo.homeCity= res.data.data.city;
					myTfaInfo.homeState= res.data.data.state;
					myTfaInfo.homeZip= FormatZipcode( res.data.data.zip);
					myTfaInfo.homePhone= FormatPhoneNumber(res.data.data.phone);
					myTfaInfo.homeCounty= res.data.data.county;

					//Let's pull out the cfa exam vs. ce courses
					setRequireHomeCounty( myTfaInfo.homeState.toLowerCase() === 'nj' );
					UpdateTfaFormData(myTfaInfo);
					toggleShowContent('tfa-form');
				}else{
					throw new Error(res.data);
				}
			} )
			.catch( error => {
				console.error('Getting [get_contact] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch TFAs for this CFA.');
				}
			});
		}

	}
	
	const deleteTfa = (contactId, tfaId) => {
		

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		//console.log(tokenInfo);


		const foundTfaInfo = currentTfas.find( (tfaInfo) => { return tfaInfo.id === tfaId; } );
		if( !foundTfaInfo ) {
			return () => {};
		}
		
		const myTfaInfo = {
			...STOCK_TFA,
			tfaId: tfaId,
			firstName: foundTfaInfo.firstName,
			lastName: foundTfaInfo.lastName,
			email: foundTfaInfo.email,
			homeAddressId: foundTfaInfo.homeAddressId,
			spanishPref: (foundTfaInfo.spanishPref === true || Number(foundTfaInfo.spanishPref) === 1 ? 1 : 0 ),
			payId: foundTfaInfo.payId,
			activeYear: foundTfaInfo.activeYear,
			
			homeStreet: '',
			homeCity: '',
			homeState: '',
			homeZip: '',
			homePhone: '',
			homeCounty: ''
		};


		if( !contactId ) {
			UpdateDeleteTfaFormData(myTfaInfo);
			toggleShowContent('delete-tfa-form');
		}else{

			//get_tfa_info( tokenInfo.getAuthToken, tfaId ) 
			get_contact_info( tokenInfo.getAuthToken, contactId )
			.then( (res) => {
				//console.log('Editing a TFA: ', res.data);
				if( res.data.data 
					//&& res.data.code === 'ONE_TFA'
					&& res.data.code === 'ONE_CONTACT'
				) {
					//console.log('Finding TFAID: `',tfaId,'` in: ', currentTfas)

					//console.log(foundTfaInfo);
					myTfaInfo.homeStreet= res.data.data.street;
					myTfaInfo.homeCity= res.data.data.city;
					myTfaInfo.homeState= res.data.data.state;
					myTfaInfo.homeZip= FormatZipcode( res.data.data.zip);
					myTfaInfo.homePhone= FormatPhoneNumber(res.data.data.phone);
					myTfaInfo.homeCounty= res.data.data.county;
					//Deleting Tfa
					UpdateDeleteTfaFormData(myTfaInfo);
					toggleShowContent('delete-tfa-form');
				}else{
					throw new Error(res.data);
				}
			} )
			.catch( error => {
				console.error('Getting [get_contact] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch TFAs for this CFA.');
				}
			});
		}




	}

	//TODO remove if necessary
	useEffect( ()=> {
		setDisableTfaBtn(false);
		toggleShowContent(showContent);

	}, [filterText, showContent] );
	

	const submitTfaInfo = (e) => {
		e.preventDefault();
		
		if( !tfaFormData.confirmTfa ) {
			console.error('Please confirm the checkbox.');
			return false;
		}
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		setDisableTfaBtn(true);

		AUTO_tfa_to_cfa( tokenInfo.getAuthToken, {
			...tfaFormData,
			
			homeZip: (''+tfaFormData.homeZip).replace(/[^0-9]/g, '').substr(0,9),
			homePhone: (''+tfaFormData.homePhone).replace(/[^0-9]/g, '').substr(0,11),
		})
			.then( (res) => {
				//console.log('ADD_NEW_TFA: ', res.data);
				refreshTfas();
				toggleShowContent('tfa-list');
				UpdateTfaFormData( STOCK_TFA );
				setRequireHomeCounty( (STOCK_TFA.homeState.toLowerCase() === 'nj') )
				setDisableTfaBtn(false);
			} )
			.catch( error => {
				console.error('Getting [ADD_NEW_TFA] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add TFA for this CFA.');
				}
				setDisableTfaBtn(false);
			});
	}
	
	
	
	const deleteTfaInfo = () => {

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		//console.log(tokenInfo);

		setDisableTfaBtn(true);

		delete_tfa_to_cfa( tokenInfo.getAuthToken, deleteTfaFormData.tfaId )
			.then( (res) => {
				//console.log('Deleting TFA: ', res.data);
				refreshTfas();
				toggleShowContent('tfa-list');
				UpdateTfaFormData( STOCK_TFA );
				setRequireHomeCounty( (STOCK_TFA.homeState.toLowerCase() === 'nj') )
				setDisableTfaBtn(false);
			} )
			.catch( error => {
				console.error('[Delete TFA] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add TFA for this CFA.');
				}
				setDisableTfaBtn(false);
			});
	}

	const handleFilterOnChange = event => {
		filterByText(event.target.value);
	};
	
	const tfaForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		//console.log(e_target, );
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            [name]: value
		}));
	}
	const tfaForm_checkBox_HandleChange = (e_target) => {
		const { name, checked } = e_target;
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            [name]: (checked ? 1 : 0)
		}));
		//setSpanishText( checked );
	}

	const handleHomeStateChange = (e_target) => {
		
		tfaForm_HandleChange(e_target);
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            homeCounty: ''
		}));
		setRequireHomeCounty( e_target.value.toLowerCase() === 'nj' );
	};




	const TfaForm = () => {
		return (
			<div style={{display: ( showContent === 'tfa-form' ? 'block' : 'none' )}}>
				<h3> Adding a new TFA </h3>
				
				<div className="margin-top-3" style={{marginTop: 1 + 'em'}}>
					<form action="" method="post" onSubmit={submitTfaInfo}>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="firstName" className="label-style-1">Name: </label>
							</div>
							<div className="large-4 medium-6 small-12  columns">
								<input type="text" id="firstName" name="firstName" placeholder="First Name" required="required" 
									value={tfaFormData.firstName}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
							<div className="large-5 medium-6 small-12  columns">
								<input type="text" id="lastName" name="lastName" placeholder="Last Name" required="required" 
									value={tfaFormData.lastName}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="small-12 columns">
								<label htmlFor="spanishPref" className="label-style-1">
									<input type="checkbox" 
										name="spanishPref" id="spanishPref" 
										value={tfaFormData.spanishPref}
										checked={tfaFormData.spanishPref}
										onClick={(e)=>{ tfaForm_checkBox_HandleChange(e.target); }}
									/>
									This TFA prefers Spanish when available
								</label>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="email" className="label-style-1">Email</label>
							</div>
							
							<div className="large-9 medium-12 small-12 columns">
								<input type="email" id="email" name="email" placeholder="name@example.com" 
									value={tfaFormData.email}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homePhone" className="label-style-1">Home Phone Number</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homePhone" name="homePhone" placeholder="" 
								
								minLength="7"
								maxLength="21"
									value={tfaFormData.homePhone}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>

						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeStreet" className="label-style-1">Home Address</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeStreet" name="homeStreet" placeholder="" 
								
									value={tfaFormData.homeStreet}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeCity" className="label-style-1">Home City</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeCity" name="homeCity" placeholder="" 

									value={tfaFormData.homeCity}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeState" className="label-style-1">Home State</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<ProfactSelect
									name="homeState" id="homeState" 
									StateDropdown={true} 
									IncludeBlank={true} 
									
									value={tfaFormData.homeState}
									onChange={e => {
										handleHomeStateChange(e.target);
										//homeForm_requireCounty(e.target);
										//homeForm_HandleChange(e.target);
									} } />
							</div>
						</div>
						<div className="row" style={{display: (requireHomeCounty ? 'block': 'none')}}>
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeCounty" className="label-style-1">Home NJ County</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<ProfactSelect
									name="homeCounty" id="homeCounty" NjCountyDropdown={true}
									required={requireHomeCounty}
									value={tfaFormData.homeCounty}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeZip" className="label-style-1">Home Zipcode</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeZip" name="homeZip" placeholder="" 
								
									minLength="5"
									maxLength="10"
									value={tfaFormData.homeZip}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="callout alert large-12 medium-12 small-12 columns">
								<p>By clicking the checkbox below, I verify that I am a Certified Fertilizer Applicator (CFA) in the state of New Jersey, and that the Trained Fertilizer Applicator (TFA) listed above has received training using the TFA training materials found on the ProFACT web site (profact.rutgers.edu) or an approved alternate, on the application, sale, and use of fertilizer. I also attest that the Trained Fertilizer Applicator understands:</p>
								<ul className="large-margin-left">
									<li>how to use fertilizer application equipment</li>
									<li>the best management practices for applying fertilizer to turf</li>
									<li>the environmental risks associated with improper application of fertilizer</li>
									<li>procedures for preventing as well as responding to spills of fertilizers</li>
									<li>all the laws, rules, and regulations pertaining to the application of fertilizer to turf</li>
								</ul>
								<p>I agree that the Trained Fertilizer Applicator will be in direct supervision by me or a CFA from at all times during which the TFA is applying fertilizer in the state of New Jersey.</p>
							
							</div>
						</div>
						<div className="row">
							<div className="large-4 medium-12 small-12 columns">
								<label htmlFor="confirmTfa" className="">I verify, attest, and agree to the statements above.   *</label>
							</div>
							<div className="large-8 medium-12 small-12  columns">
								<input type="checkbox" id="confirmTfa" name="confirmTfa" placeholder="" required="required" 
									value={tfaFormData.confirmTfa}
									checked={tfaFormData.confirmTfa}
									defaultChecked={tfaFormData.confirmTfa}
									onChange={e => tfaForm_checkBox_HandleChange(e.target)}
								/>
							</div>
							<div className="large-12 medium-12 small-12 columns text-right">
								<button onClick={ (e) => { UpdateTfaFormData(STOCK_TFA); toggleShowContent('tfa-list'); } } 
									className="button alert" type="button">
									Cancel</button>
								<button className="button" type="submit" disabled={disableAddTfaBtn}>
									Submit This TFA</button>
							</div>
						</div>
					</form>
				</div>
			</div>


		);
	}

	
	const updateSortable = (newVar, newDir) => {

		SetSortedVars({
			var: newVar,
			dir: newDir
		})
		return true;
	}

	const getCurrentTfas = () => {
		if( contentLoading ) {
			return ( 
				<>
					<tr><td colSpan="10"><i className="fas fa-circle-notch fa-spin"></i></td></tr>
				</>
			);
		}


		return (
			currentTfas
			.filter( (tfaInfo) => {
				if( filterText === '' ) { 
					return true;
				}
				const myFilterText = filterText.toLocaleLowerCase().trim();
				return (
					tfaInfo.lastName.toLocaleLowerCase().includes( myFilterText )
					|| tfaInfo.firstName.toLocaleLowerCase().includes( myFilterText )
					|| ( tfaInfo.email && tfaInfo.email.toLocaleLowerCase().includes( myFilterText ) )
					|| ( tfaInfo.payId && tfaInfo.payId.toString().toLocaleLowerCase().includes( myFilterText ) )
				);
			})
			.sort( ( a, b ) =>{

				if( sortedTable.var === 'payId' ) {
					const aVar = a[ sortedTable.var ] || 0;
					const bVar = b[ sortedTable.var ] || 0;

					if( sortedTable.dir === 'asc' ) {
						return aVar - bVar;
					}else  if( sortedTable.dir === 'desc' ) {
						return (aVar - bVar) * -1;
					}
				}

				const aVar = a[ sortedTable.var ].toLocaleLowerCase();
				const bVar = b[ sortedTable.var ].toLocaleLowerCase();

				if( sortedTable.dir === 'asc' ) {
					return aVar.localeCompare(bVar);
				}else  if( sortedTable.dir === 'desc' ) {
					return (aVar.localeCompare(bVar) * -1);
				}

				return 0;
			}).map( (tfaInfo, key)  => {
				return (
					<tr key={key}>
						
						<td>{tfaInfo.lastName}</td>
						<td>{tfaInfo.firstName}</td>
						<td>{tfaInfo.email ? tfaInfo.email : 'N/A'}</td>
						{tfaInfo.payId ? <td>Yes</td>: ( <td style={{backgroundColor:'lightgray'}}><strong>No</strong></td>)}
						{tfaInfo.activeYear >= currentYear ? <td>{tfaInfo.activeYear}</td>: ( <td style={{backgroundColor:'lightgray'}}><strong>Expired</strong></td>)}
						<td>{tfaInfo.payId ? tfaInfo.payId: ( <strong>--</strong>)}</td>
						<td>
							<button className="button warning small no-bottom-margin"
								onClick={ (e) => { editTfa(tfaInfo.homeAddressId, tfaInfo.id) } }
							><i className="fa fa-edit"></i> Edit</button>
						</td>
						<td>
							<button className="button alert small no-bottom-margin"
								onClick={ (e) => { deleteTfa(tfaInfo.homeAddressId, tfaInfo.id); } }
							><i className="fa fa-trash"></i> Remove </button>
						</td>
					</tr>
				);
			})
		);
	}
	const currentTfaTable = () => {
		return (
			<div style={{display: ( showContent === 'tfa-list' ? 'block' : 'none' )}}>
				<h3> Your Current TFAs </h3>
				<div>
					<h4>Search Table</h4>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="middle-label" className="label-style-1">Filter By Text: </label>
						</div>
						<div className="medium-5 small-12 columns no-float">
							<input type="text" id="middle-label" placeholder="Name, Address, etc..." 
								onChange={handleFilterOnChange}
							/>
						</div>
					</div>
				</div>
				<div className="table-scroll">
					<table className="table-left-align-all">
						<thead>
							<tr>
								<th>
									<button className="sortable-btn"
										onClick={()=>{
											updateSortable('lastName', (
												sortedTable.var==='lastName' && sortedTable.dir==='asc' 
												? 'desc' : 'asc'
											));
										}}
									>
										<span>Last Name</span> {(
											sortedTable.var==='lastName' ?
											<i className={`fas fa-${(
												sortedTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
											)}`}></i>
											: <i className="fas fa-sort"></i>
										)}
									</button>
								</th>
								<th>First Name</th>
								<th>
									<button className="sortable-btn"
										onClick={()=>{
											updateSortable('email', (
												sortedTable.var==='email' && sortedTable.dir==='asc' 
												? 'desc' : 'asc'
											));
										}}
									>
										<span>Email</span> {(
											sortedTable.var==='email' ?
											<i className={`fas fa-${(
												sortedTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
											)}`}></i>
											: <i className="fas fa-sort"></i>
										)}
									</button>
								</th>
								<th>
									<button className="sortable-btn"
										onClick={()=>{
											updateSortable('payId', (
												sortedTable.var==='payId' && sortedTable.dir==='asc' 
												? 'desc' : 'asc'
											));
										}}
									>
										<span>Paid / Approved</span> {(
											sortedTable.var==='payId' ?
											<i className={`fas fa-${(
												sortedTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
											)}`}></i>
											: <i className="fas fa-sort"></i>
										)}
									</button>
								</th>
								<th>Active Year</th>
								<th>Payment Id</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{getCurrentTfas()}
						</tbody>
					</table>
				</div>
			</div>
		);
	}

	//deleting a Tfa
	const DeleteTfaForm = () => {
			
		return (
			<div style={{display: ( showContent === 'delete-tfa-form' ? 'block' : 'none' )}}>
				<h5> TFA Information: </h5>
				<div>
					<dl>
						<dt>Name</dt>
							<dd>{deleteTfaFormData.firstName} {deleteTfaFormData.lastName}</dd>
						<dt>Email</dt>
							<dd>{deleteTfaFormData.email}</dd>
						{
							deleteTfaFormData.homeAddressId 
							? <>
							<dt>Phone Number</dt>
								<dd>{deleteTfaFormData.homePhone}</dd>
							<dt>Address</dt>
								<dd>
									<p className="no-bottom-margin">{deleteTfaFormData.homeStreet}</p>
									<p className="no-bottom-margin">{deleteTfaFormData.homeCity}, {deleteTfaFormData.homeState} {deleteTfaFormData.homeZip} </p>
								</dd>
							</>
							: <></>
							
						}
					</dl>
				</div>
				<h5> <strong>NOTICE: </strong> This action cannot be undone.</h5>
				<div>
					<button onClick={ (e) => { UpdateTfaFormData(STOCK_TFA); toggleShowContent('tfa-list'); } } 
						className="override-sebs button primary success" type="button">
						Cancel (DO NOT DELETE) </button>  <button onClick={ (e) => { 
							//UpdateTfaFormData(STOCK_TFA); toggleShowContent('tfa-list'); 
							deleteTfaInfo();
						} } 
						className="button primary alert" type="button">
						REMOVE TFA FROM MY LIST </button>
				</div>
			</div>
		);
	}



	return (
		<main id="main-wrapper" className="">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<div>
							<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
							<hr className="slim-hr" />
						</div>
						<h2> Your TFA Management Page </h2>
						<div className="button-group">
							<button className={
								"button small primary" +
								(showContent === 'tfa-list' ? ' is-selected' : '')
							} onClick={(e) =>{ toggleShowContent( 'tfa-list' ) }}>Current TFAs</button>
							<button className={
								"button small primary" +
								(showContent === 'tfa-form' ? ' is-selected' : '')
							} onClick={(e) =>{ toggleShowContent( 'tfa-form' ) }}>Add A New TFA</button>
							<Link className="button small primary hollow" to="/payments/unpaid-tfas"><i className="fa fa-link"></i> Pay for Unpaid TFAs</Link>
						</div>
						{currentTfaTable()}
						{TfaForm()}
						{DeleteTfaForm()}
					</div>
				</div>
			</div>
		</main>
	);
}
export default CFAAddTFA;