import React, {useState, 
	useEffect
	, useRef
} from 'react';
import { Link,
	Redirect, } from 'react-router-dom';
import { 
	//post_exam, 
	
	//get_cfa_specific_training_modules,
	get_course_content, 
	start_exam, 
	post_exam_response, put_exam_response, grade_exam 
	, DECODE_TOKEN 
	, get_exam_progress
	//, get_exam_active_now
} from '../Api/api';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
//import Config from '../Config';


const CFAExamRoomPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;


	//If we find an exam number, we will call it and see if it's valid
	// if it is, head to the latest question they need to answer
	// if not, display that it's not valid and why.

	//If there's none, display the instructions and get ready to start.
	
	const [ useSpanish, SetUseSpanish ] = useState(Number(props.UserState.user.spanishPref));


	const [ isLoading, SetIsLoading ]  = useState(false);
	const [ startRedir, SetStartRedir ]  = useState(false);
	const [ currentQuestion, SetCurrentQuestion ] = useState({
		id:null,
		question:"",
		mandatory:true,
		answer1:"",
		answer2:"",
		answer3:"",
		answer4:"",
		
		courseId:1,
		sectionId:1,
		
		extraInfo:"",
		
		spQuestion:"",
		spAns1:"",
		spAns2:"",
		spAns3:"",
		spAns4:"",
		
		selection: null,

		createdAt:"",
		updatedAt:"",
		deletedAt:null,
		examId: null
	} );
	
	const [ allQuestionsAsked, SetAllQuestionsAsked ] = useState(false);
	const [ showAfterTestScreen, SetShowAfterTestScreen ] = useState(false);
	const [ gradeExam, SetGradeExam ] = useState({
		showGrade: false,
		score: null,
		maxScore: null,
		passed: null,
		incorrect: []
	});
	//const [ showScoreScreen, SetShowScoreScreen ] = useState(false);
	const [ examInfo, SetExamInfo ] = useState({
		ready: false,
		courseId: (props.match.params.courseId ? props.match.params.courseId.split('-')[0] : null) || null,
		creditId: (props.match.params.courseId ? props.match.params.courseId.split('-')[1] : null) || null,
		examId: (props.match.params.examId) || null,
		limit: null,
		currentTime: null,
		timeMinutes: null,
		passingScore: null,
		numberOfQuestions: null,
		spanish: useSpanish || false,
		questionNumber: null, //questionNumber = 1 is the first question
	});
	const examInfoRef = useRef(examInfo);
	const [clockPointer, SetClockPointer] = useState(null);
	const clockPointerRef = useRef(clockPointer);
	const [examAlert, setExamAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});

	const [questionRepository, SetQuestionRepository] = useState([]);
	const GetInitialExamInfo = () => {

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		/*
		get_cfa_specific_training_modules(tokenInfo.getAuthToken, examInfo.courseId)
		.then((response) => {
			//console.log("TEST INFO", response);
		} )
		.catch( error => {
			//console.log(error);
			console.error('Getting public CFA modules failed.');
		});
		*/

		get_course_content(tokenInfo.getAuthToken)
		.then((response) => {
			////console.log('Getting the courses.', examInfo.courseId, response.data.data);

			const thisExam = response.data.data.find( (courseInfo) => { return courseInfo.courseId === Number(examInfo.courseId); } );
			////console.log(thisExam);
			
			
			SetExamInfo({
				...examInfo,
				timeMinutes: thisExam.examTimeLimit,
				passingScore: thisExam.passingScore,
				numberOfQuestions: thisExam.numQuestions
			});
		} )
		.catch( error => {
			console.log(error);
			console.error('Getting public CFA modules failed.');
		});


	}
	const _InsertIntoQuestionRepository = ( questionObject ) => {
		////console.log('Inserting into repo: ', questionObject);
		//Make sure the question isn't in the repo already...
		const alreadyInRepo = questionRepository.find( (question) => { return question.questionId === questionObject.questionId } );
		if(  alreadyInRepo !== undefined )  {
			//console.log('alreadyInRepo',  alreadyInRepo);
			//If the question was found, we're not going to re-add the question.
			//SetQuestionRepository(alreadyInRepo);
			SetQuestionRepository(questionRepository);
			return true;
		}
		const myQuestionRepository = questionRepository;
		myQuestionRepository.push( questionObject );
		
		SetQuestionRepository(myQuestionRepository);
	}
	const StartNewExam = () => {

		//Okay, let's start a new exam.
		let getAuthToken = null;
		let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(startExamError) {

			console.warn('Problematic Login token. Clearing. ');
			localStorage.removeItem('profact-token');
			props.UserState({
			  loggedIn: false,
			  refreshData: false,
			  //preferredLanguage: 'en-US',
			  preferredLanguage: 'en-US',
			  user:null
			});
			window.location.reload();
			return false;
		}
		
		start_exam(getAuthToken, getId.id, examInfo.courseId, examInfo.creditId, useSpanish)
			.then(function(response){
				console.log('Starting a brand new exam for this user.', response);

				if( response.status === 200 ) {

					if( response.data.code === 'OK' ) {
						SetExamInfo({
							...examInfo,
							examId: response.data.examId
						});
						SetStartRedir(true);
						//return <Redirect push to={`/exam-room/${examInfo.courseId}/${response.data.examId}`} />;
					}else{
						throw new Error( response );
					}
				}else{
					throw new Error(response);
				}
				
			})
			.catch(function(error){
				///console.log(error);
				console.error('Failed to start exam.', error);

				setExamAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				//setDisableRegBtn(false);
			});
			return false;
	}















	const GoToPrevQuestion = () => {

		if( showAfterTestScreen ) {
			SetShowAfterTestScreen(false);
			return true;
		}
		let prevQuestionNumber = examInfo.questionNumber;
		console.log('Previous Question.', prevQuestionNumber);
		if(( prevQuestionNumber - 1 ) < 1) {
			prevQuestionNumber = 1;
		}else{
			prevQuestionNumber--;
		}
		const myNewQuestion = questionRepository[ prevQuestionNumber - 1 ];
		console.log('The previous question information..............????', questionRepository, myNewQuestion)
		SetCurrentQuestion(myNewQuestion);
		SetExamInfo({
			...examInfo,
			questionNumber: prevQuestionNumber
		});
	}
	const GoToGrade = () => { 

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		let getAuthToken = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		}catch(startExamError) {

			console.warn('Problematic Login token. Clearing. ');
			localStorage.removeItem('profact-token');
			props.UserState({
			  loggedIn: false,
			  refreshData: false,
			  //preferredLanguage: 'en-US',
			  preferredLanguage: 'en-US',
			  user:null
			});
			window.location.reload();
			return false;
		}

		console.log(examInfo);
		//gradeExam
		grade_exam( getAuthToken, examInfo.examId, examInfo.creditId, tokenInfo.getId.id )
		.then((response) => {
			console.log('Grading this exam', response);

			if( response.status === 200 ) {
				if( response.data.code === 'GRADED_EXAM' ) {
					SetExamExpired(true);
					SetGradeExam({
						showGrade: true,
						score: response.data.exam.score,
						maxScore: response.data.exam.maxScore,
						passed: response.data.exam.passed,
						incorrect: response.data.incorrect
					});
				}
				
			}else{
				throw new Error(response);
			}
			
		})
		.catch((error) => {
			///console.log(error);
			console.error('Failed to grade exam.', error);

			setExamAlert({
				type: 'alert',
				display:'block', 
				title: 'Error',
				message: (error.message || error)
			});
			//setDisableRegBtn(false);
		});


	}

	//This whole operation kinda stinks; 
	// 	I dont feel like it should be looking at the question number.. i might actually have to rewrite how this page functions.
	const GoToNextQuestion = () => {
		//console.log('Next Question. (',questionRepository.length, examInfo.questionNumber,')', questionRepository);
		if( 
			questionRepository.length === (examInfo.questionNumber) 
			&& !currentQuestion.examResponseId
		) {
			SaveAnswerInDb();
		} else {
			UpdateAnswerInDb();
		}	

		return true;
	}
	const SaveAnswerInDb = () => {
		//console.log('(POST) Save Question');
		let getAuthToken = null;
		let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(startExamError) {

			console.warn('Problematic Login token. Clearing. ');
			localStorage.removeItem('profact-token');
			props.UserState({
			  loggedIn: false,
			  refreshData: false,
			  //preferredLanguage: 'en-US',
			  preferredLanguage: 'en-US',
			  user:null
			});
			window.location.reload();
			return false;
		}
		if( examExpired ) {
			return false;
		}

		post_exam_response(getAuthToken, getId.id, 
			currentQuestion.questionId,
			currentQuestion.selection,
			examInfo.examId
		)
		.then((response) => {
			//console.log('Saving this new answer for this exam.', response);

			if( response.status === 200 ) {
				//console.log('Almost done!');


				if( response.data.code === 'NO_MORE_QUESTIONS' ) {
					currentQuestion.examResponseId = response.data.prevExamResponseId;
					_UpdateRepository( examInfo.questionNumber, currentQuestion);
					SetShowAfterTestScreen(true);
					SetAllQuestionsAsked(true);
				}else if( response.data.code === 'NEXT_QUESTION' ) {
					//store the previous response id so I know how to put.
					currentQuestion.examResponseId = response.data.nextQuestion.prevExamResponseId;
					_UpdateRepository( examInfo.questionNumber, currentQuestion);

					const myNewQuestion = response.data.nextQuestion;
					const nextQuestionNumber = examInfo.questionNumber+1;
					myNewQuestion.selection = null;
	
					_InsertIntoQuestionRepository(myNewQuestion);
					SetCurrentQuestion(myNewQuestion);
					SetExamInfo({
						...examInfo,
						questionNumber: nextQuestionNumber
					});
				}else if( response.data.code === 'BAD_DUMMY_DATA' ) {
					setExamAlert({
						type: 'alert',
						display:'block', 
						title: 'Error',
						message: response.data.message
					});
					SetShowAfterTestScreen(true);
					SetAllQuestionsAsked(true);
				}

			}else{
				throw new Error(response);
			}
			
		})
		.catch((error) => {
			///console.log(error);
			console.error('Failed to POST this question.', error);

			setExamAlert({
				type: 'alert',
				display:'block', 
				title: 'Error',
				message: (error.message || error)
			});
			//setDisableRegBtn(false);
		});
/*
		const myNewQuestion = {
			...currentQuestion,
			question: 'PLACEHOLDER TITLE ' + examInfo.questionNumber,
			selection: null
		};
		_InsertIntoQuestionRepository(myNewQuestion);
		SetCurrentQuestion(myNewQuestion);
		SetExamInfo({
			...examInfo,
			questionNumber: (examInfo.questionNumber+1)
		});
*/
		return true;
	}
	const UpdateAnswerInDb = () => {
		//console.log('(PUT) Save Question');
		
		let getAuthToken = null;
		let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(startExamError) {

			console.warn('Problematic Login token. Clearing. ');
			localStorage.removeItem('profact-token');
			props.UserState({
			  loggedIn: false,
			  refreshData: false,
			  //preferredLanguage: 'en-US',
			  preferredLanguage: 'en-US',
			  user:null
			});
			window.location.reload();
			return false;
		}

		if( examExpired ) {
			return false;
		}

		put_exam_response(getAuthToken, getId.id, 
			currentQuestion.examResponseId,
			currentQuestion.questionId,
			currentQuestion.selection,
			examInfo.examId
		)
		.then((response) => {
			//console.log('UPDATING this answer answer for this exam.', response);

			if( response.status === 200 ) {
				if( response.data.code === 'RESPONSE_SAVED' || response.data.code==='NO_MORE_QUESTIONS' ) {

					_UpdateRepository( examInfo.questionNumber, currentQuestion);

					const nextQuestionNumber = examInfo.questionNumber+1;
					const myNewQuestion = questionRepository[ nextQuestionNumber-1 ];

					if( !myNewQuestion ) {
						SetShowAfterTestScreen(true);
						SetAllQuestionsAsked(true);
						SetExamInfo({
							...examInfo,
							questionNumber: nextQuestionNumber-1
						});
					}else{
						SetCurrentQuestion(myNewQuestion);
						SetExamInfo({
							...examInfo,
							questionNumber: nextQuestionNumber
						});
					}
	
				}else{
					throw new Error(response);
				}
			}else{
				throw new Error(response);
			}
			
		})
		.catch((error) => {
			///console.log(error);
			console.error('Failed to PUT this question.', error);

			setExamAlert({
				type: 'alert',
				display:'block', 
				title: 'Error',
				message: (error.message || error)
			});
			//setDisableRegBtn(false);
		});

/*
		let nextQuestionNumber = examInfo.questionNumber+1;

		const myNewQuestion = questionRepository[ nextQuestionNumber-1 ];
		SetCurrentQuestion(myNewQuestion);
		SetExamInfo({
			...examInfo,
			questionNumber: nextQuestionNumber
		});
		return true;
		*/
	}
	const _UpdateRepository = (questionNumber, questionInfo ) => {
		//const myQuestionRepository = questionRepository;
		questionRepository[questionNumber-1] = questionInfo;
		SetQuestionRepository(questionRepository);
	}

	const _RestoreExamState =  ()  => {
		//console.log('RESTORING STATE....!!!');
		if( isLoading ) {
			console.log('Already loading, get out.');
			return false;
		}

		SetIsLoading(true);
		const myToken = DECODE_TOKEN();
		if( myToken.code === 'TOKEN_FOUND' ) {

			//get_exam_active_now(myToken.getAuthToken)
			get_exam_progress( myToken.getAuthToken, examInfo.courseId, examInfo.examId )
			.then(function(response){
				console.log('Restoring the state with the following: ', response);

				
				if( response.status === 200 ) {
					if( response.data.code === 'OK' ||  response.data.code === 'NO_MORE_QUESTIONS' ) {
						/*
						console.log('The existing questions:', 
							response.data.examPacket?.questions.existingQuestions || response.data.existingQuestions );
						*/

						//
						const myQuestionRepository = [];
						if ( response.data.examPacket ) {
							for(let i=0;i<response.data.examPacket.questions.existingQuestions.length;i++){
								const myExistingQuestion = response.data.examPacket.questions.existingQuestions[i].question;
								myExistingQuestion.questionId = response.data.examPacket.questions.existingQuestions[i].questionId;
								myExistingQuestion.examResponseId = response.data.examPacket.questions.existingQuestions[i].id;
								myExistingQuestion.selection = response.data.examPacket.questions.existingQuestions[i].selection;
								myQuestionRepository.push(myExistingQuestion);
							}
						}else{
							for(let i=0;i<response.data.existingQuestions.length;i++){
								const myExistingQuestion = response.data.existingQuestions[i].question;
								myExistingQuestion.questionId = response.data.existingQuestions[i].questionId;
								myExistingQuestion.examResponseId = response.data.existingQuestions[i].id;
								myExistingQuestion.selection = response.data.existingQuestions[i].selection;
								myQuestionRepository.push(myExistingQuestion);
							}
						}
/*
						const myQuestionRepository = response.data.examPacket.questions.existingQuestions;
						
						//I'm just going to correct the ID key...
						for(let i=0;i<myQuestionRepository.length;i++) {
							if( 
								!myQuestionRepository[i].examResponseId
								&& myQuestionRepository[i].id 
							) {
								myQuestionRepository[i].examResponseId = myQuestionRepository[i].id;
							}
						}
						*/
						//SetQuestionRepository( response.data.examPacket.questions.existingQuestions );
						let myNewQuestion =null;
						if( response.data.code === 'OK' ){
							myNewQuestion = response.data.examPacket.questions.nextQuestion;
							
							myQuestionRepository.push(myNewQuestion);
						}
						SetExamInfo({
							...examInfo,
							ready: true,
							currentTime: moment().utc().tz( moment.tz.guess() ).unix()*1000,
							limit: moment.tz(response.data.examPacket.timeStarted,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
								.tz( moment.tz.guess() ).add(response.data.examPacket.timeLimit, 'minutes').unix()*1000 , //new Date(2020,9,11,15,0,0,0).getTime(),
							examId: response.data.examPacket.examId,
							spanish: response.data.examPacket.spanish,
							questionNumber: (
								myQuestionRepository.length
							)
						});
						SetIsLoading(false);
						SetStartRedir(false);
						//console.log('We are DONE loading the exam again.');

						
						if( response.data.code === 'OK' ){
							//_InsertIntoQuestionRepository(myNewQuestion);
							SetCurrentQuestion(myNewQuestion);
						}else{
							SetCurrentQuestion(myQuestionRepository[ myQuestionRepository.length-1 ]);
							SetShowAfterTestScreen(true);
							SetAllQuestionsAsked(true);
						}

						SetQuestionRepository( myQuestionRepository );
						console.log('Here are the questions in the repo: ', questionRepository);

						/*
						
						*/
					}else{
						throw new Error( response );
					}
				}else{
					throw new Error(response);
				}
/*
				if( response.status === 200 ) {
					if( response.data.code === 'EXAM_IN_PROGRESS' ) {
						SetQuestionRepository( response.data.allResponses);
						const myNewQuestion = response.data.allResponses[ response.data.allResponses.length -1];
						SetCurrentQuestion(myNewQuestion);

						
						SetExamInfo({
							...examInfo,
							ready: true,
							currentTime: moment().utc().tz( moment.tz.guess() ).unix()*1000,
							limit: moment.tz(myNewQuestion.updatedAt,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC').tz( moment.tz.guess() ).add(100, 'minutes').unix()*1000 , //new Date(2020,9,11,15,0,0,0).getTime(),
							examId: myNewQuestion.examId,
							questionNumber: response.data.allResponses.length
						});
						
					}else{
						throw new Error( response );
					}
				}else{
					throw new Error(response);
				}
				*/
			})
			.catch( (error) => {

				console.log(error.response);
				console.error('Failed to restore the state of this exam.', error);
				if( error.response.data.code === 'NOT_OK' ) {
					setExamAlert({
						type: 'alert',
						display:'block', 
						title: 'Exam is closed.',
						message: `We're sorry, but this exam has already been graded. Please go back to the examination page.`
					});
				}else{
					
					setExamAlert({
						type: 'alert',
						display:'block', 
						title: 'Error',
						message: (error.response.data.message || 'Generic / Unknown Error')
					});
				}
				
			});
			
		}else{
			setExamAlert({
				type: 'alert',
				display:'block', 
				title: 'Error',
				message: 'We\'re sorry, but we could not restore the exam. Please refresh the page. If this message persists, please contact an administrator as soon as possible.'
			});
		}
		



	}




















	const [ examExpired, SetExamExpired ] = useState( false );
	
	const GetTimeRemaining = () => {
		if( !examInfo.ready ) {
			return (
				<p> Time Remaining: Calculating...</p>
			);
		}

		if( examExpired ) {
			return (
				<p> Time Remaining: -- </p>
			);
		}

		const minutes = Math.ceil(( ( examInfo.limit - examInfo.currentTime ) / 1000) / 60 );
		if( minutes < 2 ){
			
			return (
				<p> Time Remaining: {
					Math.floor( ( examInfo.limit - examInfo.currentTime ) / 1000)
				} seconds left</p>
			);
		}
		return (
			<p> Time Remaining: {
				minutes
			} minutes left</p>
		);
	}
/*
	const _StartClock= () => {
		
		//return () => setTimeout(interval);
	}*/
	const _StopClock = () => {
		clearTimeout(clockPointerRef.current);

	}

	
	useEffect( () => {
		//console.log('Use effect????', clockPointer, examInfo, examInfoRef.current );
		examInfoRef.current = examInfo;
		if( examInfo.examId ) {

			//TODO: remove this line
			//SetCurrentQuestion(currentQuestion);
			if(examInfo.limit < examInfo.currentTime) {
				SetExamExpired(true);
				_StopClock();
			}else {
				if( examInfo.ready && !clockPointer  ) {
					//_StartClock
					const interval = setInterval(() => {
						SetExamInfo({
							...examInfoRef.current,
							currentTime: moment().utc().tz( moment.tz.guess() ).unix()*1000,
						});
					}, 1000);
					SetClockPointer (interval);
					
				}
			}
			/*else{
				SetExamInfo({
					...examInfo,
					ready: true,
					limit: new Date(2020,9,11,15,0,0,0).getTime(),
					currentTime: new Date().getTime()
				});
			}
			 */
		
		}
	}, [examInfo, clockPointer] );


	useEffect( ()=> { 
		const myUser = props.UserState;
		myUser.lastRefreshPage = window.location.pathname;
		props.SetUserState(myUser);
		return () => {
			//on close.
			const myUser = props.UserState;
			//console.log('CFA Training check user.');
			if(myUser.loggedIn && myUser.lastRefreshPage !== window.location.pathname){
				return () => {
					//console.log('Refreshing user.');
					//on close.
					myUser.lastRefreshPage = window.location.pathname;
					myUser.refreshData = true;
					props.SetUserState(myUser);
				}
			}
		}
	}, [props] );
	
	//https://www.robinwieruch.de/react-state-array-add-update-remove

	const GetIncorrectTopics = () => { 
		//console.log('show grade exam', gradeExam);
		if( !gradeExam.incorrect ) {
			return <></>
		}
		if( gradeExam.incorrect.length ) { 
			return <><p>Here are the topic areas that you have gotten wrong:</p><ul>{
				gradeExam.incorrect.map( (item) => { 
					return <li>{item.subject}</li>
				})	
			}</ul></>
		}

		return <>
		</>
	}


	const GetExamState = () => {

		


		if( gradeExam.showGrade ) {

			if( gradeExam.passed ) {
				return ( 
					<>
						<div>
							<h3>You have passed the exam!</h3>
							<p>You scored {parseInt(gradeExam.score)} out of a possible {gradeExam.maxScore} points.</p>
							<p>You can continue to {(
								examInfo.creditId ? <Link to={`/ce-training/`}>your Continuing Education Course List</Link> : <Link to="/dashboard" >your Dashboard page</Link> 
							 )} and continue using the site from there.</p>
						</div>
						{GetIncorrectTopics()}
						<div>
							<hr className="slim-hr" />
							<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
						</div>
					</>
				);
			} else {
			
				return ( 
					<>
						<div>
							<h3>You did not pass the exam. </h3>
							<p>You scored {parseInt(gradeExam.score)} out of a possible {gradeExam.maxScore} points.</p> {(
								examInfo.creditId ? <p>Please try the exam again starting from the <Link to={`/ce-training/${examInfo.courseId}`}>Continuing Education Course</Link> Page. </p> : 
								<p>You may retry the exam in about 22 hours (After {moment().add(22, 'hours').format('LLL')}). </p>
							)}
						</div>
						{GetIncorrectTopics()}
						<div>
							<hr className="slim-hr" />
							<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
						</div>
					</>
				);
			}
		}
		if( examExpired ) {
			return ( 
				<>
					<div>
						<h3>Exam has expired at [{moment( examInfo.limit ).format('LLL')}]. Please go back to your <Link to="/exams">examination page</Link>.</h3>
					</div>
					<div>
						<hr className="slim-hr" />
						<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
					</div>
				</>
			);
		}



		return ( 
			<>
				<div>
					{
					showAfterTestScreen ? 
					(<>
						<h3> You have answered all the questions. </h3>
						<p> Now is the time to go back and review your answers or press the "Grade Exam" button below to close this exam and get your final grade. </p>
					</>) :

					_CreateQuestionDiv()
					
					}
					<div>
						<button className="button primary" disabled={examInfo.questionNumber < 2} onClick={(e) => {
							GoToPrevQuestion()
						}}><i className="fa fa-back" /><i className="fa fa-arrow-left" /> Previous</button>
						<button className="button primary float-right" 
							style={{display: showAfterTestScreen ? 'none' : 'inherit'}}
							disabled={!currentQuestion.selection}
							onClick={(e) => {
								GoToNextQuestion()
							}}> Next <i className="fa fa-arrow-right" /></button>
						<button className="button success float-right override-sebs " 
							style={{display: showAfterTestScreen ? 'inherit' : 'none'}}
							disabled={!currentQuestion.selection}
							onClick={(e) => {
								GoToGrade()
							}}><i className="fa fa-check"></i> Grade Exam </button>
					</div>
				</div>
			</>
		);
	}



	const UpdateQuestionAnswer = (e) => {
		SetCurrentQuestion({
			...currentQuestion,
			selection : Number(e.target.value)
		});
	}

	const _CreateQuestionDiv = () => {
		//console.log(examInfo.questionNumber);
		let questionNumber = '';
		let question = '';
		let answerTitle = '';
		let extraInfo = null;
		let answer1 = '';
		let answer2 = '';
		let answer3 = '';
		let answer4 = '';


		//console.log('CURRENT QUESTION: ', currentQuestion);

		//if( Number(props.UserState.user.spanishPref) === 1 ) {
		if ( examInfo.spanish === 1 ||  examInfo.spanish === true ) {
			questionNumber = `Pregunta #${examInfo.questionNumber}`;
			answerTitle = 'Respuestas';
			question = currentQuestion.spQuestion;
			answer1 = (
				<label>
					<div className="callout secondary exam-answer">
						<div className="exam-answer-input">
							<input type="radio" name="question" value="1" 
								onChange={ (e) => { UpdateQuestionAnswer(e)} } checked={currentQuestion.selection === 1} 
							/>
						</div>
						<div className="exam-answer-text">
							<span> {currentQuestion.spAns1} </span>
						</div>
					</div>
				</label>
			);
			answer2 = (
				<label>
					<div className="callout secondary exam-answer">
						<div className="exam-answer-input">
							<input type="radio" name="question" value="2" 
								onChange={ (e) => { UpdateQuestionAnswer(e)} } checked={currentQuestion.selection === 2} 
							/>
						</div>
						<div className="exam-answer-text">
							<span> {currentQuestion.spAns2} </span>
						</div>
					</div>
				</label>
			);
			answer3 = (
				<label>
					<div className="callout secondary exam-answer">
						<div className="exam-answer-input">
							<input type="radio" name="question" value="3" 
								onChange={ (e) => { UpdateQuestionAnswer(e)} } checked={currentQuestion.selection === 3} 
							/>
						</div>
						<div className="exam-answer-text">
							<span> {currentQuestion.spAns3} </span>
						</div>
					</div>
				</label>
			);
			answer4 = (
				<label>
					<div className="callout secondary exam-answer">
						<div className="exam-answer-input">
							<input type="radio" name="question" value="4" 
								onChange={ (e) => { UpdateQuestionAnswer(e)} } checked={currentQuestion.selection === 4} 
							/>
						</div>
						<div className="exam-answer-text">
							<span> {currentQuestion.spAns4} </span>
						</div>
					</div>
				</label>
			);
		}else{
			questionNumber = `Question #${examInfo.questionNumber}`;
			answerTitle = 'Answers';
			question = currentQuestion.question;
			answer1 = (
				<label>
					<div className="callout secondary exam-answer">
						<div className="exam-answer-input">
							<input type="radio" name="question" value="1" 
								onChange={ (e) => { UpdateQuestionAnswer(e)} } checked={currentQuestion.selection === 1} 
							/>
						</div>
						<div className="exam-answer-text">
							<span> {currentQuestion.answer1} </span>
						</div>
					</div>
				</label>
			);
			answer2 = (
				<label>
					<div className="callout secondary exam-answer">
						<div className="exam-answer-input">
							<input type="radio" name="question" value="2" 
								onChange={ (e) => { UpdateQuestionAnswer(e)} } checked={currentQuestion.selection === 2} 
							/>
						</div>
						<div className="exam-answer-text">
							<span> {currentQuestion.answer2} </span>
						</div>
					</div>
				</label>
			);
			answer3 = (
				<label>
					<div className="callout secondary exam-answer">
						<div className="exam-answer-input">
							<input type="radio" name="question" value="3" 
								onChange={ (e) => { UpdateQuestionAnswer(e)} } checked={currentQuestion.selection === 3} 
							/>
						</div>
						<div className="exam-answer-text">
							<span> {currentQuestion.answer3} </span>
						</div>
					</div>
				</label>
			);
			answer4 = (
				<label>
					<div className="callout secondary exam-answer">
						<div className="exam-answer-input">
							<input type="radio" name="question" value="4" 
								onChange={ (e) => { UpdateQuestionAnswer(e)} } checked={currentQuestion.selection === 4} 
							/>
						</div>
						<div className="exam-answer-text">
							<span> {currentQuestion.answer4} </span>
						</div>
					</div>
				</label>
			);
		}

		if( currentQuestion.extraInfo ) {
			extraInfo = (<div>{parse(currentQuestion.extraInfo)}</div>);
		}

		return (
			<>
				<div>
					<h3>{questionNumber}</h3>
					<h4>{question}</h4>
					{extraInfo}
					<hr />
					<h4>{answerTitle}: </h4>
					<div>{answer1}</div>
					<div>{answer2}</div>
					<div>{answer3}</div>
					<div>{answer4}</div>
				</div>
			</>
		);
	}

	const GetInstructionalPanel = () => {
		//console.log('Getting instruction panel...',isLoading, examInfo);
		if(  examInfo.examId !== null && examInfo.ready === false ) {
			//console.log('EXAM NEEDS TO BE RELOADED. ', isLoading, examInfo.examId, examInfo.ready );
			_RestoreExamState();
			return null;
		}
		if( !examInfo.timeMinutes ||  !examInfo.passingScore || !examInfo.numberOfQuestions ) {
			//console.log('Waiting for course info....');
			GetInitialExamInfo();
			return null;
		}

		let myTimeString = 'TIME';
		if( examInfo.timeMinutes < 60 ) {
			myTimeString = `${examInfo.timeMinutes} Minutes`;
		}else{
			myTimeString = `${Math.floor(examInfo.timeMinutes / 60)} Hours and ${(examInfo.timeMinutes % 60)} Minutes`;
		}
		

		return(
			<>
				<h2> Examination Room </h2>
				<p> You are about to start your Certification Exam.</p>
				<p>
					Once you enter the exam you will have <strong>{myTimeString}</strong> to answer <strong>{examInfo.numberOfQuestions} multiple choice questions</strong>. 
					Questions are presented to you one at a time and you will have the option to select questions that you would like to review a second time at the end of the exam. 
				</p>
				<p>
					Once you are done with the exam you will immediately receive your score (percentage of correct answers). 
					A score of <strong>{examInfo.passingScore}% or more</strong> automatically enters your name and affiliate information into the Certified Fertilizer Applicator list on the website (see Certified Applicators in the red navigation bar at the top of the web site). 
				</p>
				<p>
					<strong>Language: {( useSpanish === 1 ) ? 'español' : 'English' }</strong> <button type="button" className="button primary small"
						onClick={(e)=>{
							if( useSpanish === 1 ) {
								SetUseSpanish(0);
							}else{
								SetUseSpanish(1);
							}
						}}
					>
						{( useSpanish === 1 ) ? 'Change to English' : 'Cambiar a español' }
					</button>
				</p>
				<div style={{textAlign: 'center'}}>
					<Link to={
						( examInfo.creditId ? `/ce-training/${examInfo.courseId}` : `/exams`  )
					 } className="button large primary"><i className="fas fa-window-close"></i> I'm not ready.</Link>  <button className="button large success override-sebs"
						onClick={(e)=>{
							//console.log(e);
							StartNewExam();
						}}
					><i className="fa fa-clock"></i> Start The Exam </button>
				</div>
			</>
		);
	}
	const GetExamQuestionPanel = () => {
		return(
			<>
				<h2> Examination Room </h2>
				{GetTimeRemaining()}
				<div>
					<button type="button" 
						style={{display:'none'}}
						className="no-bottom-margin button primary small"
						onClick={(e)=>{
							if( useSpanish === 1 ) {
								SetUseSpanish(0);
							}else{
								SetUseSpanish(1);
							}
						}}
					>
						{( useSpanish === 1 ) ? 'Change Language to English' : 'Cambiar idioma a español' }
					</button>
					{(allQuestionsAsked)? (<>
						
					</>) :<></>}
				</div>
				<hr />
				<div id="main" className="grid-x grid-margin-x">
					<div className="cell small-12 medium-10 large-8 large-offset-2 medium-offset-1">
						{GetExamState()}
					</div>
				</div>
			</>
		);
	}

	if( startRedir ) {
		return ( <Redirect to={`/exam-room/${examInfo.courseId}${(
			examInfo.creditId ? `-${examInfo.creditId}` : ''
		)}/${examInfo.examId}`} /> );
	}

	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className=""> 
				<div id="main-col" className="">
					<div id="content">
					
						<div className={"callout " + examAlert.type} style={{display:examAlert.display}} >
							<h5>{examAlert.title}</h5>
							<p>{examAlert.message}</p>
							<p>Click here to go back to your dashboard page: <Link className="button primary" to="/dashboard" >Dashboard Page</Link></p>
						</div>
						{ 
							(examInfo.ready === false || examInfo.examId === null ) 
							? GetInstructionalPanel() : 
								GetExamQuestionPanel()
						}		
					</div>
				</div>
			</div>
		</main>
	);
}
export default CFAExamRoomPage;



//TRASH ZONE

		/*{
		id: 2343,
		questionNumber: 1,
		question: 'How does land development (clearing of forests to create farms or urban land) threaten water quality?',
		answer1: 'increases the amount of sediment (soil erosion) entering streams, rivers, ponds, lakes, and coastal waters',
		answer2: 'more nutrient inputs (including fertilizer) on crop and urban lands compared to forested land',
		answer3: 'compacted soil and land contours rapidly shed water, especially in urban land, which creates more storm water runoff compared to forested land',
		answer4: 'all of the above',
		
		spQuestion: 'How does land development (clearing of forests to create farms or urban land) threaten water quality?',
		spAnswer1: 'increases the amount of sediment (soil erosion) entering streams, rivers, ponds, lakes, and coastal waters',
		spAnswer2: 'more nutrient inputs (including fertilizer) on crop and urban lands compared to forested land',
		spAnswer3: 'compacted soil and land contours rapidly shed water, especially in urban land, which creates more storm water runoff compared to forested land',
		spAnswer4: 'all of the above',

		selection: null,
		extraInfo: null
	}*/