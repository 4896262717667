import React, {useState, useEffect} from 'react';
import { 
	get_ce_courses_and_cfa_credits,
	//get_course_content,
	//payment_history,
	get_tfas_by_cfas,  

	//insert_pending_online_payment,
	//retrieve_pending_online_payment,

	payment_cfa_history,
	payment_cfa_exam,
	payment_cfa_tfas,
	payment_cfa_course,
	payment_cfa_renewal,

	DECODE_TOKEN 
} from '../Api/api';
import { Link, Redirect
	//, useLocation 
} from 'react-router-dom';
import moment from 'moment-timezone';

import{
	USE_ACTION,
	USE_CODE,
	//USE_RETURN_URLS,
	UPDATE_CART_COST,
	PAYEE_NUMBER
} from '../Api/schoolpay.api';

import {
	USE_NELNET_URLS
} from '../Api/nelnet.api';

import Config from '../Config';
import ProcessPayment from './Components/PayComponents/ProcessPayment';


const CFAPaymentPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	const OVERRIDE_TEST_BUTTON = false;
	const SHOW_TEST_BUTTON = (OVERRIDE_TEST_BUTTON === null ? Config.IS_DEV_ENV(): OVERRIDE_TEST_BUTTON)

	const [redirectTo, setRedirectTo] = useState(null);


	//[General] Overall items 
	const [showContent, toggleShowContent] = useState( props.match.params.form ? props.match.params.form : 'history' );
	const [contentLoading, setContentLoading] = useState(true);
	const [payOption, setPayOption] = useState({
		option: 'credit-card'
	});

	const [disableThirdPartyBtn, SetDisableThirdPartyBtn] = useState(false);

	const [ nelnetGET, setNelnetGET] =useState({
		submit:0,
		action: '',
		orderType: '',
		orderName: '',
		orderDescription: '',
		amount: '',
		
		userChoice24: '',
		userChoice25: '',
		redirectUrl: '',
		redirectUrlParameters: '',
		retriesAllowed: '',
		timestamp: '',
		hash: ''
	});



	const [ schoolPayPOST, setSchoolPayPOST ] = useState({
		submit:0,
		///action: 'https://payserv.scholarchip.com/api/sso/open',
		//action: 'https://rutgers.schoolpay.com/api/sso/open',
		//action: 'https://stage.schoolpay.com/api/sso/open',
		action: USE_ACTION(),
		version:5,
		items:[ 
			//{"amount":"0.02","code":"Sdvb8dL","quantity":"1","title":"Test SP Checkout Item (Sdvb8dL) From Document"},
			//{"amount":"1.00","code":"Scj4sth","quantity":"1","title":"Test SP Checkout Item ( Sb9n6Od ) From payserve"},
			//{"amount":"1.00","code":"S9CMPD","quantity":"1","title":"Test SP Checkout Item ( S9CMPD ) From payserve"},  
			//{"amount":"25.00","code":"Sdvb8dL","quantity":"1","title":"Test SP Checkout Item (Sdvb8dL) From Document"},
			//{"amount":"30.00","code":"Sb9n6Od","quantity":"1","title":"Test SP Checkout Item (Sb9n6Od)"},
			//{"amount":"40.00","code":"does_not_exist","quantity":"1","title":"Test SP Checkout Item (DNE)"}
		],
		return_url:'',
		notification_url:'',
		//provider:'ruprofact',
		provider: '',
		student_id: '',
		//provider:'ruprofact',
		//student_id: 'N/A',
	});
	console.log('TODO: Remove refs; CFA Payment Silence: ', setSchoolPayPOST);
	const WARNING_REMINDER_NOTIFICATION = (
		<div className={"callout warning"}>
			<h5>As a reminder:</h5>	
			<p>
				If you see any errors during the payment process, please contact the ProFACT office (848-932-6373) immediately for support, 
					preferably while any error message is still on your screen.
			</p>
		</div>
	);
	//[General] disable buttons if necessary
	const [disableCFAExamBtn, setDisableCFAExamBtn] = useState( props.UserState.user.examPaid === true );
	const [disableTfaBtn, setDisableTFABtn] = useState( !props.UserState.user.examPassed);
	const [disableRenewalBtn, setDisableRenewalBtn] = useState( !props.UserState.user.examPassed);
	const [disableCECourseBtn, setDisableCEBtn] = useState( !props.UserState.user.examPassed);

	//[Payment History] User Table Filter 
	const [filterText, filterByText] = useState( '' );
	const [paymentHistoryTable, setPaymentHistoryTable] = useState([]);

	//[Unpaid TFA Tab] 
	const [unpaidTfas, setUnpaidTfas] = useState([]);
	const [tfaCost, setTfaCost] = useState(0.00);

	//[CE Course Tab]
	const [selectedCourse, setSelectedCourse] = useState(null);
	const [courseList, setCourseList] = useState({
		cfaExam:{},
		ceCourses: [],
		isLoading: true
	});
	
	//[Renewal Tab] 
	//Let's prepop the list for them 
	//console.log(props.UserState);
	const INITIAL_RENEWAL = [];
	const myCycleYear = props.UserState.user.cycleYear;
	const myActiveYear = props.UserState.user.activeYear;

	for( let startOfCycle = myCycleYear-4; startOfCycle<=myCycleYear; startOfCycle++ ) {
		if( startOfCycle <= myActiveYear ) {
			INITIAL_RENEWAL.push( {
				year: startOfCycle,
				isPaid: true,
				isChosen: false,
			});
			/*
			myRenewalButtons.push(<span>
				<i className="fa fa-certificate"></i> {startOfCycle} Paid!
			</span>);*/
		}else{
			INITIAL_RENEWAL.push( {
				year: startOfCycle,
				isPaid: false,
				isChosen: false,
			});
		}
	}
	
	const [renewalOptions, setRenewalOptions] = useState(INITIAL_RENEWAL);
	const [renewalCost, setRenewalCost] = useState(0.00);

	//remove this when not needed; only here to suppress warnings
	
	useEffect( () => {
		setDisableCFAExamBtn(disableCFAExamBtn);
		setDisableTFABtn(disableTfaBtn);
		setDisableCEBtn(disableCECourseBtn);
		setDisableRenewalBtn(disableRenewalBtn);
	}, [disableCECourseBtn, disableCFAExamBtn, disableTfaBtn, disableRenewalBtn]);
	


	useEffect( () => {
		if( nelnetGET.submit !== 0) {
			//document.getElementById('nelnetGET_form').submit();
			window.open(nelnetGET.action, '_self');
		}
	}, [nelnetGET.submit, nelnetGET.action] );


	useEffect( ()=> {
		setContentLoading(true);

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		//console.log(tokenInfo);
		const currentYear = new Date().getFullYear() ;

		get_tfas_by_cfas( tokenInfo.getAuthToken, tokenInfo.getId.id ) 
		.then( (res) => {
			//console.log('TFAs by CFA: ', res.data);
			if( res.data.data && res.data.code === 'TFA_BY_CFA') {
				//Let's pull out the cfa exam vs. ce courses
				setUnpaidTfas( 
					res.data.data
					//.filter( (tfaItem) => { return !tfaItem.payId; } )
					.filter( (tfaItem) => { 
						return !tfaItem.payId || tfaItem.activeYear < currentYear; 
					} )
					.map( (tfaItem) => { tfaItem.isChosen = false; return tfaItem }) 
				);
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting [TFA_BY_CFA] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch TFAs for this CFA.');
			}
			setUnpaidTfas( [] );
		});

		get_ce_courses_and_cfa_credits(tokenInfo.getAuthToken, tokenInfo.getId.id)
		.then( (res) => {
			//console.log('Retrieving CE courses and CFA CE Credit items.', res);
			//const [ courseList, cfaCredits ] = [
				//res[0].data.filter, res[1].data
			//];
			if( res[0].status !== 200 || res[1].status !== 200 ) {
				throw res;
			}
			if( res[0].data.code !== 'ACTIVE_COURSES' || res[1].data.code !== 'OK'){
				throw res;
			}

			
			const myCourses = {
				cfaExam:{},
				ceCourses: [],
				isLoading: false
			};
			

			//Remove CFA Exam
			myCourses.cfaExam = res[0].data.data.find( (courseInfo) => { return courseInfo.title.toLowerCase() === 'cfa exam'; } );
			//console.log('A');
			const [ courseList, cfaCredits ]  = [
				//res[0].data.data.find( (courseInfo) => { return courseInfo.title.toLowerCase() === 'cfa exam'; } ),
				res[0].data.data.filter( (courseInfo) => { return courseInfo.title.toLowerCase() !== 'cfa exam'; } ),
				res[1].data.credits.filter( (courseInfo) => { return courseInfo.courseId !== myCourses.cfaExam.courseId; } ),
			];
			//console.log('B');
			courseList.forEach( (myCourseInfo) =>{ 
				const myCfaCreditItem = cfaCredits.find( (courseInfo) => { return courseInfo.courseId === myCourseInfo.courseId; } );
				myCourseInfo.creditObj = myCfaCreditItem;
			} );
			//console.log('C');
			myCourses.ceCourses = courseList.filter( (courseItem) => { return courseItem.creditObj });
			
			//console.log('D');
			//console.log(myCourses.cfaExam);
			//setPageIsLoading(false);
			setCourseList(myCourses);

		} )
		.catch( error => {

			console.log('Retrieving CE courses and CFA CE Credit items.', error);
			setCourseList(null);
		});

		payment_cfa_history(tokenInfo.getAuthToken,  tokenInfo.getId.id)
		.then( (res) => {
			if( res.data.history && res.data.code === 'CFA_PAYMENT_HISTORY') {
				//create book
				setContentLoading(false);
				setPaymentHistoryTable(res.data.history);
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting CFA\'s history failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Payment History. ');
			}
			setContentLoading(false);
			setPaymentHistoryTable(null);
		});

	}, [] );
	

	const payOption_HandleChange = (e_target) => {
		setPayOption( {
			...payOption,
			option: e_target.value
		});
	}
	
	const parseCart = (cartInfo) => {
		try{
			return JSON.parse(cartInfo);
		} catch (objError) {
			return {"items":[],"comments":"Error loading cart."};
		}
	}
	const getCartDate = (cartItems) => {
		if( !cartItems ) {
			return 'N/A';
		}
		const myShoppingCart =  parseCart(cartItems); //JSON.parse(cartItems);
		if( myShoppingCart.originalDate ) {
			return myShoppingCart.originalDate;
		}

		return null;
	}
	

	const getCartItems = (payPurpose, cartItems) => {
		///const myShoppingCart = JSON.parse(cartItems);
		const myShoppingCart =  parseCart(cartItems); //JSON.parse(cartItems);
		const myCart = (
			myShoppingCart.items ?
			myShoppingCart.items
			: myShoppingCart
		);
		
		if( myCart && myCart.orderId ){
			return `Old Order: ${myCart.orderId}`;
		}
		if( myCart && (myCart.name || myCart.phone) ){
			const myInfo = [];
			if( myCart.name ) { myInfo.push( myCart.name) }
			if( myCart.phone ) { myInfo.push( 'Ph: ' + myCart.phone) }
			return `${myInfo.join('; ')}`;
		}

		//console.log(myCart);
		if( payPurpose.toLowerCase() === 'exam' ) {
			if( myCart[0] ) {
				return `CFA Exam ($${myCart[0].amount})`;
			}
			return `CFA Exam Purchase`;
		}else if( payPurpose.toLowerCase() === 'tfa' ) {
			let numTfas = 0;
			let tfaCost= 0.0;
			
			if( myCart.forEach) {
				myCart.forEach((tfa) => {
					numTfas++;
					tfaCost += tfa.amount;
				});
				//return `TFAs (${numTfas} TFA for $${tfaCost})`;
				return `${numTfas} TFAs for $${tfaCost}`;
			}
			return `TFA Purchase`;
		}else if( payPurpose.toLowerCase() === 'course' ) {
			if( myCart[0] ) {
				return `CE Course "${myCart[0].title}" ($${myCart[0].amount})`;
			}
			return `CE Course Purchase`;
		}else if( payPurpose.toLowerCase() === 'renewal' ) {
			//let numRenews = 0;
			//let renewCost= 0.0;
			if( myCart.forEach) {
				const renewals = [];
				myCart.forEach((renew) => {
					if( !renew.year ) { return; }
					//numRenews++; 
					//renewCost += renew.amount;
					renewals.push( renew.year );
				});
				return `Renewals: ${renewals.join(', ')}`;
				//return `Renewals (${numRenews}  for $${renewCost})`;
			}
			return `Renewal Purchase`;
		}


		return '';
	}
	const OutputPaymentHistory = () => {
		if( contentLoading ) {
			return (
				<tr><td colSpan="11"><i className="fas fa-circle-notch fa-spin"></i></td></tr>
			);
		}
		if( !paymentHistoryTable ) {
			return (
				<tr><td colSpan="11">Could not retrieve your payment history.</td></tr>
			);
		}

		if( paymentHistoryTable.length < 1 ) {
			return (
				<tr><td colSpan="11">No Payment History yet.</td></tr>
			);
		}
		return paymentHistoryTable
		.filter( (paymentObj) => {
			if( filterText === '' ) { 
				return true;
			}
			const myFilterText = filterText.toLocaleLowerCase().trim();
			return (
				paymentObj.payPurpose.toLocaleLowerCase().includes( myFilterText )
				|| paymentObj.payConf.toLocaleLowerCase().includes( myFilterText )
				|| paymentObj.invoiceNumber.toLocaleLowerCase().includes( myFilterText )
				|| paymentObj.poNumber.toString().toLocaleLowerCase().includes( myFilterText )
			);
		}).map( (paymentObj) => {
			//console.log(paymentObj);
			return (
				<tr>
					<td>{paymentObj.id}</td>
					<td>{paymentObj.payPurpose}</td>
					<td>{paymentObj.payConf}</td>
					<td>{paymentObj.invoiceNumber}</td>
					<td>{paymentObj.poNumber}</td>
					<td>{paymentObj.payAmount.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})}</td>
					{paymentObj.paid ? <td>Yes</td> : (<td style={{backgroundColor:'lightgray'}}><strong>No</strong></td>) }
					<td>{
							getCartDate(paymentObj.cart ) ||
							moment.tz(paymentObj.updatedAt,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
							.tz( moment.tz.guess() )
							.format('L LT')
					}</td>
					<td>{paymentObj.cart ? getCartItems(paymentObj.payPurpose, paymentObj.cart) : 'N/A'}</td>
				</tr>
			);
		});
	}

	//Payment history "tab"
	const handleFilterOnChange = event => {
		filterByText(event.target.value);
	};
	const paymentHistory = () => {
		return (
			
			<div style={{display: ( showContent === 'history' ? 'block' : 'none' )}}>
				<div>
					<h4>Find Payment</h4>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="filter-payment" className="label-style-1">Filter By: </label>
						</div>
						<div className="medium-5 small-12 columns no-float">
							<input type="text" id="filter-payment" placeholder="Conf. Number, PO, Invoice." 
								onChange={handleFilterOnChange}
							/>
						</div>
						<div className="small-12 columns no-float">
							<p><em>If you would like information regarding previous payment history, please contact <a href={`mailto:${Config.UNIT_EMAIL}`}>{Config.UNIT_EMAIL}</a></em></p>
						</div>
					</div>
				</div>
				<div className="table-scroll">
					<table className="table-left-align-all">
						<thead>
							<tr>
								<th>Id</th>
								<th>Purpose</th>
								<th>Confirmation Number</th>
								<th>Invoice</th>
								<th>PO Number</th>
								<th>Cost</th>
								<th>Paid/Approved</th>
								<th>Date</th>
								<th>Details</th>
							</tr>
						</thead>
						<tbody>
							{OutputPaymentHistory()}
						</tbody>
					</table>
				</div>
			</div>
		);
	}

	//Payment by PO / invoice instructions
	
	const paymentByPoInvoiceCheck = () => {
		return (
			
			<div style={{display: ( showContent === 'po-invoice-check' ? 'block' : 'none' )}}>
				<div>
					<h4>Payment Instructions</h4>
					<div>
						{WARNING_REMINDER_NOTIFICATION}
						<h4> Paying by Credit Card / E-Check</h4>
						<p> You will be taken to a secure third party payment processor for Credit Card or E-Check payment.  </p>
						<h4> Paying by PO / Invoice / Check</h4>
						<p> For purchase orders, invoices, or checks, please contact​ our {Config.UNIT_NAME} Office at {Config.PHONE_NUMBER} to make arrangements. </p>
					</div>
				</div>
			</div>
		);
	}
	/*
	Old instructions 
	
						<h4> Paying by Check</h4>
						<p> Please send a check to <code>{Config.SCHOOL_NAME}</code> at <code>{Config.STREET_ADDRESS} {Config.CITY_STATE_ZIP}</code> </p>
	*/

	const getConfNumber = (prepend, length = 9) => {
		return prepend + Math.random().toString().substr(2, length);
	}
	
	const genCheckNumbers = {
		cfaExamCheck: getConfNumber('CE', 8),
		tfaCheck: getConfNumber('TF', 8),
		renewCheck: getConfNumber('RN', 8),
		ceCheck: getConfNumber('CC', 8),
	};

	//CFA Exam form
	const cfaExamForm = () => { 
		if( !courseList || courseList.isLoading ) {
			return(
				<div style={{display: ( showContent === 'cfa-exam' ? 'block' : 'none' )}}>
					<i className="fas fa-circle-notch fa-spin"></i> Loading form...
				</div>
			)
		}

		const getTestMethod = ( SHOW_TEST_BUTTON ? (
			<div className="small-12 cell">
				<label htmlFor="cfaExamPayType_test" className="">
					<input type="radio" 
						name="cfaExamPayType" id="cfaExamPayType_test" 
						value='test'
						checked={(payOption.option==='test' )}
						onClick={(e)=>{ payOption_HandleChange(e.target); }}
					/>
					TEST
				</label>
			</div>
		) : null );
		const getTestButton = ( SHOW_TEST_BUTTON ? (
			<div style={{display:(payOption.option==='test' ? 'block': 'none' ) }}> {
					Config.IS_DEV_ENV ?
					(<button className="button small warning" 
						onClick={(e) =>{ FAKE_startCfaExamPayment(true, getConfNumber('TEST'), 90.00) }}>
						[IS_DEV_ENV=TRUE] FAKE EXAM PAYMENT SUCCEESS
					</button>) : <></>
				}
			</div>
		): null );
		return (
			<div style={{display: ( showContent === 'cfa-exam' ? 'block' : 'none' )}}>
				{WARNING_REMINDER_NOTIFICATION}
				<h4>What you will be paying for:</h4>
				<ul>
					<li>Course: {courseList.cfaExam.title} </li>
					<li>Cost: {Number(courseList.cfaExam.cost).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </li>
				</ul>
				<div style={{display: ( SHOW_TEST_BUTTON ? 'block' : 'none' ) }}>
					<h4>Payment Method</h4>
					<div className="grid-x grid-margin-x">
						{getTestMethod}
						<div className="small-12 cell">
							<label htmlFor="cfaExamPayType_cc" className="">
								<input type="radio" 
									name="cfaExamPayType" id="cfaExamPayType_cc" 
									value='credit-card'
									checked={(payOption.option==='credit-card' )}
									onClick={(e)=>{ payOption_HandleChange(e.target); }}
								/>
								Credit Card / E-check
							</label>
						</div>
					</div>
				</div>
				{getTestButton}
				<div style={{display:(payOption.option==='credit-card' ? 'block': 'none' ) }}>
					<button className="button small primary" 
						disabled={disableThirdPartyBtn}
						onClick={(e) =>{ startCfaExamPayment(90.00) }}>
						Continue to Third Party Payment System 
					</button>
				</div>
			</div>
		);
	}
	
	// > CFA exam form actions
	const FAKE_startCfaExamPayment = (paid, payConf, amount) => {

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}


		const myItems = [
			//{"amount":amount,"code":"Sdvb8dL","quantity":"1","title":"CFA Exam"}
			{"amount":UPDATE_CART_COST(amount),"code":USE_CODE(schoolPayPOST.action),"quantity":"1","title":"CFA Exam"}
		];


		payment_cfa_exam( tokenInfo.getAuthToken, tokenInfo.getId.id,
			paid, payConf, amount, JSON.stringify(myItems)

		).then( (res) => {
			console.log('Payment for exam complete: ', res.data);
			if( res.data.code === 'EXAM_PAID') {
				//Let's pull out the cfa exam vs. ce courses
				window.location.reload();
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Payment for examination failed..', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch TFAs for this CFA.');
			}
		});
	}
	const startCfaExamPayment = (cost) => { 
		console.log('Starting CFA Exam payment.');
		SetDisableThirdPartyBtn(true);
		//will most likely mimic function startCoursePayment() due to the nature of this course
		const myItems = [];
		const payConf = genCheckNumbers.cfaExamCheck; //PAY_INFO.payConf;
		//{"amount":cost,"code":"Sdvb8dL","quantity":"1","title":"CFA Exam"}
		myItems.push({
			amount: UPDATE_CART_COST(cost),
			//code:"Sdvb8dL",
			code: USE_CODE(schoolPayPOST.action),
			quantity:1,
			title: "CFA Exam",
			payee: PAYEE_NUMBER,
			custom_fields: {type:'exam', payConf:payConf, amount:UPDATE_CART_COST(cost)}
		});

		const myDescription = `One CFA Exam ($${cost})`;

		const myCompleteCartItems = {items: myItems, comments:`System Comment: THIRD PARTY PAYMENT. SUBMITTED ON [${moment().format('LLLL')}]. `}
		const paid=false;
		const amount = (
			myItems.items
			&& myItems.items[0]
			? myItems.items[0].amount
			: 90
		);

		//const token = tokenInfo.getAuthToken;
		//const getId = tokenInfo.getId.id;

/*
		setSchoolPayPOST({
			...schoolPayPOST,
			items: ,
			submit: schoolPayPOST.submit+1
		});*/
		USE_NELNET_URLS(
			genCheckNumbers.cfaExamCheck, myItems, 'exam'
			, paid, payConf, amount, JSON.stringify(myCompleteCartItems),
			myDescription
		)
		.then ( (myNelnetObj) => {
			/*
			console.log('And here are the CFA Exam items to check the return URL: ',
			 myItems, myReturnUrls);
			 */
				console.log('CFA EXAM OBJ:', myNelnetObj);

				setNelnetGET({
					submit:nelnetGET.submit+1,
					action: myNelnetObj.fullUrl,
				});
				/*

				setNelnetGET({
					submit: nelnetGET.submit+1,
					//action: myNelnetObj.action_url,
					action: myNelnetObj.fullUrl,
					
					orderType: myNelnetObj.orderType,
					orderName: 'CFA Exam',
					orderDescription: myDescription,
					amount: (amount*100),
					
					userChoice24: myNelnetObj.userChoice24,
					userChoice25: myNelnetObj.userChoice25,
					redirectUrl: myNelnetObj.redirectUrl,
					redirectUrlParameters: myNelnetObj.redirectUrlParameters,
					retriesAllowed: myNelnetObj.retriesAllowed,
					timestamp: myNelnetObj.timestamp,
					hash: myNelnetObj.hash
				})
				*/
				/*
			if( !myReturnUrls || !myReturnUrls.return_url || !myReturnUrls.notification_url ) {
				alert('There was a problem storing some information.');
				SetDisableThirdPartyBtn(false);
			}else{
				//console.log('RETURN URLS TEMP: ', myReturnUrls);




				setSchoolPayPOST({
					...schoolPayPOST,
					return_url: myReturnUrls.return_url,
					notification_url: myReturnUrls.notification_url,
					items:myItems,
					submit: schoolPayPOST.submit+1
				});
				SetDisableThirdPartyBtn(false);
			}
				*/
		});
		return true;
	}
	
	
//TFA Exam form
	//(un/)select the TFA and update the cost.
	const selectTfa = (unpaidTfaInfo) => {
		let myTfaCost = 0.00;
		
		const myUnpaidTfas = unpaidTfas.map( (tfaInfo) => {
			if( tfaInfo.id === unpaidTfaInfo.id ) {
				tfaInfo.isChosen=!tfaInfo.isChosen;
			}

			if( tfaInfo.isChosen ) {
				myTfaCost+=40.00;
			}
			return tfaInfo;
		});
		setUnpaidTfas(myUnpaidTfas);
		setTfaCost(myTfaCost);
	}
	const unpaidTfaForm = () => { 


		const getTestMethod = ( SHOW_TEST_BUTTON ? (
			<div className="small-12 cell">
				<label htmlFor="tfaPayType_test" className="">
					<input type="radio" 
						name="tfaPayType" id="tfaPayType_test" 
						value='test'
						checked={(payOption.option==='test' )}
						onClick={(e)=>{ payOption_HandleChange(e.target); }}
						disabled={tfaCost === 0}
					/>
					Test
				</label>
			</div>
		) : null );
		const getTestButton = ( SHOW_TEST_BUTTON ? (
			<div style={{display:(payOption.option==='test' ? 'block': 'none' ) }}> {
					Config.IS_DEV_ENV ?
					(<button className="button small warning" 
						disabled={tfaCost === 0}
						onClick={(e) =>{ FAKE_startTfaPayment(true, getConfNumber('TEST')/*`TEST_CONF_TFA_${unpaidTfas.length}`*/, tfaCost) }}>
						[IS_DEV_ENV=TRUE] FAKE TFA PAYMENT SUCCEESS
					</button>) : <></>
				}
			</div>
		): null );





		return (
			<div style={{display: ( showContent === 'unpaid-tfas' ? 'block' : 'none' )}}>
				{WARNING_REMINDER_NOTIFICATION}
				<h4>
					If the TFA that you are looking to pay for is not here, please visit <Link to="/cfa-tfa" className="button tiny no-bottom-margin" >Your TFA Page</Link> and add them there.
				</h4>
				<h4> Please check off who you would like to pay for below.</h4>
				<h5>Each TFA will cost <strong>$40.00</strong>.</h5>
				<div>
					<ul className="no-ul-bullet">
					{
						(unpaidTfas.length < 1) ?
						<li> You have no TFAs you need to pay for this year! </li>
						:
						//onClick={(e) =>{ selectTheCeCourse( courseInfo ) }}>
						unpaidTfas.map( (unpaidTfaInfo, key)  => {
							return (
								<li key={key}> 
									{
										unpaidTfaInfo.isChosen ?
										(
											<button className={
												"override-sebs button small success vertical-align-middle no-bottom-margin" 
											}
											onClick={(e) =>{ selectTfa( unpaidTfaInfo ) }}>
											<i className="fa fa-user-times"></i> Remove TFA</button> 
										) :  (
											<button className={
												"button small primary vertical-align-middle no-bottom-margin"
											}
											onClick={(e) =>{ selectTfa( unpaidTfaInfo ) }}>
											<i className="fa fa-user-plus"></i> Add TFA</button> 
										)
									}									
									{unpaidTfaInfo.firstName} {unpaidTfaInfo.lastName}</li>
							);
						})
					}
					</ul>
				</div>
				<p><strong>Total Cost: {Number(tfaCost).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></p>
				<div style={{display: ( SHOW_TEST_BUTTON ? 'block' : 'none' ) }}>
					<h4>Payment Method</h4>
					<div className="grid-x grid-margin-x">
						{getTestMethod}
						
						<div className="small-12 cell">
							<label htmlFor="tfaPayType_cc" className="">
								<input type="radio" 
									name="tfaPayType" id="tfaPayType_cc" 
									value='credit-card'
									checked={(payOption.option==='credit-card' )}
									onClick={(e)=>{ payOption_HandleChange(e.target); }}
									disabled={tfaCost === 0}
								/>
								Credit Card / E-check
							</label>
						</div>
					</div>
				</div>
				
				<div style={{display:(tfaCost === 0 ?'none':'block')}}>
					{getTestButton}
					<div style={{display:(payOption.option==='credit-card' ? 'block': 'none' ) }}>
						<button className="button small primary" 
							disabled={tfaCost === 0 || disableThirdPartyBtn}
							onClick={(e) =>{ startTfaPayment(unpaidTfas) }}>
							Continue to Third Party Payment System 
						</button> 
					</div>
				</div>
			</div>
		);
	}
	const FAKE_startTfaPayment = (paid, payConf, amount) => {
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		
		//Compile tfas into a nice array :3
		const costPerTFA = 40.00;
		const myItems = [];
		const myTfas = unpaidTfas.filter((unpaidTfaInfo) => { return unpaidTfaInfo.isChosen; }).map( (unpaidTfaInfo)  => {
			myItems.push({
				amount: UPDATE_CART_COST(costPerTFA),
				//code:"Sdvb8dL",
				code: USE_CODE(schoolPayPOST.action),
				quantity:1,
				title: unpaidTfaInfo.lastName + ', ' + unpaidTfaInfo.firstName + ' (' + unpaidTfaInfo.id+ ')',
				tfaId: unpaidTfaInfo.id,
				payee: PAYEE_NUMBER
			});
			return unpaidTfaInfo.id;
		});

		//console.log(myItems, myTfas);
		
		payment_cfa_tfas( tokenInfo.getAuthToken, tokenInfo.getId.id,
			myTfas, paid, payConf, amount, JSON.stringify(myItems)
		).then( (res) => {
			//console.log('Payment for exam complete: ', res.data);
			if( res.data.code === 'UPDATED_TFA_PAID') {
				//Let's pull out the cfa exam vs. ce courses
				if( props.match.params.form === 'unpaid-tfas' ) {
					setRedirectTo('/cfa-tfa');
				}else{
					window.location.reload();
				}
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Payment for examination failed..', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch TFAs for this CFA.');
			}
		});
	}
	const startTfaPayment = (unpaidTfas) => { 
		console.log('Starting Unpaid TFA payment.');
		SetDisableThirdPartyBtn(true);
		const costPerTFA = 40.00;
		const myItems = [];
		let amount = 0;
		const payConf = genCheckNumbers.tfaCheck; //PAY_INFO.payConf;
		const descriptionArr = [];
		const shortDescription = [];
		const tfaIdsOnly = [];
		
		const myTfas = unpaidTfas.filter((unpaidTfaInfo) => { return unpaidTfaInfo.isChosen; })
		.map( (unpaidTfaInfo)  => {
			myItems.push({
				amount: UPDATE_CART_COST(costPerTFA),
				//code:"Sdvb8dL",
				code: USE_CODE(schoolPayPOST.action),
				quantity:1,
				title: unpaidTfaInfo.lastName + ', ' + unpaidTfaInfo.firstName + ' (' + unpaidTfaInfo.id+ ')',
				tfaId: unpaidTfaInfo.id,
				payee: PAYEE_NUMBER,
				custom_fields: {type:'tfa', tfaId:unpaidTfaInfo.id, payConf:payConf, amount: UPDATE_CART_COST(costPerTFA)}
			});
			amount+=costPerTFA;
			descriptionArr.push('[' + unpaidTfaInfo.lastName + ', ' + unpaidTfaInfo.firstName + ' (ID: ' + unpaidTfaInfo.id+ ')]');
			shortDescription.push('[' +  unpaidTfaInfo.firstName.charAt(0) + unpaidTfaInfo.lastName.charAt(0) + ' (' + unpaidTfaInfo.id+ ')]');
			tfaIdsOnly.push('' + unpaidTfaInfo.id+ '');
			return unpaidTfaInfo.id;
		});


		//console.log(myItems, myTfas);

		let tempDescription = `TFAs: (${descriptionArr.join(', ')}) at $${costPerTFA} each.`;

		if(tempDescription.length > 249){
			tempDescription= `TFAs: (${shortDescription.join(', ')}) at $${costPerTFA} each.`;
			if(tempDescription.length > 249){
				tempDescription= `TFA IDs: (${tfaIdsOnly.join(', ')}) at $${costPerTFA} each.`;
				if(tempDescription.length > 249){
					tempDescription = `${tfaIdsOnly.length} TFAs at $${costPerTFA} each.`;
				}
			}
		}


		const description = tempDescription;


		const myCompleteCartItems = {items: myItems, comments:`System Comment: THIRD PARTY PAYMENT. SUBMITTED ON [${moment().format('LLLL')}]. `}
		const paid=false;
		

		USE_NELNET_URLS(
			genCheckNumbers.tfaCheck, myItems, 'tfa'
			, paid, payConf, amount, JSON.stringify(myCompleteCartItems),
			description, 
			myTfas
		)

		//USE_RETURN_URL(genCheckNumbers.tfaCheck, myItems, 'tfa')
		.then ( (myNelnetObj) => {
			
			console.log('TFA Payment OBJ:', myNelnetObj);

			setNelnetGET({
			   submit:nelnetGET.submit+1,
				action: myNelnetObj.fullUrl,
			});
   /*

			/*
			console.log('And here are the TFA items to check the return URL: ', 
				myItems, myReturnUrls);
				*/
	/*
			if( !myReturnUrls || !myReturnUrls.return_url || !myReturnUrls.notification_url ) {
				alert('There was a problem storing some information.');
				SetDisableThirdPartyBtn(false);
			}else{
				//console.log('RETURN URLS TEMP: ', myReturnUrls);
				
				setSchoolPayPOST({
					...schoolPayPOST,
					return_url: myReturnUrls.return_url,
					notification_url: myReturnUrls.notification_url,
					items:myItems,
					submit: schoolPayPOST.submit+1
				});
				SetDisableThirdPartyBtn(false);
				
			}
			*/
		});
		/*
		console.log(myItems, myTfas);

		console.log('And here are the TFA items to check the return URL: ', USE_RETURN_URL( genCheckNumbers.tfaCheck, myItems, 'tfa'));
		
		setSchoolPayPOST({
			...schoolPayPOST,
			return_url:USE_RETURN_URL(genCheckNumbers.tfaCheck, myItems, 'tfa'),
			items:myItems,
			submit: schoolPayPOST.submit+1
		});
		
*/
		return true;
		
	}
	//Continuing Ed Form
	/*
		<div>
			<button className="button small primary" 
				onClick={(e) =>{ startTfaPayment() }}>
			Continue to Third Party Payment System </button>
		</div>
	*/
	const ceCourseForm = () => { 
		//if we selected a course from the list (would only like to pay for one at a time), we'll display that with stats.

		if( !courseList || courseList.isLoading ) {
			return(
				<div style={{display: ( showContent === 'ce-course' ? 'block' : 'none' )}}>
					<i className="fas fa-circle-notch fa-spin"></i> Loading form...
				</div>
			)
		}

		return (
			<div style={{display: ( showContent === 'ce-course' ? 'block' : 'none' )}}>
				{WARNING_REMINDER_NOTIFICATION}
				{(
					(selectedCourse !== null )?
					ceCourseSingle()
					: ceCourseSelect()
				)}
			</div>
		);
	}
	const ceCourseSingle = () => {


		const getTestMethod = ( SHOW_TEST_BUTTON ? (
			<div className="small-12 cell">
				<label htmlFor="course_test" className="">
					<input type="radio" 
						name="course" id="course_test" 
						value='test'
						checked={(payOption.option==='test' )}
						onClick={(e)=>{ payOption_HandleChange(e.target); }}
					/>
					Test
				</label>
			</div>
		) : null );
		const getTestButton = ( SHOW_TEST_BUTTON ? (
			<div className="button-group" style={{display:(payOption.option==='test' ? 'block': 'none' ) }}>
				
				<button className="button small alert" 
					onClick={(e) =>{ selectTheCeCourse(null) }}>
				<i className="fa fa-backspace"></i> Cancel </button>{
					Config.IS_DEV_ENV ?
					(<button className="button small warning" 
						onClick={(e) =>{ 
							FAKE_startCoursePayment(selectedCourse.creditObj.creditId, true, 
							getConfNumber('TEST')/*'TEST_COURSE_BUY'*/, selectedCourse.cost ) }
						}>
						[IS_DEV_ENV=TRUE] FAKE COURSE PAYMENT SUCCESS
					</button>) : <></>
				}
			</div>
		): null );


		return (
			<>
			<h4>What you will be paying for:</h4>
			<ul>
				<li>Course: {selectedCourse.title} </li>
				<li>Cost: {
					selectedCourse.cost <= 0.00 ? 'Free' :
					Number(selectedCourse.cost).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
				} </li>
			</ul>



			<div style={{display: ( SHOW_TEST_BUTTON ? 'block' : 'none' ) }}>
				<h4>Payment Method</h4>
				<div className="grid-x grid-margin-x">
					{getTestMethod}
					<div className="small-12 cell">
						<label htmlFor="course_cc" className="">
							<input type="radio" 
								name="course" id="course_cc" 
								value='credit-card'
								checked={(payOption.option==='credit-card' )}
								onClick={(e)=>{ payOption_HandleChange(e.target); }}
							/>
							Credit Card / E-check
						</label>
					</div>
				</div>
			</div>
			{getTestButton}
			<div className="button-group" style={{display:(payOption.option==='credit-card' ? 'block': 'none' ) }}>
				
				<button className="button small alert" 
					onClick={(e) =>{ selectTheCeCourse(null) }}>
				<i className="fa fa-backspace"></i> Cancel </button>
				<button className="button small primary" 
					disabled={disableThirdPartyBtn}
					onClick={(e) =>{ startCoursePayment(
						/*selectedCourse.creditObj.creditId, selectedCourse.cost
					*/) }}>
				Continue to Third Party Payment System </button> 
			</div>
			</>
		);
	}

	/*
	
				<div className="small-12 cell">
					<label htmlFor="course_poinvoice" className="">
						<input type="radio" 
							name="course" id="course_poinvoice" 
							value='po-invoice'
							checked={(payOption.option==='po-invoice' )}
							onClick={(e)=>{ payOption_HandleChange(e.target); }}
						/>
						PO / Invoice
					</label>
				</div>
				<div className="small-12 cell">
					<label htmlFor="course_check" className="">
						<input type="radio" 
							name="course" id="course_check" 
							value='check'
							checked={(payOption.option==='check' )}
							onClick={(e)=>{ payOption_HandleChange(e.target); }}
						/>
						Check
					</label>
				</div>



				
			<div style={{display:(payOption.option==='po-invoice' ? 'block': 'none' ) }}>
				<h4> Paying by PO / Invoice </h4>
				<p>
					INSTRUCTIONS for PO/Invoice 
				</p>
				<div>
					<button className="button small primary" 
						onClick={(e) =>{ FAKE_startCoursePayment(selectedCourse.creditObj.creditId, false, getConfNumber('PO'), selectedCourse.cost)  }}>
						Submit PO
					</button>
				</div>
			</div>
			<div style={{display:(payOption.option==='check' ? 'block': 'none' ) }}>
					<h4> Paying by Check</h4>
					<p>Please send a check made out to <code>{Config.SCHOOL_NAME}</code> at <code>{Config.STREET_ADDRESS} {Config.CITY_STATE_ZIP}</code> with <code>{genCheckNumbers.ceCheck}</code> on the "For:" line.</p>
				<div>
					<button className="button small primary" 
						onClick={(e) =>{ FAKE_startCoursePayment(selectedCourse.creditObj.creditId, false, genCheckNumbers.ceCheck, selectedCourse.cost)  }}>
						Send a check
					</button>
				</div>
			</div>
	*/
	const FAKE_startCoursePayment = (courseId, paid, payConf, amount)  => {
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		const myItems = [
			{"amount":UPDATE_CART_COST(selectedCourse.creditObj.course.cost),
			code: USE_CODE(schoolPayPOST.action),
//			"code":"Sdvb8dL",
			"quantity":"1","title": "CE Course: " + selectedCourse.creditObj.course.title}
		];

		payment_cfa_course( tokenInfo.getAuthToken, tokenInfo.getId.id,
			courseId, paid, payConf, amount, JSON.stringify(myItems)
		).then( (res) => {
			console.log('Payment for exam complete: ', res.data);
			if( res.data.code === 'COURSE_PAID') {
				//Let's pull out the cfa exam vs. ce courses
				window.location.reload();
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Payment for examination failed..', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch TFAs for this CFA.');
			}
		});
	}
	const selectTheCeCourse = ( courseInfo ) => {
		//This probably doesn't need to be it's own function.
		console.log('Find and select the course: ', courseInfo);
		setSelectedCourse( courseInfo );
	}
	
	const startCoursePayment = (/*courseId, paid, payConf, amount*/) => { 
		//console.log('Starting the course payment', selectedCourse.creditObj);
		SetDisableThirdPartyBtn(true);
		const payConf = genCheckNumbers.ceCheck; //PAY_INFO.payConf;
		

		const myItems = [
			{"amount":UPDATE_CART_COST(selectedCourse.creditObj.course.cost),
			code: USE_CODE(schoolPayPOST.action),
			courseId: selectedCourse.creditObj.courseId,
			creditId: selectedCourse.creditObj.creditId,
//			"code":"Sdvb8dL",
			"quantity":"1","title": "CE Course: " + selectedCourse.creditObj.course.title,
			payee: PAYEE_NUMBER,
			custom_fields: {type:'course', courseId: selectedCourse.creditObj.courseId,
			creditId: selectedCourse.creditObj.creditId,
			 payConf:payConf, amount: UPDATE_CART_COST(selectedCourse.creditObj.course.cost)}
			}
		];


		const myCompleteCartItems = {items: myItems, comments:`System Comment: THIRD PARTY PAYMENT. SUBMITTED ON [${moment().format('LLLL')}]. `}
		const paid=false;
		const amount = selectedCourse.creditObj.course.cost;

		
		USE_NELNET_URLS(
			genCheckNumbers.ceCheck, myItems, 'course'
			, paid, payConf, amount, JSON.stringify(myCompleteCartItems),
			`Course: ${selectedCourse.creditObj.course.title} ($${selectedCourse.creditObj.course.cost})`,
			selectedCourse.creditObj.courseId, selectedCourse.creditObj.creditId
		)
		//USE_RETURN_URL(genCheckNumbers.ceCheck, myItems, 'course')
		.then ( (myNelnetObj) => {
			/*
			console.log('And here are the CE Course to check the return URL: ', 
				myItems, myReturnUrls);
				*/
			console.log('Course Payment OBJ:', myNelnetObj);

			setNelnetGET({
			   submit:nelnetGET.submit+1,
				action: myNelnetObj.fullUrl,
			});
   /*
			
			if( !myReturnUrls || !myReturnUrls.return_url || !myReturnUrls.notification_url ) {
				alert('There was a problem storing some information.');
				SetDisableThirdPartyBtn(false);
			}else{
				//console.log('RETURN URLS TEMP: ', myReturnUrls);
				
				setSchoolPayPOST({
					...schoolPayPOST,
					return_url: myReturnUrls.return_url,
					notification_url: myReturnUrls.notification_url,
					items:myItems,
					submit: schoolPayPOST.submit+1
				});
				SetDisableThirdPartyBtn(false);
			}
			*/
		});
		/*
		const myReturnUrl = USE_RETURN_URL(genCheckNumbers.ceCheck, myItems, 'course');
		console.log('And here are the CE Course to check the return URL: ', USE_RETURN_URL( genCheckNumbers.renewCheck, myItems, 'course'));
		
		setSchoolPayPOST({
			...schoolPayPOST,
			return_url:myReturnUrl, //USE_RETURN_URL(genCheckNumbers.ceCheck, myItems, 'course'),
			items:myItems,
			submit: schoolPayPOST.submit+1
		});

		/*
		setSchoolPayPOST({
			...schoolPayPOST,
			items: [
				{"amount":UPDATE_CART_COST(selectedCourse.creditObj.course.cost),
				code: USE_CODE(schoolPayPOST.action),
	//			"code":"Sdvb8dL",
				"quantity":"1","title": "CE Course: " + selectedCourse.creditObj.course.title}
			],
			submit: schoolPayPOST.submit+1
		});

		*/
		return true;
	}

	const ceCourseSelect = () => {
		//console.log('Course select', courseList.ceCourses);
		return (
			<>
			<h4> Please select the Continuing Education course you want to complete payment for: </h4>
			<p> These are the courses you registered for. If you would like to register for more courses, please go to <Link to="/ce-training" className="button small no-bottom-margin" >Your Continuing Education Page</Link></p>
			<div>
				<ul className="no-ul-bullet">
				{
					courseList.ceCourses
					.filter( (courseInfo) => {
						return (!courseInfo.creditObj.payId && courseInfo.cost !== 0.00 );
					})
					.map( (courseInfo, key)  => {
						return (
							<li key={key}> <button className="button small primary vertical-align-middle no-bottom-margin" onClick={(e) =>{ selectTheCeCourse( courseInfo ) }}>
								<i className="fa fa-shopping-cart"></i> Purchase Course</button> ({
									courseInfo.cost === 0.00 ? 'Free' :
									Number(courseInfo.cost).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
								}) (ID: {courseInfo.courseId}) {courseInfo.title}</li>
						);
					})
				}
				</ul>
			</div>
			</>
		);
	}


	const toggleRenewalYearToCart = (year) => {
		let numberOfRenewals = 0;
		const myRenewalOptions = renewalOptions.map( 
			(renewalItem) => {
				if( renewalItem.isPaid ) {
					return renewalItem;
				}
				if( renewalItem.year === year ) {
					renewalItem.isChosen = !renewalItem.isChosen;
				}else{
					//If we toggle an earlier year, remove later options.
					if( year < renewalItem.year ) {
						renewalItem.isChosen=false;
					}
				}

				if( renewalItem.isChosen ) {
					numberOfRenewals++;
				}

				return renewalItem;
			}
		);
		setRenewalOptions(myRenewalOptions);
		setRenewalCost(  40.00 * numberOfRenewals );

		return true;
	}
	const renewalForm = () => {
		/*
	const [renewalOptions, setRenewalOptions] = useState({
		currentYear: new Date().getFullYear(),
		activeYear: props.UserState.user.activeYear,
		cycleYear: props.UserState.user.cycleYear
	});*/

	const myRenewalButtons = [];
	let foundUnpaid = false;
	for(let i=0;i<renewalOptions.length;i++) {
		if( renewalOptions[i].isPaid ) {
			myRenewalButtons.push(<span>
				<i className="fa fa-certificate"></i> {renewalOptions[i].year} Paid!
			</span>);
		}else{
			if( !foundUnpaid ) {
				if( renewalOptions[i].isChosen === false ) {
					foundUnpaid=true;
					myRenewalButtons.push(<button type="button" className="override-sebs button small success vertical-align-middle no-bottom-margin"  
						onClick={(e) =>{ toggleRenewalYearToCart(renewalOptions[i].year) }}
					>
						<i className="fa fa-cart-plus"></i> Add {renewalOptions[i].year}  
					</button>);
				}else{
					myRenewalButtons.push(<button type="button" className="override-sebs button small alert vertical-align-middle no-bottom-margin"  
						onClick={(e) =>{ toggleRenewalYearToCart(renewalOptions[i].year) }}
					>
						<i className="fa fa-minus"></i> Remove {renewalOptions[i].year}  
					</button>);
				}
			}else{
				myRenewalButtons.push(<button type="button" 
					disabled={true}
					className="override-sebs button small success vertical-align-middle no-bottom-margin"  
				>
					<i className="fa fa-cart-plus"></i> Add {renewalOptions[i].year}  
				</button>);
			}

		}
	}
	/*
		const myRenewalButtons = [];
		for( let startOfCycle = renewalOptions.cycleYear-4; startOfCycle<=renewalOptions.cycleYear; startOfCycle++ ) {
			if( startOfCycle <= renewalOptions.activeYear ) {
				myRenewalButtons.push(<span>
					<i className="fa fa-certificate"></i> {startOfCycle} Paid!
				</span>);
			}else{
				//Add one since payfor may be zero, and we want to go in order
				if( (renewalOptions.activeYear + renewalOptions.payFor + 1) === startOfCycle ) {
					myRenewalButtons.push(<button type="button" className="override-sebs button small success vertical-align-middle no-bottom-margin"  >
						<i className="fa fa-cart-plus"></i> Add {startOfCycle}  
					</button>);
				}else{
					myRenewalButtons.push(<button type="button" disabled="disabled" className="override-sebs button small success vertical-align-middle no-bottom-margin"  >
						<i className="fa fa-cart-plus"></i> Add {startOfCycle}  
					</button>);
				}
			}
		}
		*/


		if( redirectTo !== null && redirectTo ) {
			return( <Redirect to={redirectTo} /> )
		}


		const getTestMethod = ( SHOW_TEST_BUTTON ? (
			<div className="small-12 cell">
				<label htmlFor="renewal_test" className="">
					<input type="radio" 
						name="renewal" id="renewal_test" 
						value='test'
						disabled={renewalCost === 0}
						checked={(payOption.option==='test' )}
						onClick={(e)=>{ payOption_HandleChange(e.target); }}
					/>
					Test
				</label>
			</div>
		) : null );
		const getTestButton = ( SHOW_TEST_BUTTON ? (
			<div style={{display:(payOption.option==='test' ? 'block': 'none' ) }}>
			{
					Config.IS_DEV_ENV ?
					(<button className="button small warning" 
						disabled={renewalCost === 0}
						onClick={(e) =>{ FAKE_startRenewalPayment(true, getConfNumber('TEST')/*`TEST_RENEWAL_${renewalOptions.filter((renewalItem)=>{return renewalItem.isChosen;}).length}`*/
						, renewalCost) }}>
						[IS_DEV_ENV=TRUE] FAKE RENEWAL PAYMENT SUCCEESS
					</button>) : <></>
				}
			</div>
		): null );


				console.log('Checking renewals:',props.UserState.user.cycleYear, props.UserState.user.activeYear  );

		return (
			<div style={{display: ( showContent === 'renewals' ? 'block' : 'none' )}}>
				{WARNING_REMINDER_NOTIFICATION}
				{ 
					/* issue #275 */
					(props.UserState.user.cycleYear - ( props.UserState.user.activeYear ) ) > 5 ? 
					<div className={"callout alert"}>
						<h5><strong>Notice:</strong></h5>	
						<p>
							Our records show your last renewal payment was outside the scope of this cycle.  
							Please contact the ProFACT office (848-932-6373) to help confirm your account information and help pay for your renewals.
						</p>
					</div>
					: <>
						<h4>
						You may now pay your annual renewal fee for any years within your 5 year recertification cycle. 
						Please note that you will need to have paid the annual renewal fee for all years up through and including the current year in order to be considered certified for the current year.
						</h4>
						<h4> Your certification cycle: Start of {props.UserState.user.cycleYear-4} - End of {props.UserState.user.cycleYear}</h4>
						<h5>Each year will cost <strong>$40.00</strong>.</h5>
						<div>
							<ul className="no-ul-bullet">
							{
								//onClick={(e) =>{ selectTheCeCourse( courseInfo ) }}>
								myRenewalButtons.map( (renewalBtn, key)  => {
									return (
										<li key={key}> 
											{renewalBtn}
										</li>
									);
								})
							}
							</ul>
						</div>
						
						<p><strong>Total Cost: {Number(renewalCost).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></p>
						<div style={{display: ( SHOW_TEST_BUTTON ? 'block' : 'none' ) }}>
							<h4>Payment Method</h4>
							<div className="grid-x grid-margin-x">
								{getTestMethod}
								<div className="small-12 cell">
									<label htmlFor="renewal_cc" className="">
										<input type="radio" 
											name="renewal" id="renewal_cc" 
											value='credit-card'
											disabled={renewalCost === 0}
											checked={(payOption.option==='credit-card' )}
											onClick={(e)=>{ payOption_HandleChange(e.target); }}
										/>
										Credit Card / E-check
									</label>
								</div>
							</div>
						</div>
						{getTestButton}
						<div style={{display:(payOption.option==='credit-card' ? 'block': 'none' ) }}>
							<button className="button small primary" 
								disabled={renewalCost === 0 || disableThirdPartyBtn}
								onClick={(e) =>{ startRenewalPayment() }}>
								Continue to Third Party Payment System 
							</button> 
						</div>
					</>
				}
			</div>
		);
	}

	/*
						<div className="small-12 cell">
							<label htmlFor="renewal_poinvoice" className="">
								<input type="radio" 
									name="renewal" id="renewal_poinvoice" 
									value='po-invoice'
									disabled={renewalCost === 0}
									checked={(payOption.option==='po-invoice' )}
									onClick={(e)=>{ payOption_HandleChange(e.target); }}
								/>
								PO / Invoice
							</label>
						</div>
						<div className="small-12 cell">
							<label htmlFor="renewal_check" className="">
								<input type="radio" 
									name="renewal" id="renewal_check" 
									value='check'
									disabled={renewalCost === 0}
									checked={(payOption.option==='check' )}
									onClick={(e)=>{ payOption_HandleChange(e.target); }}
								/>
								Check
							</label>
						</div>





						
				<div style={{display:(payOption.option==='po-invoice' ? 'block': 'none' ) }}>
					<h4> Paying by PO / Invoice </h4>
					<p>
						INSTRUCTIONS for PO/Invoice 
					</p>
					<div>
						<button className="button small primary" 
							onClick={(e) =>{ FAKE_startRenewalPayment(false, getConfNumber('PO'), renewalCost)  }}>
							Submit PO
						</button>
					</div>
				</div>
				<div style={{display:(payOption.option==='check' ? 'block': 'none' ) }}>
					<h4> Paying by Check</h4>
					<p>Please send a check made out to <code>{Config.SCHOOL_NAME}</code> at <code>{Config.STREET_ADDRESS} {Config.CITY_STATE_ZIP}</code> with <code>{genCheckNumbers.renewCheck}</code> on the "For:" line.</p>
					<div>
						<button className="button small primary" 
							onClick={(e) =>{ FAKE_startRenewalPayment(false, genCheckNumbers.renewCheck, renewalCost)  }}>
							Send a check
						</button>
					</div>
				</div>
		*/
	
	const FAKE_startRenewalPayment = (paid, payConf, amount) => {
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		
		const costPerRenew = 40.00;
		const myItems = [];
		
		//Compile renewals into a nice array :3
		const myRenewalYears = renewalOptions.filter((renewalOptions) => { return renewalOptions.isPaid===false && renewalOptions.isChosen; })
		.map( (renewalOptions)  => {
			myItems.push({
				amount: UPDATE_CART_COST(costPerRenew),
				code: USE_CODE(schoolPayPOST.action),
	//			"code":"Sdvb8dL",
				quantity:1,
				title: 'Renewal Year: ' + renewalOptions.year,
				year: renewalOptions.year
			});
			return renewalOptions.year;
		});

		const totalCost = costPerRenew*myRenewalYears.length;
		
		payment_cfa_renewal( tokenInfo.getAuthToken, tokenInfo.getId.id,
			myRenewalYears, paid, payConf, totalCost, JSON.stringify(myItems)
		).then( (res) => {
			console.log('Payment for renewal complete: ', res.data);
			if( res.data.code === 'OK') {
				//Let's pull out the cfa exam vs. ce courses
				window.location.reload();
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Payment for renewal failed..', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not complete the payment for this renewal.');
			}
		});
	}
	const startRenewalPayment = () => { 
		console.log('Starting Renewal payment.');
		SetDisableThirdPartyBtn(true);
		const costPerRenew = 40.00;
		const myItems = [];
		let amount = 0;
		const payConf = genCheckNumbers.renewCheck; //PAY_INFO.payConf;
		const descriptionArr = [];
		const myRenewalYears = renewalOptions.filter((renewalOptions) => { return renewalOptions.isPaid===false && renewalOptions.isChosen; })
		.map( (renewalOptions)  => {
			myItems.push({
				amount: UPDATE_CART_COST(costPerRenew),
				code: USE_CODE(schoolPayPOST.action),
	//			"code":"Sdvb8dL",
				
				quantity:1,
				title: 'Renewal Year: ' + renewalOptions.year,
				year: renewalOptions.year,
				
				payee: PAYEE_NUMBER,
				custom_fields: {type:'renewal', year: renewalOptions.year, payConf:payConf, amount: UPDATE_CART_COST(costPerRenew)}
			});
			amount+=costPerRenew;
			
			descriptionArr.push(renewalOptions.year);
			return renewalOptions.year;
		});
		//console.log(myItems, myRenewalYears);

		const myCompleteCartItems = {items: myItems, comments:`System Comment: THIRD PARTY PAYMENT. SUBMITTED ON [${moment().format('LLLL')}]. `}
		const paid=false;
		const description = `Renewal Years: (${descriptionArr.join(', ')}) at $${costPerRenew} each.`		

		USE_NELNET_URLS(
			genCheckNumbers.renewCheck, myItems, 'renewal'
			, paid, payConf, amount, JSON.stringify(myCompleteCartItems),
			description, myRenewalYears
		)
		//USE_RETURN_URL(genCheckNumbers.renewCheck, myItems, 'renewal')
		.then ( (myNelnetObj) => {
			/*
			console.log('And here are the Renewal items to check the return URL: ',
			 myItems, myReturnUrls);
			 */
			
			 console.log('CFA RENEWALS OBJ:', myNelnetObj);

			 setNelnetGET({
				submit:nelnetGET.submit+1,
				 action: myNelnetObj.fullUrl,
			 });
	/*
			 if( !myReturnUrls || !myReturnUrls.return_url || !myReturnUrls.notification_url ) {
				alert('There was a problem storing some information.');
				SetDisableThirdPartyBtn(false);
			}else{
				console.log('RETURN URLS TEMP: ', myReturnUrls);
				
				setSchoolPayPOST({
					...schoolPayPOST,
					return_url: myReturnUrls.return_url,
					notification_url: myReturnUrls.notification_url,
					items:myItems,
					submit: schoolPayPOST.submit+1
				});
				SetDisableThirdPartyBtn(false);
			}
			*/
		});

		/*
		//console.log(myItems, myTfas);

		console.log('And here are the Renewal items to check the return URL: ', USE_RETURN_URL( genCheckNumbers.renewCheck, myItems, 'renewal'));
		
		setSchoolPayPOST({
			...schoolPayPOST,
			return_url:USE_RETURN_URL(genCheckNumbers.renewCheck, myItems, 'renewal'),
			items:myItems,
			submit: schoolPayPOST.submit+1
		});
		
		/*
		setSchoolPayPOST({
			...schoolPayPOST,
			items: myItems,
			submit: schoolPayPOST.submit+1
		});
		*/

		return true;
	}



	if( showContent === 'complete' ){

		return (
		
			<main id="main-wrapper" className=" ">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content">
							<div>
								<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
								<hr className="slim-hr" />
							</div>
							<h2> Payment </h2>
							<ProcessPayment WARNING_REMINDER_NOTIFICATION={WARNING_REMINDER_NOTIFICATION} paymentToken={props.match.params.paymentToken} />
						</div>
					</div>
				</div>
			</main>
		);
	}else if( showContent === 'success' ){
		return (
			<main id="main-wrapper" className=" ">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content">
						<div>
							<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
							<hr className="slim-hr" />
						</div>
						<h2> Payment </h2>
						<ProcessPayment WARNING_REMINDER_NOTIFICATION={WARNING_REMINDER_NOTIFICATION} paymentId={props.match.params.paymentToken} />
						</div>
					</div>
				</div>
			</main>
		);
	}
	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<div>
							<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
							<hr className="slim-hr" />
						</div>
						<h2> Payment </h2>
						<div className="button-group">
							<button className={
								"button small primary" +
								(showContent === 'history' ? ' is-selected' : '')
							} onClick={(e) =>{ toggleShowContent( 'history' ) }}>
							See Payment History </button>
							
							<button className={
								"button small primary" +
								(showContent === 'po-invoice-check' ? ' is-selected' : '')
							} onClick={(e) =>{ toggleShowContent( 'po-invoice-check' ) }}>
							Payment Instructions </button>

							<button className={
								"button small primary" +
								(showContent === 'cfa-exam' ? ' is-selected' : '')
								/*
									style={{display:(disableCFAExamBtn ? 'none': 'inherit')}}
									disabled={disableCFAExamBtn}
								*/
							} 
							style={{display:(disableCFAExamBtn ? 'none': 'inline-block')}}
							disabled={disableCFAExamBtn}
								onClick={(e) =>{ toggleShowContent( 'cfa-exam' ) }}>
							Pay for CFA Exam </button>

							<button className={
								"button small primary" +
								(showContent === 'renewals' ? ' is-selected' : '')
							} 
								disabled={disableRenewalBtn}
								onClick={(e) =>{ toggleShowContent( 'renewals' ) }}>
							Pay for Renewals </button>
							<button className={
								"button small primary" +
								(showContent === 'unpaid-tfas' ? ' is-selected' : '')
							} 
								disabled={disableTfaBtn}
								onClick={(e) =>{ toggleShowContent( 'unpaid-tfas' ) }}>
							Pay for Unpaid TFAs</button>
							<button className={
								"button small primary" +
								(showContent === 'ce-course' ? ' is-selected' : '')
							} 
								disabled={disableCECourseBtn}
								onClick={(e) =>{ toggleShowContent( 'ce-course' ) }}>
							Pay for CE Course</button>
						</div>

						{paymentHistory()}
						{paymentByPoInvoiceCheck()}
						{cfaExamForm()}
						{unpaidTfaForm()}
						{ceCourseForm()}
						{renewalForm()}

					</div>
				</div>
				
				<div>
					<form 
						id="nelnetGET_form"
						action={nelnetGET.action} 
						method="get" 
					>{/*
					<input type="hidden" name="orderType" value={nelnetGET.orderType} />
						<input type="hidden" name="orderName" value={nelnetGET.orderName} />
						<input type="hidden" name="orderDescription" value={nelnetGET.orderDescription} />
						<input type="hidden" name="amount" value={nelnetGET.amount} />
						<input type="hidden" name="userChoice24" value={nelnetGET.userChoice24} />
						<input type="hidden" name="userChoice25" value={nelnetGET.userChoice25} />
						<input type="hidden" name="redirectUrl" value={nelnetGET.redirectUrl} />
						<input type="hidden" name="redirectUrlParameters" value={nelnetGET.redirectUrlParameters} />
						<input type="hidden" name="retriesAllowed" value={nelnetGET.retriesAllowed} />
						<input type="hidden" name="timestamp" value={nelnetGET.timestamp} />
						<input type="hidden" name="hash" value={nelnetGET.hash} />
						*/}
					</form>
					{/*
					<form 
						id="schoolPayPOST_Form"
						action={schoolPayPOST.action} 
						method="post" 
					>
						<input type="hidden" name="version" 	value={schoolPayPOST.version} />
						<input type="hidden" name="items" 		value={JSON.stringify(schoolPayPOST.items)} />
						<input type="hidden" name="return_url"		value={schoolPayPOST.return_url} />
						<input type="hidden" name="notification_url" value={schoolPayPOST.notification_url} />
						<input type="hidden" name="provider" value={schoolPayPOST.provider} />
						<input type="hidden" name="student_id" value={schoolPayPOST.student_id} />
					</form>
					*/}
				</div>
			</div>
		</main>
	);
	//target="_blank"
	// <!--<button type="submit" className="button primary">Submit To new window</button>-->
}
export default CFAPaymentPage;