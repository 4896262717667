import React, {useState, 
	useEffect
	,useRef
} from 'react';
import { Link } from 'react-router-dom';
import { 
	get_exam_overall
	, get_course_content
	//,get_credits_by_cfa 
	,DECODE_TOKEN
	//, get_exam_active_now 
} from '../Api/api';

import moment from 'moment-timezone';
//import Config from '../Config';


const CFAExaminationPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	const [examHistory, SetExamHistory] = useState( [] );

	const [ trainingChecklist, SetTrainingChecklist ] = useState({
		hasCompletedTraining: props.UserState.user.trainingCompleted,
		hasPaidForExam: props.UserState.user.examPaid,
		hasPassedExam: props.UserState.user.examPassed,
		examIsInProgress: null, //null: checking; -1: study more; false: new exam; true: continue exam!,
		examId: null,
		courseId: null,
		timeMinutes: null,
		numQuestions: null,
		examMessage: ''
	})
	const trainingChecklistRef = useRef(trainingChecklist);
	
	
	useEffect( () => {

		//SetTrainingChecklist(trainingChecklist);
		SetExamHistory(examHistory);






		/*
		SetHasCompletedTraining(hasCompletedTraining);
		SetHasPaidForExam(hasPaidForExam);
		SetHasPassedExam(hasPassedExam);
		SetExamHistory(examHistory);
		SetExamIsInProgress(examIsInProgress);
		*/
	}, [
		//trainingChecklist,
		examHistory
	]);


	useEffect( () => {

		//Okay, let's start a new exam.
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.warn('Problematic Login token. Clearing. ');
			localStorage.removeItem('profact-token');
			props.UserState({
			  loggedIn: false,
			  refreshData: false,
			  //preferredLanguage: 'en-US',
			  preferredLanguage: 'en-US',
			  user:null
			});
			window.location.reload();
			return () => {};
		}

		///I *REALLY* Need to get the cfa exam token id....
		

		get_course_content(tokenInfo.getAuthToken)
		.then(function(response){
			//console.log('Getting the courses.', response);

			const cfaExam = response.data.data.find( (courseInfo) => { return courseInfo.title.toLowerCase() === 'cfa exam'; } );
			//console.log('FOUND CFA EXAM: ', cfaExam);

			get_exam_overall(tokenInfo.getAuthToken)
			.then(function(response){
				//console.log('Getting overall exam statuses.', response);
	
				if( response.data.code === 'OK' ) {

					if( response.data.allExams.activeExams.length < 1 ) {
						//Since there are no open cfa exams, we'll see if they have had a recently failed one.
						let latestFailedTest = null;

						for(let i=0;i<response.data.allExams.failedExams.length;i++) {
							const myExamEndTime = moment.tz(response.data.allExams.failedExams[i].examStarted,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
								.tz( moment.tz.guess() );
							if(
								!latestFailedTest
								|| myExamEndTime.isAfter(latestFailedTest)
							) {
								latestFailedTest = myExamEndTime;
							}
						}
						for(let i=0;i<response.data.allExams.timeOutExams.length;i++) {
							const myExamEndTime = moment.tz(response.data.allExams.timeOutExams[i].examStarted,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
								.tz( moment.tz.guess() );
							if(
								!latestFailedTest
								|| myExamEndTime.isAfter(latestFailedTest)
							) {
								latestFailedTest = myExamEndTime;
							}
						}
						if( !latestFailedTest ) {
							SetTrainingChecklist({
								...trainingChecklistRef.current
								, examIsInProgress: false
								, examMessage: 'Start a new exam'
								, courseId: cfaExam.courseId
								, timeMinutes: cfaExam.examTimeLimit
								, numQuestions: cfaExam.numQuestions
							});
						}else{
							const rightNow = moment().tz( moment.tz.guess() );//.subtract(21, 'hours');
							latestFailedTest.add(21, 'hours');
							if( rightNow.isBefore(latestFailedTest) ) {
								
								SetTrainingChecklist({
									...trainingChecklistRef.current
									, examIsInProgress: -1
									, examMessage: `Why don't you try studying a little bit more. You can take the exam again after  [${latestFailedTest.format('LLL')}]`
									, timeMinutes: cfaExam.examTimeLimit
									, numQuestions: cfaExam.numQuestions
								});
							}else{
								SetTrainingChecklist({
									...trainingChecklistRef.current
									, examIsInProgress: false
									, examMessage: 'Start a new exam'
									, courseId: cfaExam.courseId
									, timeMinutes: cfaExam.examTimeLimit
									, numQuestions: cfaExam.numQuestions
								});
							}
						}
					}else{
						const existingCFAExam = response.data.allExams.activeExams.find( (examInProg)=> { return examInProg.course.title.toLowerCase() === 'cfa exam'; } )
						SetTrainingChecklist({
							...trainingChecklistRef.current
							, examIsInProgress: true
							, courseId: existingCFAExam.courseId
							, examId: existingCFAExam.examId
							, examMessage: 'An exam is in progress.'
							, timeMinutes: cfaExam.examTimeLimit
							, numQuestions: cfaExam.numQuestions
						});
					}
				}else{
					throw new Error(response);
				}
			})
			.catch(function(error){
				///console.log(error);
				console.error('Failed to get exam status.', error);
			});
		}).catch(function(error){
			///console.log(error);
			console.error('Failed to get exam status.', error);
		});



		//Okay, going to get every course this person has and just output it.
/*

		get_credits_by_cfa(tokenInfo.getAuthToken, tokenInfo.getId.id)
		.then((response) => {
			console.log('?????????????', response);
		}).catch((error) => {
			///console.log(error);
			console.error('Failed to get exam status.', error);
		});
		8/

//get_potential_ce_exam










		/*
		get_exam_active_now(getAuthToken)
		.then(function(response){
			console.log('Starting a brand new exam for this user.', response);

			if( response.status === 200 ) {
				if( response.data.code ) {
					if( response.data.code === 'MORE_STUDY' ) {
						SetTrainingChecklist({
							...trainingChecklistRef.current
							, examIsInProgress: -1
							, examMessage: response.data.message
						});
					}else if( response.data.code === 'EXAM_IN_PROGRESS' ) {
						if( response.data.allResponses.length < 1 ) {
							SetTrainingChecklist({
								...trainingChecklistRef.current
								, examIsInProgress: false
								, examMessage: 'Start a new exam'
							});
						}else{

							SetTrainingChecklist({
								...trainingChecklistRef.current
								, examIsInProgress: true
								, examId: response.data.allResponses[0].examId
								, examMessage: 'An exam is in progress.'
							});
						}
					}
					//remove when not needed

				}
				console.log(response);
			}else{
				throw new Error(response);
			}
			
		})
		.catch(function(error){
			///console.log(error);
			console.error('Failed to get exam status.', error);
		});
		*/
	}, [props, trainingChecklistRef] );




	//Check For Exam
	const GetExamCheckListAndControls = () => {
		const myCheckList = [ ];
		let finalMessage = (<></>);
		let takeExam = (<></>);

		if( trainingChecklist.hasCompletedTraining ) {



			myCheckList.push( (<> <span className="label success"><i className="fa fa-check"></i> </span> Training Complete </>) );
			if( trainingChecklist.hasPaidForExam ) {
				myCheckList.push( (<> <span className="label success"><i className="fa fa-check"></i> </span> Exam was paid for. </>) ); 

				
				let myTimeString = 'TIME';
				if( trainingChecklist.timeMinutes < 60 ) {
					myTimeString = `${trainingChecklist.timeMinutes} Minutes`;
				}else{
					myTimeString = `${Math.floor(trainingChecklist.timeMinutes / 60)} Hours and ${(trainingChecklist.timeMinutes % 60)} Minutes`;
				}

				//Would be nice to have an id.

				if( trainingChecklist.hasPassedExam ) {
					const myCID = 'C' + props.UserState.user.id.toString().padStart(6, '0');
					takeExam =( 
						<>
							<h4> <i className="fa fa-graduation-cap"></i> You have passed your exam! Congratulations! </h4>
							<div className="button-group">
								<Link to="/certificate" target="_blank" className="button primary"> <i className="fas fa-id-card"></i> Get your certificate </Link>
								<Link className="button hollow" to={"/cfa/" + myCID}> <i className="fas fa-id-card-alt"></i> View Your Public Certificate </Link>
							</div>
						</>
					);
				}else {
					if( trainingChecklist.examIsInProgress === null ) {
						takeExam =( 
							<>
							<div className={"callout secondary"}>
								<h5>Checking for exam in progress... <i className="fas fa-circle-notch fa-spin"></i> </h5>	
							</div>
							</>
						);
					} else if( trainingChecklist.examIsInProgress === -1 ) {
						takeExam =( 
							<>
							<div className={"callout warning"}>
								<h5>You failed an exam recently... </h5>	
								<p> {trainingChecklist.examMessage} </p>
								<p> <Link to="/cfa-training" className="button success override-sebs"><i className="fa fa-book"></i> Training material </Link></p>
							</div>
							</>
						);
					} else if( trainingChecklist.examIsInProgress === true ) {
						finalMessage=(
							<>
							<h3><em>You are ready to take the exam!</em></h3>
							<p>Once you enter the exam you will have {myTimeString} to answer {trainingChecklist.numQuestions} multiple choice questions.</p>
							</>
						);
						takeExam =( 
							<>
							<div className={"callout primary"}>
								<h5>You have an exam in Progress</h5>	
								<p><Link to={ `/exam-room/${trainingChecklist.courseId}/${trainingChecklist.examId}/` } className="button success override-sebs">
									<i className="fa fa-door-open"></i> Continue Exam </Link></p>
							</div>
							</>
						);
					}else{
						finalMessage=(
							<>
							<h3><em>You are ready to take the exam!</em></h3>
							<p>Once you enter the exam you will have {myTimeString} to answer {trainingChecklist.numQuestions} multiple choice questions.</p>
							</>
						);
						takeExam =( 
							<Link to={`/exam-room/${trainingChecklist.courseId}/`} className="button success override-sebs"><i className="fa fa-door-open"></i> Go to the Online Examination </Link>
						);
					}
				}
			}else{
				myCheckList.push( (<> <span className="label warning"><i className="fa fa-times-circle"></i></span> Exam not paid for. 
					Please head to the <Link to="/payments"> <i className="fas fa-money-bill-wave"></i> Handle Your Payments </Link> Page to pay for your exam. </>) );
				finalMessage=(<h3><em>Next Step: Pay for exam.</em></h3>);
			}
		}else{
			myCheckList.push( (<> <span className="label alert"><i className="fa fa-times-circle"></i></span> Training Incomplete. 
				Please finish the material on the <Link to="/cfa-training"> CFA Training Page</Link> to continue. </>) );
			finalMessage=(<h3><em>Next Step: Complete the Online CFA Training.</em></h3>);
		}


		return (
			<>
			<ul style={{listStyleType: 'none'}}>
				{myCheckList.map( (checkListItem, key)  => {
					return (
						<li key={key}> {checkListItem} </li>
					)
				})}
			</ul>
			{finalMessage}
			{takeExam}
			</>
		);
	}


/*
	const GetExamHistory = () => {
		if( examHistory.length < 1 ) {
			return(<tr>
				<td colSpan="4"><strong><em>None available.</em></strong></td>
			</tr>);
		}

		
		return(<tr>
			<td colSpan="4">TODO: List exams desc.</td>
		</tr>);
	}


	const GetAvailableCeExams = () => {
		if( !props.UserState.user.examPassed ) {
			return (<></>);
		}


		return (<>
		
			<hr />
			<h2> Available Continuing Education Exams</h2>
			<div>
				<div className="table-scroll">
					<table >
						<thead>
							<tr>
								<th>Title</th>
								<th>Time Limit</th>
								<th>Time Taken</th> 
								<th>Score</th>
							</tr>
						</thead>
						<tbody>
							{GetExamHistory()}
						</tbody>
					</table>
				</div>
			</div>
		</>)

	}
{GetAvailableCeExams()}
*/


	useEffect( ()=> { 
		return () => {
			//on close.
			const myUser = props.UserState;
			//console.log('CFA Training check user.');
			if(myUser.loggedIn && myUser.lastRefreshPage !== window.location.pathname){
				return () => {
					//console.log('Refreshing user.');
					//on close.
					myUser.lastRefreshPage = window.location.pathname;
					myUser.refreshData = true;
					props.SetUserState(myUser);
				}
			}
		}
	}, [props] );

	return (
		<main id="main-wrapper" className="">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<div>
							<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
							<hr className="slim-hr" />
						</div>
						<h2> Your Exam Checklist </h2>
						{GetExamCheckListAndControls()}
					</div>
				</div>
			</div>
		</main>
	);
}
export default CFAExaminationPage;