import React, {useState, useEffect, createRef} from 'react';
import { Link, Redirect } from 'react-router-dom';
import ProfactSelect from '../HelperComponents/ProfactSelect';

//import axios from 'axios';
//import Config from '../Config';
import {post_cfa} from '../Api/api';

const languageLabels = {
	'en-US': {
		firstName: 'Name',
	},
	'es': {
		firstName: 'Name'
	}

};


const RegistrationPage = (props) => {
	document.title = props.title;
//class RegistrationPage extends React.Component {
	//console.log(props);
	const [startRedirect, triggerRedirect] = useState(false);
	const [preferredLanguage, setPreferredLanguage] = useState(props.UserState.preferredLanguage || 'en-US');
	const [oldSite, SetOldSite] = useState({
		isOldSite: false,
		oldProfactId: null,
		oldUserName: null
	});
	
	const [registerAlert, setRegisterAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});


	const [passwordRetype, setPasswordRetype] = useState( "" ); 
	const [registrationFormData, updateRegistrationFormData] = useState(
		{
			businessStreet: "",
			businessCity: "",
			businessState: "NJ",
			businessZip: '',
			businessPhone: '',
			businessCounty: "",
			homeStreet: "",
			homeCity: "",
			homeState: "NJ",
			homeZip: '',
			homePhone: '',
			homeCounty: '',
			//homeCounty is null for now
			businessName: "",
			firstName: "",
			lastName: "",
			email: ``,
			password: "", 
			spanishPref: false
		}
	);

	/*
	const [passwordRetype, setPasswordRetype] = useState( "98729jk27r98hfoyihfi3y298hf" ); 
		{
			businessStreet: "123 Lawndale",
			businessCity: "Riverside",
			businessState: "NJ",
			businessZip: '00001',
			businessPhone: 9999999999,
			businessCounty: "Monmouth",
			homeStreet: "456 Yardlawn",
			homeCity: "Fern Grove",
			homeState: "NJ",
			homeZip: '99999',
			homePhone: 9999999999,
			homeCounty: 'Atlantic',
			//homeCounty is null for now
			businessName: "Bob's Better Lawns",
			firstName: "Norbert",
			lastName: "Draconis",
			email: `UNIQUE_${Math.floor(Math.random()*9999)}@betterlawns.com`,
			password: "98729jk27r98hfoyihfi3y298hf", 
			spanishPref: false
		}
	*/

	//console.log(preferredLanguage);
	const [requireHomeCounty, setRequireHomeCounty] = useState( (registrationFormData.homeState.toLowerCase() === 'nj') );
	const [requireBusinessCounty, setRequireBusinessCounty] = useState( (registrationFormData.businessState.toLowerCase() === 'nj') );

	const [disableRegBtn, setDisableRegBtn] = useState(false);
	const [spanishText, setSpanishText] = useState( ( preferredLanguage === 'es' ) );
	const [showRetypeError, setShowRetypeError] = useState(false);

	const passwordRetypeInput = createRef();

	useEffect( () => {
		console.log('Current spanish preference', spanishText);
		setPreferredLanguage( 
			(spanishText ? 'es' : 'en-US')
		);

	}, [ spanishText ]);

	const handleHomeStateChange = (e_target) => {
		
		registrationForm_HandleChange(e_target);
        updateRegistrationFormData(registrationFormData => ({
            ...registrationFormData,
            homeCounty: 'No County'
		}));
		setRequireHomeCounty( e_target.value.toLowerCase() === 'nj' );
	};
	const handleBusinessStateChange = (e_target) => {
		console.log('Updating Business State');
		registrationForm_HandleChange(e_target);
        updateRegistrationFormData(registrationFormData => ({
            ...registrationFormData,
            businessCounty: 'No County'
		}));
		setRequireBusinessCounty( e_target.value.toLowerCase() === 'nj' );
	};





	const getLabel = (labelName) => {
		return ( 
			languageLabels[preferredLanguage] ?
			languageLabels[preferredLanguage][labelName]
			:languageLabels['en-US'][labelName]
		);
	}

	const registrationForm_HandleChange = (e_target) => {
		const { name, value } = e_target;

		let updateValue = value;
/*
		if( name === 'homeZip'  
		|| name === 'businessZip' ) {
			updateValue= value.replace(/[^0-9-]/g, '');
			if ( 
				updateValue.length < 5
			){
				updateValue= value.replace(/[^0-9]/g, '');
			}else {
				if( (updateValue.match(/-/g) || []).length )
			}
		}else if( 
			name === 'homePhone' 
			|| name === 'businessPhone' 
		) {
			updateValue= value.replace(/[^0-9]/g, '');

		}
		*/
		//console.log(e_target, );
        updateRegistrationFormData(registrationFormData => ({
            ...registrationFormData,
            [name]: updateValue
		}));
	}
	const registrationForm_spanishPref_HandleChange = (e_target) => {
		const { name, checked } = e_target;
        updateRegistrationFormData(registrationFormData => ({
            ...registrationFormData,
            [name]: (checked ? 1 : 0)
		}));
		setSpanishText( checked );
	}
	const registrationForm_oldSite_IsOld_HandleChange = (e_target) => {
		SetOldSite( {
			isOldSite: e_target.checked,
			oldProfactId: null,
			oldUserName: null
		} );
	}
	const registrationForm_oldSite_HandleChange = (e_target) => {
		const { name, value } = e_target;
		SetOldSite({
            ...oldSite,
            [name]: value
		});
	}

	const handleRegistrationSubmitEvent = (event) => {
		event.preventDefault();
		console.log('Registration Submit using <form>', event);
		console.log('Getting [new FormData()] ', );

		setDisableRegBtn(true);

		if( passwordRetype !== registrationFormData.password ) {
			passwordRetypeInput.current.focus();
			setShowRetypeError(true);
			setDisableRegBtn(false);
			return false;
		}else{
			setShowRetypeError(false);
		}

		const myRegistrationData = {
			...registrationFormData,
			spanishPref: ( registrationFormData.spanishPref ? true : false),
			homeZip: (''+registrationFormData.homeZip).replace(/[^0-9]/g, '').substr(0,9),
			homePhone: (''+registrationFormData.homePhone).replace(/[^0-9]/g, '').substr(0,11),
			businessZip: (''+registrationFormData.businessZip).replace(/[^0-9]/g, '').substr(0,9),
			businessPhone: (''+registrationFormData.businessPhone).replace(/[^0-9]/g, '').substr(0,11)
		};

		if( oldSite.isOldSite ) {
			myRegistrationData.oldProfactId = oldSite.oldProfactId;
			myRegistrationData.oldUserName = oldSite.oldUserName;
		}

		/*

		const submitRegistration = registrationFormData;

		
		submitRegistration.homeZip = submitRegistration.homeZip.replace(/[^0-9]/g, '');
		submitRegistration.homePhone = submitRegistration.homePhone.replace(/[^0-9]/g, '');
		submitRegistration.businessZip = submitRegistration.businessZip.replace(/[^0-9]/g, '');
		submitRegistration.businessPhone = submitRegistration.businessPhone.replace(/[^0-9]/g, '');
*/

		//https://developer.okta.com/blog/2019/10/02/jwt-react-auth
		//post_cfa(registrationFormData)
		post_cfa(
			myRegistrationData
		)
			.then( (res) => {
				console.log('Registering this CFA');
				if( res.status !== 200) {
					throw res; 
				}
				
				if( !res.data || res.data.code !== 'CFA_CREATED') {
					throw new Error(res);
				}
				//console.log(JSON.parse(atob(res.data.token.split('.')[1])));
				console.log('Token set in `localStorage`', res.data.token);
				localStorage.setItem('profact-token', res.data.token);


				
				//localStorage.setItem('profact-token', JSON.stringify(myUser));
				props.SetUserState({
					loggedIn: false,
					refreshData: true,
					lastRefreshPage: '/register',
					//preferredLanguage: 'en-US',
					preferredLanguage: 'en-US',
					user:null
				});
				
				triggerRedirect(true);


			} )

			.catch( ( error )=> {
				console.error('Could not register this user: ', error);
				document.getElementById('regFormTitle').scrollIntoView();
				if( 
					error.response &&
					error.response.data.code === 'VALIDATION_ERROR' 
				) {
					setRegisterAlert({
						type: 'alert',
						display: 'block',
						title: 'Problem Registering your account',
						message: error.response.data.message
					});
				}else if( 
					error.response &&
					error.response.data.code === 'EMAIL_EXISTS' 
				) {
					setRegisterAlert({
						type: 'alert',
						display: 'block',
						title: 'Problem Registering your account',
						message: 'This email already exists in our system. Please try logging in or use the "Forgot Password?" feature.'
					});
				}else{
					setRegisterAlert({
						type: 'alert',
						display: 'block',
						title: 'Problem Registering your account',
						message: 'Please try again or contact an administrator.'
					});
				}
				setDisableRegBtn(false);
			})
			.finally( () => {
				setDisableRegBtn(false);
			});



		return true;
	}


	return (
		<>
		{(
			startRedirect ? 
			<Redirect push to={props.match.params.redirectTo ? '/' + props.match.params.redirectTo : "/dashboard"} /> : 
		<main id="main-wrapper" className="">
			<form action="" method="post" onSubmit={handleRegistrationSubmitEvent}>
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content" className="">
							<div id="primary-sub-col" className="margin-top-1  large-10 large-offset-1 medium-12 small-12 columns">
								<h1>Certified Fertilizer Applicator Registration​​​</h1>
								<div className="">
									<div className="large-12 medium-12 small-12   columns">
										<p>
											Please register to begin the certification process. 
											Complete all fields below, and you will be emailed a confirmation message within a few minutes.​
										</p>
										<p>
											Note the following:
										</p>
										<ul style={{paddingLeft:'2em'}}>
											<li>Red text beside a field indicates a problem with your entry in that field.</li>
											<li>Be sure to enter your email correctly, and with <strong>NO spaces</strong>.</li>
											<li>When your registration is successful, you will see a message that says "Your registration request has been received." on this page.​​</li>
											<li>Enter the same password twice and record it in a safe place.</li>
											<li><strong>Please DO NOT register more than once.</strong> <Link to="/contact-us">Contact us</Link> if you have a problem logging in or do not receive an email.​​​​​​​​​​​​​​​​​​​​​</li>
										</ul>
									</div>
								</div>
								<div className="">
									<div className="callout alert large-12 medium-12 small-12  columns">
										<p>
											Entry of your first and last names are considered evidence of your identity as well as consent to participate in the certification program and abide by the NJ laws concerning the application, sale, and use of fertilizer.​​
										</p>
										<p>
											Enter your first and last name as you expect it to appear on the public listing for certified fertilizer applicators as well as the certificate (verifications of certified status). You should enter your first and last names the same as found on your official photo identifications. For reasons of security, you will not be able to change the name after it is entered.​
										</p>
									</div>
								</div>
								<h2  id="regFormTitle">Registration Form </h2>

								<div className={"callout " + registerAlert.type} style={{display:registerAlert.display}} data-closable>
									<h5>{registerAlert.title}</h5>
									<p>{registerAlert.message}</p>
									<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
										window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
											setRegisterAlert({
												type: 'success',
												display: 'none',
												title: '',
												message: ''
											});
										});
									}}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="margin-top-3" style={{marginTop: 1 + 'em'}}>
									<h3>Your Information</h3>
									<div className="row">
										<div className="large-3 medium-12 small-12  columns">
											<label htmlFor="firstName" className="label-style-1">{getLabel('firstName')}</label>
										</div>
										<div className="large-4 medium-6 small-12   columns">
											<input type="text" id="firstName" name="firstName" placeholder="First Name" required="required" 
												value={registrationFormData.firstName}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
										<div className="large-5 medium-6 small-12   columns">
											<input type="text" id="lastName" name="lastName" placeholder="Last Name" required="required" 
												value={registrationFormData.lastName}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="small-12 columns">
											<label htmlFor="spanishPref" className="label-style-1">
												<input type="checkbox" 
													name="spanishPref" id="spanishPref" 
													value={spanishText}
													defaultChecked={spanishText}
													onClick={(e)=>{ registrationForm_spanishPref_HandleChange(e.target); }}
												/>
												Prefiero español cuando esté disponible / I prefer Spanish when available
											</label>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="email" className="label-style-1">Email</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="email" id="email" name="email" placeholder="name@example.com" required="required" 
												value={registrationFormData.email}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									
									<div className="row">
										<div className="small-12 columns">
											<label htmlFor="fromOldSite" className="label-style-1">
												<input type="checkbox" 
													name="fromOldSite" id="fromOldSite" 
													onClick={(e)=>{ registrationForm_oldSite_IsOld_HandleChange(e.target); }}
												/>
												I am from the old <em>profact.rutgers.edu</em> site.
											</label>
										</div>
									</div>
									<div id="oldSite_div" style={{display:(oldSite.isOldSite?'block':'none')}}>
										<div  className="row">
											<div className="large-3 medium-12 small-12 columns">
												<label htmlFor="oldProfactId" className="label-style-1">Old Profact ID:</label>
											</div>
											<div className="large-9 medium-12 small-12 columns">
												<input type="text" id="oldProfactId" name="oldProfactId" 
													placeholder="C987654" 
													value={oldSite.oldProfactId}
													onChange={e => registrationForm_oldSite_HandleChange(e.target)}
												/>
											</div>
											<div className="large-9 medium-12 small-12 columns">
												<p><em>Please include the complete Profact ID Number (Including the 'C'). This number will be on your old certificate.</em></p>
											</div>
										</div>
										<div  className="row">
											<div className="large-3 medium-12 small-12 columns">
												<label htmlFor="oldUserName" className="label-style-1">Old Username: </label>
											</div>
											<div className="large-9 medium-12 small-12 columns">
												<input type="text" id="oldUserName" name="oldUserName" 
													placeholder="Your Old Username" 
													value={oldSite.oldUserName}
													onChange={e => registrationForm_oldSite_HandleChange(e.target)}
												/>
											</div>
										</div>
									</div>


									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="password" className="label-style-1">Password</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="password" id="password" name="password" placeholder=""  required="required" 
												value={registrationFormData.password}
												minLength="6"
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="passwordRetype" className="label-style-1">Retype Password</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="password" id="passwordRetype" name="passwordRetype" placeholder=""  required="required"
												value={passwordRetype}
												ref={passwordRetypeInput}
												onChange={e => setPasswordRetype(e.target.value)}
											/>
										</div>
										<div className="small-12 columns">
											<p className="callout alert" style={{display: (showRetypeError ? 'block' : 'none') }}> Please make sure your password and retyped password match.</p>
										</div>
									</div>



									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="homeStreet" className="label-style-1">Home Address</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="text" id="homeStreet" name="homeStreet" placeholder="" required="required"
												value={registrationFormData.homeStreet}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="homeCity" className="label-style-1">Home City</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="text" id="homeCity" name="homeCity" placeholder="" required="required" 
												value={registrationFormData.homeCity}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="homeState" className="label-style-1">Home State</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<ProfactSelect
												name="homeState" id="homeState" StateDropdown={true} 
												required="required"
												value={registrationFormData.homeState}
												onChange={e => {
													handleHomeStateChange(e.target);
													//homeForm_requireCounty(e.target);
													//homeForm_HandleChange(e.target);
												} } />
										</div>
									</div>
									<div className="row" style={{display: (requireHomeCounty ? 'block': 'none')}}>
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="homeCounty" className="label-style-1">Home NJ County</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<ProfactSelect
												name="homeCounty" id="homeCounty" NjCountyDropdown={true}
												required={requireHomeCounty}
												value={registrationFormData.homeCounty}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="homeZip" className="label-style-1">Home Zipcode</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="text" id="homeZip" name="homeZip" 
											placeholder="e.g. 01234 or 01234-5678" required="required" 
												minLength="5"
												maxLength="10"
												value={registrationFormData.homeZip}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="homePhone" className="label-style-1">Home / Mobile Phone</label>
										</div>
										<div className="large-9 medium-12 small-12  columns">
											<input type="tel" id="homePhone" name="homePhone" placeholder="Your Phone number" required="required" 
												minLength="7"
												maxLength="21"
												value={registrationFormData.homePhone}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
								</div>
								<div className="margin-top-3" style={{marginTop: 1 + 'em'}}>
									<h3>Business Information</h3>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="businessName" className="label-style-1">Company</label>
										</div>
										<div className="large-9 medium-12 small-12  columns">
											<input type="text" id="businessName" name="businessName" placeholder="Company Name" required="required" 
												value={registrationFormData.businessName}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="businessStreet" className="label-style-1">Business Address</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="text" id="businessStreet" name="businessStreet" placeholder="Business Street Address" required="required" 
												value={registrationFormData.businessStreet}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="businessCity" className="label-style-1">Business City</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="text" id="businessCity" name="businessCity" placeholder="Business City" required="required" 
												value={registrationFormData.businessCity}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
								
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="businessState" className="label-style-1">Business State</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<ProfactSelect
												name="businessState" id="businessState" StateDropdown={true} 
												required="required"
												value={registrationFormData.businessState}
												onChange={e => {
													handleBusinessStateChange(e.target);
													//homeForm_requireCounty(e.target);
													//homeForm_HandleChange(e.target);
												} } />
										</div>
									</div>
									<div className="row" style={{display: (requireBusinessCounty ? 'block': 'none')}}>
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="businessCounty" className="label-style-1">Business NJ County</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<ProfactSelect
												name="businessCounty" id="businessCounty" NjCountyDropdown={true}
												required={requireBusinessCounty}
												value={registrationFormData.businessCounty}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>

									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="businessZip" className="label-style-1">Business Zipcode</label>
										</div>
										<div className="large-9 medium-12 small-12 columns">
											<input type="text" id="businessZip" name="businessZip" placeholder="e.g. 01234 or 01234-5678" required="required" 
												minLength="5"
												maxLength="10"
												value={registrationFormData.businessZip}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="large-3 medium-12 small-12 columns">
											<label htmlFor="businessPhone" className="label-style-1">Business Phone</label>
										</div>
										<div className="large-9 medium-12 small-12  columns">
											<input type="tel" id="businessPhone" name="businessPhone" placeholder="e.g. 123-456-7890" required="required" 
												minLength="7"
												maxLength="21"
												value={registrationFormData.businessPhone}
												onChange={e => registrationForm_HandleChange(e.target)}
											/>
										</div>
									</div>

									<div className="row">
										<div className="large-12 medium-12 small-12 columns">
										<div className="callout alert  " style={{margin:'10px 0'}}>
											<p>
												​By clicking the <strong>Register</strong> button, I certify that all supporting information of my identity are complete, factually correct, and honestly prepared.
												I understand that willful omission or misrepresentation in any part of my registration is grounds for cancellation of registration or revocation of certification.​​​
											</p>
										</div>
										</div>
										<div className="large-12 medium-12 small-12 columns text-right">
											<button  className="button" type="submit" disabled={disableRegBtn}>Register</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</main>
		
		)}
		</>
	);

}

export default RegistrationPage;