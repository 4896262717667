import React, { useEffect, useState, 
//	useEffect 
} from 'react';
//, useEffect
import { Link, Redirect } from 'react-router-dom';
import { user_login, 
//	get_cfa_by_id 
} from '../Api/api';
//import axios from 'axios';
//import Config from '../Config';


const Announcement = ({type='primary', header='', messages=[], onDate}) => { 
	return  <div className="medium-10 large-6 large-offset-3 medium-offset-1 columns">
	<div className={`callout ${type}`} style={{display:'block'}}>
		<h4 style={{fontSize:'x-large',fontWeight:'bold'}}>{header}</h4>
		{
			messages.map( (message, key) => { 
				return <p key={key}>
					{message.bolded? <strong>{message.bolded}:</strong>:<></>}
					{message.text}
				</p>
			})
		}
	</div>
	</div>
}


const LoginPage = ( props ) => {

	document.title = props.title;

	const [startRedirect, triggerRedirect] = useState(false);
	/*
	{
	"onDate": "2024-05-30",
	"type":"warning",
	"header": "Maintenance: Thursday 5/30/2024",
	"message":"The website will be undergoing maintenance and logins will be disabled."
	}
	*/
	const [announcement, setAnnouncement] = useState()
	const [disableLoginBtn, SetDisableLoginBtn] = useState(true);
	const [myError, setErrMessage] = useState('');

	const [loginEmail, setLoginEmail] = useState('');
	const [loginPassword, setLoginPassword] = useState('');




	useEffect ( () => { 
		fetch('loginAnnouncement.json')
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				console.log('Login Announcement...', data);

				setAnnouncement(data);
				SetDisableLoginBtn(false);	
			})
			.catch( (e) => { 
				console.warn('No login announcement...');
				SetDisableLoginBtn(false);
			});
	}, [])

	//const loginToSystem = () => {
	const handleLoginSubmitEvent = (event) => {
		event.preventDefault();
		console.log('Logging into system.', event);
		SetDisableLoginBtn(true);
		user_login(loginEmail, loginPassword)
			.then( (res) => {
				//console.log('Loggin this user in', res);

				localStorage.removeItem('profact-token', '');
				//console.log('Token set in `localStorage`', res.data.token);
				//console.log(JSON.parse(atob(res.data.token.split('.')[0])));
				//console.log(JSON.parse(atob(res.data.token.split('.')[1])));

				if( !res.data.token ) {
					throw new Error("FAILED_LOGIN");
				}
				const myToken = res.data.token;
				localStorage.setItem('profact-token', myToken);
				//console.log(myToken);
				
				//localStorage.setItem('profact-token', JSON.stringify(myUser));

				return myToken;
			})
			.then( (myToken) => {
				//console.log('Updating token to: ', myToken);
				props.SetUserState({
					loggedIn: false,
					refreshData: true,
					lastRefreshPage: '/login',
					//preferredLanguage: 'en-US',
					preferredLanguage: 'en-US',
					user:null
				});
				
				triggerRedirect(true);
			} )
			.catch( error => {
				console.log('ERROR: Could not log this user in.', error);
				setErrMessage('Invalid Username or Password combination. Please try again.');
				SetDisableLoginBtn(false);
			});
	}

	const getLoginError= () => {
		if( myError === '' ) {
			return null;
		}

		return (
			<div className={"callout alert"} style={{display:'block'}} data-closable>
				<h5>Problem Logging in</h5>
				<p>{myError}</p>
				<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						//window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow');
						setErrMessage('');
					}}
				>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		);
	}

/*
	console.log('LOGIN PARAMETERS', props.match.params);
	let myRedirectPath = "/dashboard";
	const redirArr = [];
	if( props.match.params.redirectTo ){
		redirArr.push( props.match.params.redirectTo );
	}
	if( props.match.params.optParam1 ){
		redirArr.push( props.match.params.optParam1 );
	}
	if( props.match.params.optParam2 ){
		redirArr.push( props.match.params.optParam2 );
	}
	if( redirArr.length > 0){
		myRedirectPath = redirArr.join('/');
	}
	console.log(myRedirectPath);
	*/
//{props.match.params.redirectTo ? '/' + props.match.params.redirectTo : "/dashboard"}
	const handleEnterPress = (event) => {
		if(event.key === 'Enter'){
			//loginToSystem();
		}
	}
	console.log('start redirect? ', startRedirect,props.match.params.redirectTo,props.UserState.loggedIn);

	if( props.match.params.redirectTo && props.UserState.loggedIn ) {
		const myRedir = '/' + props.match.params.redirectTo; /*+ (
			props.match.params.optParam1 ? '/' + props.match.params.optParam1 + (
				props.match.params.optParam2 ? '/' + props.match.params.optParam2
				: ''
			)
			: ''
		);*/
	
		return (<Redirect push to={myRedir} /> )
	}

	return (
		<>
			{(
				startRedirect ? 
				<Redirect push to={
					props.match.params.redirectTo ? 
						'/' + props.match.params.redirectTo + (
							props.match.params.optParam1 ? '/' + props.match.params.optParam1 + (
								props.match.params.optParam2 ? '/' + props.match.params.optParam2
								: ''
							)
							: ''
						)
					
					: "/dashboard"} /> 
				: 
				<main id="main-wrapper" className="">
					<div id="main" className="">
						<div id="main-col" className="">
							<div id="content" className="">
								{announcement ? <Announcement type={announcement.type} messages={announcement.messages} header={announcement.header}/> : <></>}
								<form action="" method="post" onSubmit={handleLoginSubmitEvent}>
								<div className="medium-10 large-6 large-offset-3 medium-offset-1 columns">
									<div>
										<h3>Login</h3>
										<p>Log into your account here.</p>
										{getLoginError()}
										
										<div className="label-field-set">
											<label>
												Email:
												<input type="email" name="userEmail" id="userEmail" value={loginEmail} onChange={e => setLoginEmail(e.target.value)}/>
											</label>
										</div>
										<div className="label-field-set">
											<label>
												Password:
												<input type="password" name="userPw" id="userPw" value={loginPassword} onChange={e => setLoginPassword(e.target.value)}
													onKeyDown={(event) => {handleEnterPress(event)}}
												/>
											</label>
										</div>
										{
											!announcement || (announcement && announcement.onDate && new Date().toISOString().substring(0,10) <= announcement.onDate )
											?
											<>
											
										<button type="submit" className="button medium expanded"
											disabled={disableLoginBtn}
											onClick={(e)=>{}}
											>Login</button>
											
										<div className="small-collapse">
											<div className=" small-12 medium-5 columns">
												<Link to="/forgot-password" className="button expanded secondary"> Forgot Password? </Link>
											</div>	
											<div className=" small-12 medium-5 medium-offset-2 columns">
												<Link to="/register" className="button expanded primary"> Register </Link>
											</div>
										</div>
										</>: <><p>Logins disabled for maintenance.</p></>}
									</div>
								</div>
								</form>
							</div>
						</div>
					</div>
				</main>
			)}
		</>
	);
}

export default LoginPage;



//trash zone

/*
						{
							(Config.IS_DEV_ENV ? <button className="button medium expanded alert"
							onClick={(e)=>{loginToSystemCFA()}}
							>Login [AS FAKE CFA]</button> : '')
						}
						{
							(Config.IS_DEV_ENV ? <button className="button medium expanded alert"
							onClick={(e)=>{loginToSystemAdmin()}}
							>Login [AS ADMIN]</button> : '')
						}
						
	const loginToSystemCFA = () =>{
		const myUser = {
			loggedIn: true,
			//preferredLanguage: 'en-US',
			preferredLanguage: 'en-US',
			user: {
			  id: 2,
			  firstName: 'Daniel',
			  lastName: 'Farnsworth',
			  email: 'danfarns@njaes.rutgers.edu',
			  spanishPref: 0,
			  userType: 'C',
			  profactId: 'C0000001',
			  tfaList: [],
			  homeInfo: {
				contactId: 7,
				street: '90A Stonehurst Blvd Apt A',
				city: 'Freehold',
				county: 'Monmouth',
				state: 'NJ',
				zip: '07728',
				phone: '908-787-7124',
				active: 1,
			  },
			  businessInfo: {
				contactId: 6,
				name: 'Business name!',
				street: '421 Willow Ave',
				city: 'Garwood',
				state: 'NJ',
				zip: '07027',
				phone: '9087898423',
				county: 'Union',
				active: 1,
			  }
			}
		};
		localStorage.setItem('profact-token', JSON.stringify(myUser));
		props.SetUserState(myUser);
		triggerRedirect(true);
		setErrMessage('You have logged in successfully!');
	}

	let loginToSystemAdmin = () =>{ };
	if( Config.IS_DEV_ENV ) {
		loginToSystemAdmin = () =>{
			props.SetUserState({
				loggedIn: true,
				//preferredLanguage: 'en-US',
				preferredLanguage: 'en-US',
				user: {
					firstName: 'Daniel',
					lastName: 'Farnsworth',
					email: 'danfarns@njaes.rutgers.edu',
					spanishPref: 0,
					userType: 'A',
					profactId: 'A0000001',
					tfaList: [],
					homeInfo: {
					  contactId: 1,
					  street: '90A Stonehurst Blvd Apt A',
					  city: 'Freehold',
					  state: 'NJ',
					  zip: '07728',
					  phone: '908-787-7124',
					  county: 'Monmouth',
					},
					businessInfo: {
					  contactId: 2,
					  name: 'Business name!',
					  street: '90A Stonehurst Blvd Apt A',
					  city: 'Freehold',
					  state: 'NJ',
					  zip: '07728',
					  phone: '908-787-7124',
					  county: 'Monmouth',
					}
				}
			});
			triggerRedirect(true);
			setErrMessage('You have logged in successfully!');
		}
	}

*/