//
import React, { useRef, useState, useEffect } from "react";
import { DECODE_TOKEN, admin_get_all_courses, admin_add_single_credit } from "../../../../Api/api";



import { AXIOS_ERR, AXIOS_LOADING, AXIOS_OK, AXIOS_WAIT  } from "../../../NewYearsTasksPage";


/*
type CourseList = {
	courseId	6
	courseTitle	"Previous Profact System"
	description	"This user had valid credits on the old profact system (pre-2021)."
	cost	0
	credits	0
	numSections	0
	numExamQuestions	0
	examTimeLimit	0
	passingScore	70
	beginDate	"2020-12-14T05:00:00.000Z"
}
*/

/*
//send data
type CourseDataPacket = {
	id: int,
	courseId: int, required,
	userId: int, required,
	creditEarned: int,
	examId: int,
	payId: int
}

*/

export default function AddCourseToCfa ({userId, callback=()=>{}}/*:{userId: number, callback?:()=>void} */) {
	const popupRef = useRef/*<HTMLDivElement>*/(null);
	const [myPopup, setMyPopup] = useState(null);


	const [completeCourseList, setCompleteCourseList] = useState/*<number|null>*/(null);


	const [axiosState, setAxiosState] = useState/*<{isLoading: boolean, status: AXIOS_WAIT|AXIOS_OK|AXIOS_ERR|AXIOS_LOADING|> */({
		isLoading: false,
		state: AXIOS_LOADING
	});


	const [axiosSaveCourseState, setAxiosSaveCourseState] = useState/*<{isLoading: boolean, status: AXIOS_WAIT|AXIOS_OK|AXIOS_ERR|AXIOS_LOADING|> */({
		isLoading: false,
		state: AXIOS_WAIT
	});

	


	useEffect( () => {
		if( axiosState.state === AXIOS_LOADING ){
			const tokenInfo = DECODE_TOKEN();
			if( tokenInfo.code !== 'TOKEN_FOUND' ) {
				console.error('Did not find appropriate token.');
				setAxiosState({isLoading:false, state: AXIOS_ERR});
				return;
			}

			//by begin date
			const sortCourses = (a, b) => { 
				if(!a.beginDate || !b.beginDate) { 
					return 0;
				}

				if( a.beginDate > b.beginDate ) {
					return -1;
				}else if( a.beginDate < b.beginDate ) {
					return 1;
				}

				return 0;
			}
			
			admin_get_all_courses( tokenInfo.getAuthToken ) 
			.then( (res) => {		
				if( res && res.data && res.data.code && res.data.code ==='ALL_COURSES') {
					setAxiosState({isLoading:false, state: AXIOS_OK});
					setCompleteCourseList(res.data.courseList.filter ( (courseItem) => { 
						if(!courseItem.courseTitle ) {return false;}
						//Because I do not have a guarentee on the course Id. I don't have a guarentee here either but eh.
						return courseItem.courseTitle.toLocaleLowerCase() !== "cfa exam"; 
					}).sort( sortCourses ) );
				}else{
					setAxiosState({isLoading:false, state: AXIOS_ERR});
				}
			} )
			.catch( error => {

				console.error('Getting [admin_get_all_courses] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not revoke cfas.');
				}
				setAxiosState({isLoading:false, state: AXIOS_ERR});
			});
		}
	}, [axiosState] );

	
	useEffect( () => {
		if( popupRef.current ){
			const myA = new window.Foundation.Reveal( window.jQuery(popupRef.current) );
			setMyPopup(myA)
			//new Foundation.Reveal(myPopup.current)
		}
	}, [popupRef] );



	const submitAddCourse = (e) => { 
		e.preventDefault() ;
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			setAxiosState({isLoading:false, state: AXIOS_ERR});
			return;
		}


		const formData = new FormData(e.target);

		console.log('THE FORM DATA EMAIL: ', formData);
		const myData = { 
			courseId: null,
			userId: null,
			creditEarned: null,
		}
		for(let pair of formData.entries()) {
			if( pair[0] === 'userId' ) { 
				myData.userId = Number(pair[1])
			}else if( pair[0] === 'courseId' ) { 
				myData.courseId = Number(pair[1])
			}else if( pair[0] === 'creditEarned' ) { 
				if( Number(pair[1]) < 0 ){
					myData.creditEarned = 0;
				}else {
					if( pair[1].trim() ) {
						myData.creditEarned = Number(pair[1]);
					}
				}
			}else{

			}
		}

		setAxiosSaveCourseState({isLoading:true, axiosState: AXIOS_LOADING});


		admin_add_single_credit( 
			tokenInfo.getAuthToken, 
			myData
		)
		.then( (res) => {
			if(res.data.code === 'OK' ) {
				setAxiosSaveCourseState({isLoading:false, state: AXIOS_OK});
			}else{
				setAxiosSaveCourseState({isLoading:false, state: AXIOS_ERR});
			}
		})
		.catch( error => {
			console.error('saving [admin_add_single_credit] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not save course to cfa.');
			}
			setAxiosSaveCourseState({isLoading:false, state: AXIOS_ERR});
		});

		console.log("Sending: ", myData);

		return false;
		/*
		admin_add_single_credit( 
			tokenInfo.getAuthToken, 
			{
				...emailInfo,
				message: myMessage
			}
		)
		.then( (res) => {
			console.log('Sending some emails: ', res.data);

			if(res.data.code === 'OK' ) {
				SetEmailAlert({
					type: 'success',
					display: 'block',
					title: 'Email sent',
					message: 'The email has been sent out. A copy of the email you have sent will be sent to profact@sebs.rutgers.edu.'
				});
				ResetEmailForm();
			}else{
				SetEmailAlert({
					type: 'alert',
					display: 'block',
					title: 'Problem sending out an email',
					message: `There was a problem sending this email out: ${res.data.message}`
				});
			}
			SetDisableMailBtn(false);
		} )
		.catch( error => {
			console.error('Auto (Adding / Updating News Item)', error);
			SetDisableMailBtn(false);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Auto (Adding / Updating News Item)');
			}
		});
		return false;
		*/
	}


	return <div>
		<div><button type="button" className="button small success" onClick={()=>{
			myPopup.open()
		}}> <i className="fas fa-plus"></i> Add Course to CFA </button></div>
		<div ref={popupRef} className="reveal" id="disable-tfas" data-reveal>
			{
				axiosState.state === AXIOS_LOADING 
				?
				<div>
					<h1>Add Course to CFA</h1>
					<p>
					<i style={{fontSize:'xx-large'}} className="fas fa-spinner fa-spin"></i> Loading Course List</p>
				</div>
				:
				(
				axiosSaveCourseState.state !== AXIOS_OK
				?
				<div>
					<h1>Add Course to CFA</h1>
					<p className="lead">Please fill out the form below.</p>
					<form action="" onSubmit={submitAddCourse} >
						<div>
								<input type="hidden" name="userId" id="userId-courseadd" value={userId} />
								<div>
									<label>
										Course 
										<select required={true} name="courseId" id="courseId-courseadd">
											<option value="">- Select a Course -</option>
											{
												completeCourseList?.map ( (courseItem, key) => {
													return <option key={key} value={courseItem.courseId}>[{  
														new Date(courseItem.beginDate).toLocaleDateString()}] - {courseItem.courseTitle} </option>
												})
											}
										</select>
									</label>
								</div>
								<div>
									<label>
										Credits
										<input type="number" step={1} min={0} name="creditEarned" id="creditEarned-courseadd" />
									</label>
									<p> Adding credits will place this course in the CFA&rsquo;s <em><strong>COMPLETED</strong></em> list</p>
								</div>
						</div>
						<p><em><strong>This action cannot be undone.</strong></em></p>
						{axiosSaveCourseState.state === AXIOS_ERR ? <p><em> There was a problem adding the course to the CFA. </em></p> : <></>}
						<button className="close-button" data-close aria-label="Close modal" type="button"
							onClick={()=>{
								if( myPopup ) { myPopup.close(); }
								
							}}>
							<span aria-hidden="true">&times;</span>
						</button>
						<div>
							<button className="button" onClick={()=>{
								if( myPopup ) { myPopup.close(); }
								}}>
								Cancel
							</button> <button type="submit" className="button "
								disabled={axiosState.isLoading || undefined}
								onClick={()=>{
								}}>
									{axiosState.isLoading ? 'Adding...'
									:"Add Course to CFA"
									}
							</button>
						</div>
					</form>
				</div>
				:
				<div>
					<h1>Course added successfully.</h1>
					<p className="lead">The course was added to the CFA.</p>
					<div>
						<button className="button" onClick={()=>{
							if( callback) { callback() }
							if( myPopup ) { myPopup.close(); }
						}}>
							OK
						</button>
					</div>
				</div>
				)
			}
		</div>	
	</div>
}

