import React, {
	useEffect, useState
} from 'react';


import moment from 'moment-timezone';
import {
	//admin_get_all_courses,
	admin_get_all_courses_with_credits,

	DECODE_TOKEN
} from '../../../Api/api';


const CECourseTable = (props) => {
	const [SelectCourse, UpdateCourseCreditList] = [
		props.SelectCourse,
		props.UpdateCourseCreditList
	];


	const [courseList, SetCourseList] = useState({
		isLoading: true,
		hasError: false,
		filterText: '',
		showExpired: false,
		courses: []
	});
	const [tablePagination, SetTablePagination] = useState({
		tablePage: 0,
		count: 25
	});


	const RetrieveCourseList = () => {
		

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		//admin_get_all_courses(tokenInfo.getAuthToken)
		admin_get_all_courses_with_credits(tokenInfo.getAuthToken)
		.then( (res) => {
			console.log('Courses List', res.data);
			
			const [ allCourses, allCredits] = [ res[0], res[1] ];

			if( 
				allCourses.data.code === 'ALL_COURSES' 
				&& allCredits.data
			) {
				const myCourseList = allCourses.data.courseList.filter( (courseInfo) => {
					//remove cfa exam
					return courseInfo.courseTitle.toLocaleLowerCase() !== 'cfa exam';
				}).map( (courseInfo) => {
					//Find all credits for this course.
					courseInfo.cfaCredits = allCredits.data.filter( (creditInfo) => {
						return creditInfo.courseId === courseInfo.courseId;
					});

					return courseInfo;
				});

				SetCourseList({
					isLoading: false,
					hasError: false,
					filterText: '',
					showExpired: false,
					courses: myCourseList
				})
			}else{
				SetCourseList({
					isLoading: false,
					hasError: true,
					filterText: '',
					showExpired: false,
					courses: []
				})
			}
			//console.log('All Coures and credits,', allCourses, allCredits);
		} )
		.catch( error => {
			console.error('Getting [Admin Get All Courses] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch statistics.');
			}
		});
		
	}

	const handleFilterOnChange = event => {
		SetCourseList({
			...courseList,
			filterText: event.target.value
		});
		SetTablePagination({
			...tablePagination,
			tablePage: 0
		});
	};

	const DisplayCourses = () => {
		
		if( courseList.isLoading ) {
			return ( 
				<>
					<tr><td colSpan="10"><i className="fas fa-circle-notch fa-spin"></i> Loading Courses...</td></tr>
				</>
			);
		}
		if( courseList.hasError ) {
			return ( 
				<>
					<tr><td colSpan="10"><i className="fas fa-times-circle"></i> Failed to load courses. </td></tr>
				</>
			);
		}
		if( courseList.courses.length<1 ) {
			return ( 
				<>
					<tr><td colSpan="10"> No courses available. </td></tr>
				</>
			);
		}

		//console.log('Displaying courses: ', courseList.courses);
		return (
			courseList.courses
			.sort( (a,b) => {
				//sort by date
				//b first for desc
				return b.beginDate.localeCompare(a.beginDate);
			})
			.filter( (courseInfo) => {
				//check date
				if( !courseList.showExpired ) {
					if( 
						moment.tz(courseInfo.beginDate,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
							.tz( moment.tz.guess() )
							.isSameOrBefore( moment().subtract(1, 'week') )
					){
						return false;
					}
				}


				if( courseList.filterText === '' ) { 
					return true;
				}
				console.log('Course Info: ', courseInfo, 'filtered by', courseList.filterText);
				const myFilterText = courseList.filterText.toLocaleLowerCase();
				 return (
					courseInfo.courseTitle.toLocaleLowerCase().includes( myFilterText )
					|| courseInfo.description.toLocaleLowerCase().includes( myFilterText )
				);
				/*
				const myFilterText = filterText.toLocaleLowerCase().trim();
				return (
					tfaInfo.lastName.toLocaleLowerCase().includes( myFilterText )
					|| tfaInfo.firstName.toLocaleLowerCase().includes( myFilterText )
					|| tfaInfo.email.toLocaleLowerCase().includes( myFilterText )
					|| tfaInfo.payId.toString().toLocaleLowerCase().includes( myFilterText )
				);
				*/
			})
			.slice(
				tablePagination.tablePage * tablePagination.count,
				(tablePagination.tablePage * tablePagination.count) + tablePagination.count
			)
			/*
			.sort( ( a, b ) =>{
				return aVar.localeCompare(bVar);
			})*/
			.map( (courseInfo, key)  => {
				return (
					<tr key={key}>
						
						<td>{courseInfo.courseTitle}</td>
						<td>{courseInfo.description && courseInfo.description.length > 100 ? courseInfo.description.substr(0,100) + '...' : courseInfo.description }</td>
						<td>{courseInfo.credits}</td>
						<td>{moment(courseInfo.beginDate,'YYYY-MM-DDTHH:mm:ss.SSSZ')
							.format('L')
						}</td>
						<td>{courseInfo.cfaCredits.length} CFAs</td>
						<td>
							<button className="button primary small no-bottom-margin"
								onClick={ (e) => { 
									SelectCourse(courseInfo);
								} }
							><i className="fa fa-edit"></i> Update Course</button>
						</td>
						<td>
							<button className="button primary small no-bottom-margin"
								onClick={ (e) => { 
									UpdateCourseCreditList(courseInfo)
								} }
							><i className="fa fa-users"></i> Update CFAs </button>
						</td>
					</tr>
				);
			})
		);
	}

	const _GetTablePagination = () => {
		const myCount = courseList.courses
		.filter( (courseInfo) => {
			//check date
			if( !courseList.showExpired ) {
				if( 
					moment.tz(courseInfo.beginDate,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
						.tz( moment.tz.guess() )
						.isSameOrBefore( moment().subtract(1, 'week') )
				){
					return false;
				}
			}


			if( courseList.filterText === '' ) { 
				return true;
			}
			console.log('Course Info: ', courseInfo, 'filtered by', courseList.filterText);
			const myFilterText = courseList.filterText.toLocaleLowerCase();
			 return (
				courseInfo.courseTitle.toLocaleLowerCase().includes( myFilterText )
				|| courseInfo.description.toLocaleLowerCase().includes( myFilterText )
			);
			/*
			const myFilterText = filterText.toLocaleLowerCase().trim();
			return (
				tfaInfo.lastName.toLocaleLowerCase().includes( myFilterText )
				|| tfaInfo.firstName.toLocaleLowerCase().includes( myFilterText )
				|| tfaInfo.email.toLocaleLowerCase().includes( myFilterText )
				|| tfaInfo.payId.toString().toLocaleLowerCase().includes( myFilterText )
			);
			*/
		}).length;

		const maxPages = Math.ceil( myCount / tablePagination.count);
		/*
		console.log('Pagination: ', {
			listCount: myCount,
			tablePage: tablePagination.tablePage,
			count: tablePagination.count,
			maxPages
		});*/

		if( maxPages < 2 ) {
			return (<></>);
		}
		const getPageBtns = [];

		let trimFrontPages = false;
		let trimBackPages = false;

		if( maxPages > 10 ) {
			if(tablePagination.tablePage < 5 ) {
				trimBackPages =true;
				for(let i=0;i<6;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}else if(tablePagination.tablePage > maxPages-6 ) {
				trimFrontPages =true;
				for(let i=maxPages-6;i<maxPages;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}else{
				trimBackPages =true;
				trimFrontPages =true;
				for(let i=tablePagination.tablePage-2;i<tablePagination.tablePage+3;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}
		}else{
			for(let i=0;i<maxPages;i++){
				getPageBtns.push( 
					<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
						<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
									e.preventDefault();  
									e.stopPropagation(); 
									SetTablePagination({
										...tablePagination,
										tablePage: i
									});
									return false; 
								}}>
							<span className="show-for-sr">You're on table page</span> {i+1} 
						</a>
					</li>
				);
			}
		}

		return (
			<nav aria-label="Table Pagination">
				<ul className="pagination">{
						tablePagination.tablePage < 1 ? 
						<li className={"pagination-previous disabled"}>Previous <span className="show-for-sr">page</span></li>
						: <li className={"pagination-previous"}>
							<a href="/#" aria-label="Previous page" onClick={(e) => { 
								e.preventDefault();  
								e.stopPropagation(); 
								SetTablePagination({
									...tablePagination,
									tablePage: tablePagination.tablePage-1
								});
								return false; 
							} }>Previous <span className="show-for-sr">page</span> </a>
						</li>
					}
					{trimFrontPages ? <>
						<li className={(tablePagination.tablePage === (0) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (0+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: 0
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {0+1} 
							</a>
						</li>
						<li className={"disabled"}>... <span className="show-for-sr">trimmed front pages</span></li> 
					</>: <></>}
					{getPageBtns}
					{trimBackPages ? <>
						<li className={"disabled"}>... <span className="show-for-sr">trimmed back pages</span></li> 
						<li className={(tablePagination.tablePage === (maxPages-1) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (maxPages)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: maxPages-1
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {maxPages} 
							</a>
						</li>
					</> : <></>}
					{
						tablePagination.tablePage >= maxPages-1 ? 
						<li className={"pagination-next disabled"}>Next <span className="show-for-sr">page</span></li>
						: <li className={"pagination-next"}>
							<a href="/#" aria-label="Next page" onClick={(e) => { 
								e.preventDefault(); 
								e.stopPropagation(); 
								SetTablePagination({
									...tablePagination,
									tablePage: tablePagination.tablePage + 1
								});
								return false; 
							}}>Next <span className="show-for-sr">page</span> </a>
						</li>
					}
				</ul>
			</nav>
		);
	}

	
	useEffect( () => {
		RetrieveCourseList();
	}, []);
	

	return (
		<>
			<h3> Course Form </h3>
			<div>
				<h4>Search Table</h4>
				<div className="">
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="middle-label" className="label-style-1">Filter By Text: </label>
						</div>
						<div className="medium-5 small-12 no-float columns">
							<input type="text" id="middle-label" placeholder="Course Name" 
								onChange={handleFilterOnChange}
							/>
						</div>
					</div>
					<div className="row">
						<div className="medium-3 small-12 columns" >
							<label htmlFor="tablePaginationRows"
								className="label-style-1" > Number of Courses Per Page: </label> 
						</div> 
						<div className="medium-5 small-12 no-float columns" >
							<select 
								id="tablePaginationRows"
								name="tablePaginationRows"
								value={tablePagination.count}
								onChange={e => { 
									SetTablePagination({
										...tablePagination,
										tablePage:0,
										count: Number(e.target.value)
									});
								} }>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="200">200</option>
							</select> 
						</div> 
					</div>
					<div className="row">
						<div className="small-12 columns button-group">
							<button className={
							`button small primary ${courseList.showExpired ? 'is-selected' : ''}`
							} onClick={(e) =>{ SetCourseList( {
								...courseList,
								showExpired: true
							}) }}>Show all</button> <button className={
								`button small primary ${!courseList.showExpired ? 'is-selected' : ''}`
							} onClick={(e) =>{ SetCourseList( {
								...courseList,
								showExpired: false
							}) }}> Show Recent Or Future Courses Only</button>
						</div>
						<div className="small-12 columns">
							<p><em>"Recent or Future Courses" will show courses starting from {moment().subtract(1, 'weeks').format('MM/DD/YYYY')} into the future.</em></p>
						</div>
					</div>
				</div>
			</div>
			<div className="small-12 columns">
				{_GetTablePagination()}
			</div>
			<div className="small-12 columns">
				<div className="table-scroll">
					<table className="table-left-align-all">
						<thead>
							<tr>
								<th>Course Title</th>
								<th>Description</th>
								<th>Credits</th>
								<th>Date</th>
								<th># of CFAs</th>
								<th colSpan="2" style={{textAlign:'center'}}>Update</th>
							</tr>
						</thead>
						<tbody>
							{DisplayCourses()}
						</tbody>
					</table>
				</div>
			</div>
			<div className="small-12 columns">
				{_GetTablePagination()}
			</div>
		</>
	);
};

export default CECourseTable;