import React, {
	//useEffect,
	//useState,
} from 'react';
import { Link } from 'react-router-dom';
/*


import TrainingManual from './TrainingManual';
import ExamOptions from './ExamOptions';

import {
	admin_get_course_info,

	get_online_training_content,
	get_credits_by_cfa,
	get_credit_info,

	DECODE_TOKEN
} from '../../../Api/api';
*/

const ContentManager = (props) =>{


	console.log(props);





/*
	const GET_ONLINE_CONTENT = () => {
		if( props.course === 'cfa' ) {
			console.log('Course: CFA Traning Manual');
			return true;
		}


		console.log('Course: CE Course');
	}
*/


	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h2> Manage Courses </h2>
						<p><em>You can update Content on this page </em></p>
						<Link to={`/manage-courses`} className="no-bottom-margin button primary small"><i className="fas fa-backward"></i> Back to list</Link>
						<hr />
						<h2> Content Management forms coming soon!</h2>
					</div>
				</div>
			</div>
		</main>
	);
}

export default ContentManager;

/*
	if( !props.course ) {
		return (
			<main id="main-wrapper" className=" ">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content">
							<h2> Manage Courses </h2>
							<p><em>You can update Content on this page </em></p>
							<Link to={`/manage-courses`} className="no-bottom-margin button primary small"><i className="fas fa-backward"></i> Back to list</Link>
							<hr />
							<h2> No course detected. please go back and select a course. </h2>
						</div>
					</div>
				</div>
			</main>
		);
	}
*/