import React, { useState, useEffect } from 'react';
import ProfactSelect from '../../../HelperComponents/ProfactSelect';
//import { Link } from 'react-router-dom';
import axios from 'axios';
import { 
	update_my_profile,
	update_contact_info,
	reset_my_password
} from '../../../Api/api';
import { Link } from 'react-router-dom';

import {FormatPhoneNumber, FormatZipcode} from '../../../Config';

const ProfilePage = (props) => {
	//const [firstName, lastName] = [props.UserState.user.firstName, props.UserState.user.lastName];
	//document.title = props.title;
	/**
	 * User input and states
	 */
	//user id
	const userId = props.UserState.user.id;
	const homeAddressId = props.UserState.user.homeAddressId;
	const businessAddressId = props.UserState.user.businessAddressId;


	//tabs
	const [profileForm, setProfileForm] = useState('personal');
	
	const [disablePersonalBtn, SetDisablePersonalBtn] = useState(false);
	const [disablePasswordBtn, SetDisablePasswordBtn] = useState(false);
	const [disableHomeBtn, SetDisableHomeBtn] = useState(false);
	const [disableBusinessBtn, SetDisableBusinessBtn] = useState(false);

	//personal information

	const [personalInfo, setPersonalInfo] = useState( {
		id: userId,
		firstName: props.UserState.user.firstName,
		lastName: props.UserState.user.lastName,
		email: props.UserState.user.email,
		spanishPref: (props.UserState.user.spanishPref === "1" || props.UserState.user.spanishPref === 1 ? 1 : 0),
	} );
	const [personalAlert, setPersonalAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	
	const personalForm_spanishPref_HandleChange = (e_target) => {
		//console.log(e_target, [e_target.name, e_target.checked]);
		const { name, checked } = e_target;
        setPersonalInfo(personalInfo => ({
            ...personalInfo,
            [name]: (checked ? 1 : 0)
		}));
	}

	const personalForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		//console.log(e_target, );
        setPersonalInfo(personalInfo => ({
            ...personalInfo,
            [name]: value
		}));
	}

	//Password
	const [pwInfo, setPwInfo] = useState( {
		id: userId,
		oldPass: '',
		newPass: '',
		newPassRetype: ''
	} );
	const [passwordAlert, setPasswordsAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	
	const passwordForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
        setPwInfo(pwInfo => ({
            ...pwInfo,
            [name]: value
		}));
	}

	//Business information
	const [businessName, setBusinessName] = useState( {
		businessName: props.UserState.user.businessName,
		//id: userId
	} );

	const [businessAddress, setBusinessAddress] = useState( {
		...props.UserState.user.businessAddress,
		zip: FormatZipcode(props.UserState.user.businessAddress.zip),
		phone: FormatPhoneNumber(props.UserState.user.businessAddress.phone)
		//name: props.UserState.user.businessName,
		//id: userId
	} );
	
	const businessForm_requireCounty = (e_target) => {
        setBusinessAddress(businessAddress => ({
            ...businessAddress,
            county: ''
		}));

		if (e_target.value.toLowerCase() === 'nj' ) {
			setRequireBusinessCounty(true);
		}else{
			setRequireBusinessCounty(false);
		}
		
	};

	const businessForm_HandleChange = (e_target) => {
		const { name, value } = e_target;

		if( name === 'businessName' ) {
			setBusinessName(businessName => ({
				...businessName,
				[name]: value
			}));
		}else{
			
			let updateValue = value;
			/*
			if( 
				name === 'zip' 
				|| name === 'phone' 
			) {
				updateValue = value.replace(/[^0-9]/g, '');
			}
			*/
			
			setBusinessAddress(businessAddress => ({
				...businessAddress,
				[name]: updateValue
			}));
		}
	}
	
	const [businessAddressAlert, setBusinessAddressAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	
	//Home Information
	const [homeAddressAlert, setHomeAddressAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});

	const [requireHomeCounty, setRequireHomeCounty] = useState( (props.UserState.user.homeAddress.state.toLowerCase() === 'nj') );
	const [requireBusinessCounty, setRequireBusinessCounty] = useState( (props.UserState.user.businessAddress.state.toLowerCase() === 'nj') );
	const [homeAddress, setHomeAddress] = useState( {
		...props.UserState.user.homeAddress,
		zip: FormatZipcode(props.UserState.user.homeAddress.zip),
		phone: FormatPhoneNumber(props.UserState.user.homeAddress.phone)
	} );

	useEffect(() =>{ 

		setPersonalAlert({
			type: 'success',
			display: 'none',
			title: '',
			message: ''
		});
		setPasswordsAlert({
			type: 'success',
			display: 'none',
			title: '',
			message: ''
		});
		setHomeAddressAlert({
			type: 'success',
			display: 'none',
			title: '',
			message: ''
		});
		setBusinessAddressAlert({
			type: 'success',
			display: 'none',
			title: '',
			message: ''
		});


	},[profileForm]);

	const homeForm_requireCounty = (e_target) => {
        setHomeAddress(homeAddress => ({
            ...homeAddress,
            county: ''
		}));

		if (e_target.value.toLowerCase() === 'nj' ) {
			setRequireHomeCounty(true);
		}else{
			setRequireHomeCounty(false);
		}
		
	};


	const homeForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		
		let updateValue = value;
		/*
		if( 
			name === 'zip' 
			|| name === 'phone' 
		) {
			updateValue = value.replace(/[^0-9]/g, '');
		}
		*/
        setHomeAddress(homeAddress => ({
            ...homeAddress,
            [name]: updateValue
		}));
	}

	//Form Submits
	const handlePersonalDetailsEvent = (e) => {
		e.preventDefault();

		//console.log('Submitting personal details: ',  personalInfo);
		let getAuthToken = null;
		let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(loginStatusErr) {
		  
		  console.warn('Problematic Login token. Clearing. ');
		  localStorage.removeItem('profact-token');
		  props.UserState({
			loggedIn: false,
			refreshData: false,
			//preferredLanguage: 'en-US',
			preferredLanguage: 'en-US',
			user:null
		  });
		  window.location.reload();
		  return false;
		}
		SetDisablePersonalBtn(true);
		update_my_profile( getAuthToken, getId, personalInfo )
			.then(function(response){
				//console.log('Updating this current user.', response);
				
				setPersonalAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully updated!',
					message: 'We have saved your personal information.'	
				});

				const userObj = {
					...props.UserState.user,
					firstName: personalInfo.firstName,
					lastName: personalInfo.lastName,
					email: personalInfo.email,
					spanishPref: personalInfo.spanishPref
				};
				//userObj.firstName = personalInfo.firstName;

				//console.log( 'props.Setting User state',  );
				props.SetUserState({
					...props.UserState,
					user: userObj
				});
				document.getElementById('personal-details-div').scrollIntoView();
				SetDisablePersonalBtn(false);
			})
			.catch(function(error){
				console.error('Failed to update this user\'s home details.', error);
				setPersonalAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				SetDisablePersonalBtn(false);
			});
		return false;
	};

	const handlePasswordResetEvent = (e) => {
		
		e.preventDefault();

		//console.log('Submitting new password details');
		setPasswordsAlert({
			type: 'primary',
			display:'none', 
			title: '',
			message: ''
		});

		//console.log(pwInfo);
		if( pwInfo.newPass !== pwInfo.newPassRetype) {
			setPasswordsAlert({
				type: 'warning',
				display:'block', 
				title: 'Passwords must match',
				message: 'Please make sure that your "New Password" and "Retype New Password" fields match.'
			});
			return false;
		}
		if( pwInfo.newPass.length < 6 ) {
			setPasswordsAlert({
				type: 'warning',
				display:'block', 
				title: 'Passwords length',
				message: 'Please make sure that your new password is at least 6 characters in length. '
			});
			return false;
		}


		let getAuthToken = null;
		//let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  //getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(loginStatusErr) {
		  
		  console.warn('Problematic Login token. Clearing. ');
		  localStorage.removeItem('profact-token');
		  props.UserState({
			loggedIn: false,
			refreshData: false,
			//preferredLanguage: 'en-US',
			preferredLanguage: 'en-US',
			user:null
		  });
		  window.location.reload();
		  return false;
		}
		
		SetDisablePasswordBtn(true);
		reset_my_password(getAuthToken, pwInfo)
			.then(function(response){
				//console.log('Updating this current user.', response);
				
				setPasswordsAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully updated!',
					message: 'We have saved your new password.'	
				});
				
				setPwInfo( {
					...pwInfo,
					oldPass: '',
					newPass: '',
					newPassRetype: ''
				} );
				document.getElementById('password-details-div').scrollIntoView();
				SetDisablePasswordBtn(false);
			})
			.catch(function(error){
				console.error('Failed to update this user\'s poassword details.', error);

				setPasswordsAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				SetDisablePasswordBtn(false);
				//setDisableRegBtn(false);
			});
		return false;
	}
	
	const handleHomeDetailsEvent = (e) => {
		e.preventDefault();

		//console.log('Submitting home details: ',  homeAddress);
		let getAuthToken = null;
		//let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  //getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(loginStatusErr) {
		  
		  console.warn('Problematic Login token. Clearing. ');
		  localStorage.removeItem('profact-token');
		  props.UserState({
			loggedIn: false,
			refreshData: false,
			//preferredLanguage: 'en-US',
			preferredLanguage: 'en-US',
			user:null
		  });
		  window.location.reload();
		  return false;
		}
		
		SetDisableHomeBtn(true);

		update_contact_info( getAuthToken, homeAddressId, {
			...homeAddress,
			zip: (''+homeAddress.zip).replace(/[^0-9]/g, '').substr(0,9),
			phone: (''+homeAddress.phone).replace(/[^0-9]/g, '').substr(0,11),
		 } )
			.then(function(response){
				//console.log('Updating this current user.', response);
				
				props.SetUserState({
					...props.UserState,
					user: {
						...props.UserState.user,
						homeAddress: {
							...props.UserState.user.homeAddress,
							homeAddress
						}
					}
				});
				setHomeAddressAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully updated!',
					message: 'We have saved your home address information.'	
				});
				document.getElementById('home-details-div').scrollIntoView();
				SetDisableHomeBtn(false);
			})
			.catch(function(error){
				console.error('Failed to update this user\'s home details.', error);

				setHomeAddressAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				SetDisableHomeBtn(false);
			});
		return false;
	};
	const handleBusinessDetailsEvent = (e) => {
		e.preventDefault();

		//console.log('Submitting business details: ',  businessAddress);
		let getAuthToken = null;
		let getId = null;
		try{ 
		  getAuthToken = localStorage.getItem('profact-token');
		  getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
		}catch(loginStatusErr) {
		  
		  console.warn('Problematic Login token. Clearing. ');
		  localStorage.removeItem('profact-token');
		  props.UserState({
			loggedIn: false,
			refreshData: false,
			//preferredLanguage: 'en-US',
			preferredLanguage: 'en-US',
			user:null
		  });
		  window.location.reload();
		  return false;
		}
		//Special: Business currently is set in /api/cfa
		//the address is set in /api/contact
		//	> So we're going to use axios.all
		SetDisableBusinessBtn(true);
		axios.all([
			update_contact_info( getAuthToken, businessAddressId, {
				...businessAddress,
				zip: (''+businessAddress.zip).replace(/[^0-9]/g, '').substr(0,9),
				phone: (''+businessAddress.phone).replace(/[^0-9]/g, '').substr(0,11),
			 } ),
			update_my_profile( getAuthToken, getId, businessName )
		])
		.then(function(response){
			//console.log('Updating this current user.', response);
			
			props.SetUserState({
				...props.UserState,
				user: {
					...props.UserState.user,
					businessAddress: {
						...props.UserState.user.businessAddress,
						businessAddress
					}
				}
			});
			setBusinessAddressAlert({
				type: 'success',
				display:'block', 
				title: 'Successfully updated!',
				message: 'We have saved your business information.'	
			});
			document.getElementById('business-details-div').scrollIntoView();
			SetDisableBusinessBtn(false);
		})
		.catch(function(error){
			console.error('Failed to update this user\'s home details.', error);

			setBusinessAddressAlert({
				type: 'alert',
				display:'block', 
				title: 'Error',
				message: (error.message || error)
			});
			SetDisableBusinessBtn(false);
			//setDisableRegBtn(false);
		});
		return false;
	};

	/**
	 * FORMS
	 */


	const generateProfileForm = () => {

/*
									<input type="text" value={personalInfo.firstName} 
										name="firstName" id="firstName"  
										required="required"
										onChange={e => personalForm_HandleChange(e.target)} />

									<input type="text" value={personalInfo.lastName} 
										name="lastName" id="lastName"  
										required="required"
										onChange={e => personalForm_HandleChange(e.target)} />
*/

		return (
			<div id="personal-details-div" style={{display: (profileForm==='personal' ? 'block' : 'none')}}>
				<h3>Personal Details</h3>
				<div className={"callout " + personalAlert.type} style={{display:personalAlert.display}} data-closable>
					<h5>{personalAlert.title}</h5>
					<p>{personalAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							setPersonalAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="">
					<form action="" method="post" onSubmit={handlePersonalDetailsEvent}>
						<div className="">
							<div className="row">
								<div className="medium-3 small-12 columns">
									<label htmlFor="firstName" className="label-style-1">First Name</label>
								</div>
								<div className="medium-9 small-12 columns">
									{personalInfo.firstName} 
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12 columns">
									<label htmlFor="lastName" className="label-style-1">Last Name</label>
								</div>
								<div className="medium-9 small-12 columns">
									{personalInfo.lastName} 
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12 columns">
									<label htmlFor="email" className="label-style-1">Email</label>
								</div>
								<div className="medium-9 small-12 columns">
									<input type="email" value={personalInfo.email} 
										name="email" id="email"  
										required="required"
										onChange={e => personalForm_HandleChange(e.target)} />
									<p><em>You will use this email to log in.</em></p>
								</div>
							</div>
							<div className="medium- small-12 columns">
								<label htmlFor="spanishPref" className="label-style-1">
									Spanish Preferred
									<input type="checkbox" className="small-margin-left" name="spanishPref" id="spanishPref" 
										value={personalInfo.spanishPref}
										defaultChecked={personalInfo.spanishPref}
										onClick={(e)=>{ personalForm_spanishPref_HandleChange(e.target); }}
									/>
								</label>
							</div>
						</div>
						<div>
							<button type="submit" className="button expanded"
								disabled={disablePersonalBtn}
							>
								<i style={{display: (disablePersonalBtn ? 'inline-block' : 'none') }} className="fas fa-circle-notch fa-spin" > 
								</i> Save Your Details</button>
						</div>
					</form>
				</div>
			</div>
		);
	}; 
	const generatePasswordForm = () => {
		return (
			<div id="password-details-div" style={{display: (profileForm==='password' ? 'block' : 'none')}}>
				<h3>Update Password</h3>
				<p>Please select a password that is at least 6 characters in length</p>
				<div className={"callout " + passwordAlert.type} style={{display:passwordAlert.display}} data-closable>
					<h5>{passwordAlert.title}</h5>
					<p>{passwordAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							setPasswordsAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form action="" method="post" onSubmit={handlePasswordResetEvent}>
					<div className="">
						<div className="row">
							<div className="medium-5 small-12 columns">
								<label htmlFor="password" className="label-style-1">Current Password</label>
								<span className="form-error">
									This field is required.
								</span>
							</div>
							<div className="medium-7 small-12 columns">
								<input type="password"
									name="oldPass" id="oldPass"  
									value={pwInfo.oldPass}
									required="required"
									onChange={e => passwordForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-5 small-12 columns">
								<label htmlFor="newPass" className="label-style-1">New Password</label>
								<span className="form-error">
									This field is required.
								</span>
							</div>
							<div className="medium-7 small-12 columns">
								<input type="password" value={pwInfo.newPass}
									name="newPass" id="newPass"  
									required="required"
									autoComplete="off"
									onChange={e => passwordForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-5 small-12 columns">
								<label htmlFor="newPassRetype" className="label-style-1">Retype New Password</label>
								<span className="form-error">
									This field must match the new password.
								</span>
							</div>
							<div className="medium-7 small-12 columns">
								<input type="password" value={pwInfo.newPassRetype}
									name="newPassRetype" id="newPassRetype"  
									required="required"
									autoComplete="off"
									onChange={e => passwordForm_HandleChange(e.target)} />
							</div>
						</div>
						<div>
							<button type="submit" className="button expanded"
								disabled={disablePasswordBtn}
							>
							<i style={{display: (disablePasswordBtn ? 'inline-block' : 'none') }} className="fas fa-circle-notch fa-spin" > 
							</i> Save New Password</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
	const generateHomeForm = () => {
		//console.log('Generating Home form...', homeAddress);
		return (
			<div id="home-details-div" style={{display: (profileForm==='home' ? 'block' : 'none')}}>
				<h3>Update Home Details</h3>
				<div className={"callout " + homeAddressAlert.type} style={{display:homeAddressAlert.display}} data-closable>
					<h5>{homeAddressAlert.title}</h5>
					<p>{homeAddressAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							setHomeAddressAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form action="" method="post" onSubmit={handleHomeDetailsEvent}>
					<div className="">
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_street" className="label-style-1">Address</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={homeAddress.street} 
									name="street" id="hinfo_street"  
									required="required"
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">	
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_city" className="label-style-1">City</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={homeAddress.city} 
									name="city" id="hinfo_city"  
									required="required"
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_state" className="label-style-1">State</label>
							</div>
							<div className="medium-9 small-12 columns">
								<ProfactSelect
									name="state" id="hinfo_state" StateDropdown={true} 
									value={homeAddress.state}
									required="required"
									onChange={e => {
										homeForm_requireCounty(e.target);
										homeForm_HandleChange(e.target);
									} } />
							</div>
						</div>
						<div className="row">
							<div style={{display:(requireHomeCounty ? 'block' : 'none')}} className="medium-3 small-12 columns">
								<label htmlFor="hinfo_county" className="label-style-1">NJ County</label>
							</div>
							<div style={{display:(requireHomeCounty ? 'block' : 'none')}} className="medium-9 small-12 columns">
								<ProfactSelect
									name="county" id="hinfo_county" NjCountyDropdown={true}
									value={homeAddress.county}
									required={requireHomeCounty}
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_zip" className="label-style-1">Zipcode</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={homeAddress.zip} 
									name="zip" id="hinfo_zip"
									minLength="5"
									maxLength="10"
									required="required"
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_phone" className="label-style-1">Phone</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="tel" value={homeAddress.phone} 
									name="phone" id="hinfo_phone"
									minLength="7"
									maxLength="21"
									required="required"
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
					</div>
					<div>
						<button className="button expanded"
							disabled={disableHomeBtn}
						>
						<i style={{display: (disableHomeBtn ? 'inline-block' : 'none') }} className="fas fa-circle-notch fa-spin" > 
						</i> Save Home Information</button>
					</div>
				</form>
			</div>
		);
	}
	const generateBusinessForm = () => {
		return (
			<div id="business-details-div" style={{display: (profileForm==='business' ? 'block' : 'none')}}>
				<h3>Update Business Details</h3>
				<div className={"callout " + businessAddressAlert.type} style={{display:businessAddressAlert.display}} data-closable>
					<h5>{businessAddressAlert.title}</h5>
					<p>{businessAddressAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							setBusinessAddressAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form action="" method="post" onSubmit={handleBusinessDetailsEvent}>
					<div className="">
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="binfo_street" className="label-style-1">Business Name</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={businessName.businessName} 
									name="businessName" id="binfo_businessName"
									required="required"
									onChange={e => businessForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="binfo_street" className="label-style-1">Address</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={businessAddress.street} 
									name="street" id="binfo_street"
									required="required"
									onChange={e => businessForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">

							<div className="medium-3 small-12 columns">
								<label htmlFor="binfo_city" className="label-style-1">City</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={businessAddress.city} 
									name="city" id="binfo_city"
									required="required"
									onChange={e => businessForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
						
							<div className="medium-3 small-12 columns">
								<label htmlFor="binfo_state" className="label-style-1">State</label>
							</div>
							<div className="medium-9 small-12 columns">
								<ProfactSelect
									name="state" id="binfo_state" StateDropdown={true} 
									required="required"
									value={businessAddress.state}
									onChange={e => {
										businessForm_requireCounty(e.target);
										businessForm_HandleChange(e.target)
									} } />
							</div>
						</div>
						<div className="row">
							<div style={{display:(requireBusinessCounty ? 'block' : 'none')}} className="medium-3 small-12 columns">
								<label htmlFor="binfo_county" className="label-style-1">NJ County</label>
							</div>
							<div style={{display:(requireBusinessCounty ? 'block' : 'none')}} className="medium-9 small-12 columns">
								<ProfactSelect
									name="county" id="binfo_county" NjCountyDropdown={true}
									value={businessAddress.county}
									required={requireBusinessCounty}
									onChange={e => businessForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="binfo_zip" className="label-style-1">Zipcode</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={businessAddress.zip} 
									minLength="5"
									maxLength="10"
									name="zip" id="binfo_zip"
									required="required"
									onChange={e => businessForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="binfo_phone" className="label-style-1">Phone</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="tel" value={businessAddress.phone} 
									name="phone" id="binfo_phone"
									minLength="7"
									maxLength="21"
									required="required"
									onChange={e => businessForm_HandleChange(e.target)} />
							</div>
						</div>
					</div>
					<div>
						<button className="button expanded"
							disabled={disableBusinessBtn}
						>
						<i style={{display: (disableBusinessBtn ? 'inline-block' : 'none') }} className="fas fa-circle-notch fa-spin" > 
						</i> Save Business Details</button>
					</div>
				</form>					
			</div>
		);
	}





	//If admin 
	






	return(
		<div id="content">
			<div className="small-12 columns">
				<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
				<hr className="slim-hr" />
			</div>
			<div className="small-12 columns">
				<div className="medium-10 large-8 large-offset-2 medium-offset-1 columns">
					<h2>Your Profile</h2>
					<div className="stacked-for-small button-group">
						<button className={"button primary" + ( (profileForm === 'personal' ? ' is-selected' : '') ) } onClick={(e)=>setProfileForm('personal')}> <i className="fas fa-user"></i> Personal </button>
						<button className={"button primary" + ( (profileForm === 'password' ? ' is-selected' : '') ) }  onClick={(e)=>setProfileForm('password')}> <i className="fas fa-key"></i> Password </button>
						<button className={"button primary" + ( (profileForm === 'home' ? ' is-selected' : '') ) }  onClick={(e)=>setProfileForm('home')}> <i className="fas fa-home"></i> Home </button>
						<button className={"button primary" + ( (profileForm === 'business' ? ' is-selected' : '') ) }  onClick={(e)=>setProfileForm('business')}> <i className="fas fa-building"></i> Business </button>
					</div>
					<div>
						{generateProfileForm()}
						{generatePasswordForm()}
						{generateHomeForm()}
						{generateBusinessForm()}
					</div>
				</div>
			</div>
		</div>
	);
/*
	return(
		<main id="main-wrapper" className="grid-container ">
			<div id="main" className="grid-x grid-margin-x">
				<div id="main-col" className="cell">
					<h2>Profile</h2>
					<div className="row">
						<div className="columns tabs-override">
							<ul className="tabs" data-responsive-accordion-tabs="tabs small-accordion medium-accordion large-tabs" id="example-tabs">
								<li className="tabs-title is-active"><a href="#panel1" >Personal</a></li>
								<li className="tabs-title"><a href="#panel2">Home</a></li>
								<li className="tabs-title"><a href="#panel3">Business</a></li>
								<li className="tabs-title"><a href="#panel4">Password</a></li>
							</ul>

							<div className="tabs-content" data-tabs-content="example-tabs">
								<div className="tabs-panel is-active" id="panel1">
									<p>one</p>
									<p>Check me out! I'm a super cool Tab panel with text content!</p>
								</div>
								<div className="tabs-panel" id="panel2">
									<p>two</p>
									<img className="thumbnail" alt="placeholder" src="//foundation.zurb.com/sites/docs/assets/img/generic/rectangle-7.jpg" />
								</div>
								<div className="tabs-panel" id="panel3">
									<p>three</p>
									<p>Check me out! I'm a super cool Tab panel with text content!</p>
								</div>
								<div className="tabs-panel" id="panel4">
									<p>four</p>
									<img className="thumbnail" alt="placeholder" src="//foundation.zurb.com/sites/docs/assets/img/generic/rectangle-2.jpg" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
	*/
};

export default ProfilePage;