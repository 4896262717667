import React from 'react';
import Config from '../../Config';

class NJAESUnitBarWrapper extends React.Component {
	render() {
		return (
			<div id="unit-bar-wrapper" className="contain-to-grid-white">
				<div id="unit-bar">
					<a href={Config.WEBSITE_URL}> {Config.UNIT_NAME} </a>
				</div>
			</div>
		);
	}
}
export default NJAESUnitBarWrapper;