import React from 'react';

//NJAES
import MainLogoWrapper from './Components/MainLogoWrapper';
import NJAESUberNavWrapper from './Components/NJAESUberNavWrapper';
import NJAESUnitBarWrapper from './Components/NJAESUnitBarWrapper';
import NJAESMenu from './Components/NJAESMenu';

//SEBS 
import Menu from './Components/Menu';
import RUBarWrapper from './Components/RUBarWrapper';
import RUUnitBar from './Components/RUUnitBar';


const Header = (props) =>{
	const USE_SEBS = false;
//class Header extends React.Component {
	/*
	constructor(props){
		super(props);
		this.userState = props.UserState;
	}
	handleStatusChange(UserState) {
		console.log('Header Updating.');
		this.setState({
			userState: UserState
		});
	}
	render() {
		*/


	
	if( !USE_SEBS ) {
		return (
		
		<header>
			<a className="visually-hide" href="#skip-nav">Skip Navigation</a>
			<div id="print-header-reg">
				<img
					className="lazyload"
					src={process.env.PUBLIC_URL + '/ru-njaes/2018/images/header-njaes-print-reg.png'}
					alt="Rutgers New Jersey Agricultural Experiment Station"
				/>		
			</div>

			<MainLogoWrapper />
			<NJAESUberNavWrapper />
			<NJAESUnitBarWrapper />
			<div className="title-bar" data-responsive-toggle="site-nav" data-hide-for="large" style={{"display": "none"}}>
				<button className="menu-icon" type="button" data-toggle="site-nav"><span className="visually-hide">Menu</span></button>
				<div className="title-bar-title">Menu</div>
			</div>
			<NJAESMenu {...props} />

		</header>
	);
		/*
		return (
			<header>
				<a className="visually-hide" href="#main">Skip Navigation</a>
				<div id="print-header">
					<img
						className="lazyload"
						src={process.env.PUBLIC_URL + '/ru-njaes/2018/images/header-njaes-print-reg.png'}
						alt="Rutgers New Jersey Agricultural Experiment Station"
					/>
				</div>
				<MainLogoWrapper />
				<NJAESUberNavWrapper />
				<NJAESUnitBarWrapper />
			</header>
		);

		*/
	}
	/*
				<RUUnitBar />
				<Menu {...props} />
	*/



	//SEBS HEADER
	return (
		<header>
			<a className="visually-hide" href="#main">Skip Navigation</a>
			<div id="print-header">
				<img
					className="lazyload"
					src={process.env.PUBLIC_URL + '/ru-sebs/images/header-sebs-print.png'}
					alt="Rutgers School of Environmental and Biological Sciences"
				/>
			</div>
			<RUBarWrapper />
			<RUUnitBar />
			<Menu {...props} />
		</header>
	);
	//}

}

export default Header;



/*
			<div id="site-nav-wrapper" className="contain-to-grid">
				<nav id="site-nav" className="top-bar">
				<ul id="site-nav-main" className="dropdown vertical large-horizontal menu" data-responsive-menu="accordion large-dropdown">
					<li><a href="https://njaes.rutgers.edu">Home</a></li>
					<li className="has-submenu"> <a href="https://njaes.rutgers.edu/about/">About</a>
					<ul className="menu vertical">
						<li><a href="https://njaes.rutgers.edu/administration/">Administration</a></li>
						<li><a href="https://njaes.rutgers.edu/centers/">Centers, Institutes &amp; Farms</a></li>
						<li><a href="https://njaes.rutgers.edu/economic-development/">Economic Development</a></li>
						<li><a href="https://events.rutgers.edu/njaes/">Events</a></li>
						<li><a href="https://njaes.rutgers.edu/jobs/">Job Opportunities</a></li>
						<li><a href="http://sebsnjaesnews.rutgers.edu/category/njaes/">NJAES News</a></li>
						<li><a href="https://njaes.rutgers.edu/pubs/">Publications</a></li>
						<li><a href="https://rah.rutgers.edu/">Rutgers Against Hunger</a></li>
						<li><a href="http://rutgersgardens.rutgers.edu/">Rutgers Gardens</a></li>
						<li><a href="https://njaes.rutgers.edu/support-units/">Support Units</a></li>
						<li><a href="https://urbanextension.rutgers.edu/">Urban Extension and Engagement</a></li>
						<li><a href="https://njaes.rutgers.edu/volunteer/">Volunteer with RCE</a></li>
					</ul>
					</li>
					<li className="has-submenu"><a href="https://njaes.rutgers.edu/extension/">Cooperative Extension</a>
					<ul className="menu vertical">
						<li><a href="http://nj4h.rutgers.edu/">4-H Youth Development</a></li>
						<li><a href="https://njaes.rutgers.edu/anr/">Agriculture and Natural Resources</a></li>
						<li><a href="https://njaes.rutgers.edu/fchs/">Family and Community Health Sciences</a></li>
						<li><a href="http://www.cpe.rutgers.edu/">Office of Continuing Professional Education</a></li>
					</ul>
					</li>
					<li><a href="https://sebsnjaesresearch.rutgers.edu/">Research</a></li>
					<li className="has-submenu"><a href="https://njaes.rutgers.edu/topics/">Topics</a>
					<ul className="menu vertical">
						<li><a href="https://njaes.rutgers.edu/ag/">Commercial Agriculture</a></li>
						<li><a href="https://njaes.rutgers.edu/environment/">Environment &amp; Natural Resources</a></li>
						<li><a href="https://njaes.rutgers.edu/fisheries/">Fisheries &amp; Aquaculture</a></li>
						<li><a href="https://njaes.rutgers.edu/food-nutrition-health/">Food, Nutrition &amp; Health</a></li>
						<li><a href="https://njaes.rutgers.edu/home-lawn-garden/">Home, Lawn, &amp; Garden</a></li>
						<li><a href="https://njaes.rutgers.edu/youth/">Youth &amp; Community Development</a></li>
					</ul>
					</li>
					<li><a href="https://njaes.rutgers.edu/county/">County Offices</a></li>
					<li className="has-submenu align-right"><a href="https://njaes.rutgers.edu/services/">Services</a>
					<ul className="menu vertical">
						<li><a href="http://ora.rutgers.edu">Office of Research Analytics</a></li>
						<li><a href="https://njaes.rutgers.edu/plant-diagnostic-lab/">Plant Diagnostic Lab</a></li>
						<li><a href="https://njaes.rutgers.edu/soil-testing-lab/">Soil Testing Lab</a></li>
					</ul>
					</li>
					<li><a href="https://makeagift.rutgers.edu/">Giving</a></li>
					<li><a href="https://njaes.rutgers.edu/contact/">Contact Us</a></li>
					<li>
					<form id="search" method="get" name="searchform" action="https://njaes.rutgers.edu/search">
						<input type="hidden" name="cx" value="011056768870291402219:ufl6go21ary" />
						<input type="hidden" name="sa" value="Search NJAES" />
						<input type="hidden" name="cof" value="FORID:11" />
						<label className="visually-hide" for="search-text">Search</label>
						<input type="text" name="q" id="search-text" placeholder="Search NJAES" />
						<input type="submit" id="search-submit" value="Search" />
					</form>
					</li>
				</ul>
				</nav>
			</div>
			*/