//import sha512 from 'crypto-js/sha512';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

import crypto from 'crypto-js';
import Config from '../Config';
import jwt from 'jsonwebtoken';

import {
	insert_pending_online_payment,
	DECODE_TOKEN
} from './api';

const USE_STAGING = Config.ENABLE_STAGING_SCHOOL_PAY;
const SET_TO='pending';
const ADD_TO_CONV_FEE=.46;
const SECRET_KEY = 'Rr1W4Lc4jA';
export const PAYEE_NUMBER = 55980;

export const VERIFY_TOKEN = (token) => {

	try {
		jwt.verify(token, SECRET_KEY);
		return true;
	} catch(err) {
	// err
		console.log('There was a problem with this token for some reason: ', err);
		return false;
	}
}
export const USE_ACTION = () => {
	console.log('Enable Staging? ',USE_STAGING, typeof USE_STAGING,  Config.ENABLE_STAGING_SCHOOL_PAY);
	if ( USE_STAGING ) {
		return 'https://stage.schoolpay.com/api/sso/open';
	}
	return 'https://rutgers.schoolpay.com/api/sso/open';
};
export const USE_CODE = (action) => {
	if( action === 'https://stage.schoolpay.com/api/sso/open') {
		return 'Scj4sth';
	}
	//return 'SbLofqD';
	return 'Sdvb8dL';
};

//itemsJsonString = JSON.stringify(myItems); myItems = cart
/**
 * 
 * @param {*} confNumber The confirmation number
 * @param {*} cart An array of items
 * @param {*} type can be `exam`, `tfa`, `course`, `renewal`
 * @param {boolean} paid 
 * @param {*} payConf Confirmation nunmber again. Just leaving it for now.
 * @param {*} amount The total amount
 * @param {*} itemsJsonString cart JSON Stringified
 * @param {*} extraVariable1 if type is `tfa`: this will be the TFAs array. if `renewal` this will be the renewal years. if `course` this will be the courseid
 * @param {*} extraVariable2 if type is `course` this will be the credit id
 */
export const USE_RETURN_URLS = async (
	confNumber, cart, type=''
	, paid, payConf, amount, itemsJsonString, 
	extraVariable1, extraVariable2
) => {

	const tokenInfo = DECODE_TOKEN( localStorage.getItem('profact-token') );
	if( tokenInfo.code === 'TOKEN_DECODED' ) {
		try{
			const cfaId = (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id);
			const payload = jwt.sign(
				{id: cfaId, cart, type},
				SECRET_KEY,
				{ expiresIn: '2h'}
			);

			const result = await insert_pending_online_payment( tokenInfo.getAuthToken, confNumber, payload 
				, cfaId, type, paid, payConf, amount, itemsJsonString, 
				extraVariable1, extraVariable2
			);
	
			const body = result.data;
			let payId = null;
			if( body && body.data ) {
				if( body.data.payId ) {
					payId = body.data.payId;
				}else if(body.data.id) {
					payId = body.data.id;
				}else{
					console.log('Problem starting payment: bad ID.');
					return null;
				}
			}
			//console.log(body);
			if( type === 'exam' ) {
				if( body.code === 'OK' ) {
					return {
						return_url: `${Config.BASE_WEB_URL}payment-processing/in-progress/${payId}/${confNumber}`,
						notification_url: `${Config.API_LOCATION()}api/payment/schoolpay/${payId}`
					}
				}
			}else if( type === 'tfa' ) {
				if( body.code === 'OK' ) {
					return {
						return_url: `${Config.BASE_WEB_URL}payment-processing/in-progress/${payId}/${confNumber}`,
						notification_url: `${Config.API_LOCATION()}api/payment/schoolpay/${payId}`
					}
				}
			}else if( type === 'course' ) {
				if( body.code === 'OK' ) {
					return {
						return_url: `${Config.BASE_WEB_URL}payment-processing/in-progress/${payId}/${confNumber}`,
						notification_url: `${Config.API_LOCATION()}api/payment/schoolpay/${payId}`
					}
				}
			}else if( type === 'renewal' ) {
				if( body.code === 'OK' ) {
					return {
						return_url: `${Config.BASE_WEB_URL}payment-processing/in-progress/${payId}/${confNumber}`,
						notification_url: `${Config.API_LOCATION()}api/payment/schoolpay/${payId}`
					}
				}
			}
			return null;

			/*
			localStorage.setItem(confNumber, jwt.sign(
				{id: (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id), cart, type},
				SECRET_KEY,
				{ expiresIn: '15m'}
			));
			*/
		}
		catch(err) {
			console.log('Problem creating slot: ', err);
			return null;
		}
	}

}




export const USE_RETURN_URL_1 = (cart, type='') => {
	const tokenInfo = DECODE_TOKEN( localStorage.getItem('profact-token') );
	if( tokenInfo.code === 'TOKEN_DECODED' ) {

		
		return Config.BASE_WEB_URL + '/processing/'+(
			jwt.sign(
				{id: (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id), cart, type},
				SECRET_KEY,
				{ expiresIn: '15m'}
			)
		);

/*
		const prepBase64 = btoa( JSON.stringify({id: (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id), cart, type}) );		
		console.log( 'Base 64 string', prepBase64, jwt.sign(
			{id: (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id), cart, type},
			SECRET_KEY,
			{ expiresIn: '15m' /* 15 minutes * /}
		) );
* /
const base64cart = btoa( JSON.stringify({id: (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id), cart, type}) );
console.log( 'Base 64 string', base64cart, jwt.sign(
	{id: (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id), cart, type},
	SECRET_KEY,
	{ expiresIn: '15m' /* 15 minutes * /}
) );
return Config.BASE_WEB_URL + '/payment-processing/in-progress/'+base64cart
/*
return Config.BASE_WEB_URL + '/payment-processing/in-progress/'+(
	jwt.sign(
		{ base64cart },
		SECRET_KEY,
		{ expiresIn: '15m'}
	)
);
*/
	}

	/*
		return Config.BASE_WEB_URL + '/payment-processing/in-progress/'+(

			jwt.sign(
				{id: (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id), cart, type},
				SECRET_KEY,
				{ expiresIn: '15m' /* 15 minutes * /}
			)
		);
	}
	if ( USE_STAGING ) {
		return 'http://';
	}
	return 'https://mosaic.njaes.rutgers.edu/profact';
	*/
};
export const USE_NOTIF_URL = () => {
	return Config.BASE_WEB_URL;
	/*
	if ( USE_STAGING ) {
		return 'http://';
	}
	return 'https://mosaic.njaes.rutgers.edu/profact';
	*/
};

export const UPDATE_CART_COST = (updateCost ) => {
	console.log('Updating Cart Cost ',updateCost, SET_TO );
/**
 * $X.10 => Decline; setTo='decline'
 * $X.11 => Decline; setTo='decline11' 
 * $X.12 => Error ; setTo='error'
 * $X.02 => Settled ; setTo='settled'
 * Else => Pending Pending transactions will batch at 6am. ; setTo='pending'
 */
	if ( USE_STAGING ) {
		switch(SET_TO){
			case 'decline':
				return Math.floor(updateCost) + .10 + ADD_TO_CONV_FEE;
			case 'decline11':
				return Math.floor(updateCost) + .11 + ADD_TO_CONV_FEE;
			case 'error':
				return Math.floor(updateCost) + .12 + ADD_TO_CONV_FEE;
			case 'settled':
				return Math.floor(updateCost) + .02 + ADD_TO_CONV_FEE;
			default:
				return updateCost;
		}
	}
	return updateCost;

}






export const GET_DEVELOPER_VARS = (pw) => {
/*
remote_id:mosaicPcTest
time:1601300214
nonce:mosaicLocalTest2
signature:=
version:5
items:[{"amount":"20","code":"Scj4sth","quantity":"1","title":"Test SP Checkout Item"}]
return_url:http://requestb.in/1dge8101
notification_url:http://www.google.com/
provider:stage.schoolpay.com
*/

	console.log(crypto.lib.WordArray.random(10));

	const remote_id = 'mosaicPcTest';
	const time = '1601310895' ; //Math.floor(new Date().getTime()/1000);
	const password = pw;



/*
This field proves that the request is legitimately from your system. To calculate:

    Concatenate remote_id and time
    Compute the HMAC-SHA512 of this string
    Convert the binary output of the hash into base64

$string = $remote_id . $time;
$sig = hash_hmac('sha512', $string, $password, true);
$sig = base64_encode($sig);
*/
/*
	//const message, nonce, path, privateKey; // ...
	//const hashDigest = sha256(nonce + message);
	//const hmacDigest = Base64.stringify(hmacSHA512(path + hashDigest, privateKey));
*/

/*
Expected: pbimPGfA0igPkGI27yxUZ55b2+TU7cVdVPl7P3Bd1v8yEmCV1p8RtQztNu7EINtp/nPvWNjEZHCNVcsIhhkuLg==
Received: j9zLDvoOqtm3M8rlVxezDL/HY0y8/iDz6t1F0dne0AcITQ0g3SJVac2mF5YtCs57gOmK5QnL6j7uQMuLdbwp8w==
String to sign: mosaicPcTest1601308567
*/
	const expected = '';// 'pbimPGfA0igPkGI27yxUZ55b2+TU7cVdVPl7P3Bd1v8yEmCV1p8RtQztNu7EINtp/nPvWNjEZHCNVcsIhhkuLg==';
	const nonce = 'mosaicDevServerTest4';
	const string = '' + remote_id + '' + time;
	console.log(string);
	//const hashDigest = sha256(nonce + message);
	
	//const hmacDigest = Base64.stringify(hmacSHA512(string, 'd29e8c23f66a8f719451ad99ba846abe6592139dfdc0e003a7c5856b4765c1ee'));
	const hmacDigest = Base64.stringify(hmacSHA512(string, password));
	const hmacNonceDigest = Base64.stringify(hmacSHA512(string + nonce , password));
	//console.log(remote_id, time, password);


	return { string, password, time, remote_id, hmacDigest, hmacNonceDigest, expected};

}