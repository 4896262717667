import Config from '../Config';
import SHA256 from 'crypto-js/sha256';
/*
import MD5 from 'crypto-js/md5';
import HMAC_SHA256 from 'crypto-js/hmac-sha256';
import HMAC_MD5 from 'crypto-js/hmac-md5';
*/
import {
	insert_pending_online_payment,
	DECODE_TOKEN
} from './api';
import jwt from 'jsonwebtoken';
const SECRET_KEY = 'Rr1W4Lc4jA';

const USE_STAGING = Config.ENABLE_DEVELOPMENT_PAYMENT_LINKS;

const paymentLinks = {
	'exam': {
		orderType:"PROFACT_FertilizerExam",
		test: "https://uatquikpayasp.com/rutgers/commerce_manager/payer.do",
		live: "https://quikpayasp.com/rutgers/commerce_manager/payer.do",
	},
	'tfa': {
		orderType:"PROFACT_TFAs",
		test: "https://uatquikpayasp.com/rutgers/commerce_manager/payer.do",
		live: "https://quikpayasp.com/rutgers/commerce_manager/payer.do",
	},
	'course': {
		orderType:"PROFACT_CECourses",
		test: "https://uatquikpayasp.com/rutgers/commerce_manager/payer.do",
		live: "https://quikpayasp.com/rutgers/commerce_manager/payer.do",
	},
	'renewal': {
		orderType:"PROFACT_RenewalFees",
		test: "https://uatquikpayasp.com/rutgers/commerce_manager/payer.do",
		live: "https://quikpayasp.com/rutgers/commerce_manager/payer.do",
	}
};

/*
const MAKE_KEY = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}
*/
/*
orderName=JoeSmith
orderDescription=Test
amount=100
redirectUrl= https://abc.xyz.edu/Yourname/Confirmation.com
redirectUrlParameters=transactionType,transactionStatus,transactionId,transactionTotal
Amount,transactionDate,transactionAcountType,transactionResultCode,transactionRes
ultMessage,orderNumber,payerFullName
retriesAllowed=1
timestamp= 1394219109822
key = key
*/


export const NELNET_TEST_HASH = (hashMe, key) => {

	if(!hashMe) { 
		hashMe='Yourordertype17JoeSmithTest100https://abc.xyz.edu/Yourname/Confirmation.comtransactionType,transactionStatus,transactionId,transactionTotalAmount,transactionDate,transactionAcountType,transactionResultCode,transactionResultMessage,orderNumber,payerFullName11394219109822key';
		key= '3e3ce3d9ca60ed3a87e09b369e16adecb7115287094e04f1786205b0b47c8b06';
	}
	console.log('Testing NELNET Hash: ', {hashMe, key});
	const testHash = SHA256(hashMe).toString();
	if( testHash === key) {
		console.log('Testing NELNET Hash: ', {hashMe,testHash, key});
		return true;
	}

	console.error('NELNET Hash failed: ', {hashMe,testHash, key});
	return false;

}


export const CREATE_NELNET_ITEMS = (
	choosePayForType,
	orderName="Order Name",
	orderDescription="Description",
	amount=0, //Literally how many cents lol
	payId=0, 
	confNumber="NA00000000", // confNumber
	redirectUrl="https://mosaic.njaes.rutgers.edu/",
	redirectUrlParameters="transactionType,transactionStatus,transactionId,transactionTotalAmount,transactionDate,transactionAcountType,transactionResultCode,transactionResultMessage,orderNumber,orderAmount,orderAmountDue,payerFullName,userChoice24,userChoice25",
	retriesAllowed=1
	) => {
	const timestamp = Date.now();

	let isTest = 'test';

	if( !USE_STAGING ) {
		isTest='live';
	}

	const key =  process.env.REACT_APP_SECRET_NELNET_KEY;  ///MAKE_KEY(20);
	//console.log(process.env);
	//console.log(key);
	const stringToHash = [
		paymentLinks[choosePayForType].orderType,
		orderName,
		orderDescription,
		amount,
		payId, //uC24
		confNumber, //uC25
		redirectUrl,
		redirectUrlParameters,
		retriesAllowed,
		timestamp,
		key,
	].join('');

	//const HASH = SHA256(stringToHash).toString();
	//const HASH =HMAC_MD5(stringToHash,key);
	//const HASH =MD5(stringToHash);
	const HASH =SHA256(stringToHash).toString();

	const myParameters=[
		`orderType=${encodeURIComponent(paymentLinks[choosePayForType].orderType)}`,
		`orderName=${encodeURIComponent(orderName)}`,
		`orderDescription=${encodeURIComponent(orderDescription)}`,
		`amount=${encodeURIComponent(amount)}`,
		//`userChoice23=${userChoice23}`,
		`userChoice24=${payId}`,
		`userChoice25=${confNumber}`,
		`redirectUrl=${redirectUrl}`,
		`redirectUrlParameters=${redirectUrlParameters}`,
		`retriesAllowed=${retriesAllowed}`,
		`timestamp=${encodeURIComponent(timestamp)}`,
		//`key=${encodeURIComponent(key)}`,
		`hash=${HASH}`
	];


	return {
		HASH
		,timestamp
		, fullUrl: `${paymentLinks[choosePayForType][isTest]}?${myParameters.join('&')}`
	};
	
	
}




export const GO_TO_NELNET_URL = (
	choosePayForType,
	orderName="Test Order",
	orderDescription="This is a test description",
	amount=9900, //Literally how many cents lol
	userChoice23="payId", //payId
	userChoice24=1234, //"payConf", //payConf
	userChoice25="TEST000000", // confNumber
	redirectUrl="https://mosaic.njaes.rutgers.edu/",
	//redirectUrl="https://mosaic.njaes.rutgers.edu/profact/payment-processing/in-progress/",
	redirectUrlParameters="transactionType,transactionStatus,transactionId,transactionTotalAmount,transactionDate,transactionAcountType,transactionResultCode,transactionResultMessage,orderNumber,orderAmount,orderAmountDue,payerFullName,userChoice23,userChoice24,userChoice25",
	//redirectUrlParameters="transactionType,transactionStatus,transactionId,originalTransactionId,transactionTotalAmount,transactionDescription,transactionResultCode,transactionResultMessage,orderNumber,orderAmount",
	retriesAllowed=1
	) => {
	let isTest = 'test';
	const timestamp = Date.now();

	const key =  'key';///MAKE_KEY(20);

	if( !USE_STAGING ) {
		isTest='live';
	}

/*
	const result = await insert_pending_online_payment( tokenInfo.getAuthToken, confNumber, payload 
		, cfaId, type, paid, payConf, amount, itemsJsonString, 
		extraVariable1, extraVariable2
	);

	const body = result.data;
*/




const stringToHash = [
	paymentLinks[choosePayForType].orderType,
	orderName,
	orderDescription,
	amount,
	//userChoice23,
	userChoice24,
	userChoice25,
	redirectUrl,
	redirectUrlParameters,
	retriesAllowed,
	timestamp,
	key,
].join('');

	//const HASH = SHA256(stringToHash).toString();
	//const HASH =HMAC_MD5(stringToHash,key);
	//const HASH =MD5(stringToHash);
	const HASH =SHA256(stringToHash).toString();

	const myParameters=[
		`orderType=${encodeURIComponent(paymentLinks[choosePayForType].orderType)}`,
		`orderName=${encodeURIComponent(orderName)}`,
		`orderDescription=${encodeURIComponent(orderDescription)}`,
		`amount=${encodeURIComponent(amount)}`,
		//`userChoice23=${userChoice23}`,
		`userChoice24=${userChoice24}`,
		`userChoice25=${userChoice25}`,
		`redirectUrl=${redirectUrl}`,
		`redirectUrlParameters=${redirectUrlParameters}`,
		`retriesAllowed=${retriesAllowed}`,
		`timestamp=${encodeURIComponent(timestamp)}`,
		//`key=${encodeURIComponent(key)}`,
		`hash=${HASH}`
	];


	console.log(myParameters);
	console.log({stringToHash, HASH, stringHash: HASH.toString(), isGood: NELNET_TEST_HASH(stringToHash, HASH)});
	console.log(`${paymentLinks[choosePayForType][isTest]}?${myParameters.join('&')}`);

	window.open(`${paymentLinks[choosePayForType][isTest]}?${myParameters.join('&')}`);
	//window.location.href = `${paymentLinks[choosePayForType][isTest]}?${myParameters.join('&')}`;
	return true;
	
}

/**
 * 
 * @param {*} confNumber The confirmation number
 * @param {*} cart An array of items
 * @param {*} type can be `exam`, `tfa`, `course`, `renewal`
 * @param {boolean} paid 
 * @param {*} payConf Confirmation nunmber again. Just leaving it for now.
 * @param {*} amount The total amount IN DOLLARS
 * @param {*} itemsJsonString cart JSON Stringified
 * @param {*} extraVariable1 if type is `tfa`: this will be the TFAs array. if `renewal` this will be the renewal years. if `course` this will be the courseid
 * @param {*} extraVariable2 if type is `course` this will be the credit id
 */
 export const USE_NELNET_URLS = async (
	confNumber, cart, type=''
	, paid, payConf, amount, itemsJsonString, 
	description,
	extraVariable1, extraVariable2
) => {

	let isTest = 'test';
	if( !USE_STAGING ) {
		isTest='live';
	}

	const returnUrlParameters = "transactionType,transactionStatus,transactionId,transactionTotalAmount,transactionDate,transactionAcountType,transactionResultCode,transactionResultMessage,orderNumber,orderAmount,orderAmountDue,payerFullName,userChoice24,userChoice25";
	const retriesAllowed =3;
	const tokenInfo = DECODE_TOKEN( localStorage.getItem('profact-token') );
	if( tokenInfo.code === 'TOKEN_DECODED' ) {
		try{
			const cfaId = (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id);
			const payload = jwt.sign(
				{id: cfaId, cart, type},
				SECRET_KEY,
				{ expiresIn: '2h'}
			);

			const result = await insert_pending_online_payment( tokenInfo.getAuthToken, confNumber, payload 
				, cfaId, type, paid, payConf, amount, itemsJsonString, 
				extraVariable1, extraVariable2
			);
	
			const body = result.data;
			let payId = null;
			if( body && body.data ) {
				if( body.data.payId ) {
					payId = body.data.payId;
				}else if(body.data.id) {
					payId = body.data.id;
				}else{
					console.log('Problem starting payment: bad ID.');
					return null;
				}
			}
			//console.log(body);
			if( type === 'exam' ) {
				if( body.code === 'OK' ) {
					const examObj = CREATE_NELNET_ITEMS(
						type,
						'CFA Exam',
						description,
						(amount*100), //dollars to CENTS
						payId,
						confNumber,
						`${Config.API_LOCATION()}api/payment/payPortal/`,
						returnUrlParameters,
						retriesAllowed
					);
					return {
						orderType: paymentLinks[type].orderType,
						hash: examObj.HASH,
						timestamp: examObj.timestamp,
						userChoice24: payId,
						userChoice25: confNumber,
						returnUrlParameters,
						retriesAllowed,
						fullUrl: examObj.fullUrl,
						action_url: paymentLinks[type][isTest],//`${Config.BASE_WEB_URL}payment-processing/in-progress/${payId}/${confNumber}`,
						redirectUrl: `${Config.API_LOCATION()}api/payment/payPortal/`
					}
				}
			}else if( type === 'tfa' ) {
				if( body.code === 'OK' ) {
					const examObj = CREATE_NELNET_ITEMS(
						type,
						'TFAs',
						description,
						(amount*100), //dollars to CENTS
						payId,
						confNumber,
						`${Config.API_LOCATION()}api/payment/payPortal/`,
						returnUrlParameters,
						retriesAllowed
					);
					return {
						orderType: paymentLinks[type].orderType,
						hash: examObj.HASH,
						timestamp: examObj.timestamp,
						userChoice24: payId,
						userChoice25: confNumber,
						returnUrlParameters,
						retriesAllowed,
						fullUrl: examObj.fullUrl,
						action_url: paymentLinks[type][isTest],//`${Config.BASE_WEB_URL}payment-processing/in-progress/${payId}/${confNumber}`,
						redirectUrl: `${Config.API_LOCATION()}api/payment/payPortal/`
					}
				}
			}else if( type === 'course' ) {
				if( body.code === 'OK' ) {
					const examObj = CREATE_NELNET_ITEMS(
						type,
						'Course',
						description,
						(amount*100), //dollars to CENTS
						payId,
						confNumber,
						`${Config.API_LOCATION()}api/payment/payPortal/`,
						returnUrlParameters,
						retriesAllowed
					);
					return {
						orderType: paymentLinks[type].orderType,
						hash: examObj.HASH,
						timestamp: examObj.timestamp,
						userChoice24: payId,
						userChoice25: confNumber,
						returnUrlParameters,
						retriesAllowed,
						fullUrl: examObj.fullUrl,
						action_url: paymentLinks[type][isTest],//`${Config.BASE_WEB_URL}payment-processing/in-progress/${payId}/${confNumber}`,
						redirectUrl: `${Config.API_LOCATION()}api/payment/payPortal/`
					}
				}
			}else if( type === 'renewal' ) {
				if( body.code === 'OK' ) {
					const examObj = CREATE_NELNET_ITEMS(
						type,
						'Renewals',
						description,
						(amount*100), //dollars to CENTS
						payId,
						confNumber,
						`${Config.API_LOCATION()}api/payment/payPortal/`,
						returnUrlParameters,
						retriesAllowed
					);
					return {
						orderType: paymentLinks[type].orderType,
						hash: examObj.HASH,
						timestamp: examObj.timestamp,
						userChoice24: payId,
						userChoice25: confNumber,
						returnUrlParameters,
						retriesAllowed,
						fullUrl: examObj.fullUrl,
						action_url: paymentLinks[type][isTest],//`${Config.BASE_WEB_URL}payment-processing/in-progress/${payId}/${confNumber}`,
						redirectUrl: `${Config.API_LOCATION()}api/payment/payPortal/`
					}
				}
			}
			return null;
		}
		catch(err) {
			console.log('Problem creating slot: ', err);
			return null;
		}
	}

}
