import React, 
{
	useState, useEffect, 
	useRef
} from 'react';

//import {get_news} from '../Api/api'
//import Config from '../Config';

//import parser from 'html-react-parser';

import JoditEditor from 'jodit-react';
import sanitizeHtml from 'sanitize-html';

import { 
	get_cfa_list,
	//MASS_MAILER,
	//MASS_MAILER_JSON,

	DECODE_TOKEN 
} from '../Api/api';



const AdminDummyMassMailer = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;

	//JODIT config
	const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

	const joditEditor = useRef(null);
	const joditConfig = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
		, 
		uploader: {
		  "insertImageAsBase64URI": true
		},
        toolbarAdaptive: false,
		buttons: [
			'source',
			'|',
			'bold',
			'strikethrough',
			'underline',
			'italic',
			'eraser',
			'|',
			'superscript',
			'subscript',
			'|',
			'ul',
			'ol',
			'|',
			'outdent',
			'indent',
			'|',
			'font',
			'fontsize',
			'brush',
			'paragraph',
			'|',
			'align',
			'undo',
			'redo',
			'\n',
			'selectall',
			'cut',
			'copy',
			'paste',
			'copyformat',
			'|',
			'link',
			'hr',
			'symbol',
			'fullsize',
			'print',
			'preview',
			'find',
			'about'
		],
		askBeforePasteFromWord: false,
		askBeforePasteHTML: false,
		events: {
			//change: () => {}, 
			//change: (a,b) => { console.log('CHANGE', a,b); setPrepHtml(a); },
			blur: (event) => {
				console.log('Blur event: ', event); 
				if( event.relatedTarget ) {
					return;
				}
			 	SetEmailContent(event.target.innerHTML); /* console.log(joditEditor);*/ 
			}
		}
	}

	const [cfas, SetCfas] = useState({
		isLoading: true,
		hasError: false,
		filterText: '',
		list: [],
		selectedIds: []
	});
	const [disableMailBtn, SetDisableMailBtn] = useState(false);
	const [emailAlert, SetEmailAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
/*const x = {
  recipients: '', //String where the value is one of the following: [
	  'ALL_REGISTERED_USERS',
	  'UNPAID_REGISTERED_USERS',
	'CERTIFIED_CFAS',
	'CERTIFIED_CFAS_MISSING_CREDITS',
	'SPECIFIC_PEOPLE',
	'EXPIRING_CFAS_MISSING_PAYMENTS',
],
  cfaIds: [], //Only in use if .recipients is 'SPECIFIC_PEOPLE',
  subject: 'Subject',
  message: 'Message Text',
  attachment: null
}
Issue #169
*/
	const [emailInfo, SetEmailInfo] = useState({
		recipients: 'ALL_REGISTERED_USERS',
		subject: '',
		message: '',
		attachment: null,
		cfaIds: [],
	});
	//const fileInput = useRef(null);
	const formElement = useRef(null);

//For CFA selection

	const handleFilterOnChange = event => {
		SetCfas({
			...cfas,
			filterText: event.target.value
		});
	};
	const ToggleCfa = (cfaId) => {
		console.log('Selecting this CFA', cfaId);
		SetCfas({
			...cfas,
			list: cfas.list.map( (cfaApp) => {
				if( cfaApp.id === cfaId ) {
					cfaApp.isSelected= !cfaApp.isSelected;
				}
				return cfaApp;
			})
		});

	}

	const GetAvailableCfas = () => {

		if( cfas.list.length < 1 ) {
			return( <p><em>No CFAs available to add.</em></p>)
		}
		if( 
			cfas.filterText === '' 
			|| cfas.filterText.length < 3
		) {
			return( <p><em>Please type more letters.</em></p>)
		}

		const unselectedCfas = cfas.list
		.filter((cfaApp) => 
			{

				if( cfas.filterText === '' ) { 
					return !cfaApp.isSelected;
				}
				const myFilterText = cfas.filterText.toLocaleLowerCase().trim();
				return !cfaApp.isSelected && (
					('' + cfaApp.id.toString().padStart(6, '0')).toLocaleLowerCase().includes( myFilterText )
					|| (cfaApp.profactId ? cfaApp.profactId.toLocaleLowerCase().includes( myFilterText ) : false )
					|| cfaApp.lastName.toLocaleLowerCase().includes( myFilterText )
					|| cfaApp.firstName.toLocaleLowerCase().includes( myFilterText )
					|| cfaApp.businessName.toLocaleLowerCase().includes( myFilterText )
					|| ( cfaApp.businessAddress ? 
						cfaApp.businessAddress.street.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.city.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.state.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.phone.toString().toLocaleLowerCase().includes( myFilterText )
					: false )
				);
		})
		.slice(0,10);
		if( unselectedCfas.length < 1 ) {
			return( <p><em>No CFAs available to be selected with this filter.</em></p>);
		}
		return (
			<ul>
			{unselectedCfas
			.map( (cfaApp, key) => {
				return (<li key={key}>
					<button type="button" 
						onClick={() => { ToggleCfa(cfaApp.id) } }
						className="button primary tiny vertical-align-middle no-bottom-margin">
						<i className="fas fa-plus"></i> Add</button> {cfaApp.firstName} {cfaApp.lastName} [{
							cfaApp.profactId && cfaApp.examPassed ? (
								cfaApp.profactId
							) : `#${cfaApp.id}`
						}]
				</li>);
			})}
			</ul>
		)
	}
	const GetAddedCfaList = () => {
		const mySelectedCfas = cfas.list
			.filter((cfaApp) => {
					return cfaApp.isSelected;
			});
		if( mySelectedCfas.length < 1 ) {
			return( <p><em>No CFAs selected yet.</em></p>)
		}

		return (
			<ul>
			{mySelectedCfas
			.map( (cfaApp, key) => {
				return (<li key={key}>
					<button type="button" 
						onClick={() => { ToggleCfa(cfaApp.id) } }
						className="button success override-sebs tiny vertical-align-middle no-bottom-margin">
						<i className="fas fa-minus"></i> Remove</button> {cfaApp.firstName} {cfaApp.lastName} [{
							cfaApp.profactId && cfaApp.examPassed ? (
								cfaApp.profactId
							) : `#${cfaApp.id}`
						}]
				</li>);
			})}
			</ul>
		)
	}
	const GetCfaList = () => {
		

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		get_cfa_list(tokenInfo.getAuthToken)
		.then( (res) => {
			console.log('CFA List', res.data);

			if( res.data.code === 'ALL_CFAS' ) {
				const myCfas = res.data.data.map( (cfaApp) => {
					cfaApp.isSelected=false;
					return cfaApp;
				});

				SetCfas({
					isLoading: false,
					hasError: true,
					filterText: '',
					list: myCfas //res.data.data
				});
			}else{
				SetCfas({
					isLoading: false,
					hasError: true,
					filterText: '',
					list: []
				})
			}
			
		} )
		.catch( error => {
			console.error('Getting [Admin Get All CFAs] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch statistics.');
			}
		});
		
	}
/*
	const HandleEmailChange_Attachment = ( e_target ) => {
		console.log('FILE TEST: ', e_target);
	}
	*/
	const ResetEmailForm = ( ) => {
		ClearAllControls();
		formElement.current.reset();
	}
	/*
	const ResetFileInput = ( ) => {
		console.log(fileInput);
		fileInput.current.value='';
	}
	*/
	const SetEmailContent = ( emailContent ) => {
		console.log(emailContent);
		SetEmailInfo({
			...emailInfo,
			message: sanitizeHtml(emailContent,{
				allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'span','p', 'img', 'table' ]),
				transformTags: {
					'a': function(tagName, attribs) {
						return {
							tagName: tagName,
							attribs: {
								...attribs,
								href: withHttp(attribs.href)
							}
						}
					}
				},        
				allowedAttributes: {
					...sanitizeHtml.defaults.allowedAttributes,
					'p': ["style"],
					'span': ["style"],
					'h1': ["style"],
					'h2': ["style"],
					'h3': ["style"],
					'h4': ["style"],
					'h5': ["style"],
					a: [ 'href', 'name', 'target', 'rel' ]
				},
				allowedSchemesByTag: {
					...sanitizeHtml.defaults.allowedSchemesByTag,
					img: [ 'data' ],
				},
				allowedStyles: {
					'*': {
						// Match HEX and RGB
						'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
						'text-align': [/^left$/, /^right$/, /^center$/],
						// Match any number with px, em, or %
						'font-size': [/^\d+(?:px|em|%|rem)$/]
					},
				}
			})
		});
	}
	const HandleEmailChange = (e_target) => {
		const { name, value } = e_target;
		//console.log('Updating {name, value}', { name, value })
		SetEmailInfo({
			...emailInfo,
            [name]: value
		})
	}
	const ChangeEmailRecipients = (e_target) => {
		
		SetEmailInfo({
			...emailInfo,
			recipients: e_target.value
		});
		return true;
	}


	const ShowSpecificPeopleControls = () => {
		if( emailInfo.recipients !== 'SPECIFIC_PEOPLE') {
			return null;
		}

		return (
			<>
			<div className="rows">
			<div className="medium-6 small-12 columns">
				<h5>Filter CFAs</h5>
				<p> Start typing the name (first name, last name, CFA ID, or business) of the CFA you want to add.</p>
				<div className="grid-x">
					<div className="medium-3 small-12 cell">
						<label htmlFor="cfaFilter" className="label-style-1">Name: </label>
					</div>
					<div className="medium-5 small-12 cell">
						<input type="text" 
							id="cfaFilter" 
							name="cfaFilter"
							value={cfas.filterText}
							placeholder="CFA Filter" 
							onChange={handleFilterOnChange}
						/>
					</div>
					<div className="small-12 cell">
						<p>Showing top 10 results: </p>
						{GetAvailableCfas()}
					</div>
				</div>
			</div>
			<div className="medium-6 small-12 columns">
				<h5>Added CFAs</h5>
				{GetAddedCfaList()}
			</div>
			</div>
		</>);


	}

	const submitEmailInformation = (e) => {
		e.preventDefault();
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		console.log('SUBMITTING EMAIL: ', e);
		const formData = new FormData(e.target);
		if( emailInfo.recipients === 'SPECIFIC_PEOPLE' ) {
			const mySelectedCfas = cfas.list.filter( (cfaApp) => { return cfaApp.isSelected } );

			if(mySelectedCfas.length < 1) {
				SetEmailAlert({
					type: 'alert',
					display: 'block',
					title: 'No CFAs specified',
					message: 'Please select at least one CFA to send this email to.'
				});
				return true;
			}
			emailInfo.cfaIds = mySelectedCfas.map( (selectedUser) => {
				console.log('Selected user: ', selectedUser);
				formData.append('cfaIds[]', selectedUser.id);
				return selectedUser.id;
			});
		}else{
			emailInfo.cfaIds = [];
		}

		console.log('THE FORM DATA EMAIL: ', formData);
		for(let pair of formData.entries()) {
		   console.log(pair[0]+ ', '+ pair[1]); 
		}
		console.log('Standard JSON = ', emailInfo);
		//return true;
		SetDisableMailBtn(true);


		const myMessage = sanitizeHtml(joditEditor.current.value, {
			allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'span','p', 'img', 'table' ]),
			transformTags: {
				'a': function(tagName, attribs) {
					return {
						tagName: tagName,
						attribs: {
							...attribs,
							href: withHttp(attribs.href)
						}
					}
				}
			},        
			allowedAttributes: {
				...sanitizeHtml.defaults.allowedAttributes,
				'p': ["style"],
				'span': ["style"],
				'h1': ["style"],
				'h2': ["style"],
				'h3': ["style"],
				'h4': ["style"],
				'h5': ["style"],
				a: [ 'href', 'name', 'target', 'rel' ]
			},
			allowedSchemesByTag: {
				...sanitizeHtml.defaults.allowedSchemesByTag,
				img: [ 'data' ],
			},
			allowedStyles: {
				'*': {
					// Match HEX and RGB
					'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
					'text-align': [/^left$/, /^right$/, /^center$/],
					// Match any number with px, em, or %
					'font-size': [/^\d+(?:px|em|%|rem)$/]
				},
			}
		});

		console.log('Sending the following: ', {
			...emailInfo,
			message: myMessage
		});
		SetDisableMailBtn(false);
		SetEmailAlert({
			type: 'success',
			display: 'block',
			title: 'Email sent',
			message: 'The email has been sent out. A copy of the email you have sent will be sent to profact@sebs.rutgers.edu.'
		});
		ResetEmailForm();
		return true;

		/*
		MASS_MAILER( 
			tokenInfo.getAuthToken, 
			formData
		)
		*/
		/*
		MASS_MAILER_JSON( 
			tokenInfo.getAuthToken, 
			{
				...emailInfo,
				message: myMessage
			}
		)
		.then( (res) => {
			console.log('Sending some emails: ', res.data);

			if(res.data.code === 'OK' ) {
				SetEmailAlert({
					type: 'success',
					display: 'block',
					title: 'Email sent',
					message: 'The email has been sent out. A copy of the email you have sent will be sent to profact@sebs.rutgers.edu.'
				});
				ResetEmailForm();
			}else{
				SetEmailAlert({
					type: 'alert',
					display: 'block',
					title: 'Problem sending out an email',
					message: `There was a problem sending this email out: ${res.data.message}`
				});
			}
			SetDisableMailBtn(false);
		} )
		.catch( error => {
			console.error('Auto (Adding / Updating News Item)', error);
			SetDisableMailBtn(false);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Auto (Adding / Updating News Item)');
			}
		});

		return true;
*/
	}
	const ClearAllControls = () => {
		SetCfas({
			isLoading: false,
			hasError: true,
			filterText: '',
			list: cfas.list.map( (cfaApp) => {	cfaApp.isSelected=false; return cfaApp } )
		});

		SetEmailInfo({
			...emailInfo,
			message: ''
		});
	}

	useEffect( () => {
		GetCfaList();
	}, [] );

	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
					<form ref={formElement} action="post" encType="multipart/form-data" onSubmit={submitEmailInformation}>
						<h2> Mass Mailer / Mail Merge </h2>
						<p><em>You can send out emails from here.</em></p>
						<div className={"callout " + emailAlert.type} style={{display:emailAlert.display}} data-closable>
							<h5>{emailAlert.title}</h5>
							<p>{emailAlert.message}</p>
							<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
								window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
									SetEmailAlert({
										type: 'success',
										display: 'none',
										title: '',
										message: ''
									})
								});
							}}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<h3> Choose Email Recipients </h3>
						<div className="grid-x grid-margin-x">
							<div className="small-12 cell">
								<label htmlFor="emailRecipt_all" className="label-style-1">
									<input type="radio" 
										name="recipients" id="emailRecipt_all" 
										value='ALL_REGISTERED_USERS'
										defaultChecked={(emailInfo.recipients==='ALL_REGISTERED_USERS' )}
										onChange={(e)=>{ ChangeEmailRecipients(e.target); }}
									/>
									All Registered Users
								</label>
							</div>
							<div className="small-12 cell">
								<label htmlFor="emailRecipt_unpaid" className="label-style-1">
									<input type="radio" 
										name="recipients" id="emailRecipt_unpaid" 
										value='UNPAID_REGISTERED_USERS'
										defaultChecked={(emailInfo.recipients==='UNPAID_REGISTERED_USERS' )}
										onChange={(e)=>{ ChangeEmailRecipients(e.target); }}
									/>
									Registered Users who haven't paid yet this year​
								</label>
							</div>
							<div className="small-12 cell">
								<label htmlFor="emailRecipt_certCfas" className="label-style-1">
									<input type="radio" 
										name="recipients" id="emailRecipt_certCfas" 
										value='CERTIFIED_CFAS'
										defaultChecked={(emailInfo.recipients==='CERTIFIED_CFAS' )}
										onChange={(e)=>{ ChangeEmailRecipients(e.target); }}
									/>
									Certified CFAs Only​
								</label>
							</div>
							<div className="small-12 cell">
								<label htmlFor="emailRecipt_missCred" className="label-style-1">
									<input type="radio" 
										name="recipients" id="emailRecipt_missCred" 
										value='CERTIFIED_CFAS_MISSING_CREDITS'
										defaultChecked={(emailInfo.recipients==='CERTIFIED_CFAS_MISSING_CREDITS' )}
										onChange={(e)=>{ ChangeEmailRecipients(e.target); }}
									/>
									CFAs on their last year missing required CE Credits​
								</label>
							</div>
							<div className="small-12 cell">
								<label htmlFor="emailRecipt_missPay" className="label-style-1">
									<input type="radio" 
										name="recipients" id="emailRecipt_missPay" 
										value='EXPIRING_CFAS_MISSING_PAYMENTS'
										defaultChecked={(emailInfo.recipients==='EXPIRING_CFAS_MISSING_PAYMENTS' )}
										onChange={(e)=>{ ChangeEmailRecipients(e.target); }}
									/>
									CFAs on their last year missing payments
								</label>
							</div>
							<div className="small-12 cell">
								<label htmlFor="emailRecipt_specific" className="label-style-1">
									<input type="radio" 
										name="recipients" id="emailRecipt_specific" 
										value='SPECIFIC_PEOPLE'
										defaultChecked={(emailInfo.recipients==='SPECIFIC_PEOPLE' )}
										onChange={(e)=>{ ChangeEmailRecipients(e.target); }}
									/>
									Specific People
								</label>
							</div>
							{ShowSpecificPeopleControls()}
						</div>
						<hr />
						<h3> Email Message </h3>
						<div>
							<div className="row">
								<div className="medium-3 small-12 columns">
									<label htmlFor="subject" className="label-style-1">Subject: </label>
								</div>
								<div className="medium-5 small-12 columns no-float">
									<input type="text" 
										id="subject" 
										name="subject"
										placeholder="Email Subject" 
										onChange={(e) => {HandleEmailChange(e.target);} }
										required="required"
									/>
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12 columns">
									<label htmlFor="message" className="label-style-1">Message: </label>
								</div>
								<div className="medium-8 small-12 columns no-float">
									<JoditEditor
										required="required"
										ref={joditEditor}
										//value={emailInfo.message}
										config={joditConfig}
										tabIndex={1} // tabIndex of textarea
										//onBlur={newContent => setNewsItem(newContent)} // preferred to use only this option to update the content for performance reasons
										//onChange={newContent => {}}
									/>
								</div>
							</div>
							<div className="row" style={{marginTop:'1em'}}>
								<div>
								<h5> Dummy Page</h5>
									<ul>
										<li>recipients: {emailInfo.recipients}</li>
										<li>subject: {emailInfo.subject}</li>
										<li>message: {emailInfo.message}</li>
										<li>attachment: {emailInfo.attachment ? 'yes' : 'no'}</li>
									</ul>
								</div>
								<div className="medium-12 small-12 columns text-align-right">
									<button type="reset" 
										className="button primary"
										onClick={ (e) => {
											ClearAllControls();
										}}
										>Clear </button> <button type="submit" 
										className="button success override-sebs"
										disabled={disableMailBtn}
										
										><i className="fas fa-paper-plane"></i> Send Email</button>
								</div>
							</div>
						</div>
					</form>
					</div>
				</div>
			</div>
		</main>
/*

								<textarea id="message" 
									name="message"
									rows="4"
									placeholder="Enter a message" 
									onChange={(e) => {HandleEmailChange(e.target);} }
									required="required"
								></textarea>
						<div className="grid-x">
							<div className="medium-3 small-12 cell">
								<label htmlFor="attachment" className="middle">Attachment: </label>
							</div>
							<div className="medium-5 small-12 cell">
								<input type="file" 
									id="attachment" 
									ref={fileInput}
									name="attachment"
									placeholder="Select one attachment." 
									onChange={(e) => {HandleEmailChange_Attachment(e.target);} }
								/>
								<button type="button" 
								className="button small alert"
								onClick={ (e) => {
									ResetFileInput();
								}}><i className="fas fa-trash"></i> Clear File</button>
							</div>
						</div>
*/
/*
								<JoditEditor
									required="required"
									ref={joditEditor}
									value={emailInfo.message}
									config={joditConfig}
									tabIndex={1} // tabIndex of textarea
									//onBlur={newContent => setNewsItem(newContent)} // preferred to use only this option to update the content for performance reasons
									//onChange={newContent => {}}
								/>
*/
	);
}
export default AdminDummyMassMailer;