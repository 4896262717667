import React, {
	useState, 
	//useEffect
	//createRef
} from 'react';

import { 
	update_admin,
	reset_my_password_admin,

	DECODE_TOKEN 
} from '../../../Api/api';
import { Link } from 'react-router-dom';


const AdminProfilePage = (props) => {
	console.log(props.UserState);
	//these items are functions to the parent page.

	
	//tabs
	const [profileForm, setProfileForm] = useState('personal');

	const [disableSubmitBtn, SetDisableSubmitBtn] = useState(false);
	const [adminAlert, SetAdminAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	
	//Password
	const [pwInfo, setPwInfo] = useState( {
		id: null,
		oldPass: '',
		newPass: '',
		newPassRetype: ''
	} );
	const [passwordAlert, setPasswordsAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	
	const passwordForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
        setPwInfo(pwInfo => ({
            ...pwInfo,
            [name]: value
		}));
	}

	const [selectedUser, SetSelectedUser] = useState({
		email: props.UserState.user.data.email,
		firstName: props.UserState.user.data.firstName,
		lastName: props.UserState.user.data.lastName,
		userType: props.UserState.userType
	})
	//console.log('Currently selected user? ', selectedUser );

	const adminProfileForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		SetSelectedUser({
			...selectedUser,
            [name]: value
		});
	}
	const handleAdminProfileDetailsSubmit = (e) => {
		e.preventDefault();

		SetDisableSubmitBtn(true);


		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}
		selectedUser.adminId = tokenInfo.getId.id;

		update_admin( tokenInfo.getAuthToken, selectedUser )
			.then(function(response){
				console.log('Updating this current user.', response);
				SetAdminAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully updated!',
					message: 'We have saved your information.'	
				});
				
				const userObj = {
					...props.UserState.user
				};
				userObj.data.email = selectedUser.email;
				userObj.data.firstName = selectedUser.firstName;
				userObj.data.lastName = selectedUser.lastName;
				
				//userObj.firstName = personalInfo.firstName;
				console.log( 'props.Setting User state', props.SetUserState({
					...props.UserState,
					user: userObj
				}) );
				
				SetDisableSubmitBtn(false);
			})
			.catch(function(error){
				console.error('Failed to update this user\'s home details.', error);

				SetAdminAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				SetDisableSubmitBtn(false);
			});

	}

	
	const handlePasswordResetEvent = (e) => {
		
		e.preventDefault();

		console.log('Submitting new password details');
		setPasswordsAlert({
			type: 'primary',
			display:'none', 
			title: '',
			message: ''
		});

		console.log(pwInfo);
		if( pwInfo.newPass !== pwInfo.newPassRetype) {
			setPasswordsAlert({
				type: 'warning',
				display:'block', 
				title: 'Passwords must match',
				message: 'Please make sure that your "New Password" and "Retype New Password" fields match.'
			});
			return false;
		}
		if( pwInfo.newPass.length < 6 ) {
			setPasswordsAlert({
				type: 'warning',
				display:'block', 
				title: 'Passwords length',
				message: 'Please make sure that your new password is at least 6 characters in length. '
			});
			return false;
		}


		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}
		
		pwInfo.id = tokenInfo.getId.id;
		reset_my_password_admin(tokenInfo.getAuthToken, pwInfo)
			.then(function(response){
				console.log('Updating this current user.', response);
				
				setPasswordsAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully updated!',
					message: 'We have saved your new password.'	
				});
				
				setPwInfo( {
					...pwInfo,
					oldPass: '',
					newPass: '',
					newPassRetype: ''
				} );
			})
			.catch(function(error){
				console.error('Failed to update this user\'s poassword details.', error);

				setPasswordsAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				//setDisableRegBtn(false);
			});
		return false;
	}
	
	const generatePasswordForm = () => {
		return (
			<div style={{display: (profileForm==='password' ? 'block' : 'none')}}>
				<h3>Update Password</h3>
				<p>Please select a password that is at least 6 characters in length</p>
				<div className={"callout " + passwordAlert.type} style={{display:passwordAlert.display}} data-closable>
					<h5>{passwordAlert.title}</h5>
					<p>{passwordAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							setPasswordsAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form action="" method="post" onSubmit={handlePasswordResetEvent}>
					<div className="">
						<div className="row">
							<div className="medium-5 small-12 columns">
								<label htmlFor="password" className="middle">Current Password</label>
								<span className="form-error">
									This field is required.
								</span>
							</div>
							<div className="medium-7 small-12 columns">
								<input type="password"
									name="oldPass" id="oldPass"  
									value={pwInfo.oldPass}
									required="required"
									onChange={e => passwordForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-5 small-12 columns">
								<label htmlFor="newPass" className="middle">New Password</label>
								<span className="form-error">
									This field is required.
								</span>
							</div>
							<div className="medium-7 small-12 columns">
								<input type="password" value={pwInfo.newPass}
									name="newPass" id="newPass"  
									required="required"
									autoComplete="off"
									onChange={e => passwordForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-5 small-12 columns">
								<label htmlFor="newPassRetype" className="middle">Retype New Password</label>
								<span className="form-error">
									This field must match the new password.
								</span>
							</div>
							<div className="medium-7 small-12 columns">
								<input type="password" value={pwInfo.newPassRetype}
									name="newPassRetype" id="newPassRetype"  
									required="required"
									autoComplete="off"
									onChange={e => passwordForm_HandleChange(e.target)} />
							</div>
						</div>
						<div>
							<button type="submit" className="button expanded">Save New Password</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
	const generateProfileForm = () => {
			return (<>
				<div style={{display: (profileForm==='personal' ? 'block' : 'none')}}>
					<h3>Your Profile</h3>
					
					<div className={"callout " + adminAlert.type} style={{display:adminAlert.display}} data-closable>
						<h5>{adminAlert.title}</h5>
						<p>{adminAlert.message}</p>
						<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
							window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
								SetAdminAlert({
									type: 'success',
									display: 'none',
									title: '',
									message: ''
								})
							});
						}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				<div className="">
					<form action="" method="post" onSubmit={handleAdminProfileDetailsSubmit} style={{flex:'1'}}>
						<div className="">
							<div className="row">
								<div className="medium-3 small-12 columns">
									<label htmlFor="firstName" className="label-style-1">First Name</label>
								</div>
								<div className="medium-9 small-12 columns">
									<input type="text" value={selectedUser.firstName} 
									name="firstName" id="firstName"  
									required="required"
									onChange={e => adminProfileForm_HandleChange(e.target)} />
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12 columns">
									<label htmlFor="lastName" className="label-style-1">Last Name</label>
								</div>
								<div className="medium-9 small-12 columns">
									<input type="text" value={selectedUser.lastName} 
									name="lastName" id="lastName"  
									required="required"
									onChange={e => adminProfileForm_HandleChange(e.target)} />
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12 columns">
									<label htmlFor="email" className="label-style-1">Email</label>
								</div>
								<div className="medium-9 small-12 columns">
									<input type="email" value={selectedUser.email} 
									name="email" id="email"  
									required="required"
									onChange={e => adminProfileForm_HandleChange(e.target)} />
									<p><em>You will use this email to log in.</em></p>
								</div>
							</div>
							<div className="row">
								<div className="small-12 cell text-align-right">
									<button 
										disabled={disableSubmitBtn}
										type="submit" className="button expanded"
									>Save Your Profile</button>
								</div>
							</div>
						</div>
					</form>
				</div>
				</div>
			</>);
	}



	return (
		<>
			<div id="content">
				<div className="columns small-12">
					<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
					<hr className="slim-hr" />
				</div>
				<div className="columns small-12">
					<div className="medium-10 large-8 large-offset-2 medium-offset-1 columns">
						<div>
							<div className="stacked-for-small button-group">
								<button className={"button primary " + ( (profileForm === 'personal' ? 'is-selected' : '') ) } onClick={(e)=>setProfileForm('personal')}> <i className="fas fa-user"></i> Personal </button>
								<button className={"button primary " + ( (profileForm === 'password' ? 'is-selected' : '') ) }  onClick={(e)=>setProfileForm('password')}> <i className="fas fa-key"></i> Password </button>
							</div>
						</div>
						<div>
							{generateProfileForm()}
							{generatePasswordForm()}
						</div>
					</div>
				</div>
			</div>	
		</>
	);
};

export default AdminProfilePage;