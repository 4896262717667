import React, {useState, useEffect} from 'react';
//import Config from '../Config';


const ManageContEdPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	const [isEditingContent, toggleEditingContent] = useState( false );
	


	//User Table Filter
	const [filterText, filterByText] = useState( '' );
	
	useEffect( ()=> {

	}, [filterText] );
	
	const handleFilterOnChange = event => {
		filterByText(event.target.value);
	};

	return (
		<main id="main-wrapper" className="grid-container ">
			<div id="main" className="grid-x grid-margin-x">
				<div id="main-col" className="cell">
					<h2> Manage Continuing Education </h2>
					<button className="button small primary" onClick={(e) =>{ toggleEditingContent( !isEditingContent ) }}>Toggle Form</button>
					<div style={{display: ( isEditingContent ? 'block' : 'none' )}}>
						<h3> Form </h3>
					</div>
					<div style={{display: ( isEditingContent ? 'none' : 'block' )}}>
						<h3> Existing DB Items </h3>
						<div>
							<h4>Search Table</h4>
							<div className="grid-x">
								<div className="medium-3 small-12 cell">
									<label htmlFor="middle-label" className="label-style-1">Filter By Text: </label>
								</div>
								<div className="medium-5 small-12 cell">
									<input type="text" id="middle-label" placeholder="ID, Business Name, Address, etc." 
										onChange={handleFilterOnChange}
									/>
								</div>
							</div>
						</div>
						<div className="table-scroll">
							<table  className="table-left-align-all">
								<thead>
									<tr>
										<th>Course Id</th>
										<th>Edit</th>
										<th>Delete</th>
									</tr>
								</thead>
								<tbody>

								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}
export default ManageContEdPage;