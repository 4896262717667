import React, {useEffect, useState, 
	//useEffect
} from 'react';
import {
	password_token_check,
	password_token_use
} from '../Api/api';


import { Link } from 'react-router-dom';

//import axios from 'axios';
//import Config from '../Config';
/*
const languageLabels = {
	'en-US': {
		firstName: 'Name',
	},
	'es': {
		firstName: 'Nombre'
	}

};
*/

const RecoverPasswordPage = (props) => {
	document.title = props.title;


	
	const recoveryToken = props.match.params.token;
	const [disableUpdatePwBtn, setDisableChangePwBtn] = useState(false);
	const [recoverPws, setRecoverPws] = useState({
		password: '',
		retypePassword: ''
	})
	const [tokenStatus, setTokenStatus] = useState({
		isChecking: true,
		isError: false,
		isAvailable: false,
		isUsed: false,
		message: 'There was a problem checking the token. Please refresh the page and try again.',
	});
	const [recoverPwAlert, setRecoverPwAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});

	const checkTokenStatus = (myRecoveryToken) => {
		
		password_token_check(myRecoveryToken)
		.then( (res) => {
			console.log('Checking recovery token', res);

			if( res.status !== 200 ) {
				throw new Error(res);
			}

			switch( res.data.code ) {
				case 'OK' : 
					setTokenStatus({
						isChecking: false,
						isError: false,
						isAvailable: true,
						isUsed: false,
						message: res.data.message
					});
				
				break;
				default: 
					setTokenStatus({
						isChecking: false,
						isError: true,
						isAvailable: false,
						isUsed: false,
						message: res.data.message
					});
				
				break;
			}

		} )
		.catch( error => {
			console.log(error);
			setTokenStatus({
				isChecking: false,
				isError: true,
				isAvailable: false,
				isUsed: false,
				message: error.response.data.message || 'There was a problem checking the token. Please refresh the page and try again.',
			});
		});

	}
	const handleRecoverPwEvent = (event) => {
		event.preventDefault();
		console.log('Registration Submit using <form>', event);
		console.log('Getting [new FormData()] ', );

		setDisableChangePwBtn(true);
		if( recoverPws.password !== recoverPws.retypePassword ) {
			setRecoverPwAlert({
				type: 'alert',
				display: 'block',
				title: 'Passwords do not match',
				message: 'Please make sure both passwords match each other and are longer than 6 characters.'
			})
			setDisableChangePwBtn(false);
			return false;
		}

		password_token_use(recoveryToken, recoverPws.password)
			.then( (res) => {
				console.log('Email recovery start', res);

				if( res.status !== 200 || res.data.code !== 'OK' ) {
					throw new Error(res);
				}

				setTokenStatus({
					...tokenStatus,
					isUsed: true
				});
				//setShowMessage('thank-you-message');
				setDisableChangePwBtn(false);
			} )
			.catch( error => {
				console.log(error);
				setRecoverPwAlert({
					type: 'alert',
					display: 'block',
					title: 'Error',
					message: 'There was a problem creating a token for you. Please try again.'
				})
				setDisableChangePwBtn(false);
			});

		return true;
	}


	useEffect( () => {
		checkTokenStatus(recoveryToken);
	}, [recoveryToken] );



	const handlePwInputChange = (e_target) => {
		const { name, value } = e_target;
		console.log(name, value);
        setRecoverPws(recoverPws => ({
            ...recoverPws,
            [name]: value
		}));
	}

	

	const showProperForm = () => {
		console.log('IS CHECKING? ', tokenStatus.isChecking);
		if( tokenStatus.isChecking ) {
			return tokenIsBeingChecked();
		}

		if( tokenStatus.isError ) { 
			return tokenHasProblem();
		}
		
		if( tokenStatus.isAvailable && tokenStatus.isUsed ) {
			return tokenIsUsed();
		}else if( tokenStatus.isAvailable ) { 
			return tokenIsReady();
		}else if( tokenStatus.isUsed ) { 
			return tokenWasAlreadyused();
		}

		return (<><p>There was a problem loading this form... please contact an administrator.</p></>)
	}
	const tokenIsReady = () => {
		
		return( 
			<div id="primary-sub-col" className="margin-top-1 cell large-10 large-offset-1 medium-12 small-12">
				<h1>Password Recovery</h1>	
				<form action="" method="post" onSubmit={handleRecoverPwEvent}>
					<div className="callout primary large-12 medium-12 small-12 cell">
						<p>
							Please choose a new password that is 6 characters (letters, numbers, and special characters like [!,@,#]) long.
						</p>
					</div>
					<div className={"callout " + recoverPwAlert.type} style={{display:recoverPwAlert.display}} data-closable>
						<h5>{recoverPwAlert.title}</h5>
						<p>{recoverPwAlert.message}</p>
						<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
							window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
								setRecoverPwAlert({
									type: 'success',
									display: 'none',
									title: '',
									message: ''
								})
							});
						}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="">
						<label htmlFor="password" className="label-style-1">Password</label>
					</div>
					<div className="">
						<input type="password" id="password" name="password" placeholder="" required="required"
							value={recoverPws.password}
							minLength="6"
							onChange={e => handlePwInputChange(e.target)}
						/>
					</div>
					<div className="">
						<label htmlFor="password" className="label-style-1">Retype Password</label>
					</div>
					<div className="">
						<input type="password" id="retypePassword" name="retypePassword" placeholder="" required="required"
							value={recoverPws.retypePassword}
							minLength="6"
							onChange={e => handlePwInputChange(e.target)}
						/>
					</div>
					<div className="text-right">
						<button  className="button" type="submit" disabled={disableUpdatePwBtn}>Reset Password</button>
					</div>
				</form>
			</div>
		);
	}
	const tokenIsUsed = () => {
		return (
			<div id="primary-sub-col" className="margin-top-1 cell large-10 large-offset-1 medium-12 small-12">
				<h1>Password Recovery</h1>	
				<div className={"callout success"} style={{display:'block'}} >
					<h5>Password changed!</h5>
					<p>Your password has been changed. Please head on over to the <Link to="/login" className="button small primary no-bottom-margin">login</Link> page to try logging in again. If you continue to have trouble, please contact an administrator.</p>
				</div>
			</div>
		);
	}
	const tokenWasAlreadyused = () => {
		return (
			<div id="primary-sub-col" className="margin-top-1 cell large-10 large-offset-1 medium-12 small-12">
				<h1>Password Recovery</h1>	
				<div className={"callout alert"} style={{display:'block'}} >
					<h5>We&rsquo;re sorry</h5>
					<p>This token has already been used. Please start the process again <Link to="/forgot-password" className="button small primary no-bottom-margin">here</Link>. If you continue to have trouble, please contact an administrator.</p>
				</div>
			</div>
		);
	}

	const tokenHasProblem = () => {
		return (
			<div id="primary-sub-col" className="margin-top-1 cell large-10 large-offset-1 medium-12 small-12">
				<h1>Password Recovery</h1>	
				<div className={"callout alert"} style={{display:'block'}} >
					<h5>Error</h5>
					<p>{tokenStatus.message}</p>
				</div>
			</div>
		);
	}

	const tokenIsBeingChecked = () => {
		return (
			<div id="primary-sub-col" className="margin-top-1 cell large-10 large-offset-1 medium-12 small-12">
				<h1>Password Recovery</h1>	
				<p><i className="fas fa-circle-notch fa-spin"></i> Checking the token... Please wait...</p>
			</div>
		);
	}
	
	return (
		<>
		<main id="main-wrapper" className="grid-container full">
			<div id="main" className="grid-x grid-margin-x">
				<div id="main-col" className="cell">
					<div id="content" className="grid-x grid-margin-x">
						{showProperForm()}
					</div>
				</div>
			</div>
		</main>
		</>
	);

}

export default RecoverPasswordPage;