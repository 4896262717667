
import React, { useRef, useState, useEffect } from "react";
import { DECODE_TOKEN, nyeCfaReplenishCycleYear } from "../../../Api/api";


import { AXIOS_ERR, AXIOS_LOADING, AXIOS_OK, AXIOS_WAIT } from "../../NewYearsTasksPage";



export default function CfaReplenishCycleYear () {
	const popupRef = useRef/*<HTMLDivElement>*/(null);
	const [myPopup, setMyPopup] = useState(null);
	const [numReplenished, setNumReplenished] = useState/*<number|null>*/(null);
	const [axiosState, setAxiosState] = useState/*<{isLoading: boolean, status: AXIOS_WAIT|AXIOS_OK|AXIOS_ERR|AXIOS_LOADING|> */({
		isLoading: false,
		state: AXIOS_WAIT
	});


	useEffect( () => {
		if( axiosState.state === AXIOS_LOADING ){
			const tokenInfo = DECODE_TOKEN();
			if( tokenInfo.code !== 'TOKEN_FOUND' ) {
				console.error('Did not find appropriate token.');
				setAxiosState({isLoading:false, state: AXIOS_ERR});
				return;
			}

			
			nyeCfaReplenishCycleYear( tokenInfo.getAuthToken ) 
			.then( (res) => {		
				if( res && res.data && res.data.code && res.data.code ==='REPLENISHED') {
					setAxiosState({isLoading:false, state: AXIOS_OK});
					setNumReplenished(res.data.numReplenished)
				}else{
					setAxiosState({isLoading:false, state: AXIOS_ERR});
				}
			} )
			.catch( error => {

				console.error('Getting [nyeCfaReplenishCycleYear] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not revoke cfas.');
				}
				setAxiosState({isLoading:false, state: AXIOS_ERR});
			});
		}
	}, [axiosState] );

	
	useEffect( () => {
		if( popupRef.current ){
			const myA = new window.Foundation.Reveal( window.jQuery(popupRef.current) );
			setMyPopup(myA)
			//new Foundation.Reveal(myPopup.current)
		}
	}, [popupRef] );


	return <div>
		<div><button type="button" className="button expanded alert" onClick={()=>{
			myPopup.open()
		}}>Replenish CFA Cycle Year </button></div>
		<div ref={popupRef} className="reveal" id="disable-tfas" data-reveal>
			{
				axiosState.state !== AXIOS_OK
				?
				<div>
					<h1>Replenish CFA Cycle Year</h1>
					<p className="lead">This will replenish anyone with a cycle year of {new Date().getFullYear()} to now have a cycle year of {new Date().getFullYear()+5}; this should not be done before revoking the users who need to be revoked because it will change their cycle year.  This will also remove any credits that have counted toward previous cycles. This should not be done at any time other than early January because it will remove any credits the user has, including those taken this year.</p>
					<p><em><strong>This action cannot be undone.</strong></em></p>
					{axiosState.state === AXIOS_ERR ? <p><em> There was a problem replenishing the CFA Cycle Year. </em></p> : <></>}
					<button className="close-button" data-close aria-label="Close modal" type="button"
						onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							
						}}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div>
						<button className="button" onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							}}>
							Nevermind
						</button> <button className="button "
							disabled={axiosState.isLoading}
							onClick={()=>{
								setAxiosState({isLoading:true, state: AXIOS_LOADING});
							}}>
								{axiosState.isLoading ? 'Replenishing...'
							:"Yes, Replenish CFA Cycle Year"
								}
						</button>
					</div>
				</div>
				:
				<div>
					<h1>Replenished {numReplenished} CFA Cycle Year(s)</h1>
					<p className="lead">The CFA Cycle Year was replenished.</p>
					<div>
						<button className="button" onClick={()=>{
							if( myPopup ) { myPopup.close(); }
						}}>
							OK
						</button>
					</div>
				</div>

			}
		</div>	
	</div>
}

