import React, {
	useState, 
	//useEffect
	createRef
} from 'react';

import { 
	AUTO_update_admin_list,

	DECODE_TOKEN 
} from '../../../Api/api';


const EditAdminProfile = (props) => {
	
	//these items are functions to the parent page.
	const [
		cancelEditing,
		confirmEditing
	] = [
		props.cancelEditing, 
		props.confirmEditing 
	];
	
	const passwordRetypeInput = createRef();
	const [disableSubmitBtn, SetDisableSubmitBtn] = useState(false);
	const [showRetypeError, SetShowRetypeError] = useState(false);
	const [adminAlert, SetAdminAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});

	const [selectedUser, SetSelectedUser] = useState({
		...props.selectedUser,
		password: '',
		retypePassword: '',
	 } || {
		adminId: null,
		email: '',
		password: '',
		retypePassword: '',
		firstName: '',
		lastName: '',
		userType: "admin"
	})
	//console.log('Currently selected user? ', selectedUser );

	const adminProfileForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		SetSelectedUser({
			...selectedUser,
            [name]: value
		});
	}
	const handleAdminProfileDetailsSubmit = (e) => {
		e.preventDefault();

		SetDisableSubmitBtn(true);
		//if this user is new or there is an entered password
		if( 
			!selectedUser.adminId
			|| (
				selectedUser.password !== ''
				|| selectedUser.retypePassword !== ''
			)
		) {
			//If this is a new user or they started at least typing a new password
			if( selectedUser.retypePassword !== selectedUser.password ) {
				passwordRetypeInput.current.focus();
				SetShowRetypeError(true);
				SetDisableSubmitBtn(false);
				return false;
			}else{
				SetShowRetypeError(false);
			}
		}


		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}

		AUTO_update_admin_list( tokenInfo.getAuthToken, selectedUser )
			.then(function(response){
				//console.log('Updating this current user.', response);
				if(
					!selectedUser.adminId ||
					response.data.code === 'ADMIN_CREATED' 
				) {
					//const decodeTokenInfo = DECODE_TOKEN(response.data.token);
					/*
					SetSelectedUser({
						...selectedUser,
						adminId: decodeTokenInfo.getId.id
					})
					*/
					SetAdminAlert({
						type: 'success',
						display:'block', 
						title: 'Successfully updated!',
						message: 'We have saved the admin\'s information.'	
					});

					SetDisableSubmitBtn(false);
					confirmEditing('admin-only');
				}else{
					SetAdminAlert({
						type: 'success',
						display:'block', 
						title: 'Successfully updated!',
						message: 'We have saved the admin\'s information.'	
					});
					SetDisableSubmitBtn(false);
				}
			})
			.catch(function(error){
				console.error('Failed to update this user\'s home details.', error);

				SetAdminAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				SetDisableSubmitBtn(false);
			});

	}

	const SetUpForm = () => {
		if( selectedUser.adminId ) {
			return (<>
				<h3>{selectedUser.firstName} {selectedUser.lastName}'s Profile</h3>
				<div className={"callout " + adminAlert.type} style={{display:adminAlert.display}} data-closable>
					<h5>{adminAlert.title}</h5>
					<p>{adminAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							SetAdminAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			<div className="">
				<form action="" method="post" onSubmit={handleAdminProfileDetailsSubmit} style={{flex:'1'}}>
					<div className="">
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="firstName" className="label-style-1">First Name</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={selectedUser.firstName} 
								name="firstName" id="firstName"  
								required="required"
								onChange={e => adminProfileForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="lastName" className="label-style-1">Last Name</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={selectedUser.lastName} 
								name="lastName" id="lastName"  
								required="required"
								onChange={e => adminProfileForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="email" className="label-style-1">Email</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="email" value={selectedUser.email} 
								name="email" id="email"  
								required="required"
								onChange={e => adminProfileForm_HandleChange(e.target)} />
								<p><em>This admin will use this email to log in.</em></p>
							</div>
						</div>
						<div className="small-12 columns">
							<h4>An admin can use the reset password feature and fix their password if they cannot log in.</h4>
						</div>
						<div className="small-12 columns text-align-right">
							<button type="button" className="button"
							onClick={e => {cancelEditing('admin-only')}} 
							>Cancel</button> <button 
								disabled={disableSubmitBtn}
								type="submit" className="button"
							>Update Admin</button>
						</div>
					</div>
				</form>
			</div>
			</>);
		}
//not sure why flex needs to be 1
		return (<>
			<h3>Adding a new admin</h3>
			<div className={"callout " + adminAlert.type} style={{display:adminAlert.display}} data-closable>
				<h5>{adminAlert.title}</h5>
				<p>{adminAlert.message}</p>
				<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
					window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
						SetAdminAlert({
							type: 'success',
							display: 'none',
							title: '',
							message: ''
						})
					});
				}}
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="">
				<form action="" method="post" onSubmit={handleAdminProfileDetailsSubmit} style={{flex:'1'}}>
					<div className="">
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="firstName" className="label-style-1">First Name</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={selectedUser.firstName} 
								name="firstName" id="firstName"  
								required="required"
								onChange={e => adminProfileForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="lastName" className="label-style-1">Last Name</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={selectedUser.lastName} 
								name="lastName" id="lastName"  
								required="required"
								onChange={e => adminProfileForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="email" className="label-style-1">Email</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="email" value={selectedUser.email} 
								name="email" id="email"  
								required="required"
								onChange={e => adminProfileForm_HandleChange(e.target)} />
								<p><em>This admin will use this email to log in.</em></p>
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="password" className="label-style-1">Password</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="password" value={selectedUser.password} 
								name="password" id="password"  
								required="required"
								onChange={e => adminProfileForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row">
							<div className="medium-3 small-12 columns">
								<label htmlFor="firstName" className="label-style-1">Retype Password</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="password" value={selectedUser.retypePassword} 
								name="retypePassword" id="retypePassword"  
								required="required"
								ref={passwordRetypeInput}
								onChange={e => adminProfileForm_HandleChange(e.target)} />
								<p className="callout alert" style={
									{display: (showRetypeError ? 'block' : 'none') }}
								> 
									Please make sure your password and retyped password match.
								</p>
							</div>
						</div>
						<div className="small-12 columns text-align-right">
							<button type="button" className="button"
							onClick={e => {cancelEditing('admin-only')}} 
							>Cancel</button> <button 
								disabled={disableSubmitBtn}
								type="submit" className="button"
							>Add New Admin</button>
						</div>
					</div>
				</form>
			</div>
		</>);
	}



	return (
		<>
			<div className="medium-10 large-6 large-offset-3 medium-offset-1 columns">
				<div>
					<hr className="slim-hr" />
				</div>
				<div>
					{SetUpForm()}
				</div>
			</div>
			<div className="small-12 columns">
				<hr />
				<div>
					<button className="no-bottom-margin button primary small"
						onClick={ (e) => { confirmEditing('admin-only') }}
					><i className="fas fa-angle-left"></i> Back To List</button>
				</div>
			</div>
		</>
	);
};

export default EditAdminProfile;