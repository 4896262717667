import React, {
	useEffect,
	useState, 
	//useEffect
} from 'react';
import { 
	//payment_cfa_history,
	//payment_cfa_exam,
	//payment_cfa_tfas,
	//payment_cfa_renewal,
	//payment_cfa_course,
	
	
	//generic_update_payment,

	//retrieve_pending_online_payment,
	retrieve_pending_online_payment_by_payid,

	DECODE_TOKEN 
} from '../../../Api/api';
/*
import { Link
} from 'react-router-dom';
*/
//import moment from 'moment-timezone';

import { Redirect } from 'react-router-dom';
//import { VERIFY_TOKEN } from '../../../Api/schoolpay.api';



const ProcessPayment = (props) => {

	const [paymentId, paymentKey, summary]= [props.paymentId, props.paymentToken, props.summary];
	console.log(paymentId, paymentKey, summary);
	const WARNING_REMINDER_NOTIFICATION = (
		<div className={"callout warning"}>
			<h5>As a reminder:</h5>	
			<p>
				If you see any errors during the payment process, please contact the ProFACT office (848-932-6373) immediately for support, 
					preferably while any error message is still on your screen.
			</p>
		</div>
	);

	const [processPayment, SetProcessPayment] = useState({
		isProcessing: true,
		hasError: false,
		errorCode: '',
		errorMsg: '',
		summary: {},
		setPaymentId: null,
		setPaymentConf: null
	});
/*

	const FIND_PAYMENT = (getAuthToken, id, payConf) => {

		payment_cfa_history(getAuthToken, id)
		.then( (res) => {
			if( res.data.history && res.data.code === 'CFA_PAYMENT_HISTORY') {
				const findPayment = res.data.history.find( (payInfo) => {
					return payInfo.payConf === payConf
				});
				if(!findPayment) {

					SetProcessPayment({
						isProcessing: false,
						hasError: true,
						errorCode: 'COULD_NOT_FIND_PAYMENT',
						errorMsg: 'This error should not occur, but we could not find the payment information we just inserted into the database. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
						summary: {}
					});
				}

			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting CFA\'s history failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Payment History. ');
			}
			SetProcessPayment({
				isProcessing: false,
				hasError: true,
				errorCode: 'COULD_NOT_FIND_PAYMENT',
				errorMsg: 'This error should not occur, but we could not find the payment information we just inserted into the database. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
				summary: {}
			});
		});

	}
*/






	//Should only do this once.
	useEffect( () => {
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}

		//verify token
		//retrieve_pending_online_payment( tokenInfo.getAuthToken, paymentKey )

		const getPaymentToken = async (paymentId) => {
			//const result = await retrieve_pending_online_payment( tokenInfo.getAuthToken, key );
			const result = await retrieve_pending_online_payment_by_payid(tokenInfo.getAuthToken, paymentId);
			console.log('GET PAYMENT TOKEN', result, paymentId);

 
			if( result.data.code === 'PAYMENT_BY_ID' ) {
				//console.log('WE FOUUUUNNNNNDDD IT!');
				const PAY_INFO = result.data.data;

				if( PAY_INFO.userId !== tokenInfo.getId.id ) {
					SetProcessPayment({
						isProcessing: false,
						hasError: true,
						errorCode: 'INCORRECT_USER_INFORMATION',
						errorMsg: 'The user info you have did not match the payment information we have received. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
						summary: {}
					});
					return () => {};
				}
				console.log('User info correct: ',PAY_INFO.userId, tokenInfo.getId.id )


				if( 
					PAY_INFO.payPurpose === 'exam' 
					|| PAY_INFO.payPurpose === 'tfa' 
					|| PAY_INFO.payPurpose === 'renewal' 
					|| PAY_INFO.payPurpose === 'course' 
				) {
					SetProcessPayment({
						isProcessing: false,
						hasError: false,
						errorCode: '',
						errorMsg: '',
						summary: PAY_INFO,
						setPaymentId: paymentId, //paymentKey,
						setPaymentConf: PAY_INFO.payConf
					});
				}else{
					console.error('Could not find type of payment...');
					SetProcessPayment({
						isProcessing: false,
						hasError: true,
						errorCode: 'BAD_PAYMENT_INFO',
						errorMsg: 'We could not find your payment information. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
						summary: {}
					});
				}


				return true;
				//No longer doing this part.
/*
				if( PAY_INFO.payPurpose === 'exam' ) {
					console.log('Commit CFA Exam Payment into db');
					CONFIRM_CFA_EXAM_PAYMENT(paymentId, PAY_INFO);
				}else if( PAY_INFO.payPurpose === 'tfa' ) {
					console.log('Commit TFA Payment into db');
					CONFIRM_TFA_PAYMENT(paymentId, PAY_INFO);
				}else if( PAY_INFO.payPurpose === 'renewal' ) {
					console.log('Commit Renewal Payment into db');
					CONFIRM_RENEWAL_PAYMENT(paymentId, PAY_INFO);
				}else if( PAY_INFO.payPurpose === 'course' ) {
					console.log('Commit Course Payment into db');
					CONFIRM_COURSE_PAYMENT(paymentId, PAY_INFO);
				}else{
					console.error('Could not find type of payment...');
					SetProcessPayment({
						isProcessing: false,
						hasError: true,
						errorCode: 'BAD_PAYMENT_INFO',
						errorMsg: 'We could not find your payment information. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
						summary: {}
					});
				}
*/



				/*
				const paymentToken = result.data.pending.payload;

				const isTokenValid = VERIFY_TOKEN(paymentToken);
				console.log('Is payment Token valid? ', isTokenValid);
				
				if( !isTokenValid ) {
					SetProcessPayment({
						isProcessing: false,
						hasError: true,
						errorCode: 'EXPIRED_PAYMENT_TOKEN',
						errorMsg: 'This payment token may have expired. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
						summary: {}
					});
					return () => {};
				}
		
				const PAYMENT_TOKEN_INFO = DECODE_TOKEN(paymentToken);
		
				console.log('Here is what is currently in the token: ', PAYMENT_TOKEN_INFO);
				if( PAYMENT_TOKEN_INFO.getId.id !== tokenInfo.getId.id ) {
					SetProcessPayment({
						isProcessing: false,
						hasError: true,
						errorCode: 'INCORRECT_USER_INFORMATION',
						errorMsg: 'The user info you have did not match the payment information we have received. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
						summary: {}
					});
					return () => {};
				}
				
				if( PAYMENT_TOKEN_INFO.getId.type === 'exam' ) {
					console.log('Commit CFA Exam Payment into db');
					CONFIRM_CFA_EXAM_PAYMENT(paymentKey, PAYMENT_TOKEN_INFO.getId);
				}else if( PAYMENT_TOKEN_INFO.getId.type === 'tfa' ) {
					console.log('Commit TFA Payment into db');
					CONFIRM_TFA_PAYMENT(paymentKey, PAYMENT_TOKEN_INFO.getId);
				}else if( PAYMENT_TOKEN_INFO.getId.type === 'renewal' ) {
					console.log('Commit Renewal Payment into db');
					CONFIRM_RENEWAL_PAYMENT(paymentKey, PAYMENT_TOKEN_INFO.getId);
				}else if( PAYMENT_TOKEN_INFO.getId.type === 'course' ) {
					console.log('Commit Course Payment into db');
					CONFIRM_COURSE_PAYMENT(paymentKey, PAYMENT_TOKEN_INFO.getId);
				}else{
					console.error('Could not find type of payment...');
					SetProcessPayment({
						isProcessing: false,
						hasError: true,
						errorCode: 'BAD_PAYMENT_INFO',
						errorMsg: 'We could not find your payment information. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
						summary: {}
					});
				}

				*/

				
			}else{
				
				SetProcessPayment({
					isProcessing: false,
					hasError: true,
					errorCode: 'EXPIRED_PAYMENT_TOKEN',
					errorMsg: 'This payment token may have expired or is invalid. If you have successfully submitted payment to school pay and have received an email confirmation stating so, please contact the administrators above with this error code and we should be able to easily assist you.',
					summary: {}
				});
			}


			return result;
		};


		//const result = await insert_pending_online_payment( tokenInfo.getAuthToken, confNumber, payload );
		getPaymentToken(paymentId /*paymentKey*/);
		
	}, [paymentId /*paymentKey*/] );
/*
	const GET_SUMMARY = () => {
		const paymentToken = localStorage.getItem(paymentId);


		const isTokenValid = VERIFY_TOKEN(paymentToken);
		console.log('Is payment Token valid? ', isTokenValid);

		if( !paymentToken ) {
			return( <>
				<h4> Please go to <Link to="/payments" className="button small primary">Your Payments Page</Link> to see your payment history.</h4>
				<p> If you have any questions or feel there is a problem, please contact an administrator and they will be able to assist you.</p>
			</>)
		}
		
		if( !isTokenValid ) {
			return( <>
				<h4> This order may have already been processed  for confirmation number: {paymentId}. Please go to <Link to="/payments" className="button small primary">Your Payments Page</Link> to see your payment history.</h4>
				<p> If you have any questions, please contact an administrator and they will be able to assist you.</p>
			</>)
		}
		const PAYMENT_TOKEN_INFO = DECODE_TOKEN(paymentToken);

		let myReceipt = null;
		const myItems = PAYMENT_TOKEN_INFO.cart;
		if( PAYMENT_TOKEN_INFO.getId.type === 'exam' ) {
			myReceipt = (
				<>
					<ul>
						<li>CFA Exam: ${(
							myItems.items
							&& myItems.items[0]
							? myItems.items[0].amount
							: 90
						)}</li>
					</ul>
				</>
			);
		}
		console.log(PAYMENT_TOKEN_INFO);
		return (
			<>
			<h4> Here is your ProFACT Receipt </h4>
			{myReceipt}
			</>
		);
	}
*/
	const GetProcessingState = () => {

		

		if( processPayment.isProcessing ) {
			return (<>
				{WARNING_REMINDER_NOTIFICATION}
				<h3>Finalizing Payment... <i className="fas fa-circle-notch fa-spin"></i> </h3>
				<h4>Please do not refresh this page. </h4>
			</>);
		}
		if( processPayment.hasError ) {
			return (<>
				{WARNING_REMINDER_NOTIFICATION}
				<div className={"callout alert "}>
					<h3> Error! </h3>
					<h4> {processPayment.errorCode} </h4>
					<p> {processPayment.errorMsg} </p>
					<p> {processPayment.hasError} </p>
				</div>
			</>);
		}

		return (<Redirect to={`/payment-processing/success/${processPayment.setPaymentId}/${processPayment.setPaymentConf}`} />);

	}


	return ( GetProcessingState() );
	/*
	return (<>
		<h3> Processing! </h3>
		<p><em>{paymentToken}</em></p>
	</>)
	*/
};

export default ProcessPayment;




