export const generic500 = {
	status: 500,
	message: "This is a generic error.",
	res: {
		data: []
	}
};
export const generic200 = {
	status: 200,
	message: "This is a generic success",
	res: {
		data: []
	}
};
export const genericPlaceholder = (code, message='No message supplied.', data=[]) => {
	return {
		status: code,
		message: message,
		res: {
			data: data
		}
	};
};

export const newsItems = [
	{
		"id": 100,
		"title": "For information regarding COVID-19 regulations:",
		"content": "<ul><li>Golf Course Superintendents Association of New Jersey </li><li>Irrigation Association of New Jersey  </li><li>New Jersey Green Industry Council  </li><li>New Jersey Landscape Contractors Association  </li><li>New Jersey Nursery and Landscape Association  </li><li>Sports Field Managers Association of New Jersey  </li></ul>",
		"createdAt": "2020-08-06 14:22:09",
		"updatedAt": "2020-08-06 14:22:09",
		"deletedAt": "2020-08-06 14:22:09"
	},
	{
		"id": 101,
		"title": "Training & Exam (English/Spanish) - Middlesex County",
		"content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eget lacus ut felis convallis finibus. Sed ut nisi in lacus eleifend dignissim ut finibus augue. Phasellus vestibulum augue diam, nec gravida neque efficitur non. Aliquam vel lectus sed metus porttitor fermentum eget vel purus.",
		"createdAt": "2020-08-06 14:22:09",
		"updatedAt": "2020-08-06 14:22:09",
		"deletedAt": "2020-08-06 14:22:09"
	},
	{
		"id": 102,
		"title": "Did you take the exam in 2015? This is the final year to accumulate 8 credits",
		"content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eget lacus ut felis convallis finibus. Sed ut nisi in lacus eleifend dignissim ut finibus augue. Phasellus vestibulum augue diam, nec gravida neque efficitur non. Aliquam vel lectus sed metus porttitor fermentum eget vel purus.",
		"createdAt": "2020-08-06 14:22:09",
		"updatedAt": "2020-08-06 14:22:09",
		"deletedAt": "2020-08-06 14:22:09"
	},
	{
		"id": 103,
		"title": "Blackout Dates for Fertilizer Application",
		"content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eget lacus ut felis convallis finibus. Sed ut nisi in lacus eleifend dignissim ut finibus augue. Phasellus vestibulum augue diam, nec gravida neque efficitur non. Aliquam vel lectus sed metus porttitor fermentum eget vel purus.",
		"createdAt": "2020-08-06 14:22:09",
		"updatedAt": "2020-08-06 14:22:09",
		"deletedAt": "2020-08-06 14:22:09"
	}
];

export const cfaListPublic = [
	{
		"id": 1234,
		"businessName": "Terrific Lawns",
		"businessStreet": "123 Lawndale",
		"businessCity": "Rockgrove",
		"businessState": "JD",
		"businessZip": 1,
		"businessPhone": 5555555555,
		"businessCounty": "Perthshire",
		"homeStreet": "123 Lawndale",
		"homeCity": "Rockgrove",
		"homeState": "JD",
		"homeZip": 1,
		"homePhone": 5555555555,
		"firstName": "Bob",
		"lastName": "Roberts",
		"email": "bob@bobslawns.com",
		"password": "2938472029830429hf989cjc9w87c98",
		"spanishPref": 1,
		"modules": 1,
		"examPaid": 1,
		"examPassed": 1,
		"certDate": "2020-07-20",
		"activeYear": 2022,
		"credits": 5,
		"cycleYear": 2024
	  }
];
export const cfaList = [
	{
	  "id": 1234,
	  "businessId": 234298,
	  "homeAddressId": 2038,
	  "firstName": "Bob",
	  "lastName": "Roberts",
	  "email": "bob@bobslawns.com",
	  "spanishPref": 1,
	  "modules": 1,
	  "examPaid": 1,
	  "examPassed": 1,
	  "certDate": "2020-07-20",
	  "activeYear": 2022,
	  "credits": 5,
	  "cycleYear": 2024
	}
];

export const tfaList = [
	{
	  "id": 1234,
	  "cfaId": 1234,
	  "payId": 1234,
	  "firstName": "Bob",
	  "lastName": "Roberts",
	  "homeAddressId": 4982,
	  "spanishPref": true,
	  "activeYear": 2020,
	  "createdAt": "2020-08-06 14:22:09",
	  "updatedAt": "2020-08-06 14:22:09",
	  "deletedAt": "2020-08-06 14:22:09"
	}
]
  ;
export const tfaPublicList = [
	{
	  "id": 1234,
	  "cfaId": 1234,
	  "payId": 1234,
	  "firstName": "Bob",
	  "lastName": "Roberts",
	  "homeAddressId": 4982,
	  "spanishPref": true,
	  "activeYear": 2020,
	  "createdAt": "2020-08-06 14:22:09",
	  "updatedAt": "2020-08-06 14:22:09",
	  "deletedAt": "2020-08-06 14:22:09"
	}
  ];