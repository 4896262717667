import React from 'react';
import Config from '../Config';


const ContactUsPage = (props) => {
	document.title = props.title;
	return (
		<main id="main-wrapper" className="">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h1>Contact Us</h1>
						<p>For general inquiries, financial questions, suggestions, comments and concerns about the certification and training program, contact the ProFACT administration team.</p>
						<p><strong>Phone:</strong> <a href="tel:+1-848-932-6373">848-932-6373</a></p>
						<p><strong>Email:</strong> <a href={`mailto:${Config.UNIT_EMAIL}`}>{Config.UNIT_EMAIL}</a></p>
						<p><strong>Mailing Address:</strong> </p>
						<div style={{paddingLeft:'2em'}}>
							<p className="no-bottom-margin">{Config.UNIT_NAME}</p>
							<p className="no-bottom-margin">{Config.BUILDING_NAME}</p>
							<p className="no-bottom-margin">{Config.RUTGERS} </p>
							<p className="no-bottom-margin">{Config.STREET_ADDRESS} </p>
							<p className="no-bottom-margin">{Config.CITY_STATE_ZIP} </p>
						</div>
						<p>
							For technical questions regarding this site, including problems with completing training material, invalid links or any error messages, 
							please contact the <a href={'mailto:' + Config.WEB_MASTER} >webmaster</a>. </p>
					</div>
				</div>
			</div>
		</main>
	);
}

export default ContactUsPage;