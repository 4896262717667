import React, { useState, useEffect }  from 'react';
//import { cfaList } from '../../Api/Json/static';
import { Link } from 'react-router-dom';
import {FormatPhoneNumber} from '../../Config';
const CFATable = ( {CfaList} ) => {

	const [filterText, filterByText] = useState( '' );

	const [sortedTable, SetSortedVars] = useState({
		var: 'lastName',
		dir: 'asc'
	});
	
	const [tablePagination, SetTablePagination] = useState({
		tablePage: 0,
		count: 25
	});

	useEffect( ()=> {
		//console.log('Filtering Table by: ', filterText);
		//setPageItem(  showPage );
	}, [filterText] );


	//console.log(newsItems);
	const handleFilterOnChange = event => {
		filterByText(event.target.value);
		SetTablePagination({
			...tablePagination,
			tablePage: 0
		});
	};


	const generateTable = () => {
		if( !CfaList ) {
			return (
				<tr >
					<td colSpan="8"><strong><em>There was a problem fetching the Certified Applicator List.</em></strong></td>
				</tr>
			);
		}
		console.log('Generating table with: ', tablePagination);
		return CfaList
			.filter( (cfaApp) => {
				if( filterText === '' ) { 
					return true;
				}
				const myFilterText = filterText.toLocaleLowerCase().trim();
				return (
					//('C' + cfaApp.id.toString().padStart(6, '0')).toLocaleLowerCase().includes( myFilterText )
					(
						cfaApp.profactId ?
						cfaApp.profactId.toLocaleLowerCase().includes( myFilterText )
						: false
					)
					//cfaApp.profactId.toLocaleLowerCase().includes( myFilterText )
					|| cfaApp.lastName.toLocaleLowerCase().includes( myFilterText )
					|| cfaApp.firstName.toLocaleLowerCase().includes( myFilterText )
					|| cfaApp.businessName.toLocaleLowerCase().includes( myFilterText )
					|| ( cfaApp.businessAddress ? 
						cfaApp.businessAddress.street.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.city.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.state.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.phone.toString().toLocaleLowerCase().includes( myFilterText )
					: false )
				);
			}) 
			.sort( ( a, b ) =>{


				const aVar = a[ sortedTable.var ] ? a[ sortedTable.var ].toLocaleLowerCase() : a.businessAddress[ sortedTable.var ];
				const bVar = b[ sortedTable.var ] ? b[ sortedTable.var ].toLocaleLowerCase() : b.businessAddress[ sortedTable.var ];

				if( sortedTable.dir === 'asc' ) {
					return aVar.localeCompare(bVar);
				}else  if( sortedTable.dir === 'desc' ) {
					return (aVar.localeCompare(bVar) * -1);
				}

				return 0;
			})
			.slice(
				tablePagination.tablePage * tablePagination.count,
				(tablePagination.tablePage * tablePagination.count) + tablePagination.count
			)
			.map( (cfaApp, key)  => {
				//const myCID = 'C' + cfaApp.id.toString().padStart(6, '0');
				/*
				const myCID = (
					cfaApp.profactId ?
					cfaApp.profactId
					: cfaApp.id
				)*/
				return (
					<tr key={key}>
						<td><Link to={"/cfa/" + cfaApp.id}>{cfaApp.profactId}</Link></td>
						<td>{cfaApp.lastName}</td>
						<td>{cfaApp.firstName}</td>
						<td>{cfaApp.businessName}</td>
						<td>{cfaApp.businessAddress ? cfaApp.businessAddress.street : 'N/A'}</td>
						<td>{cfaApp.businessAddress ? cfaApp.businessAddress.city : 'N/A'}</td>
						<td>{cfaApp.businessAddress ? cfaApp.businessAddress.state : 'N/A'}</td>
						<td>{cfaApp.businessAddress ? FormatPhoneNumber(cfaApp.businessAddress.phone) : 'N/A'}</td>
						<td>{new Date(cfaApp.certDate).toLocaleDateString()}</td>
					</tr>
				);
		} )
	};

	const updateSortable = (newVar, newDir) => {

		SetSortedVars({
			var: newVar,
			dir: newDir
		})
		return true;
	}

	const _GetTablePagination = () => {
		const myCount = CfaList
		.filter( (cfaApp) => {
			if( filterText === '' ) { 
				return true;
			}
			const myFilterText = filterText.toLocaleLowerCase().trim();
			return (
				//('C' + cfaApp.id.toString().padStart(6, '0')).toLocaleLowerCase().includes( myFilterText )
				(
					cfaApp.profactId ?
					cfaApp.profactId.toLocaleLowerCase().includes( myFilterText )
					: false
				)
				|| cfaApp.lastName.toLocaleLowerCase().includes( myFilterText )
				|| cfaApp.firstName.toLocaleLowerCase().includes( myFilterText )
				|| cfaApp.businessName.toLocaleLowerCase().includes( myFilterText )
				|| ( cfaApp.businessAddress ? 
					cfaApp.businessAddress.street.toLocaleLowerCase().includes( myFilterText )
						|| cfaApp.businessAddress.city.toLocaleLowerCase().includes( myFilterText )
						|| cfaApp.businessAddress.state.toLocaleLowerCase().includes( myFilterText )
						|| cfaApp.businessAddress.phone.toString().toLocaleLowerCase().includes( myFilterText )
				: false )
			);
		}).length;

		const maxPages = Math.ceil( myCount / tablePagination.count);
		/*
		console.log('Pagination: ', {
			listCount: myCount,
			tablePage: tablePagination.tablePage,
			count: tablePagination.count,
			maxPages
		});*/

		if( maxPages < 2 ) {
			return (<></>);
		}
		const getPageBtns = [];

		let trimFrontPages = false;
		let trimBackPages = false;

		if( maxPages > 10 ) {
			if(tablePagination.tablePage < 5 ) {
				trimBackPages =true;
				for(let i=0;i<6;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}else if(tablePagination.tablePage > maxPages-6 ) {
				trimFrontPages =true;
				for(let i=maxPages-6;i<maxPages;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}else{
				trimBackPages =true;
				trimFrontPages =true;
				for(let i=tablePagination.tablePage-2;i<tablePagination.tablePage+3;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}
		}else{
			for(let i=0;i<maxPages;i++){
				getPageBtns.push( 
					<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
						<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
									e.preventDefault();  
									e.stopPropagation(); 
									SetTablePagination({
										...tablePagination,
										tablePage: i
									});
									return false; 
								}}>
							<span className="show-for-sr">You're on table page</span> {i+1} 
						</a>
					</li>
				);
			}
		}

		return (
			<nav aria-label="Table Pagination">
				<ul className="pagination">{
						tablePagination.tablePage < 1 ? 
						<li className={"pagination-previous disabled"}>Previous <span className="show-for-sr">page</span></li>
						: <li className={"pagination-previous"}>
							<a href="/#" aria-label="Previous page" onClick={(e) => { 
								e.preventDefault();  
								e.stopPropagation(); 
								SetTablePagination({
									...tablePagination,
									tablePage: tablePagination.tablePage-1
								});
								return false; 
							} }>Previous <span className="show-for-sr">page</span> </a>
						</li>
					}
					{trimFrontPages ? <>
						<li className={(tablePagination.tablePage === (0) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (0+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: 0
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {0+1} 
							</a>
						</li>
						<li className={"disabled"}>... <span className="show-for-sr">trimmed front pages</span></li> 
					</>: <></>}
					{getPageBtns}
					{trimBackPages ? <>
						<li className={"disabled"}>... <span className="show-for-sr">trimmed back pages</span></li> 
						<li className={(tablePagination.tablePage === (maxPages-1) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (maxPages)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: maxPages-1
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {maxPages} 
							</a>
						</li>
					</> : <></>}
					{
						tablePagination.tablePage >= maxPages-1 ? 
						<li className={"pagination-next disabled"}>Next <span className="show-for-sr">page</span></li>
						: <li className={"pagination-next"}>
							<a href="/#" aria-label="Next page" onClick={(e) => { 
								e.preventDefault(); 
								e.stopPropagation(); 
								SetTablePagination({
									...tablePagination,
									tablePage: tablePagination.tablePage + 1
								});
								return false; 
							}}>Next <span className="show-for-sr">page</span> </a>
						</li>
					}
				</ul>
			</nav>
		);
	}

	return (
		<>
			<div>
				<h4>Search Table</h4>
				<div className="row">
					<div className="medium-3 small-12 columns">
						<label htmlFor="middle-label" className="label-style-1">Search Table: </label>
					</div>
					<div className="medium-5 small-12 no-float columns">
						<input type="text" id="middle-label" placeholder="ID, Business Name, Address, etc." 
							onChange={handleFilterOnChange}
						/>
					</div>
				</div>
				<div className="row">
					<div className="medium-3 small-12 columns" >
						<label htmlFor="tablePaginationRows"
							className="label-style-1" > Number of CFAs Per Page: </label> 
					</div> 
					<div className="medium-5 small-12 no-float columns" >
						<select 
							id="tablePaginationRows"
							name="tablePaginationRows"
							value={tablePagination.count}
							onChange={e => { 
								SetTablePagination({
									...tablePagination,
									tablePage:0,
									count: Number(e.target.value)
								});
							} }>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
								<option value="200">200</option>
						</select> 
					</div> 
				</div>
			</div>
			{_GetTablePagination()}
			<div className="table-scroll">
				<table  className="table-left-align-all">
					<thead>
						<tr>
							<th>ProFACT ID</th>
							<th >
								<button className="sortable-btn"
									onClick={()=>{
										updateSortable('lastName', (
											sortedTable.var==='lastName' && sortedTable.dir==='asc' 
											? 'desc' : 'asc'
										));
									}}
								>
									<span>Last Name</span> {(
										sortedTable.var==='lastName' ?
										<i className={`fas fa-${(
											sortedTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
										)}`}></i>
										: <i className="fas fa-sort"></i>
									)}
								</button>
							</th>	
							<th>First Name</th>
							<th>
								<button className="sortable-btn"
									onClick={()=>{
										updateSortable('businessName', (
											sortedTable.var==='businessName' && sortedTable.dir==='asc' 
											? 'desc' : 'asc'
										));
									}}
								>
									<span>Company</span> {(
										sortedTable.var==='businessName' ?
										<i className={`fas fa-${(
											sortedTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
										)}`}></i>
										: <i className="fas fa-sort"></i>
									)}
								</button>
							</th>
							<th>Address</th>
							<th>
								<button className="sortable-btn"
									onClick={()=>{
										updateSortable('city', (
											sortedTable.var==='city' && sortedTable.dir==='asc' 
											? 'desc' : 'asc'
										));
									}}
								>
									<span>City</span> {(
										sortedTable.var==='city' ?
										<i className={`fas fa-${(
											sortedTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
										)}`}></i>
										: <i className="fas fa-sort"></i>
									)}
								</button>
							</th>
							<th>State</th>
							<th>Phone</th>
							<th>Certification Date</th>
						</tr>
					</thead>
					<tbody>
						{generateTable()}
					</tbody>
				</table>
			</div>
			{_GetTablePagination()}
		</>
	);
/*
				<div id="page-1">
					<h3>For information regarding COVID-19 regulations:</h3>
					<ul>
						<li>Golf Course Superintendents Association of New Jersey </li>
						<li>Irrigation Association of New Jersey  </li>
						<li>New Jersey Green Industry Council  </li>
						<li>New Jersey Landscape Contractors Association  </li>
						<li>New Jersey Nursery and Landscape Association  </li>
						<li>Sports Field Managers Association of New Jersey  </li>
					</ul>
				</div>
*/
}

export default CFATable;