import React, {useState, useEffect, useRef} from 'react';
import {get_news} from '../Api/api'
//import Config from '../Config';
import moment from 'moment-timezone';
import JoditEditor from 'jodit-react';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
//import parser from 'html-react-parser';

import { 
	AUTO_update_news,
	get_specific_news,
	delete_news,
	DECODE_TOKEN 
} from '../Api/api';

const AdminManageNewsPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	//Aux functions
	const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;


	
	const [currentSubpage, SetCurrentSubpage] = useState('existing-news');
	
	const joditEditor = useRef(null);
	const joditConfig = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
		, uploader: {
		  "insertImageAsBase64URI": true
		},
		askBeforePasteFromWord: false,
		askBeforePasteHTML: false,
		events: {
			//change: () => {}, 
			//change: (a,b) => { console.log('CHANGE', a,b); setPrepHtml(a); },
			blur: (event) => {
				console.log('Blur event: ', event); 
				if( event.relatedTarget ) {
					return;
				}

				SetNewsContent(event.target.innerHTML); console.log(joditEditor); 
			}

		}
	}

	//const [isEditingUser, toggleEditinguser] = useState( false );
	const [existingNews, SetExistingNewsItems] = useState({
		isLoading: true,
		isError: false,
		newsItems: []
	});
	const[updateNewsItem, SetUpdateNewsItem ] = useState({
		id: '',
		title: '',
		content: '',
		sticky: false,
		expires: new Date(),
	});
	const[deleteNewsItem, SetDeleteNewsItem ] = useState({
		id: '',
		title: '',
		content: '',
		sticky: false,
		expires: new Date(),
	});
	const [disableNewsSubmitBtn, SetDisableNewsSubmitBtn] = useState(false);
	const [disableNewsDeleteBtn, SetDisableNewsDeleteBtn] = useState(false);

	const newsForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		SetUpdateNewsItem({
			...updateNewsItem,
            [name]: value
		});
	}
	const newsForm_changeDate = (date) => {
		SetUpdateNewsItem({
			...updateNewsItem,
            expires: date
		});
	}
	const newsForm_checkBox_HandleChange = (e_target) => {
		const { name, checked } = e_target;
        SetUpdateNewsItem({
			...updateNewsItem,
            [name]: (checked ? 1 : 0)
		});
		//setSpanishText( checked );
	}
	/*
	const x = '<a href="https://google.com" target="_blank" rel="nofollow">google</a>';
	console.log( sanitizeHtml.defaults.allowedTags, sanitizeHtml(x,{
		allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'a', 'img', 'table' ]),
		transformTags: {
			'a': function(tagName, attribs) {
				console.log(tagName, attribs);
				return {
					tagName: tagName,
					attribs: {
						...attribs,
						href: withHttp(attribs.href)
					}
				}
			}
		},
		allowedSchemesByTag: {
			img: [ 'data' ],
			a: [ 'href', 'name', 'target', 'rel' ]
		},
		allowedAttributes: {
			a: [ 'href', 'name', 'target', 'rel' ]
		},
		allowedSchemes: [ 'http', 'https' ],
		allowedSchemesAppliedToAttributes: [ 'href' ],
	})
	);*/

	const SetNewsContent = ( newsContent ) => {
		SetUpdateNewsItem({
			...updateNewsItem,
			content: sanitizeHtml(newsContent,{
				allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'a', 'img', 'table' ]),
				transformTags: {
					'a': function(tagName, attribs) {
						return {
							tagName: tagName,
							attribs: {
								...attribs,
								href: withHttp(attribs.href)
							}
						}
					}
				},
				allowedSchemesByTag: {
					...sanitizeHtml.defaults.allowedSchemesByTag,
					img: [ 'data' ]
				},				
				allowedAttributes: {
					...sanitizeHtml.defaults.allowedAttributes,
					a: [ 'href', 'name', 'target', 'rel' ]
				}
				
			})
		});
	}




	//News Table Filter
	const [filterNewsText, FilterNewsByText] = useState( '' );
	const [showExpired, SetShowExpired] = useState(false);

	

	useEffect( ()=> {
		GetExistingNews()
	}, [] );
	
	const handleFilterOnChange = event => {
		FilterNewsByText(event.target.value);
	};

	const GetExistingNews = () =>{
		SetExistingNewsItems({
			isLoading: true,
			isError: false,
			newsItems: []
		});
		get_news()
			.then( (res) => {
				console.log('Retrieving news from database.');
				if( res.status && res.status !== 200 ) {
					throw res; 
				}

				SetExistingNewsItems({
					isLoading: false,
					isError: false,
					newsItems: res.data
				});
				//setNewsItems( res.data );
				//setNewsItems(data);
			} )
			.catch( error => {

				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch news items. ');
				}
				SetExistingNewsItems({
					isLoading:false,
					isError:true,
					newsItems: []
				});
				//setNewsError(true);
			});

	}







	const _GetFilteredNews = () => {

		return existingNews.newsItems.filter( (newsInfo) => { 
			
			if( !showExpired ) {
				//check for expiring before we bother the text filter.
				if( newsInfo.expires ) {
					
					if( moment.tz(newsInfo.expires,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
						.tz( moment.tz.guess() )
						.isSameOrBefore( new Date() )
					){
						console.log('This news item is expired', newsInfo.title);
						return false;
					}
					
				}
			}
			
			if( filterNewsText === '' ) { 
				return true;
			}
			const myFilterText = filterNewsText.toLocaleLowerCase().trim();
			return (
				newsInfo.title.toLocaleLowerCase().includes(myFilterText)
				||newsInfo.content.toLocaleLowerCase().includes(myFilterText)
			);
			/*
			console.log(newsInfo);
			return true;
			*/
		} )
		.sort( (a,b) => {
			return b.id - a.id;
		})
		.map( (newsItem, key) => {
			return(<tr key={key}>
				<td>{newsItem.id}</td>
				<td>{newsItem.title}</td>
				<td>{moment(newsItem.createdAt,'YYYY-MM-DDTHH:mm:ss.SSSZ')
					.format('L')
				}</td>
				<td>{ newsItem.sticky ? 'Yes' : 'No' }</td>
				<td>{
					newsItem.expires ?
					moment(newsItem.expires,'YYYY-MM-DDTHH:mm:ss.SSSZ')
					.format('L')
					: 'No'
				}</td>

				<td><button type="button" className="button warning small"
					onClick={ (e) => {
						retrieveNewsItem(newsItem.id);
					}}
				><i className="fas fa-edit"></i></button></td>
				<td><button type="button" className="button alert small"
					onClick={ (e) => {
						retrieveDeleteNewsItem(newsItem.id);
					}}><i className="fas fa-trash"></i></button></td>
			</tr>);
		})
		;
	}

	const ExistingNewsTable = () =>{
		

		return (
			<>
			<div style={{display: ( currentSubpage!=='existing-news' ? 'none' : 'block' )}}>
				<h3> Here is a list of the current news: </h3>
				<div>
					<h4>Search Table</h4>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="middle-label" className="label-style-1">Filter By Text: </label>
						</div>
						<div className="medium-5 small-12 no-float columns">
							<input type="text" id="middle-label" placeholder="Title, content" 
								onChange={handleFilterOnChange}
							/>
						</div>
					</div>
				</div>
				<div className="button-group stacked-for-small button-group-override">
					<button className={
							`button small primary ${showExpired? 'is-selected' : ''}`
						} onClick={(e) =>{ SetShowExpired( true ) }}>Show All News Items</button>
					<button className={
							`button small primary ${showExpired? '' : 'is-selected'}`
						} onClick={(e) =>{ SetShowExpired( false ) }}>Show Unexpired News items</button>
				</div>
				<div className="table-scroll">
					<table  className="table-left-align-all">
						<thead>
							<tr>
								<th>News ID</th>
								<th>Title</th>
								<th>Date Added</th>
								<th>Sticky?</th>
								<th>Expires?</th>

								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{
								existingNews.isLoading 
								? <tr><td colSpan="10"><i className="fas fa-circle-notch fa-spin"></i> Loading</td></tr>
								: (
									existingNews.isError
									? <tr><td colSpan="10"><i className="fas fa-exclamation-triangle"></i> There was a problem fetching the news. </td></tr>
									:_GetFilteredNews()
								)
/*parse('<p><br id="remove"></p>', {
  replace: ({ attribs }) => attribs && attribs.id === 'remove' && <Fragment />
});*/

							}
						</tbody>
					</table>
				</div>
			</div>


			</>
		)
	}
	
	const retrieveNewsItem = (newsId) => {
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		get_specific_news(
			tokenInfo.getAuthToken, 
			newsId
		)
		.then( (res) => {
			console.log('Getting specific news item', res.data);
			if( res.status === 200 ) {
				SetUpdateNewsItem({
					id: res.data.id,
					title: res.data.title,
					content: res.data.content,
					sticky: res.data.sticky,
					expires: (
						res.data.expires ?
						moment(res.data.expires).tz( moment.tz.guess() ).toDate()
						: null
					),
				});
				SetCurrentSubpage('news-form');
			}
		} )
		.catch( error => {
			console.error('Getting specific news item', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Getting specific news item failed.');
			}
		});
	}
	
	const retrieveDeleteNewsItem = (newsId) => {
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		get_specific_news(
			tokenInfo.getAuthToken, 
			newsId
		)
		.then( (res) => {
			console.log('Getting specific news item', res.data);
			if( res.status === 200 ) {
				SetDeleteNewsItem({
					id: res.data.id,
					title: res.data.title,
					content: res.data.content,
					sticky: res.data.sticky,
					expires: ( res.data.expires ? moment(res.data.expires).tz( moment.tz.guess() ).toDate() : null ),
				});
				SetCurrentSubpage('delete-news-form');
			}
		} )
		.catch( error => {
			console.error('Getting specific news item', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Getting specific news item failed.');
			}
		});
	}

	const submitNewsItem = (e) => {
		e.preventDefault();
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		SetDisableNewsSubmitBtn(true);
		console.log('My News expires on: ', updateNewsItem.expires, moment(updateNewsItem.expires)
		.tz( 'Etc/UTC' )
		.format('YYYY-MM-DDTHH:mm:SS'));

		AUTO_update_news( 
			tokenInfo.getAuthToken, 
			{
				...updateNewsItem,
				expires: (
					updateNewsItem.expires ?
					moment(updateNewsItem.expires)
						.tz( 'Etc/UTC' )
						.format('YYYY-MM-DDT23:59:59')
					: null
				)
			}
		)
			.then( (res) => {
				console.log('Auto (Adding / Updating News Item): ', res.data);
				SetUpdateNewsItem({
					id: '',
					title: '',
					content: '',
					sticky: false,
					expires: new Date(),
				});
				GetExistingNews();
				//refreshTfas();
				//toggleShowContent('tfa-list');
				//UpdateTfaFormData( STOCK_TFA );
				//setRequireHomeCounty( (STOCK_TFA.homeState.toLowerCase() === 'nj') )
				SetCurrentSubpage('existing-news');
				SetDisableNewsSubmitBtn(false);
			} )
			.catch( error => {
				console.error('Auto (Adding / Updating News Item)', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Auto (Adding / Updating News Item)');
				}
				SetDisableNewsSubmitBtn(false);
			});
			
	}
	const cancelNews = () => {
		
		SetUpdateNewsItem({
			id: '',
			title: '',
			content: '',
			sticky: false,
			expires: new Date(),
		});
		SetDeleteNewsItem({
			id: '',
			title: '',
			content: '',
			sticky: false,
			expires: new Date(),
		});
		SetCurrentSubpage('existing-news');
	}
	
	const NewsForm = () =>{
		

		return (
			<>
			<div style={{display: ( currentSubpage!=='news-form' ? 'none' : 'block' )}}>
				<h3> News Form: </h3>
				<form action="" method="post" onSubmit={submitNewsItem}>
					<div className="">
						<input type="hidden" 
							id="newsId"
							name="id"
							value={updateNewsItem.id} 
						/>
						<div className="large-8 columns">
							
							<div>
								<h4>Title</h4>
								<input type="text" className="small-margin-left" name="title" id="newsTitle" 
									value={updateNewsItem.title}
									required="required"
									onChange={(e) => {newsForm_HandleChange(e.target);} }
									/>
							</div>
							<div className="small-bottom-margin">
								<h4>Content</h4>
								<p><em>Preview should update when you click off the editor. Press the button below if the preview does not update.</em></p>
								<p><button type="button" className="button small primary" 
									onClick={()=>{
										console.log(joditEditor);
										SetNewsContent(joditEditor.current.value);
										//joditEditor.current.blur();
										//joditEditor.blur();
									}}
								>Refresh Content Preview</button></p>
								<JoditEditor
									required="required"
									ref={joditEditor}
									//value={updateNewsItem.content}
									config={joditConfig}
									tabIndex={1} // tabIndex of textarea
									//onBlur={newContent => setNewsItem(newContent)} // preferred to use only this option to update the content for performance reasons
									//onChange={newContent => {}}
								/>
							</div>
							<div className="small-bottom-margin">
								<h4>Sticky this news Item?</h4>
								<p><em>Should we prioritize this news item and always place it near the top?</em></p>
								<div className="grid-x  grid-margin-x">
									<div className="small-12 cell">
										<label htmlFor="isSticky" className="">
											<input type="checkbox" 
												name="sticky" id="isSticky" 
												value={updateNewsItem.sticky}
												checked={updateNewsItem.sticky}
												onChange={(e)=>{ newsForm_checkBox_HandleChange(e.target); }}
											/>
											Place this item in front of other news items.
										</label>
									</div>
								</div>
							</div>
							<div>
								<h4>Expires on?</h4>
								<p><em>Clear or Leave blank if this news item should not expire (will never be taken off the Breaking News).</em></p>
								<DatePicker 
									className="no-bottom-margin"
									selected={updateNewsItem.expires} 
									onChange={date => {newsForm_changeDate(date)
										//console.log('Date picker changed: ', date);
									}} 
									popperPlacement="top-end"
									isClearable
								/>
							</div>
						</div>
						<div className="large-4 columns">
							<h3> Preview: </h3>
							<div className="panel">
								<h2>{updateNewsItem.title}</h2>
								{parse(updateNewsItem.content)}
							</div>
						</div>
						<div className="large-8 medium-12 cell" style={{textAlign:'right'}}>
							<button 
								className="button primary override-sebs" 
								type="button"
								onClick={(e) => {
									cancelNews()
							}}> Cancel </button> <button 
								className="button success override-sebs" 
								type="submit" disabled={disableNewsSubmitBtn}
								><i className="fa fa-save" /> Save News Item </button>
						</div>
					</div>
				</form>
			</div>


			</>
		)
	}

	const submitDeleteNewsItem = (e) => {
		e.preventDefault();
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		SetDisableNewsDeleteBtn(true);


		delete_news( 
			tokenInfo.getAuthToken, 
			deleteNewsItem.id
		)
			.then( (res) => {
				console.log('Deleting news item ', res.data);
				SetDeleteNewsItem({
					id: '',
					title: '',
					content: '',
					sticky: false,
					expires: new Date(),
				});
				GetExistingNews();
				//refreshTfas();
				//toggleShowContent('tfa-list');
				//UpdateTfaFormData( STOCK_TFA );
				//setRequireHomeCounty( (STOCK_TFA.homeState.toLowerCase() === 'nj') )
				SetCurrentSubpage('existing-news');
				SetDisableNewsDeleteBtn(false);
			} )
			.catch( error => {
				console.error('Deleting news item', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Deleting news item error');
				}
				SetDisableNewsDeleteBtn(false);
			});

	}
	const DeleteNewsForm = () =>{
		console.log(deleteNewsItem.expires)
;
		return (
			<>
			<div style={{display: ( currentSubpage!=='delete-news-form' ? 'none' : 'block' )}}>
				<h3> Are you sure you want to remove this news item? </h3>
				<form action="" method="post" onSubmit={submitDeleteNewsItem}>
					<div className="">
						<input type="hidden" 
							id="newsId"
							name="id"
							value={deleteNewsItem.id} 
						/>
						<div className="large-6 columns">
							<h3> Preview: </h3>
							<div className="panel">
								<h2>{deleteNewsItem.title}</h2>
								{parse(deleteNewsItem.content)}
							</div>
						</div>
						<div className="small-12 columns">
							<ul>
								<li><strong> Is Sticky? </strong>{deleteNewsItem.sticky ? 'Yes' : 'No'}</li>
								<li><strong> Expires on? </strong> {deleteNewsItem.expires ? moment(deleteNewsItem.expires,'YYYY-MM-DDTHH:mm:ss.SSSZ')
									.format('L') : 'Does not expire'}</li>
							</ul>
							<p> This action cannot be undone. </p>
						</div>
						<div className="large-8 medium-12 columns" style={{textAlign:'right'}}>
							<button 
								className="button primary override-sebs" 
								type="button"
								onClick={(e) => {
									cancelNews()
							}}> Cancel </button> <button 
								className="button alert override-sebs" 
								type="submit" disabled={disableNewsDeleteBtn}
								><i className="fa fa-trash" /> Delete News Item </button>
						</div>
					</div>
				</form>
			</div>


			</>
		)
	}



	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h2> Manage News </h2>
						<div className="button-group stacked-for-small button-group-override">
							<button className={
								`button small primary ${currentSubpage === 'existing-news' ? 'is-selected' : ''}`
							} onClick={(e) =>{ SetCurrentSubpage( 'existing-news' ) }}>Existing News Items</button>
							<button className={
								`button small primary ${currentSubpage === 'news-form' ? 'is-selected' : ''}`
							} onClick={(e) =>{ SetCurrentSubpage( 'news-form' ) }}><i className="fas fa-plus"></i> Add / Update News Items</button>
						</div>
						
						{ExistingNewsTable()}
						{NewsForm()}
						{DeleteNewsForm()}
					</div>
				</div>
			</div>
		</main>
	);
}
export default AdminManageNewsPage;