import React, {
	useState, useEffect
} from 'react';
import { Link } from 'react-router-dom';
import Config from '../../../Config';
import { 
	get_tfas_by_cfas, 
	DECODE_TOKEN 
} from '../../../Api/api';
//import QRCode from 'qrcode.react';

const CFADashboard = (props) => {
	const [firstName, lastName] = [props.UserState.user.firstName, props.UserState.user.lastName];
	
	const [contentLoading, setContentLoading] = useState(true);
	const [currentTfas, setCurrentTfas] = useState([]);
	const currentYear = new Date().getFullYear() ;
	//props.UserState.user.tfaList.push({a:'b'});


	const retrieveTfaListFromDb = () => {
		
		setContentLoading(true);

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		//console.log(tokenInfo);

		get_tfas_by_cfas( tokenInfo.getAuthToken, tokenInfo.getId.id ) 
		.then( (res) => {
			console.log('TFAs by CFA: ', res.data);
			if( res.data.data && res.data.code === 'TFA_BY_CFA') {
				//Let's pull out the cfa exam vs. ce courses
				setContentLoading(false);
				setCurrentTfas( 
					res.data.data
				);
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting [TFA_BY_CFA] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch TFAs for this CFA.');
			}
			setContentLoading(false);
			setCurrentTfas( [] );
		});
	}
	useEffect( () => {
		retrieveTfaListFromDb();
	}, []);

	const getCurrentTfas = () => {
		if( contentLoading ) {
			return ( 
				<>
					<tr><td colSpan="10"><i className="fas fa-circle-notch fa-spin"></i></td></tr>
				</>
			);
		}


		return (
			currentTfas
			/*
			.filter( (tfaInfo) => {
				if( filterText === '' ) { 
					return true;
				}
				const myFilterText = filterText.toLocaleLowerCase().trim();
				return (
					tfaInfo.lastName.toLocaleLowerCase().includes( myFilterText )
					|| tfaInfo.firstName.toLocaleLowerCase().includes( myFilterText )
					|| tfaInfo.email.toLocaleLowerCase().includes( myFilterText )
					|| tfaInfo.payId.toString().toLocaleLowerCase().includes( myFilterText )
				);
			})
			*/
			.map( (tfaInfo, key)  => {
				return (
					<tr key={key}>
						
						<td>{(tfaInfo.payId && tfaInfo.activeYear >= currentYear) ? 
							<Link to={`/tfa-certificate/${'T' + tfaInfo.id.toString().padStart(6, '0')}`} target="_blank">
								{'T' + tfaInfo.id.toString().padStart(6, '0')}</Link> : <></>}</td>
						<td>{tfaInfo.lastName}</td>
						<td>{tfaInfo.firstName}</td>
						<td>{tfaInfo.email}</td>
						{tfaInfo.activeYear >= currentYear ? <td>{tfaInfo.activeYear}</td>: ( <td style={{backgroundColor:'lightgray'}}><strong>{
							tfaInfo.payId ? 'Expired': ( 'Inactive')
						}</strong></td>)}
						{tfaInfo.payId && tfaInfo.activeYear >= currentYear ? <td>Yes</td>: ( <td style={{backgroundColor:'lightgray'}}><strong>No</strong></td>)}
						<td>{tfaInfo.payId ? tfaInfo.payId: ( <strong>--</strong>)}</td>
					</tr>
				);
			})
		);
	}
	const getCertificate = () => {
		if( props.UserState.user.examPassed ) {
			
			//const myCID = 'C' + props.UserState.user.id.toString().padStart(6, '0');
			return (<div>
					<h4>Here is a link to your certificate and ProFACT Public Certificate</h4>
					<div className="button-group">
						<Link to="/certificate" target="_blank" className="button primary"> <i className="fas fa-id-card"></i> Get your certificate </Link>
						<Link className="button hollow" to={"/cfa/" + props.UserState.user.id} target="_blank"> <i className="fas fa-id-card-alt"></i> View Your Public Certificate </Link>
					</div>
					<h4> Active / Cycle Year Information</h4>
					<ul>
						<li> <strong>Active Year Paid Through</strong>: {props.UserState.user.activeYear}</li>
						<li> <strong>Cycle Year</strong>: {props.UserState.user.cycleYear}</li>
					</ul>
					
					<hr />
				</div>);
		}
/*<a className="button hollow" href="http://profact.rutgers.edu/Pages/CFA.aspx?CID=7221">
				<QRCode value="http://profact.rutgers.edu/Pages/CFA.aspx?CID=7221" />
			</a>
			*/
		return null;
	}
	const Get_Current_Step = () => {
		const myCheckList = [
			{checked:props.UserState.user.trainingCompleted, titleOk:'CFA Training Guide completed', titleNok: 'CFA Training Guide not complete'},
			{checked:props.UserState.user.examPaid, titleOk:'CFA Exam payment received', titleNok: 'CFA Exam payment not received'},
			{checked:props.UserState.user.examPassed, titleOk:'CFA Exam passed', titleNok: 'CFA Exam not passed'}
		];
		let myNextStep = <></>;
		if( !props.UserState.user.trainingCompleted ) {
			myNextStep = <p><strong>Your Next Step</strong>: Complete reading the CFA Training Guide.</p>;
		}else if( !props.UserState.user.examPaid ) {
			myNextStep = <p><strong>Your Next Step</strong>: Pay for your CFA Exam on your payments page.</p>;
		}else if( !props.UserState.user.examPassed ) {
			myNextStep = <p><strong>Your Next Step</strong>: Pass your CFA Exam. You need to score 75% or higher to pass.</p>;
		}else{
			//Complete
		}

		return (
		<>
		{myNextStep}
		<ul style={{listStyleType: 'none'}}>
			{myCheckList.map( (checkListItem, key)  => {

				return (
					<li key={key}> {
						checkListItem.checked
						? <>
							<span className="label success"><i className="fa fa-check"></i> </span> {checkListItem.titleOk}
						</>
						: <>
						<span className="label alert"><i className="fa fa-times-circle"></i></span> {checkListItem.titleNok}
						</>
					} </li>
				)
			})}
		</ul>
		</>);
		/*
		if( !props.UserState.user.trainingCompleted ) {
			return 'Complete reading the CFA Training Guide';
		}

		if( !props.UserState.user.examPaid ) {
			return 'Pay for your exam';
		}

		if( !props.UserState.user.examPassed ) {
			return 'Pass your exam';
		}

		return 'You are done with the CFA Certification process!';
		<p><strong>Your Next Step</strong>: {Get_Current_Step()}</p>
		*/
	}
	return(
		<>
			<h2>Your Dashboard</h2>
			<h3> Welcome {firstName} {lastName}! </h3>
			<div>
				<p>
					This portal is designed to guide you through the process of becoming a Certified Fertilizer Applicator. 
					We will guide you through the process as best as we can below.
				</p>
				{Get_Current_Step()}
				<p><em>
					Please note that it can currently take up to 24 hours (usually less) for your "Completed Training" status to update. 
					If you believe it has been more than 24 hours since you completed training and your status has not been changed, please contact <a href={'mailto:' + Config.WEB_MASTER} >ProFACT</a> by email or phone.
				</em></p>
				<p>If you have any other questions, please contact ProFACT at <a href={'mailto:' + Config.WEB_MASTER} >{Config.WEB_MASTER}</a> or by phone.</p>
				<hr/>
			</div>
			<div>
				{getCertificate()}
			</div>
			<div>
				<h4> Here are some tasks you can check up on.</h4>
				<div className="button-group">
					<Link to="/cfa-training" className="button primary"> <i className="fas fa-user-graduate"></i> CFA Training Guide </Link>
					{	
						props.UserState.user.trainingCompleted ?
						<Link to="/payments" className="button primary"> <i className="fas fa-money-bill-wave"></i> Payment Options  </Link>
						: <button className="button primary" disabled={!props.UserState.user.trainingCompleted}> <i className="fas fa-money-bill-wave"></i> Payment Options </button>
					}
					
					{
						props.UserState.user.examPaid ?
						<Link to="/exams" className="button primary"> <i className="fas fa-pencil-ruler"></i> Take CFA Exam </Link>
						: <button className="button primary" disabled={!props.UserState.user.examPaid}> <i className="fas fa-pencil-ruler"></i> Take CFA Exam </button>
					}
					
					{
						props.UserState.user.examPassed ?
						<>
							<Link to="/cfa-tfa" className="button primary"> <i className="fas fa-users"></i> Add Some TFAs </Link>
							<Link to="/ce-training" className="button primary"> <i className="fas fa-graduation-cap"></i> Continuing Education </Link>
						</>
						: 
						<>
							<button className="button primary" disabled={!props.UserState.user.examPassed}> <i className="fas fa-users"></i> Add Some TFAs </button>
							<button className="button primary" disabled={!props.UserState.user.examPassed}> <i className="fas fa-graduation-cap"></i> Continuing Education </button>
						</>
					}
					
					<Link to="/profile" className="button primary"> <i className="fas fa-id-card"></i> Update Profile / Password </Link>
				</div>
				<hr />
			</div>
			{ ( props.UserState.user.examPassed ? (
			<>
			<div>
				<h4>Continuing Education</h4>
				<p> You have <strong>{props.UserState.user.credits ? props.UserState.user.credits : 0 }</strong> continuing education credits for this cycle.</p>
				{ (!props.UserState.user.credits || props.UserState.user.credits < 8 )? <>
					<div>
						See if there's some courses you can take here:  <Link to="/ce-training" className="no-bottom-margin button primary small"> <i className="fas fa-graduation-cap"></i> Continuing Education </Link>
					</div>
					
				</> : <></>}
			</div>
			<hr />
			<div>
				<h4>Associated Trained Fertilizer Applicators </h4>
				<p>Below is a list of the Trained Fertilizer Applicators (TFAs) associated with you. Click the TFA's ID in the first column to view and print the certificate.</p>
				<p>You may submit a new name and contact information for any professional who has received annual training and will be applying fertilizer under your direct supervision. Click the "Add TFA" button to do so.</p>
				
				<table  className="table-left-align-all">
					<thead>
						<tr>
							<th>TFA ID</th>
							<th>Last Name</th>
							<th>First Name</th>
							<th>Email</th>
							<th>Active Year</th>
							<th>Paid / Approved</th>
							<th>Payment Id</th>
						</tr>
					</thead>
					<tbody>
						{getCurrentTfas()}
					</tbody>
				</table>
			</div>
			</>
			) : null ) }
		</>
	);
};

export default CFADashboard;