import React, { useEffect, useState } from 'react';
//, useEffect
import { Redirect } from 'react-router-dom';

//import axios from 'axios';
//import Config from '../Config';

const LogoutPage = ( props ) => {

	document.title = props.title;
	const [startRedirect, triggerRedirect] = useState(false);
	localStorage.removeItem('profact-token');
	useEffect(() => {
		const timer = setTimeout(() => {
		  	triggerRedirect(true);
		}, 5000);
		return () => clearTimeout(timer);
	}, []);
	
	useEffect(() => {
		if( props.UserState.loggedIn) {
			props.SetUserState({
				loggedIn: false,
				//preferredLanguage: 'en-US',
				preferredLanguage: 'en-US',
				user: null
			});
		}
	}, [props]);


	return (
		<>
			{(
				startRedirect ? <Redirect to="/" /> : 
				
				<main id="main-wrapper" className="">
					<div id="main" className="">
						<div id="main-col" className="">
							<div id="content" className="">
								<div className="medium-12 large-12 columns">
									<h2>Logged out</h2>
									<p>You have been logged out. You will be redirected to the home page in 5 seconds. </p>
								</div>
							</div>
						</div>
					</div>
				</main>

			)}
		</>
	)
/*
	return (
		<>
			<div id="main" className="grid-x grid-margin-x">
				<form action="" className="grid-x grid-margin-x">
					<div className="medium-6 large-6 cell large-offset-3 medium-offset-3">
						<h3>Login</h3>
						<label>
							Email:
							<input type="email" value={loginEmail} onChange={e => setLoginEmail(e.target.value)}/>
						</label>
						<label>
							Password:
							<input type="password" value={loginPassword} onChange={e => setLoginPassword(e.target.value)}/>
						</label>
					</div>
				</form>
			</div>
		</>
	)
	*/
	/*
	const [cfaList, setCfaList] = useState([]);

	useEffect( () => {
		const fetchData = async () => {
			const results = await fetch("/cfa/public");
			const body = await results.json();
			setCfaList( body );
			console.log(body);
		}
		fetchData();
	}, [] );

	return (
		<main id="main-wrapper" className="grid-container ">
			<div id="main" className="grid-x grid-margin-x">
				<div id="main-col" className="cell">
					<h1>Certified Applica​​​​tors List​</h1>
					<h3>
						All professional applicators listed below have met the requirements for applying fertilizer in the state of 
							New Jersey according to <a href={NJ_ACT_C112} target="_blank" rel="noopener noreferrer">New Jersey Act, ​P.L. 2010, c. 112 (C.58:10A-64)</a>​.​​​​​​​​​​
					</h3>
					<hr />
					<CFATable CfaList={cfaList} />
				</div>
			</div>
		</main>
	);
*/
}

export default LogoutPage;