import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Profact from './Profact';
//import Foundation from 'react-foundation';

import * as serviceWorker from './serviceWorker';

//StrictMode triggers changes twice so that it's easier to spot errors. 
ReactDOM.render(
  <React.StrictMode>
    <Profact />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
