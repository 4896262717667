import React from 'react';
//import Config from '../../Config';

class NJAESUberNavWrapper extends React.Component {
	render() {
		return (
			
		<div id="uber-nav-wrapper" className="contain-to-grid-black">
			<div className="top-bar" id="uber-nav">
				<ul className="top-bar-right">
					<li><a href="https://search.rutgers.edu/web.html">Search Rutgers</a></li>
					<li className="has-form">
						<form id="search" method="get" name="searchform" action="https://search.rutgers.edu/web.html" onSubmit={ () => { return window.searchsite(); } }>
							<input type="hidden" name="site" id="site" value="profact.rutgers.edu" />
							<label className="visually-hide" htmlFor="search-text">Search</label>
							<input type="text" name="q" id="search-text" placeholder="Search This Site" />
							<input type="submit" id="search-submit" value="Search" />
						</form>
					</li>
				</ul>
			</div>
		</div>
		);
	}
}
export default NJAESUberNavWrapper;