import React, 
{ useState } from 'react';

import CECourseTable from './Components/CourseComponents/CECourseTable';
import UpdateCECourseForm from './Components/CourseComponents/UpdateCECourseForm'; 
import QuickAddCourseForm from './Components/CourseComponents/QuickAddCourseForm';

const AdminManageCeCredits = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;

	const [showScreen, SetShowScreenTo] = useState('ce-list');
	const [selectedCourse, SetSelectedCourse] = useState(null);


	const SelectCourse = (courseInfo) => {
		SetSelectedCourse(courseInfo)
		SetShowScreenTo('course-form');
	}
	const UpdateCourseCreditList = (courseInfo) => {
		SetSelectedCourse(courseInfo)
		SetShowScreenTo('update-credit-list');
	}

	const confirmEditing = () => {
		//SetSelectedUser(null);
		SetSelectedCourse(null);
		SetShowScreenTo('ce-list');
	}
	const cancelEditing = () => {
		SetSelectedCourse(null);
		SetShowScreenTo('ce-list');
	}

	const ShowComponent = () => {
		if( showScreen === 'ce-list' ) {
			return <CECourseTable SelectCourse={SelectCourse} UpdateCourseCreditList={UpdateCourseCreditList}/>
		}else if( showScreen === 'course-form' ) {
			return <QuickAddCourseForm selectedCourse={selectedCourse} confirmEditing={confirmEditing} cancelEditing={cancelEditing} />
		}else if( showScreen === 'update-credit-list' ) {
			return <UpdateCECourseForm selectedCourse={selectedCourse} confirmEditing={confirmEditing} cancelEditing={cancelEditing} />
		}

		return null;
	}


	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h2> Apply Continuing Education Credits​ </h2>
						<p><em>You can apply credits here.​</em></p>
						<div className="button-group stacked-for-small button-group-override">
							<button className={
							`button small primary ${showScreen === 'ce-list' ? 'is-selected' : ''}`
							} onClick={(e) =>{ SetShowScreenTo( 'ce-list' ) }}>C.E. List</button>
							<button className={
								`button small primary ${showScreen === 'course-form' ? 'is-selected' : ''}`
							} onClick={(e) =>{ SetShowScreenTo( 'course-form' ) }}> <i className="fas fa-plus"></i> Add / Update Course</button>
						</div>
						{ShowComponent()}
					</div>
				</div>
			</div>
		</main>
	);
}
export default AdminManageCeCredits;