import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  //withRouter
} from 'react-router-dom';
//import axios from 'axios';
//import logo from './logo.svg';

import Config from './Config';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import {
//  user_status,
  get_cfa_by_id,
  get_admin_by_id
} from  './Api/api';

import './Profact.css';

//Pages
import AuthRoute from './HelperComponents/AuthRoute';
import CFACertificate from './HelperComponents/CFACertificate';
import TFACertificate from './HelperComponents/TFACertificate';
//Front End Pages
import HomePage from './Pages/HomePage';
import BackgroundPage from './Pages/BackgroundPage';
import TemplatePage from './Pages/TemplatePage';
import RegistrationPage from './Pages/RegistrationPage';
import CFAIntroductionPage from './Pages/CFAIntroductionPage';
import CFAPublicProfilePage from './Pages/CFAPublicProfilePage';
import TFAPublicProfilePage from './Pages/TFAPublicProfilePage';
import CFATrainingPage from './Pages/CFATrainingPage';
import CFATrainingContentPage from './Pages/CFATrainingContentPage';
import CFAListPage from './Pages/CFAListPage';
import TFAIntroductionPage from './Pages/TFAIntroductionPage';
import TFATrainingPage from './Pages/TFATrainingPage';
import TFAListPage from './Pages/TFAListPage';
import ContactUsPage from './Pages/ContactUsPage';
import ForgottenPasswordPage from './Pages/ForgottenPasswordPage';
import RecoverPasswordPage from './Pages/RecoverPasswordPage';

import DeveloperPage from './Pages/DeveloperPage';
import PaymentTestPage from './Pages/PaymentTestPage';
import LoginPage from './Pages/LoginPage';

//Registered users Pages
import LogoutPage from './Pages/LogoutPage';
import DashboardPage from './Pages/DashboardPage';
import ProfilePage from './Pages/ProfilePage';

//CFA Pages
import CFAPaymentPage from './Pages/CFAPaymentPage';
import CFAPaymentProcessingPage from './Pages/CFAPaymentProcessingPage';
import CFAAddTFA from './Pages/CFAAddTFA';
import CFAExamRoomPage from './Pages/CFAExamRoomPage';
import CFAExaminationPage from './Pages/CFAExaminationPage';
import CFAContinuingEducationPage from './Pages/CFAContinuingEducationPage';
//import CFACETrainingPage from './Pages/CFACETrainingPage';

//Admin pages
import AdminManageUsersPage from './Pages/AdminManageUsersPage';
import ManageContentPage from './Pages/AdminManageContentPage';
//import ManagePaymentsPage from './Pages/ManagePaymentsPage';
import ManageExamsPage from './Pages/ManageExamsPage';
import ManageContEdPage from './Pages/ManageContEdPage';
import AdminManageNewsPage from './Pages/AdminManageNewsPage';
import AdminManagePaymentsPage from './Pages/AdminManagePaymentsPage';
import AdminDownloadReportsPage from './Pages/AdminDownloadReportsPage';
import NewYearsTasksPage from './Pages/NewYearsTasksPage';
import AdminMassMailer from './Pages/AdminMassMailer';
import AdminDummyMassMailer from './Pages/AdminDummyMassMailer';
import AdminManageCeCredits from './Pages/AdminManageCeCredits';
//import NJAESMenu from './Header/Components/NJAESMenu';


const Profact = (props) => {

  const [userState, setUserState] = useState({
    loggedIn: null,
    refreshData: false,
    //preferredLanguage: 'en-US',
    preferredLanguage: 'en-US',
    user:null
  });

  useEffect( () => {
    //console.log('PROFACT User State changed ', userState.loggedIn);


  }, [userState]);
  
/*
  useEffect( () => {
    console.log('PROFACT PAGE CHANGE? ', window.location.pathname);
  }, [window.location.pathname]);*/

  useEffect( () => {
    checkLoginStatus();
    window.triggerFoundation();
  }, []);


  const checkLoginStatus = () => {
    //console.log('Checking Login status.');
    if( !localStorage.getItem('profact-token') ) {
      console.warn('No login token detected.');
      setUserState({
        loggedIn: false,
        refreshData: false,
        lastRefreshPage: window.location.pathname,
        //preferredLanguage: 'en-US',
        preferredLanguage: 'en-US',
        user:null
      });
      return false;
    }

    //console.log('Found a token: ',localStorage.getItem('profact-token'));
    let getAuthToken = null;
    let getId = null;
    try{ 
      getAuthToken = localStorage.getItem('profact-token');
      getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );

      //console.log('GET ID: ',getId.exp,  new Date().getTime()/1000);
      if( getId.exp < new Date().getTime()/1000 ) {
        console.warn("[Check Login Status] Expired Token.");
        localStorage.clear();
        localStorage.removeItem('profact-token');
        setUserState({
          loggedIn: false,
          refreshData: false,
          lastRefreshPage: '',
          //preferredLanguage: 'en-US',
          preferredLanguage: 'en-US',
          user:null
        });
        return false;
      }
    }catch(loginStatusErr) {
      
      console.warn('Problematic Login token. Clearing. ');
      localStorage.clear();
      localStorage.removeItem('profact-token');
      setUserState({
        loggedIn: false,
        refreshData: false,
        lastRefreshPage: window.location.pathname,
        //preferredLanguage: 'en-US',
        preferredLanguage: 'en-US',
        user:null
      });
      return false;
    }
//console.log(getId, getId.id);

    if( getId.admin ) {

      get_admin_by_id(getAuthToken, (getId.id ? getId.id : getId._id) )
      .then( (res) => {
        //console.log('Retrieving the user\'s current login information...', res);
        
        if( res.status && res.status !== 200 ) {
  
          throw res; 
        }
  
        if( res.data ) {
          //console.log('Here is the data form the login: ', res.data, getAuthToken);
  
          //localStorage.setItem('profact-token', JSON.stringify(myUser));
          setUserState({
            loggedIn: true,
            refreshData: false,
            lastRefreshPage: window.location.pathname,
            //preferredLanguage: 'en-US',
            preferredLanguage: 'en-US',
            user:{
              ...res.data,
              userType: (getId.admin ? 'A' : 'C')
            }
          });
  
          //console.log('Done checking User state.');
        }
      } )
      .catch( error => {
        if( error.statusCode && ( error.statusCode === 500 ||  error.statusCode === 404 ) ) {
          console.error('Could not fetch user\'s current login information... ');
        }
      });
    }else{
      //console.log('AAAAAAAAAAAAAAA', getAuthToken, getId );
      get_cfa_by_id(getAuthToken, (getId.id ? getId.id : getId._id) )
      .then( (res) => {
        //console.log('Retrieving the user\'s current login information...', res);
        
        if( res.status && res.status !== 200 ) {
  
          throw res; 
        }
  
        if( res.data.code === 'ONE_CFA' && res.data.data ) {
          //console.log('Here is the data form the login: ', res.data, getAuthToken);
  
          //localStorage.setItem('profact-token', JSON.stringify(myUser));
          setUserState({
            loggedIn: true,
            refreshData: false,
            lastRefreshPage: window.location.pathname,
            //preferredLanguage: 'en-US',
            preferredLanguage: 'en-US',
            user:{
              ...res.data.data,
              userType: (getId.admin ? 'A' : 'C')
            }
          });
  
          //console.log('Done checking User state.');
        }
      } )
      .catch( error => {

        if( error.statusCode && ( error.statusCode === 500 ||  error.statusCode === 404 ) ) {
          console.error('Could not fetch user\'s current login information... ');
        }
        //console.log()
      });
    }



  }


    let myDevPage = (<></>);
    if( Config.IS_DEV_ENV() ) {
      myDevPage = 
      <>
        <Route path="/developer" 
          render={props => (
            <DeveloperPage {...props} title={Config.GET_PAGE_TITLE('DEVELOPER PAGE')} />
          )}
        />
        <Route path="/payment-test" 
          render={props => (
            <PaymentTestPage {...props} title={Config.GET_PAGE_TITLE('PAYMENT TEST PAGE')} />
          )}
        />
      </>
    }

    if( userState.loggedIn === undefined ) { 
      return null;
    }
    //Maybe have an intermediate step for protected pages?
    
    //<NJAESMenu UserState={userState} />

    //Removed ` id="content"` from content-wrapper's div

    if( process.env.REACT_APP_PROFACT_MAINT_MODE && process.env.REACT_APP_PROFACT_MAINT_MODE==='true' ) {
      return( <>
        <Router basename={Config.BASENAME}>
        <Header UserState={userState} />
                <main id="main-wrapper" className="">
                  <div id="main" className="">
                    <div id="main-col" className="">
                      <div id="content" className="">
                        <div className="small-12 columns">
                          <h1>Maintenance</h1>
                          <p>{
                          process.env.REACT_APP_PROFACT_MAINT_MESSAGE 
                          ? 
                          process.env.REACT_APP_PROFACT_MAINT_MESSAGE 
                          :
                          `We are currently down for maintenance. Please bear with us. We should be up soon.`}
                          </p>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
            <Footer />
            </Router>
      </>);
  
    }




    return (
      <>
      <Router basename={Config.BASENAME}>

        <Switch>
          <Route path="/certificate" exact
            render={props => (
              <CFACertificate {...props} UserState={userState} title={Config.GET_PAGE_TITLE('Certificate')} />
            )}
          />
          <Route path="/tfa-certificate/:T_CODE" exact
            render={props => (
              <TFACertificate {...props} UserState={userState} title={Config.GET_PAGE_TITLE('TFA Certificate')} />
            )}
          />
          <>
          <Header UserState={userState} />
          <main>
          <div id="content-wrapper" className="contain-to-grid Profact">
            <div>
            <Route path="/" exact
              render={props => (
                <HomePage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('Home')} />
              )}
            />
            {myDevPage}

            <Route path="/register"
              render={props => (
                <RegistrationPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('Registration')} />
              )}
            />
            <Route path="/forgot-password/:token"
              render={props => (
                <RecoverPasswordPage {...props} title={Config.GET_PAGE_TITLE('Recover Password')} />
              )}
            />
            <Route path="/forgot-password"
              exact
              render={props => (
                <ForgottenPasswordPage {...props} title={Config.GET_PAGE_TITLE('Forgot Password')} />
              )}
            />
            

            <Route path="/background"
              render={props => (
                <BackgroundPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('Background')} />
              )}
            />
            <Route path="/template"
              render={props => (
                <TemplatePage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('Template')} />
              )}
            />

<Route 
              path="/cfa/:C_CODE"
              exact
              render={props => (
                <CFAPublicProfilePage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('CFA Public Profile')} />
              )}
            />
            <Route 
              path="/tfa/:T_CODE"
              exact
              render={props => (
                <TFAPublicProfilePage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('TFA Public Profile')} />
              )}
            />
            <Route path="/cfa-instructions"
              render={props => (
                <CFAIntroductionPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('CFA Instructions')} />
              )}
            />
            <Route 
              path="/cfa-training"
              exact
              render={props => (
                <CFATrainingPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('CFA Training')} />
              )}
            />

            <AuthRoute path="/cfa-training/:courseId/:module/:section" UserState={userState} SetUserState={setUserState} component={CFATrainingContentPage} 
              title={Config.GET_PAGE_TITLE('CFA Online Training Manual')} />

            <Route path="/certified-applicators"
              render={props => (
                <CFAListPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('Certified Applicators')} />
              )}
            />

            <Route path="/tfa-instructions"
              render={props => (
                <TFAIntroductionPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('TFA Instructions')} />
              )}
            />
            <Route path="/tfa-training"
              render={props => (
                <TFATrainingPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('TFA Training')} />
              )}
            />
            <Route path="/trained-applicators"
              render={props => (
                <TFAListPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('Trained Applicators')} />
              )}
            />

            <Route path="/contact-us"
              render={props => (
                <ContactUsPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('Contact Us')} />
              )}
            />


            <Route path="/login/:redirectTo?/:optParam1?/:optParam2?"
              render={props => (
                <LoginPage {...props} UserState={userState} SetUserState={setUserState} title={Config.GET_PAGE_TITLE('Login')} />
              )}
            />
            <Route path="/logout"
              render={props => (
                <LogoutPage {...props} UserState={userState} SetUserState={setUserState}  title={Config.GET_PAGE_TITLE('Logout')} />
              )}
            />

              <AuthRoute path="/dashboard" UserState={userState} SetUserState={setUserState} component={DashboardPage} title={Config.GET_PAGE_TITLE('Dashboard')} />
              <AuthRoute path="/profile" UserState={userState} SetUserState={setUserState} component={ProfilePage} title={Config.GET_PAGE_TITLE('Your Profile')} />

              
              <AuthRoute path="/cfa-tfa" AllowedUsers={['C']} UserState={userState} SetUserState={setUserState} component={CFAAddTFA} title={Config.GET_PAGE_TITLE('Your TFAs')} />
              <AuthRoute path="/payment-processing/:processType/:paymentId?/:paymentToken?" AllowedUsers={['C']} UserState={userState} SetUserState={setUserState} component={CFAPaymentProcessingPage} title={Config.GET_PAGE_TITLE('Your Payments')} />
              <AuthRoute path="/payment/:processType/:paymentId?/:paymentToken" AllowedUsers={['C']} UserState={userState} SetUserState={setUserState} component={CFAPaymentProcessingPage} title={Config.GET_PAGE_TITLE('Your Payments')} />
              <AuthRoute path="/payments/:form?" AllowedUsers={['C']} UserState={userState} SetUserState={setUserState} component={CFAPaymentPage} title={Config.GET_PAGE_TITLE('Your Payments')} />
              <AuthRoute path="/exams" AllowedUsers={['C']} UserState={userState} SetUserState={setUserState} component={CFAExaminationPage} title={Config.GET_PAGE_TITLE('Your Examination')} />
              <AuthRoute path="/exam-room/:courseId/:examId?" AllowedUsers={['C']} UserState={userState} SetUserState={setUserState} component={CFAExamRoomPage} title={Config.GET_PAGE_TITLE('Examination Room')} />
              
              <AuthRoute path="/ce-training/:courseId/:module/:section" UserState={userState} SetUserState={setUserState} 
                IsCE={true}
                component={CFATrainingContentPage} 
                title={Config.GET_PAGE_TITLE('Continuing Education Online Course Manual')} />
              <AuthRoute path="/ce-training/:courseId" exact AllowedUsers={['C']} UserState={userState} SetUserState={setUserState} component={CFATrainingPage} title={Config.GET_PAGE_TITLE('Continuing Education Course')} />
              <AuthRoute path="/ce-training" exact AllowedUsers={['C']} UserState={userState} SetUserState={setUserState} component={CFAContinuingEducationPage} title={Config.GET_PAGE_TITLE('Your Continuing Education Courses')} />
              


              <AuthRoute path="/manage-news" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={AdminManageNewsPage} title={Config.GET_PAGE_TITLE('Manage News')} />
              <AuthRoute path="/manage-user-payments" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={AdminManagePaymentsPage} title={Config.GET_PAGE_TITLE('Manage Payments')} />
              
              
              <AuthRoute path="/manage-users/:cfaId?" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={AdminManageUsersPage} title={Config.GET_PAGE_TITLE('Manage Users')} />
              <AuthRoute path="/manage-ce-credits" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={AdminManageCeCredits} 
                title={Config.GET_PAGE_TITLE('Manage C.E. Credits')} />
              <AuthRoute path="/manage-courses/:course?" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={ManageContentPage} 
                title={Config.GET_PAGE_TITLE('Manage Course Settings')} />
              <AuthRoute path="/manage-exams" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={ManageExamsPage} title={Config.GET_PAGE_TITLE('Manage Exams')} />
              <AuthRoute path="/manage-cont-ed" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={ManageContEdPage} title={Config.GET_PAGE_TITLE('Manage Continuing Education')} />
              <AuthRoute path="/download-reports" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={AdminDownloadReportsPage} title={Config.GET_PAGE_TITLE('Download Reports')} />
              <AuthRoute path="/new-years-tasks" AllowedUsers={['A']} 
              UserState={userState} SetUserState={setUserState} component={NewYearsTasksPage} 
              title={Config.GET_PAGE_TITLE('New Years Tasks')} />
              
              <AuthRoute path="/mass-mailer" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={AdminMassMailer} title={
                Config.GET_PAGE_TITLE('Mass Mailer / Mail Merge')} />

              <AuthRoute path="/mass-mailer-dummy" AllowedUsers={['A']} UserState={userState} SetUserState={setUserState} component={AdminDummyMassMailer} title={
                Config.GET_PAGE_TITLE('(DUMMY) Mass Mailer / Mail Merge')} />
              
          </div>
          </div>
          </main>
          <Footer />
          </>
        </Switch>
        
      </Router>
      </>
    );
}

export default Profact;
