import React from 'react';
import Config from '../Config';


class Footer extends React.Component {
  




	render() {
    const USE_SEBS = false;

    if( !USE_SEBS ) {
      return( 
<footer className="contain-to-grid-black">
		<div id="footer">
			<div className="row">
				<div className="large-4 medium-6 columns">
					<p className="contact-info">
            {Config.UNIT_NAME}<br />

            {Config.SCHOOL_NAME}<br />
            {Config.RUTGERS}<br />
            {Config.STREET_ADDRESS}<br />
            {Config.CITY_STATE_ZIP}<br />
            {Config.WEB_MASTER}
					</p>
					
					<ol className="social">
						<li><a href="https://facebook.com/RutgersCooperativeExtension"><i className="fi-social-facebook"><span className="visually-hide">Follow NJAES on Facebook</span></i></a></li>
						<li><a href="https://twitter.com/RutgersNJAES"><i className="fi-social-twitter"><span className="visually-hide">Follow NJAES on Twitter</span></i></a></li>
						<li><a href="https://youtube.com/user/RutgersNJAES"><i className="fi-social-youtube"><span className="visually-hide">Follow NJAES on Youtube</span></i></a></li>
					</ol>
				</div>
				
				<div className="large-4 medium-6 columns">
					<ol className="linklist">
						<li><a href="https://njaes.rutgers.edu/">Rutgers New Jersey Agricultural Experiment Station</a></li>
						<li><a href="https://execdeanagriculture.rutgers.edu">Executive Dean of Agriculture and Natural Resources</a></li>
						<li><a href="https://www.rutgers.edu/">Rutgers, The State University of New Jersey</a></li>
					</ol>
				</div>
				
				<div className="large-4 medium-6 columns" id="campaign">
					<p className="accessibility">Rutgers University is an equal access/equal opportunity institution. Individuals with disabilities are encouraged to direct suggestions, comments, or complaints concerning any accessibility issues with Rutgers web sites to: <a href="mailto:accessibility@rutgers.edu">accessibility&#64;rutgers.edu</a> or complete the <a href="https://it.rutgers.edu/it-accessibility-initiative/barrierform/">Report Accessibility Barrier or Provide Feedback Form</a>.</p>
					
					<p className="copyright"><a href="https://newbrunswick.rutgers.edu/site-resource/copyright-information">Copyright</a> &copy; 2020 Rutgers, The State University of New Jersey.</p>
				</div>
			</div> 
		</div>
	</footer>

      )
    }


		return (
        <footer id="footer-wrapper">
          <div id="footer1-wrapper" className="grid-container full">
            <div id="footer1" className="grid-x grid-margin-x">
              <div className="cell small-12 medium-6 large-5">
                <h2>Contact</h2>

                <p id="address" className="icon i-location">
                  {Config.UNIT_NAME}
				  <br />
              {Config.SCHOOL_NAME}<br />
              {Config.RUTGERS}<br />
              {Config.STREET_ADDRESS}<br />
              {Config.CITY_STATE_ZIP}<br />
                  <a href="https://maps.rutgers.edu/#/?selected=6006">{Config.BUILDING_NAME}</a>, {Config.CAMPUS}<br />
                </p>

                <ol id="phones" className="icon i-phone">
                  <li>{Config.PHONE_NUMBER}</li>
                </ol>

                <ol id="social">
                  <li><a href="https://www.facebook.com/RutgersSEBS"><i className="fab fa-facebook-square"><span className="vh">Facebook</span></i></a></li>
                  <li><a href="https://www.instagram.com/RutgersSEBS"><i className="fab fa-instagram"><span className="vh">Instagram</span></i></a></li>
                  <li><a href="https://twitter.com/RutgersSEBS"><i className="fab fa-twitter"><span className="vh">Twitter</span></i></a></li>
                  <li><a href="https://www.youtube.com/user/RutgersSEBS"><i className="fab fa-youtube-square"><span className="vh">YouTube</span></i></a></li>
                </ol>

                <p id="webmaster">Webmaster: {Config.WEB_MASTER}</p>
              </div>

              <div className="cell small-12 medium-6 large-5">
                <h2>Related Units</h2>

                <ol className="lined">
                  <li><a href="https://www.rutgers.edu">Rutgers, The State University of New Jersey</a></li>
                  <li><a href="https://newbrunswick.rutgers.edu">Rutgers&ndash;New Brunswick</a></li>
                  <li><a href="https://sebs.rutgers.edu">Rutgers School of Environmental and Biological Sciences</a></li>
                  <li><a href="https://njaes.rutgers.edu">Rutgers New Jersey Agricultural Experiment Station</a></li>
                  <li><a href="https://execdeanagriculture.rutgers.edu">Executive Dean of Agriculture &amp; Natural Resources</a></li>
                </ol>
              </div>

              <div className="cell small-12 medium-6 large-2">
                <a href="http://health.rutgers.edu/do-something-to-help/"><img className="lazyload campaign" src={process.env.PUBLIC_URL + '/ru-sebs/images/concerned-logo-m.png'} alt="Click to share a concern." /></a>
              </div>
            </div>
          </div>

          <div id="footer2-wrapper" className="grid-container full">
            <div id="footer2" className="grid-x grid-margin-x">
              <p><a href="https://www.rutgers.edu/site-resource/copyright-information">Copyright &copy; 2020</a> Rutgers, The State University of New Jersey</p>

              <p id="accessibility">Rutgers University is an equal access/equal opportunity institution. Individuals with disabilities are encouraged to direct suggestions, comments, or complaints concerning any accessibility issues with Rutgers web sites to: <a href="mailto:accessibility@rutgers.edu">accessibility&#64;rutgers.edu</a> or complete the <a href="https://it.rutgers.edu/it-accessibility-initiative/barrierform/">Report Accessibility Barrier or Provide Feedback Form</a>.</p>
            </div>
          </div>
        </footer>
		);
	}
}

export default Footer