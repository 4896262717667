import React, {
	useEffect, useState
} from 'react';

import {
	AUTO_admin_course,

	DECODE_TOKEN
} from '../../../Api/api';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const QuickAddCourseForm = (props) => {

	const [
		selectedCourse,
		cancelEditing,
		confirmEditing
	] = [
		props.selectedCourse, 
		props.cancelEditing, 
		props.confirmEditing 
	];

	const [disableSubmitBtn, SetDisableSubmitBtn] = useState(false);
	const [courseInfo, SetCourseInfo] = useState((
		selectedCourse ? 
		{
			...selectedCourse,
			beginDate: moment(selectedCourse.beginDate).tz( moment.tz.guess() ).toDate()
		 }: {
			courseId: null,
			courseTitle: '',
			description: '',
			credits: '',
			beginDate: '',
			cfas: []
		}
	));

	//console.log('The selected coruse: ', courseInfo);

	
	const courseForm_changeDate = (date) => {
		SetCourseInfo({
			...courseInfo,
			beginDate: date
		})
		/*
		SetUpdateNewsItem({
			...updateNewsItem,
            expires: date
		});*/
	}

	const handleCourseOnChange = (e_target) => {
		const { name, value } = e_target;
		//console.log('Updating {name, value}', { name, value })
		SetCourseInfo({
			...courseInfo,
            [name]: value
		})
	}

	const submitCourseInformation = (e) => {
		e.preventDefault();
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		SetDisableSubmitBtn(true);

		//console.log(moment(courseInfo.beginDate).format('L'));

		AUTO_admin_course( 
			tokenInfo.getAuthToken, {
				...courseInfo,
				title: courseInfo.courseTitle,
				beginDate: moment(courseInfo.beginDate).format('L')
			}
		)
		.then( (res) => {
			console.log('Adding or updating course ', res.data);
			
			SetDisableSubmitBtn(false);
			confirmEditing();
		} )
		.catch( error => {
			console.error('Updating news error', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Updating news error');
			}
			SetDisableSubmitBtn(false);
		});

	}

	useEffect( () => {
		//GetCfaList();
	}, [] );

	return (
		<>
			<h3> Course Information </h3>
			<div>
			<form action="" method="post" onSubmit={submitCourseInformation}>
				<div className="row">
					<div className="medium-3 small-12 columns">
						<label htmlFor="courseTitle" className="label-style-1">Title: </label>
					</div>
					<div className="medium-5 small-12 no-float columns">
						<input type="text" 
							id="courseTitle" 
							name="courseTitle"
							value={courseInfo.courseTitle}
							placeholder="Course Title" 
							onChange={(e) => {handleCourseOnChange(e.target);} }
							required="required"
						/>
					</div>
				</div>
				<div className="row">
					<div className="medium-3 small-12 columns">
						<label htmlFor="description" className="label-style-1">Description: </label>
					</div>
					<div className="medium-5 small-12 no-float columns">
						<textarea id="description" 
							name="description"
							rows="4"
							value={courseInfo.description}
							placeholder="Course Description" 
							onChange={(e) => {handleCourseOnChange(e.target);} }
							required="required"
						></textarea>
					</div>
				</div>
				<div className="row">
					<div className="medium-3 small-12 columns">
						<label htmlFor="credits" className="label-style-1">Credits: </label>
					</div>
					<div className="medium-5 small-12 no-float columns">
						<input type="number" 
							id="credits" 
							name="credits"
							value={courseInfo.credits}
							placeholder="Course Credts" 
							onChange={(e) => {handleCourseOnChange(e.target);} }
							required="required"
						/>
					</div>
				</div>
				<div className="row" style={{marginBottom:'1em'}}>
					<div className="medium-3 small-12 columns">
						<label htmlFor="courseDate" className="label-style-1">Course Date: </label>
					</div>
					<div className="medium-5 small-12 no-float columns">
						<DatePicker 
						
							className="no-bottom-margin"
							id="courseDate"
							name="courseDate"
							selected={courseInfo.beginDate} 
							onChange={date => {
								courseForm_changeDate(date)
								console.log('Date picker changed: ', date);
							}} 
							popperPlacement="top-end"
							isClearable
							required="required"
						/>
					</div>
				</div>
				<div className="row">
					<div className="medium-8 small-12 columns" style={{textAlign:'right'}}>
						<button type="button"  className="button primary" onClick={(e) =>{
							cancelEditing();
						}}>Cancel</button> <button 
							type="submit" disabled={disableSubmitBtn} className="button success override-sebs"
							>Save Course</button>
					</div>
				</div>
			</form>
			</div>
		</>
	);
};

export default QuickAddCourseForm;