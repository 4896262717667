import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { nyeCfaRevokeDanger,
	nyeRevokeByCfaIds,
	DECODE_TOKEN  } from '../Api/api';
import NyeEmailUsers from './Components/NewYearsTasksComponents/Email';
import TfaPrevDeactivate from './Components/NewYearsTasksComponents/TfaPrevDeactivate';
import CfaReplenishCycleYear from './Components/NewYearsTasksComponents/CfaReplenish';
	

export const AXIOS_WAIT = 'WAIT';
export const AXIOS_OK = 'OK';
export const AXIOS_ERR = 'ERR';
export const AXIOS_LOADING = 'LOADING';





const RevokeModalWindow = ({cfaIds, callback=(refresh=false)=>{}}/*:{cfaIds:Array<number>, callback: ()=>void}*/) => {
	const popupRef = useRef/*<HTMLDivElement>*/(null);
	const [myPopup, setMyPopup] = useState(null);
	const [axiosState, setAxiosState] = useState/*<{isLoading: boolean, status: AXIOS_WAIT|AXIOS_OK|AXIOS_ERR|AXIOS_LOADING|> */({
		isLoading: false,
		state: AXIOS_WAIT
	});


	useEffect( () => {
		if( axiosState.state === AXIOS_LOADING ){
			const tokenInfo = DECODE_TOKEN();
			if( tokenInfo.code !== 'TOKEN_FOUND' ) {
				console.error('Did not find appropriate token.');
				setAxiosState({isLoading:false, state: AXIOS_ERR});
				return;
			}

			nyeRevokeByCfaIds( tokenInfo.getAuthToken, cfaIds ) 
			.then( (res) => {		
				if( res && res.data && res.data.code && res.data.code ==='REVOKED') {
					setAxiosState({isLoading:false, state: AXIOS_OK});
				}else{
					setAxiosState({isLoading:false, state: AXIOS_ERR});
				}
			} )
			.catch( error => {

				console.error('Getting [nyeRevokeByCfaIds] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not revoke cfas.');
				}
				setAxiosState({isLoading:false, state: AXIOS_ERR});
			});
		}
	}, [axiosState, cfaIds] );

	
	useEffect( () => {
		if( popupRef.current ){
			const myA = new window.Foundation.Reveal( window.jQuery(popupRef.current) );
			myA.open()
			setMyPopup(myA)
			//new Foundation.Reveal(myPopup.current)
		}
	}, [popupRef] );


	return <div>
		<div ref={popupRef} className="reveal" id="revoke-cfas" data-reveal>
			{
				axiosState.state !== AXIOS_OK
				?
				<div>
					<h1>Revoke {cfaIds.length} CFA(s)</h1>
					<p className="lead">warns the user about how the data will be lost and if they continue</p>
					<p><em><strong>This action cannot be undone.</strong></em></p>
					{axiosState.state === AXIOS_ERR ? <p><em> There was a problem revoking these CFAs. </em></p> : <></>}
					<button className="close-button" data-close aria-label="Close modal" type="button"
						onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							if(callback) { callback(false) }}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<div>
						<button className="button" onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							if(callback) { callback(false) }}}>
							Nevermind
						</button> <button className="button "
							disabled={axiosState.isLoading}
							onClick={()=>{
								setAxiosState({isLoading:true, state: AXIOS_LOADING});
							}}>
								{axiosState.isLoading ? 'Revoking...'
							:"Yes, Revoke CFAs"
								}
						</button>
					</div>
				</div>
				:
				<div>
					<h1>Revoked {cfaIds.length} CFA(s)</h1>
					<p className="lead">The CFAs were revoked.</p>
					<div>
						<button className="button" onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							if(callback) { callback(true) }}}>
							OK
						</button>
					</div>
				</div>

			}
		</div>	
	</div>
}







const NyeRevokeDangerTable = ({ignoreCurrentYear=false} /*:{ignoreCurrentYear:boolean}*/) => { 
	const [axiosStatus, setAxiosStatus] = useState(AXIOS_LOADING); /* useState<string> */
	const [cfaList, setCfaList] = useState([]); /* useState<Array<Cfa>>*/
	const [cfaRevokeList, setCfaRevokeList] = useState/*<Array<number>>*/([]);
	const [cfaEmailList, setCfaEmailList] = useState/*<Array<number>>*/([]);
	const [showRevokeModal, setShowRevokeModal] = useState/*<boolean> */(false);
	const [showEmailForm, setShowEmailForm] = useState/*<boolean> */(false);
	
	
	useEffect( () => {
		if( axiosStatus === AXIOS_LOADING ){
			const tokenInfo = DECODE_TOKEN();
			if( tokenInfo.code !== 'TOKEN_FOUND' ) {
				console.error('Did not find appropriate token.');
				setAxiosStatus(AXIOS_ERR);
				return;
			}

			
			nyeCfaRevokeDanger( tokenInfo.getAuthToken ) 
			.then( (res) => {		
				if( res && res.data && res.data.code && res.data.code ==='REVOKE_DANGER') {
					setCfaList(res.data.data);
					setAxiosStatus(AXIOS_OK);
				}else{
					setAxiosStatus(AXIOS_ERR);
				}
			} )
			.catch( error => {

				console.error('Getting [nyeCfaRevokeDanger] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch TFAs for this CFA.');
				}
				setAxiosStatus(AXIOS_ERR);
			});

		}
	}, [axiosStatus] );

	useEffect( () => { 
		//remove items from list if needed
			const ids = cfaList.filter((cfa)=>{
				if( ignoreCurrentYear ) { 
					const currYear = new Date().getFullYear();
					//[stinky] [todo] I'm not sure yet which way this should go 
					//	so i'll just do the exact instructions of ignoring the current year
					if( cfa.cycleYear === currYear) {
						return true;
					}
				}	
				return false;
			}).map ( (cfa) => { 
				return cfa.id;
			});

			console.log('ignoreCurrentYear', ignoreCurrentYear, ids)

			if( ignoreCurrentYear ) {
				setCfaRevokeList( cfaRevokeList => cfaRevokeList.filter( (id) => { 
					return !ids.includes(id);
				}))
				setCfaEmailList( cfaEmailList => cfaEmailList.filter( (id) => { 
					return !ids.includes(id);
				}))
			}


	}, [ignoreCurrentYear, cfaList] );

	if( axiosStatus === AXIOS_LOADING) {
		return <div>
			<i style={{fontSize:'xx-large'}} className="fas fa-spinner fa-spin"></i>
		</div>
	}
	if( axiosStatus === AXIOS_ERR) {
		return <div>
			<i style={{ fontSize: 'xx-large' }} className="fas fa-exclamation-triangle"></i> There was a problem loading the CFAs who are in danger of being revoked. <button
				style={{ marginBottom: '0', verticalAlign: 'middle' }} className="button"
				onClick={() => { setAxiosStatus(AXIOS_LOADING) }}
			>Retry?</button>
		</div>
	}

	const FilteredCfaList = (cfaList) => { 
		return cfaList.filter((cfa)=>{
			if( ignoreCurrentYear ) { 
				const currYear = new Date().getFullYear();
				//[stinky] [todo] I'm not sure yet which way this should go 
				//	so i'll just do the exact instructions of ignoring the current year
				if( cfa.cycleYear === currYear) {
					return false;
				}
			}	
			return true;
		})
	}

	const MyFilteredList = FilteredCfaList(cfaList)


	if( showEmailForm ) {
		return <NyeEmailUsers cfaIds={cfaEmailList} callback={(refresh=false)=>{
			if( refresh ) {
				setCfaEmailList([]);
				setAxiosStatus(AXIOS_LOADING);
			}
			setShowEmailForm(false);  
		}} />;
	}

	return <div>
		<div style={{display:'flex'}} className="grid-x grid-margin-x">
			<div style={{paddingRight: '.5rem'}}  className="cell small-12 medium-2">
			<TfaPrevDeactivate />
			</div>

			<div style={{paddingRight: '.5rem'}} className="cell small-12 medium-2">
				<button className="button expanded warning hollow"
			disabled={cfaEmailList.length<1 || undefined}
			onClick={()=>{ setShowEmailForm(true) }}> Email Checked CFAs </button>
			</div>

			<div style={{paddingRight: '.5rem'}} className="cell small-12 medium-2">
			<button className="button expanded alert"
			disabled={cfaRevokeList.length<1 || undefined}
				onClick={()=>{setShowRevokeModal(true);}}>  Revoke Checked CFAs </button>
			</div>
			<div style={{paddingRight: '.5rem'}}  className="cell small-12 medium-3">
				<CfaReplenishCycleYear />
			</div>
		</div>
		<div style={{display:'flex'}} className="grid-x grid-margin-x">
			<div className="cell small-12 table-scroll">
				<table className="table-left-align-all">
					<thead>
						<tr>
							<th>ID</th>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Email</th>
							<th>Credits</th>
							<th>Active Year</th>
							<th>Cycle Year</th>					
							<th style={{textAlign:'center'}}>Revoke? <input type="checkbox" onChange={(e)=>{
								if( e.currentTarget.checked ) { 
									setCfaRevokeList(MyFilteredList.map((cfa)=>{return cfa.id}))
								}else{
									setCfaRevokeList([])
								}
							}}
								checked={MyFilteredList.length === cfaRevokeList.length && MyFilteredList.length !== 0}
							/></th>		
							<th style={{textAlign:'center'}}>Email?<input type="checkbox" onChange={(e)=>{
									if( e.currentTarget.checked ) { 
										setCfaEmailList(MyFilteredList.map((cfa)=>{return cfa.id}))
									}else{
										setCfaEmailList([])
									}
								}}
								checked={MyFilteredList.length === cfaEmailList.length && MyFilteredList.length !== 0}
							/></th>		
						</tr>
					</thead>
					<tbody>
						{
							MyFilteredList.length ? 
							MyFilteredList.map( (cfa, key) => { 
								return <tr key={key}>
									<td>{cfa.profactId ? cfa.profactId : `ID#${cfa.id}`}</td>
									<td>{cfa.firstName || "N/A"}</td>
									<td>{cfa.lastName || "N/A"}</td>
									<td>{cfa.email || "N/A"}</td>
									<td>{cfa.credits || "N/A"}</td>
									<td>{cfa.activeYear || "N/A"}</td>
									<td>{cfa.cycleYear || "N/A"}</td>
									<td style={{textAlign:'center'}}><input type="checkbox" name="cfaRevoke[]" 
										onChange={()=>{
											if( cfaRevokeList.includes(cfa.id) ) {
												setCfaRevokeList(cfaRevokeList.filter( (thisCfaId) => { 
													return !(thisCfaId === cfa.id);
												}));
											}else{
												setCfaRevokeList([...cfaRevokeList, cfa.id]);
									}
								}}
								checked={ cfaRevokeList.includes(cfa.id) /*cfaRevokeList.includes(cfa.id) || undefined*/}
								value={cfa.id} /></td>
							<td style={{textAlign:'center'}}><input type="checkbox" name="cfaEmail[]" 
								onChange={()=>{
									if( cfaEmailList.includes(cfa.id) ) {
										setCfaEmailList(cfaEmailList.filter( (thisCfaId) => { 
											return !(thisCfaId === cfa.id);
										}));
									}else{
										setCfaEmailList([...cfaEmailList, cfa.id]);
									}
								}}
								checked={ cfaEmailList.includes(cfa.id)  /*cfaRevokeList.includes(cfa.id) || undefined*/}
								value={cfa.id} 
								/>
							</td>
						</tr>
					})
					:<tr><td colSpan={10}>No CFAs are in danger of revokement.</td></tr>
				}
			</tbody>
		</table>
			</div>
		</div>
		
		
		{showRevokeModal? 
		(
			cfaRevokeList.length > 0 ? 
			<RevokeModalWindow cfaIds={cfaRevokeList} callback={(refresh=false)=>{
				if( refresh ) {
					setCfaRevokeList([]);
					setAxiosStatus(AXIOS_LOADING);
				}
				setShowRevokeModal(false);  
			}}/>
			:null
		)
		:null}
	</div>
}



const NewYearsTasksPage = (props) =>{

	document.title = props.title;
	const [ignoreCurrentYear, setIgnoreCurrentYear] = useState/*<boolean>*/(false);
	
	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content" className="">
						<h1>New Year&rsquo;s Tasks</h1>
							<div>
								It is important that the tasks are done in a certain order:
								<ul>
									<li>deactivate TFAs,</li>
									<li>revoke users,</li>
									<li>replenish cycle year;</li>
								</ul>
								<p><strong>The logic will not work if done out of order.</strong></p>
        
							</div>
						<p><em>
							If it is the end of the year and this list 
								should show who is in danger of being revoked at New Years, 
								leave as is, if it is after New Years and the list should show who is 
								about to be revoked, click the &ldquo;Do not show current year&rdquo; button.</em></p>
						<div>
							<button type="button" className="button" onClick={()=>{setIgnoreCurrentYear(!ignoreCurrentYear)}}>
								{ignoreCurrentYear ? <i className="far fa-check-square"></i> :<i className="fas fa-square"></i>
								} Do not show current year
							</button>
						</div>
						<hr />
						<NyeRevokeDangerTable ignoreCurrentYear={ignoreCurrentYear} />
					</div>
				</div>
			</div>
		</main>
	);
}
export default NewYearsTasksPage;