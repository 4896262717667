import React, {
	useEffect,
	useState, 
	//useEffect
} from 'react';
import { 
	get_cfa_list,
	get_tfas_by_cfas, 
	get_ce_courses_and_cfa_credits, 
	AUTO_admin_payment, 

	DECODE_TOKEN 
} from '../../../Api/api';
/*
import { 
	get_course_content,

	DECODE_TOKEN 
} from '../../../Api/api';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
/*
import { 
	Link 
} from 'react-router-dom';
*/
/*
import axios from 'axios';
import Config from '../../../Config';
*/


const PaymentForm = (props) => {

	const [
		CancelUpdatePayment,
		ConfirmUpdatePayment
	] = [
		props.cancelUpdatePayment, 
		props.confirmUpdatePayment 
	];
	const [filterCfaList, SetFilterCfaList] = useState('');

	const [cfaList, SetCfaList] =useState({
		isLoading: true,
		hasError: false,
		list: []
	});

	const [payDetails, SetPayDetails] = useState(props.initialPayDetails);
	

	//console.log('Pay Details: ', payDetails); 
	










	const [courseList, setCourseList] = useState({
		upcoming: [],
		registered: []
	});
	const [selectedCourseId, setSelectedCourseId] = useState(null);
	//const [courseRegistration, setCourseRegistration] = useState(null);

	//const [tfaList, setTfaList] = useState([]);
	const [unpaidTfaList, setUnpaidTfaList] = useState([]);	
	const [renewalOptions, setRenewalOptions] = useState(null);


	
	const refreshRenewalOptions = ( userId, cfaList, initDetails ) => {
		console.log('Refreshing renwal options', userId, cfaList);
		if( !cfaList ) {
			setRenewalOptions(null);
			return false;
		}
		//We should have these values already, so no remote call is needed
		const findCfa = cfaList.find( (cfaApp) => {
			//console.log(cfaApp.id, userId );
			return cfaApp.id === Number(userId);
		});

		console.log("The cfa found is... ", findCfa);
		if( !findCfa ) {
			setRenewalOptions(null);
			return false;
		}

		const INITIAL_RENEWAL = [];
		const myCycleYear = findCfa.cycleYear;
		const myActiveYear = findCfa.activeYear;
		//console.log('Renewal check: ', [myCycleYear, myActiveYear]);
		if( myCycleYear && myActiveYear ){
			for( let startOfCycle = myCycleYear-4; startOfCycle<=myCycleYear; startOfCycle++ ) {
				if( startOfCycle <= myActiveYear ) {
					INITIAL_RENEWAL.push( {
						year: startOfCycle,
						isPaid: true,
						isChosen: false,
					});
					/*
					myRenewalButtons.push(<span>
						<i className="fa fa-certificate"></i> {startOfCycle} Paid!
					</span>);*/
				}else{
					INITIAL_RENEWAL.push( {
						year: startOfCycle,
						isPaid: false,
						isChosen: false,
					});
				}
			}
		}

		console.log('Okay, here are the renewal options: ', INITIAL_RENEWAL);
		

		if( initDetails.payPurpose === 'renewal' ) {
			const myRenewalYears = initDetails.cart.items.map( (renewItem) => { return renewItem.year; } );
			setRenewalOptions(
				INITIAL_RENEWAL.map( (renewalItem) => {
					//console.log('Looking for ', renewalItem.year, ' in ', myRenewalYears, 'with', renewalItem);
					renewalItem.isChosen=myRenewalYears.includes( renewalItem.year ); 

					return renewalItem;
				})
			)
		}else{
			setRenewalOptions(INITIAL_RENEWAL);
		}
	}
	const refreshTfas = (userId, initDetails) => {
		console.log('Find by user id ', userId);
		//setTfaContentLoading(true);

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		//console.log(tokenInfo);

		get_tfas_by_cfas( tokenInfo.getAuthToken, userId ) 
		.then( (res) => {
			console.log('TFAs by CFA: ', res.data);
			const currentYear = new Date().getFullYear() ;
			if( res.data.data && res.data.code === 'TFA_BY_CFA') {
				//Let's pull out the cfa exam vs. ce courses
				//setTfaContentLoading(false);
				/*setTfaList( 
					res.data.data
				);*/

				if( initDetails.payPurpose === 'tfa' ) {
					const myTfaIds = initDetails.cart.items.map( (tfaItem) => { return tfaItem.tfaId; } );
					//console.log('Found TFA Ids:', myTfaIds);
					
					setUnpaidTfaList( 
						res.data.data
						//.filter( (tfaItem) => { return !tfaItem.payId; } )
						.filter( (tfaItem) => { 
							return !tfaItem.payId || tfaItem.activeYear < currentYear; 
						} )
						.map( (tfaItem) => { 
							console.log('Looking for ', tfaItem.id, ' in ', myTfaIds);
							tfaItem.isChosen = myTfaIds.includes( tfaItem.id ); 
							return tfaItem ;
						}) 
					);
				}else{
					setUnpaidTfaList( 
						res.data.data
						//.filter( (tfaItem) => { return !tfaItem.payId; } )
						.filter( (tfaItem) => { 
							return !tfaItem.payId || tfaItem.activeYear < currentYear; 
						} )
						.map( (tfaItem) => { tfaItem.isChosen = false; return tfaItem }) 
					);
				}
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting [TFA_BY_CFA] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch TFAs for this CFA.');
			}
			//setTfaContentLoading(false);
			//setTfaList( null );
			setUnpaidTfaList( null );
		});
	}
	
	const getCourseList = (userId, initDetails) => {
		//setCourseRegistration(null);

		const tokenInfo = DECODE_TOKEN();
		if (tokenInfo.code !== 'TOKEN_FOUND') {
			console.error('Did not find appropriate token.');
			return () => { };
		}

		get_ce_courses_and_cfa_credits(tokenInfo.getAuthToken, userId)
			.then((res) => {
				console.log('Retrieving CE courses and CFA CE Credit items.', res);
				//const [ courseList, cfaCredits ] = [
				//res[0].data.filter, res[1].data
				//];
				if (res[0].status !== 200 || res[1].status !== 200) {
					throw res;
				}
				if (res[0].data.code !== 'ACTIVE_COURSES' || res[1].data.code !== 'OK') {
					throw res;
				}

				//Remove CFA Exam
				const cfaExam = res[0].data.data.find((courseInfo) => {
					return courseInfo.title.toLowerCase() === 'cfa exam';
				});

				const [courseList, cfaCredits] = [
					//res[0].data.data.find( (courseInfo) => { return courseInfo.title.toLowerCase() === 'cfa exam'; } ),
					res[0].data.data.filter((courseInfo) => {
						return courseInfo.title.toLowerCase() !== 'cfa exam';
					}),
					res[1].data.credits.filter((courseInfo) => {
						return courseInfo.courseId !== cfaExam.courseId;
					}),
				];
				/*
							console.log('??????????', {
								cfaExam,
								courseList,
								cfaCredits
							})*/

				courseList.forEach((myCourseInfo) => {
					const myCfaCreditItem = cfaCredits.find((courseInfo) => {
						return courseInfo.courseId === myCourseInfo.courseId;
					});
					myCourseInfo.creditObj = myCfaCreditItem;
				});

				// I guess we should reverse sync....
				cfaCredits.forEach((myCreditItem) => {
					const myCourseItem = courseList.find((courseInfo) => {
						return courseInfo.courseId === myCreditItem.courseId;
					});
					myCreditItem.courseAvailable = (myCourseItem ? myCourseItem.beginDate : (
						myCreditItem.courseAvailable ? myCreditItem.courseAvailable : null
					));
				});


				if( initDetails.payPurpose === 'course' ) {
					//console.log('Let us find the selected course id...: ', courseList, myCart);
					setSelectedCourseId(cfaCredits.find( (unpaidItem) => {
						return unpaidItem.creditId === initDetails.cart.items[0].creditId;
					}));
				}
				
				setCourseList({
					upcoming: courseList,
					registered: cfaCredits //res[1].data.credits
				});

			})
			.catch(error => {

				console.log('Retrieving CE courses and CFA CE Credit items.', error);

				setCourseList(null);
			});
		}

	const GetCfaList = () => {

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		get_cfa_list(tokenInfo.getAuthToken)
		.then( (res) => {
			console.log('CFA CONTENT: ', res);

			const myUserList = {
				isLoading:false,
				hasError: false,
				list: [],
			};
			

			if( !res.data.code === 'ALL_CFAS' ) {
				throw new Error(res);
			}

			//Time to do big loopies
			res.data.data.forEach( (cfaObj) => {
				myUserList.list.push({
					...cfaObj,
					userType: 'cfa'
				});
			});

			SetCfaList(myUserList);
			console.log(myUserList);

		} )
		.catch( error => {
			console.error('Getting [User_list] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Users List or Admin User List.');
			}
			SetCfaList({
				isLoading:false,
				hasError: true,
				list: null
			});
		});


	}
	const handleFilterOnChange = event => {
		
		SetPayDetails(payDetails => ({
			...payDetails,
			userId: ''
		}));
		SetFilterCfaList(event.target.value);
	};

	useEffect( () => {
		if( payDetails.userId ) {
			//handle tfa refresh
			refreshTfas(payDetails.userId,props.initialPayDetails);

			//handle renewalOption refresh
			refreshRenewalOptions(payDetails.userId, cfaList.list,props.initialPayDetails);

			//handle course payments
			getCourseList(payDetails.userId,props.initialPayDetails);
		}else{
			//setTfaList([]);
			setUnpaidTfaList([]);
			setRenewalOptions(null);
			setCourseList({
				upcoming: [],
				registered: []
			});
		}
	}, [payDetails.userId,  cfaList.list, props.initialPayDetails]);

	useEffect( () => {
		GetCfaList();
	}, []);



	const [disablePaymentSubmitBtn, SetDisablePaymentSubmitBtn] = useState(false);
	
	const submitPaymentItem = (e) => {
		e.preventDefault();
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		SetDisablePaymentSubmitBtn(true);
		
		const myPayDetails = payDetails;

		myPayDetails.paid = ( payDetails.payMethod === 'adminWaived' ||  (payDetails.paid === true ||  payDetails.paid === 1) );

		const myItems = {items:[], comments: payDetails.payNotes};
		if( payDetails.payPurpose === 'exam' ) {
			myItems.items = [
				{"amount":payDetails.payAmount,"code":"Sdvb8dL","quantity":"1","title":"CFA Exam"}
			];
		}else if( payDetails.payPurpose === 'tfa' ) {
			const costPerTFA=40;
			const myTfas = unpaidTfaList.filter((unpaidTfaInfo) => { return unpaidTfaInfo.isChosen; })
				.map( (unpaidTfaInfo)  => {
					myItems.items.push({
						amount: costPerTFA,
						code:"Sdvb8dL",
						quantity:1,
						title: unpaidTfaInfo.lastName + ', ' + unpaidTfaInfo.firstName + ' (' + unpaidTfaInfo.id+ ')',
						tfaId: unpaidTfaInfo.id
					});
					return unpaidTfaInfo.id;
				});
			myPayDetails.myTfas = myTfas;
		}else if( payDetails.payPurpose === 'renewal' ) {
			const costPerRenew = 40.00;
			const myRenewalYears = renewalOptions.filter((renewalOptions) => { return renewalOptions.isPaid===false && renewalOptions.isChosen; })
			.map( (renewalOptions)  => {
				myItems.items.push({
					amount: costPerRenew,
					code:"Sdvb8dL",
					quantity:1,
					title: 'Renewal Year: ' + renewalOptions.year,
					year: renewalOptions.year
				});
				return renewalOptions.year;
			});
			myPayDetails.myRenewalYears = myRenewalYears;
		}else if( payDetails.payPurpose === 'course' ) {
			
			myItems.items = [
				{
					"courseId":selectedCourseId.courseId,
					"creditId":selectedCourseId.creditId,
					"amount":( selectedCourseId.course ? selectedCourseId.course.cost : -1 ),
				"code":"Sdvb8dL","quantity":"1","title": "CE Course: " + selectedCourseId.course.title}
			];
			myPayDetails.myCourseId = selectedCourseId.courseId;
			myPayDetails.myCreditId = selectedCourseId.creditId;
		}

		
		myPayDetails.cart = myItems;

		AUTO_admin_payment( tokenInfo.getAuthToken, myPayDetails)
			.then( (res) => {
				console.log('Automatic Payment details', res);
				SetDisablePaymentSubmitBtn(false);
				ConfirmUpdatePayment();
			} )
			.catch( error => {
				console.error('Updating payment [submitPayInfo] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add/update the payment for this CFA.');
				}
				SetDisablePaymentSubmitBtn(false);
			});

	}

	const getConfNumber = (type, length = 9) => {
		if( !type ) {
			return '';
		}

		let prepend='__';

		if( type==='exam' ) {
			prepend='CE';
		}else if( type==='tfa' ) {
			prepend='TF';
		}else if( type==='renewal' ) {
			prepend='RN';
		}else if( type==='course' ) {
			prepend='CC';
		}

		return prepend + Math.random().toString().substr(2, length);
	}
	
	const payForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		//console.log(e_target, );
		SetPayDetails(payDetails => ({
			...payDetails,
			[name]: value
		}));
	}
	const payForm_checkBox_HandleChange = (e_target) => {
		const { name, checked } = e_target;
		SetPayDetails(payDetails => ({
			...payDetails,
            [name]: (checked ? 1 : 0)
		}));
	}
	
	const toggleRenewalYearToCart = (year) => {
		let numberOfRenewals = 0;
		const myRenewalOptions = renewalOptions.map( 
			(renewalItem) => {
				if( renewalItem.isPaid ) {
					return renewalItem;
				}
				if( renewalItem.year === year ) {
					renewalItem.isChosen = !renewalItem.isChosen;
				}else{
					//If we toggle an earlier year, remove later options.
					if( year < renewalItem.year ) {
						renewalItem.isChosen=false;
					}
				}

				if( renewalItem.isChosen ) {
					numberOfRenewals++;
				}

				return renewalItem;
			}
		);
		setRenewalOptions(myRenewalOptions);
		SetPayDetails(payDetails => ({
			...payDetails,
            payAmount: (  40.00 * numberOfRenewals )
		}));

		return true;
	}
	const selectTfa = (unpaidTfaInfo) => {
		let myTfaCost = 0.00;
		
		const myUnpaidTfas = unpaidTfaList.map( (tfaInfo) => {
			if( tfaInfo.id === unpaidTfaInfo.id ) {
				tfaInfo.isChosen=!tfaInfo.isChosen;
			}

			if( tfaInfo.isChosen ) {
				myTfaCost+=40.00;
			}
			return tfaInfo;
		});
		setUnpaidTfaList(myUnpaidTfas);
		SetPayDetails(payDetails => ({
			...payDetails,
            payAmount: myTfaCost
		}));
	}

	const _PAY_PURPOSE_CONTROLS = () => { 

		//const myCart = payDetails.cart || JSON.parse(`{"items":[],"comments":"N/A"}`);
		//console.log('Current Cart: ', myCart);		

		if( payDetails.payPurpose === 'exam' ) {
			console.log('Exam chosen.');
			return (<></>);
		}else if( payDetails.payPurpose === 'tfa' ) {
			//we need to get unpaid tfas



			return (<>
				<div className="small-12 cell">
					<h4>Select Unpaid TFAs below: </h4>
					<div>
						<ul className="no-ul-bullet">
						{
							(unpaidTfaList.length < 1) ?
							<li> You have no TFAs you need to pay for this year! </li>
							:
							//onClick={(e) =>{ selectTheCeCourse( courseInfo ) }}>
							unpaidTfaList.map( (unpaidTfaInfo, key)  => {
								return (
									<li key={key}> 
										{
											unpaidTfaInfo.isChosen ?
											(
												<button type="button" className={
													"override-sebs button small success vertical-align-middle no-bottom-margin" 
												}
												onClick={(e) =>{ selectTfa( unpaidTfaInfo ) }}>
												<i className="fa fa-user-times"></i> Remove TFA</button> 
											) :  (
												<button type="button" className={
													"button small primary vertical-align-middle no-bottom-margin"
												}
												onClick={(e) =>{ selectTfa( unpaidTfaInfo ) }}>
												<i className="fa fa-user-plus"></i> Add TFA</button> 
											)
										} {unpaidTfaInfo.firstName} {unpaidTfaInfo.lastName}
									</li>
								);
							})
						}
						</ul>
					</div>
				</div>
			</>);
		}
		else if( payDetails.payPurpose === 'renewal' ) {
			if( !renewalOptions || renewalOptions.length < 1) {
				return (<>
					<div className="small-12 cell">
						<p> <strong>This user has not passed his or her exam yet and does not have a cycle started to approve years.</strong> </p>
					</div>
				</>);
			}
			const myRenewalButtons = [];
			let foundUnpaid = false;
			for(let i=0;i<renewalOptions.length;i++) {
				if( renewalOptions[i].isPaid ) {
					myRenewalButtons.push(<span>
						<i className="fa fa-certificate"></i> {renewalOptions[i].year} Paid!
					</span>);
				}else{
					if( !foundUnpaid ) {
						if( renewalOptions[i].isChosen === false ) {
							foundUnpaid=true;
							myRenewalButtons.push(<button type="button" className="override-sebs button small success vertical-align-middle no-bottom-margin"  
								onClick={(e) =>{ toggleRenewalYearToCart(renewalOptions[i].year) }}
							>
								<i className="fa fa-cart-plus"></i> Add {renewalOptions[i].year}  
							</button>);
						}else{
							myRenewalButtons.push(<button type="button" className="override-sebs button small alert vertical-align-middle no-bottom-margin"  
								onClick={(e) =>{ toggleRenewalYearToCart(renewalOptions[i].year) }}
							>
								<i className="fa fa-minus"></i> Remove {renewalOptions[i].year}  
							</button>);
						}
					}else{
						myRenewalButtons.push(<button type="button" 
							disabled={true}
							className="override-sebs button small success vertical-align-middle no-bottom-margin"  
						>
							<i className="fa fa-cart-plus"></i> Add {renewalOptions[i].year}  
						</button>);
					}

				}
			}
			
			return (<>
				<div className="small-12 cell">
					<ul className="no-ul-bullet">
					{
						//onClick={(e) =>{ selectTheCeCourse( courseInfo ) }}>
						myRenewalButtons.map( (renewalBtn, key)  => {
							return (
								<li key={key}> 
									{renewalBtn}
								</li>
							);
						})
					}
					</ul>
				</div>
			</>);
		}else if( payDetails.payPurpose === 'course' ) {
			//console.log(courseRegistration);
			//console.log(courseList);
			const myCourseList = courseList.registered.filter( (unpaidItem) => {
				return unpaidItem.payId === null && (
					unpaidItem.course ? 
					unpaidItem.course.cost !== 0
					: true
				);
			});

			return (<>
				<div className="small-12 cell">
					<p> Select a single course to apply credit to: </p>
					<ul>
					{   myCourseList.length < 1 ?
						<li> No courses available to be paid for.</li>
						:
						myCourseList.map(
							(unpaidItem, key) => {
								//console.log('Unpaid Item:', unpaidItem);
								return (
									<li key={key}> 
										{
											( selectedCourseId && unpaidItem.courseId === selectedCourseId.courseId ) ?
											(
												<button type="button" className={
													"override-sebs button small success vertical-align-middle no-bottom-margin" 
												}
												onClick={(e) =>{ 
												}}>
												<i className="fa fa-check"></i> Paying For This Course</button> 
											) :  (
												<button type="button" className={
													"button small primary vertical-align-middle no-bottom-margin"
												}
												onClick={(e) =>{ 
												//	selectTfa( unpaidTfaInfo )
													setSelectedCourseId(unpaidItem);
													SetPayDetails({
														...payDetails,
														payAmount: (unpaidItem.course ? unpaidItem.course.cost : 0 )
													}) 
												}}>
												<i className="fa fa-delete"></i> Choose this course </button> 
											)
										}
										

										{
										unpaidItem.course ?
										unpaidItem.course.cost.toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
										})
										: 'N/A'
										} {unpaidItem.course ? unpaidItem.course.title : 'N/A'}
									</li>
								);
							}
						)
					}
					</ul>
				</div>
			</>);
		}
		

		return (<></>);
	}


	const GetUserOptions = () => {

		if( cfaList.isLoading ) {
			return(<>
				<option value="">Loading List... </option>
			</>);
		}
		if( cfaList.hasError ) {
			return(<>
				<option value=""> ERROR </option>
			</>);
		}

		return(<>
			<option value="">Select a CFA</option>
			{
				cfaList.list
				.filter( (cfaApp) => {
					if( filterCfaList === '' ) { 
						return true;
					}
					const myFilterText = filterCfaList.toLocaleLowerCase().trim();
					return (
						('' + cfaApp.id.toString().padStart(6, '0')).toLocaleLowerCase().includes( myFilterText )
						|| (cfaApp.profactId ? cfaApp.profactId.toLocaleLowerCase().includes( myFilterText ) : false )
						
						|| cfaApp.lastName.toLocaleLowerCase().includes( myFilterText )
						|| cfaApp.firstName.toLocaleLowerCase().includes( myFilterText )
						|| cfaApp.email.toLocaleLowerCase().includes( myFilterText )
						|| cfaApp.businessName.toLocaleLowerCase().includes( myFilterText )
						|| ( cfaApp.businessAddress ?
							cfaApp.businessAddress.street.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.city.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.state.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.phone.toString().toLocaleLowerCase().includes( myFilterText )
							: false
						)
					);
				})
				.sort( ( a, b ) =>{
					return a.lastName.localeCompare(b.lastName);
				})
				.map( (cfaInfo, key) => { 
					return(
						<option value={cfaInfo.id} key={key}> {cfaInfo.lastName}, {cfaInfo.firstName} </option>
					)
				})
			}
		</>);
	}


	return (
		<>
		<div>
			<h3> Apply / Update Payment Details For a User </h3>
			<form action="" method="post" onSubmit={submitPaymentItem}>
				<div className="">
					{
						props.initialPayDetails.id ?
						(
							<div className="small-12 columns">
								<h4> 
									Please note that you are updating a payment. 
									While you can change who this payment is for, it is certainly not really recommended as it may be confusing for the person you are switching this payment from.
								</h4>
							</div>
						): null
					}
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="filterCfaList" className="label-style-1">Search for User</label>
						</div>
						<div className="medium-9 small-12 columns">
							<input type="text" value={filterCfaList} 
								name="filterCfaList" id="filterCfaList"
								placeholder="Search for ID, Name, Business, Email"
								onChange={handleFilterOnChange} />
						</div>
					</div>
					<div className="row">

						<div className="medium-3 small-12 columns">
							<label htmlFor="filterCfaList" className="label-style-1">Select CFA</label>
						</div>
						<div className="medium-9 small-12 columns">
							<select id="userId" name="userId" required="required"
								value={payDetails.userId}
								onChange={e => { 
									payForm_HandleChange(e.target)
								} }>
								{GetUserOptions()}
							</select>
						</div>
					</div>
					<div className="row">

						<div className="medium-3 small-12 columns">
							<label htmlFor="payPurpose" className="label-style-1">Payment Purpose</label>
						</div>
						<div className="medium-9 small-12 columns">
							<select id="payPurpose" name="payPurpose" value={payDetails.payPurpose}
								required="required"
								onChange={e => {
									if( !payDetails.id ) {
										SetPayDetails( {
											...payDetails,
											payConf: getConfNumber(e.target.value,9)
										});
									}
									payForm_HandleChange(e.target)
								}} 
							>
								<option value="">Select Payment Purpose</option>
								<option value="exam">Exam</option>
								<option value="tfa">TFAs</option>
								<option value="renewal">Renewal</option>
								<option value="course">Course</option>
							</select>
						</div>
					</div>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="payConf" className="label-style-1">Confirmation Number</label>
						</div>
						<div className="medium-9 small-12 columns">
							<input type="text" value={payDetails.payConf} 
								name="payConf" id="payConf"
								required="required"
								onChange={e => payForm_HandleChange(e.target)} />
						</div>
					</div>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="payMethod" className="label-style-1">Payment Method</label>
						</div>
						<div className="medium-9 small-12 columns">
							<select id="payMethod" name="payMethod" value={payDetails.payMethod}
								required="required"
								onChange={e => { payForm_HandleChange(e.target)} }>
								<option value="">Select Payment Method</option>
								<option value="po">PO </option>
								<option value="invoice">Invoice</option>
								<option value="check">Check</option>
								<option value="adminWaived">WAIVE PAYMENT</option>
								<option value="online">Online* </option>
							</select>
							<p style={{display: (payDetails.payMethod === 'online' ? 'block' : 'none' )}}><strong>*</strong> While this option ("Online") is available for you to select, it is mainly used for CFA electronic payments. Please try to use another option if available.</p>
						</div>
					</div>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="poNumber" className="label-style-1">PO Number</label>
						</div>
						<div className="medium-9 small-12 columns">
							<input type="text" value={payDetails.poNumber} 
								name="poNumber" id="poNumber"
								onChange={e => payForm_HandleChange(e.target)} />
						</div>
					</div>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="invoiceNumber" className="label-style-1">Invoice Number</label>
						</div>
						<div className="medium-9 small-12 columns">
							<input type="text" value={payDetails.invoiceNumber} 
								name="invoiceNumber" id="invoiceNumber"
								onChange={e => payForm_HandleChange(e.target)} />
						</div>
					</div>
					<div className="row">
					{_PAY_PURPOSE_CONTROLS()}
					</div>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="payAmount" className="label-style-1">Amount Paid</label>
						</div>
						<div className="medium-9 small-12 columns">
							<input type="text" value={payDetails.payAmount} 
								name="payAmount" id="payAmount"
								required="required"
								onChange={e => payForm_HandleChange(e.target)} />
						</div>
					</div>
					<div className="row">
						<div className="small-12 columns">
							<label htmlFor="paid" className="label-style-1">
								<input type="checkbox" 
									name="paid" id="paid" 
									checked={payDetails.paid}
									onChange={(e)=>{ payForm_checkBox_HandleChange(e.target); }}
								/>
								Approve Payment
							</label>
						</div>

						<div className="medium-12 small-12 columns">
							<label htmlFor="payNotes" className="label-style-1">Personal Notes</label>
							<p><em>Are there any notes you would like to attach to this payment?</em></p>
						</div>
						<div className="medium-12 small-12 columns">
							<textarea value={payDetails.payNotes}
								name="payNotes" id="payNotes"
								onChange={e => payForm_HandleChange(e.target)} ></textarea>
						</div>
					</div>
				</div>
				{
					payDetails.payMethod === 'adminWaived'
					? <div><p><strong><em>NOTE: WAIVING AN PAYMENT WILL AUTOMATICALLY APPROVE THIS PAYMENT. THIS ACTION CANNOT BE UNDONE.</em></strong></p></div>
					: null
				}
				<div>
					<button type="button" className="button"
					onClick={e => {CancelUpdatePayment()}} 
					>Cancel Payment Update</button> <button 
						disabled={disablePaymentSubmitBtn}
						type="submit" className="button"
					>Save Payment Details</button>
				</div>
			</form>		
		</div>


		</>
	)
};

export default PaymentForm;