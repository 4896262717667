import React from 'react';
import Config from '../Config';

import { Link } from 'react-router-dom';
import TFA_Fert_PPS from '../tfa-files/Trained Fertilizer Applicator Training.pps';
import TFA_Fert_PPS_ESP from '../tfa-files/TFA Training-Spanish June 21 2012.pps';

import TFA_Fert_PDF from '../tfa-files/Trained Fertilizer Applicator Training.pdf';
import TFA_Fert_PDF_ESP from '../tfa-files/TFA Training-Spanish June 21 2012.pdf';

import TFA_Training from '../tfa-files/Training Manual ver 2_17_2012.pdf';
import TFA_Training_ESP from '../tfa-files/spanish version Training Manual June 25 2012 Final.pdf';


const TFATrainingPage = ( props ) => {

	document.title = props.title;
	
	return (
		<main id="main-wrapper" className="">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content" className="">
						<div className="small-12 columns" style={{marginBottom:'1em'}}>
							<h1>Trained Fertilizer Applicators Program​​</h1>
						</div>
						<div id="primary-sub-col" className="small-12 large-8 columns">
							<h2>Training Instructions</h2>
							<p>Trained Fertilizer Applicators under the direct supervision of a Certified Fertilizer Applicator (CFA) may apply fertilizer to turf. Direc​​t supervision means that Certified Fertilizer Applicators shall provide written instructions regarding the application of fertilizer and maintain immediate contact with the Trained Fertilizer Applicator via radio or mobile phone (voice) communication.</p>
							<p>For any professional to become a Trained Fertilizer Applicator, the individual must:</p>
							<ol style={{marginLeft:'3em'}}>
								<li>Receive annual training on the laws, rules and regulations, standards and requirements applicable to the use and application of fertilizer. The standards for training are provided on the right-side of this page.</li>
								<li>CFA registers each Trained Fertilizer Applicator by logging in and going to the Status page.  CFA adds name and contact info for each TFA.  </li>
								<li>Pay the registration fee ($40).   At the time you make payment, you can print out the permanent certificate.</li>
								<li>CFA prints the certificate.    CFA prints out a certificate for each TFA, clicking a link beside each TFA's name on the Status page.   </li>
							</ol>
							<p>Once this process is complete, the individual's name and affiliation will appear in the <Link to="/trained-applicators">Trained Fertilizer Applicator list</Link> on the ProFACT website. </p>
						</div>
						<div id="secondary-sub-col" className="panel small-12 large-4 columns">
							<h2>Training Materials</h2>
							<p>The training material for Trained Fertilizer Applicators is provided below in three basic forms: a PowerPoint slideshow (pps), a printable (pdf) version of the slideshow, and a printable (pdf) manual. Any one of the forms can be used to complete the required training.</p>
							<ul>
								<li><a href={TFA_Fert_PPS} target="_blank" rel="noopener noreferrer">PowerPoint slide show (pps)</a></li>
								<li><a href={TFA_Fert_PPS_ESP} target="_blank" rel="noopener noreferrer">(En Español) PowerPoint slide show (pps)</a></li>
								<li><a href={TFA_Fert_PDF} target="_blank" rel="noopener noreferrer">PowerPoint printable version (pdf)</a> 42 pages</li>
								<li><a href={TFA_Fert_PDF_ESP} target="_blank" rel="noopener noreferrer">(En Español) PowerPoint printable version (pdf)</a> 42 pages</li>
								<li><a href={TFA_Training} target="_blank" rel="noopener noreferrer">Training Manual (pdf)</a> 15 pages</li>
								<li><a href={TFA_Training_ESP} target="_blank" rel="noopener noreferrer">(En Español) Training Manual (pdf)</a> 15 pages</li>
							</ul>
							<p>Certified Fertilizer Applicators are allowed to develop and use their own training material but it must be submitted to the ProFACT program for approval. Please send a copy of training materials to...</p>
							<p><strong>Email:</strong> <a href={`mailto:${Config.UNIT_EMAIL}`}>{Config.UNIT_EMAIL}</a></p>
							<p><strong>Mailing Address:</strong> </p>
							<div style={{paddingLeft:'2em'}}>
								<p className="no-bottom-margin">ProFACT </p>
								<p className="no-bottom-margin">Department of Plant Biology and Pathology </p>
								<p className="no-bottom-margin">Rutgers, The State University of New Jersey </p>
								<p className="no-bottom-margin">59 Dudley Road </p>
								<p className="no-bottom-margin">New Brunswick, NJ 08901-8520 </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default TFATrainingPage;