import React from 'react';
import { Link } from 'react-router-dom';


const NJAESMenu = (props) =>{
	

	/**
	 * Necessary because being a "Single Page Application", the menu typically gets reset when navigated away, 
	 * 	but since the menu doesn't automatically close, we kinda need to do it for them. Not a big deal.
	 */
	const closeMenuAfterClick = () => {
		//sebs
		//window.document.querySelector('i.fas.fa-bars').click();
		return true;
	}
	const onTouchEvents = (e) => {
		//console.log('Touch event test', e);
		//console.log(e.target);
		e.target.click();
		return true;
	};

	//Conditional rendering:
	let myUserMenu = <></>;
	if( props.UserState.loggedIn ) {
		//Change the type of menu by user type here.
		if( props.UserState.user.userType === 'A' ) {
			//Admin
			myUserMenu = (<>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/dashboard" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-tachometer-alt"></i> Dashboard </Link></li>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/manage-users" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-users"></i> Manage Users </Link></li>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/manage-user-payments" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-credit-card"></i> Manage Payments </Link></li>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/manage-ce-credits" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-feather-alt"></i> Apply CE Credits </Link></li>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/manage-courses" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-book"></i> Manage Courses </Link></li>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/profile" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-id-card"></i> <strong>Your</strong> Profile </Link></li>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/logout" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}> Logout </Link></li>
			</>);
		}else if( props.UserState.user.userType === 'C' ) {
			//CFA
			myUserMenu = (<>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/dashboard" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-tachometer-alt"></i>  Dashboard </Link></li>
				{( props.UserState.user.trainingCompleted ? <li className="is-submenu-item is-dropdown-submenu-item" role="menuitem">
						<Link to="/payments" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}>  
					<i className="fas fa-money-bill-wave"></i> Payments (Test, Renewal, TFAs, CE) </Link></li> : <></>)}
				{( props.UserState.user.examPaid ? <li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/exams" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-pencil-ruler"></i>  CFA Exam </Link></li> : <></>)}
				{( props.UserState.user.examPassed ? <li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/cfa-tfa" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-users"></i> Your TFAs </Link></li> : <></>)}
				{( props.UserState.user.examPassed ? <li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/ce-training" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-book"></i> CE Courses </Link></li> : <></>)}
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/profile" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-id-card"></i> Profile </Link></li>
				<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/logout" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-sign-out-alt"></i> Logout </Link></li>
			</>);
		}else{
			//Unknown
			
			myUserMenu = (<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/logout" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-sign-out-alt"></i> Logout </Link></li>);
		}
	}else{
		myUserMenu = (<>
			<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/register" onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-clipboard"></i>  Register </Link></li>
			<li className="is-submenu-item is-dropdown-submenu-item" role="menuitem"><Link to="/login" onClick={(e) => { closeMenuAfterClick() }}><i className="fas fa-sign-in-alt"></i> Login </Link></li>
			
		</>);
		//<li><Link to="/template" onClick={(e) => { closeMenuAfterClick() }}> Menu Item With a Long Title (Big Template) </Link></li>
	}

	const GetLogInItems = (	<>
		<li><Link to="/register" onClick={(e) => { closeMenuAfterClick() }}> Register </Link></li>
		<li><Link to="/login" onClick={(e) => { closeMenuAfterClick() }}> Login </Link></li>
		</>
	);
	/*
	const SET_MENU = !props.UserState.loggedIn ?
	GetLogInItems
	: <li className="has-submenu" style={{
		backgroundColor:'gray',
		paddingRight:'1em' 
	}}> 
		<Link to={( props.UserState.loggedIn ? "/dashboard" : '/login' )}>
			Portal
		</Link>
		<ul className="menu vertical">
				{myUserMenu}
		</ul>
	</li>;
	*/
		//!props.UserState.loggedIn ?


	return (
		<>

			<div id="site-nav-wrapper" className="contain-to-grid">
				<nav id="site-nav" className="top-bar">
				<ul id="site-nav-main" className="dropdown vertical large-horizontal menu" data-responsive-menu="accordion large-dropdown">
					<li>
						<Link to="/" onClick={(e) => { closeMenuAfterClick() }}> Home </Link>
					</li>
					<li>
						<Link to="/background" onClick={(e) => { closeMenuAfterClick() }}> Background </Link>
					</li>
					<li className="has-submenu"> 
						<Link to="/cfa-instructions">
							Certified Applicators 
						</Link>
						<ul className="menu vertical">
							<li><Link to="/cfa-instructions" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}> Instructions </Link></li>
							<li><Link to="/cfa-training" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}> Training </Link></li>
							<li><Link to="/certified-applicators" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}> Certified Applicators </Link></li>
						</ul>
					</li>	
					<li className="has-submenu"> 
						<Link to="/tfa-instructions">
							Trained Applicators
						</Link>
						<ul className="menu vertical">
							<li><Link to="/tfa-instructions" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}> Instructions </Link></li>
							<li><Link to="/tfa-training" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}> TFA Program </Link></li>
							<li><Link to="/trained-applicators" onTouchStart={(e)=>{onTouchEvents(e)}} onClick={(e) => { closeMenuAfterClick() }}> Trained Applicators </Link></li>
						</ul>
					</li>	
					<li>
						<Link to="/contact-us" onClick={(e) => { closeMenuAfterClick() }}> Contact Us </Link>
					</li>	
					{(props.UserState.loggedIn ? null: GetLogInItems)}
					<li className="has-submenu help-table-cell" style={{
						//display: (props.UserState.loggedIn ? 'inherit': 'none' ),
						visibility: (props.UserState.loggedIn ? 'visible': 'hidden' ),
						backgroundColor:'gray',
						paddingRight:'1em' 
					}}> 
						<Link to={( props.UserState.loggedIn ? "/dashboard" : '/login' )}>
							Portal
						</Link>
						<ul className="menu vertical">
							{myUserMenu}
						</ul>
					</li>



					
				</ul>
				</nav>
			</div>
		</>
	);

	
}

export default NJAESMenu;

/*

					<li className="has-submenu"> <a href="https://njaes.rutgers.edu/about/">About</a>
					<ul className="menu vertical">
						<li><a href="https://njaes.rutgers.edu/administration/">Administration</a></li>
						<li><a href="https://njaes.rutgers.edu/centers/">Centers, Institutes &amp; Farms</a></li>
						<li><a href="https://njaes.rutgers.edu/economic-development/">Economic Development</a></li>
						<li><a href="https://events.rutgers.edu/njaes/">Events</a></li>
						<li><a href="https://njaes.rutgers.edu/jobs/">Job Opportunities</a></li>
						<li><a href="http://sebsnjaesnews.rutgers.edu/category/njaes/">NJAES News</a></li>
						<li><a href="https://njaes.rutgers.edu/pubs/">Publications</a></li>
						<li><a href="https://rah.rutgers.edu/">Rutgers Against Hunger</a></li>
						<li><a href="http://rutgersgardens.rutgers.edu/">Rutgers Gardens</a></li>
						<li><a href="https://njaes.rutgers.edu/support-units/">Support Units</a></li>
						<li><a href="https://urbanextension.rutgers.edu/">Urban Extension and Engagement</a></li>
						<li><a href="https://njaes.rutgers.edu/volunteer/">Volunteer with RCE</a></li>
					</ul>
					</li>
		<nav id="side-nav" className="large-2 columns">
			<h2>ProFACT Menu</h2>
			<ol className="side-nav main-menu">
				<li><a href="index.html">Home</a></li>
				<li><a href="index.html">Sample Homepage 1</a></li>
				<li><a href="index2.html">Sample Homepage 2</a></li>
				<li><a href="index3.html">Sample Homepage 3</a></li>
				<li><a href="interior.html">Sample Page 1</a></li>
				<li><a href="interior2.html">Sample Page 2</a></li>
				<li><a href="interior3.html">Sample Contact Page</a></li>
			</ol>
		</nav>

		<div id="nav-bar-wrapper" className="grid-container full">
			<nav id="nav-bar" className="grid-x">
				<div id="small-nav-bar" className="title-bar" data-responsive-toggle="nav-menu-wrapper" data-hide-for="large">
					<i className="fas fa-bars" data-toggle="nav-menu-wrapper"><span className="vh">Menu</span></i>
				</div>
				<div id="nav-menu-wrapper" className="cell">
					<ol id="nav-menu" className="nav-menu vertical large-horizontal menu" data-responsive-menu="drilldown large-dropdown" data-parent-link="true">
						<li>
							<Link to="/" onClick={(e) => { closeMenuAfterClick() }}> Home </Link>
						</li>
						<li>
							<Link to="/background" onClick={(e) => { closeMenuAfterClick() }}> Background </Link>
						</li>
						<li>
							<Link to="/cfa-instructions">
								<span className="main-menu-item">Certified Applicators </span>
								<i className="fas fa-angle-down"><span className="vh">Down Arrow</span>
								</i>
							</Link>
							<ul className="menu vertical nested">
								<li><Link to="/cfa-instructions" onClick={(e) => { closeMenuAfterClick() }}> Instructions </Link></li>
								<li><Link to="/cfa-training" onClick={(e) => { closeMenuAfterClick() }}> Training </Link></li>
								<li><Link to="/certified-applicators" onClick={(e) => { closeMenuAfterClick() }}> Certified Applicators </Link></li>
							</ul>
						</li>
						<li>
							<Link to="/tfa-instructions"><span className="main-menu-item">Trained Applicators</span><i className="fas fa-angle-down"><span className="vh">Down Arrow</span></i></Link>
							<ul className="menu vertical nested">
								<li><Link to="/tfa-instructions" onClick={(e) => { closeMenuAfterClick() }}> Instructions </Link></li>
								<li><Link to="/tfa-training" onClick={(e) => { closeMenuAfterClick() }}> TFA Program </Link></li>
								<li><Link to="/trained-applicators" onClick={(e) => { closeMenuAfterClick() }}> Trained Applicators </Link></li>
							</ul>
						</li>	
						<li>
							<Link to="/contact-us" onClick={(e) => { closeMenuAfterClick() }}> Contact Us </Link>
						</li>	
						{(
							!props.UserState.loggedIn ?
							GetLogInItems
							: <></>
						)}					
						<li style={{
							display: (props.UserState.loggedIn ? 'inherit': 'none'),
							backgroundColor:'lightgray' 
						}}>
							<Link to={( props.UserState.loggedIn ? "/dashboard" : '/login' )}>
								<span className="main-menu-item">Portal</span>
								<i className="fas fa-angle-down"><span className="vh">Down Arrow</span></i>
							</Link>
							<ul className="menu vertical nested">
								{myUserMenu}
							</ul>
						</li>
					</ol>
				</div>
			</nav>
		</div>
*/