import React from 'react';
import stateList from './state';
import njCountiesList from './njCounties';

const ProfactSelect = ( props ) => {

	if( props.StateDropdown === true ) {
		return ( 
			<select id={props.id} name={props.name} value={props.value}
				required={props.required}
				onChange={props.onChange}
				>
				{
					props.IncludeBlank 
					? <option value="">-Select A State-</option>
					: <></>
				}	
				{
					stateList
					.map( (stateInfo, key)  => {
						return (
							<option value={stateInfo.abbreviation} 
								key={key}>{stateInfo.abbreviation} - {stateInfo.name} </option>
						)
					})
				}
			</select>

		);
	}else if( props.NjCountyDropdown === true ) {
		let useValue = props.value || '';

		//Find potentially
		//console.log('???', useValue);
		let findCounty = njCountiesList.find( (countyItem) => { return useValue && countyItem.COUNTY.toLocaleLowerCase() === useValue.toLocaleLowerCase() } );
		if( findCounty ) {
			useValue = findCounty.COUNTY_TITLECASE;
		}

		return ( 
			<select id={props.id} name={props.name} value={useValue}
				required={props.required}
				onChange={props.onChange}
				>
				<option value="">-Select A NJ County-</option>
				{
					njCountiesList
					.map( (countyInfo, key)  => {
						return (
							<option value={countyInfo.COUNTY_TITLECASE} 
								key={key}>{countyInfo.COUNTY_TITLECASE} </option>
						)
					})
				}
			</select>

		);
	}


	return (<select id={props.id} name={props.name} value={props.value}
		onChange={props.onChange}>
			<option value="">Not Initialized</option>
			</select>);



}



export default ProfactSelect;