import React from 'react';

import { Link } from 'react-router-dom';
import Config from '../Config';


class CFAIntroductionPage extends React.Component {

	constructor( props ) {
		super(props);
		document.title = props.title;
	}

	render() {
		return (
			<main id="main-wrapper" className="">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content" className="">
							<div className="small-12 columns">
								<h1>Instructions</h1>
								<h2>Certified Fertilizer Applicators</h2>

								<p>
									To become a Certified Fertilizer Applicator, you must start by <Link to="/register">registering</Link> on this site. 
									Click the <Link to="/register">Register</Link> menu item under the Certified Applicators tab at the top of the page to begin registration. 
									You will receive a confirmation email that confirms your registration (user ID and password). 
									Please keep a record of your user name and password, since you will need these to successfully complete the certification process.
								</p>
								<p>
									To receive full credit for the self-paced online training and qualify for the exam, you will need to view each of the section topic pages. 
									Details of how to view the training material are outlined below. 
									Once you have viewed every section in the training material, see the <Link to="/dashboard">dashboard page</Link> for taking the certification exam.
								</p>
							</div>
							<div className="small-12 columns">
								<ol>
									<li>To start your self-paced training AFTER you have logged in with your user ID and password, click on the Training Material menu item on the top of the page, under the Certified Applicators tab. This will open an outline view of the training material. The training material is organized into 5 subject areas or modules; each module is further organized by section topics.</li>
									<li>Click on the 1st section in Module 1 to start viewing the <Link to="/cfa-training">training material</Link>.</li>
									<li>Study the material in each section. Please note, the certification exam will have one question that pertains to the material in each of these section topics.</li>
									<li>At the bottom of each section page, there are three buttons to assist you in navigating through the training material:  <strong>Previous Section</strong>, <strong>Next Section</strong>, and <strong>Return to the Table of Contents</strong>. You must click "Next Section" or 
											"Finish" (last page only) to indicate that you have viewed the page. The website will automatically track viewing progress for each user through the training material. You may end a viewing session at any time.</li>
									<li>Once you have finished studying every section of the training material (clicked on Next Section or Finish at the bottom of each section topic page), you will be eligible to take the certification exam. 
										Check the <Link to="/dashboard">dashboard page</Link> to see the next steps for taking the certification examination.</li>
								</ol>
							</div>
							<div className="small-12 columns">
								<p>
									As mentioned, this training material is designed to be self-paced. 
									The web site will track your progress, so you are welcome to exit and re-enter the training material at your choice. 
									The website tracks your progress in viewing the training material. 
									Your progress can be viewed on the Table of Contents page when you are logged in. 
									You will see a checked box in front of the number of any section you have read.
								</p>
								<p>
									It may take 24 hours for the program to update your training status as completed. 
									Please contact {Config.UNIT_EMAIL} if you your training status has not been updated after 24 hours.</p>
								<p>Once training is completed, you can take the <strong>certification exam</strong>.</p>
							</div>
							<div className=" small-12 columns">
								<ol>
									<li>Go to the dasbhoard page to verify that you have completed the material. Then click where indicated to pay for your exam. </li>
									<li>The link takes you to a secure third party payment processor for credit card payment. For purchase orders, please contact​ our ProFACT office at 848-932-6373 to make arrangements. </li>
									<li>Once you have paid for the exam ($90), you will find a link to the exam on the <Link to="/dashboard">dashboard page</Link>. </li>
									<li>Detailed instructions for taking the exam will be provided before you enter the exam. Some general information about the exam follows. Once you enter the exam you will have 1 hour and 40 minutes to answer 50 multiple choice questions. Questions are presented to you one at a time and you will have the option to select questions that you would like to review a second time at the end of the exam. Once you are done with the exam you will immediately receive your score (percentage of correct answers). A score of 75% or more automatically enters your name and affiliate information into the Certified Fertilizer Applicator list on the website (see Certified Applicators in the red navigation bar at the top of the web site). </li>
									<li>If necessary, you may re-take the exam. The exam will be available for a re-take 24 hours after the initial exam. You will not need to pay for re-take. </li>
								</ol>
							</div>
							<div className=" small-12 columns">
								<p>Once you successfully complete the exam, you will find a link on the <Link to="/dashboard">dashboard page</Link> that takes you to your personal certificate card. You can print this and carry it with you. Store it among other business records that you carry on the job, or cut it out and laminate to carry in your wallet.</p>
								<p>The certificate serves as verification that an individual is compliant with the certification requirement in the fertilizer law. Each certificate will have a QR code that is unique to an individual Certified Fertilizer Applicator. The QR code can be scanned with smartphone app that will open that individual's Status page on the website. This certificate along with the public listing on the website are the two methods that will be used to verify an applicator's status in the program.</p>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}

}

export default CFAIntroductionPage;