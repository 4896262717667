import React, {
	//useState, useEffect
} from 'react';
import { Link
} from 'react-router-dom';

import ProcessPayment from './Components/PayComponents/ProcessPayment';
import ProcessPaymentFailed from './Components/PayComponents/ProcessPaymentFailed';
import Receipt from './Components/PayComponents/Receipt';


const CFAPaymentProcessingPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	//console.log('Process type: ', props.match.params.processType );

	if( props.match.params.processType === 'in-progress' ){

		return (
		
			<main id="main-wrapper" className=" ">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content">
							<div>
								<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
								<hr className="slim-hr" />
							</div>
							<h2> Payment </h2>
							<ProcessPayment paymentId={props.match.params.paymentId} paymentToken={props.match.params.paymentToken} />
						</div>
					</div>
				</div>
			</main>
		);
	}else if( props.match.params.processType === 'failed' ){

		return (
		
			<main id="main-wrapper" className=" ">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content">
							<div>
								<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
								<hr className="slim-hr" />
							</div>
							<h2> Payment </h2>
							<ProcessPaymentFailed paymentId={props.match.params.paymentId} paymentToken={props.match.params.paymentToken} />
						</div>
					</div>
				</div>
			</main>
		);
	}else if( 
		props.match.params.processType === 'success' 
		|| props.match.params.processType === 'receipt' 
	){
		return (
			<main id="main-wrapper" className=" ">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content">
							<div>
								<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
								<hr className="slim-hr" />
							</div>
							<h2> Payment </h2>
							<Receipt paymentId={props.match.params.paymentId} confNumber={props.match.params.paymentToken} />
						</div>
					</div>
				</div>
			</main>
		);
	}

	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<div>
							<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
							<hr className="slim-hr" />
						</div>
						<h2> Payment </h2>
						<p> Unknown payment type. This may be an error. </p>
					</div>
				</div>
			</div>
		</main>
	);
	// <!--<button type="submit" className="button primary">Submit To new window</button>-->
}
export default CFAPaymentProcessingPage;