import React from 'react';
import { Link } from 'react-router-dom';

import BreakingNews from './Components/BreakingNews';

import USER_GUIDE from '../user-guides/Profact_User_Walkthrough.pdf';
//import newsItems from  '../Api/news-items.js';

const HomePage = (props) => {
//class HomePage extends React.Component {
	document.title = props.title;
	/*
	constructor( props ) {
		super(props);
		document.title = props.title;
	}*/

	//May want to tell them they're done with recording their progress
	/*
	
										<div className={"callout warning"} style={{display:'block'}} data-closable>
											<h4 style={{fontSize:'x-large',fontWeight:'bold'}}>Notice: Scheduled Maintenance</h4>
											<p><strong>(Update 1/10)</strong> Maintenance was postponed due to weather.</p>
											<p>We are performing maintenance on the ProFACT website on <strong>Wednesday, January 17th</strong> between <strong>9 AM and 12 PM</strong>.</p>
											<p> The site will be inaccessible to logins on Wednesday morning.</p>
											<p>We apologize for the inconvenience.</p>
										</div>
	*/
		return (
			<main id="main-wrapper" className="">
				<div id="main" className="">
					<div id="main-col" className="">
						<div id="content" className="">
							<div id="primary-sub-col" className="small-12 large-8 columns">
								<h1>Professional Fertilizer Applicator Certification and Training </h1>
								<p>Welcome to the Professional Fertilizer Applicator Certification and Training (ProFACT) website!</p>

								<p>On January 5, 2011 the state of New Jersey enacted New Jersey Act P.L. 2010, c. 112 (C.58:10A-64), which establishes standards for fertilizer applications to turf, requires certification of professional fertilizer applicators, and regulates labeling and sale of specialty fertilizers for turf. </p>
								<p>ProFACT provides online training and testing for professional fertilizer applicators seeking to become either certified or trained. The training and testing materials were developed by the Rutgers New Jersey Agricultural Experiment Station in consultation with the NJ Department of Environmental Protection and other stakeholders. </p>
								<p>Anyone interested in fertilizers used for turf is welcome to view the training material (no fee required). Simply click on the Table of Contents tab above to start viewing the training material. If you want to view the training material and receive credit for online certification training, please follow the instructions to the right under Quick Start Guide to Certification.​​​​ </p>
							</div>

							<div id="secondary-sub-col" className="small-12 large-4 columns">
								<div className="panel">
									<h2>Quick Start Guide to Certification</h2>
									<ol>
										<li>Read the <Link to="/cfa-instructions">instructions</Link></li>
										<li>Register for an <Link to="/register">account</Link></li>
										<li><Link to="/login">Login</Link> to record your progress</li>
										<li>Read all the <Link to="/cfa-training">training material</Link></li>
										<li>Take the test</li>
									</ol>
									<p>Registered Applicators can view their progress and certification status on the <Link to="/dashboard">dashboard page</Link>.</p>
									<p>
										You can download the <a href={USER_GUIDE} target="_blank" rel="noopener noreferrer">ProFACT User Guide</a> as well to get familiar with the system.
									</p>
								</div>
								<BreakingNews />
							</div>
						</div>
					</div>
				</div>
			</main>
		);

}
/*
//SEBS
		return (
			<main id="main-wrapper" className="grid-container full">
				<div id="main" className="grid-x grid-margin-x">
					<div id="main-col" className="cell">
						<div id="content" className="grid-x grid-margin-x">
							<div id="primary-sub-col" className="cell small-12 large-8">
								<h1>Professional Fertilizer Applicator ​Certification and Training </h1>
								<p>Welcome to the Professional Fertilizer Applicator Certification and Training (ProFACT) website!</p>

								<p>On January 5, 2011 the state of New Jersey enacted New Jersey Act P.L. 2010, c. 112 (C.58:10A-64), which establishes standards for fertilizer applications to turf, requires certification of professional fertilizer applicators, and regulates labeling and sale of specialty fertilizers for turf. </p>
								<p>ProFACT provides online training and testing for professional fertilizer applicators seeking to become either certified or trained. The training and testing materials were developed by the Rutgers New Jersey Agricultural Experiment Station in consultation with the NJ Department of Environmental Protection and other stakeholders. </p>
								<p>Anyone interested in fertilizers used for turf is welcome to view the training material (no fee required). Simply click on the Table of Contents tab above to start viewing the training material. If you want to view the training material and receive credit for online certification training, please follow the instructions to the right under Quick Start Guide to Certification.​​​​ </p>
							</div>

							<div id="secondary-sub-col" className="cell small-12 large-4">
								<div className="callout">
									<h2>Quick Star​t Guide to Certification</h2>
									<ol>
										<li>Read the <Link to="/cfa-instructions">instructions</Link></li>
										<li>Register for an <Link to="/register">account</Link></li>
										<li><Link to="/login">Login</Link> to record your progress</li>
										<li>Read all the <Link to="/cfa-training">training material</Link></li>
										<li>Take the test</li>
									</ol>
									<p>Registered Applicators can view their progress and certification status on the <Link to="/dashboard">dashboard page</Link>.</p>
								</div>
								<BreakingNews />
							</div>
						</div>
					</div>
				</div>
			</main>
		);
*/		

export default HomePage;