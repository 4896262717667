import React, {useState, useEffect} from "react";

//import Config from '../Config';
//import axios from 'axios';
//import {get_cfa_training_modules} from '../Api/api';

//import CFA_ENG_PDF from '../cfa-files/ProFACT_Training_Manual.pdf';
//import CFA_ESP_PPT from '../cfa-files/PPT-ProFACT-Training-Spanish-translated-by-ARSP-FINAL.pptx';
//import CFA_ESP_DOC from '../cfa-files/Spanish ProFACT Training Manual-translatededited-byARSP-FINAL (clean version).docx';
import { Link
	//, useLocation 
} from 'react-router-dom';
import parse from 'html-react-parser';
import { 
	//get_cfa_training_modules,
	get_cfa_specific_training_content,
	get_cfa_single_module,
	DECODE_TOKEN
} from '../Api/api';
//import axios from "axios";

const CFATrainingContentPage = ( props ) => {
//class CFATrainingPage extends React.Component {

	document.title = props.title; //( props.match.params.courseId ? 'Continuing Education Content' : props.title );
	//const location = useLocation();
	
	const [pageIsloading, SetPageIsLoading] = useState(true);

	const [moduleInformation, SelectModuleInformation] = useState({
		allInformation: [],
		useInformation: {
			contentId: null, 
			moduleTitle: 'Module Header',
			module: 'X',
			section: 'X',
			sectionTitle: 'Sectional Header',
			content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
		}
	});

	useEffect(() => {
		
		window.scrollTo(0, 0);

		let getAuthToken = null;
		let getId = null;
		try{ 
				
			const tokenInfo = DECODE_TOKEN();
			if( tokenInfo.code !== 'TOKEN_FOUND' ) {
				console.error('Did not find appropriate token.');
				return false;
			}

			//console.log(tokenInfo);
			getAuthToken = tokenInfo.getAuthToken;
			getId = tokenInfo.getId;

			//getAuthToken = localStorage.getItem('profact-token');
			//getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
			
			//console.log('GET ID: ', tokenInfo.getId);

			console.log('Getting training Modules');
			//get_cfa_training_modules(getAuthToken)
			get_cfa_single_module(getAuthToken, props.match.params.courseId)
			.then( (res) => {
				//console.log('Step 1', res);
				const thisModulesTraining = res.data.data.filter( (courseInfo) => { return courseInfo.courseId === Number(props.match.params.courseId); } );
				const findSpecificItemForId = res.data.data.find( (content) => { return (
					content.courseId === Number(props.match.params.courseId)
					&& content.module === Number(props.match.params.module)
					&& content.section === Number(props.match.params.section)
				) } );
				//console.log('AAAAAAAAAAAAAA', thisModulesTraining,findSpecificItemForId);
				if( tokenInfo.getId.admin === false ) {

					get_cfa_specific_training_content(
						getAuthToken, 
						getId,
						findSpecificItemForId.courseId, 
						findSpecificItemForId.id
					)
					.then( (res2) => {
						///console.log('BBBBBBBBBBBBB');
						//console.log('Step 2', res2);
	
						SetPageIsLoading(false);
						SelectModuleInformation(
							{
								allInformation: thisModulesTraining,
								useInformation: res2.data.contentData
							}
						)
					} )
					.catch( error => {
						///console.log('ERRRRR BBBBBBBBBBBBB');
						if( error.statusCode && error.statusCode === 500) {
							console.error('Could not fetch CFA Training Content items. ', error);
						}
						SetPageIsLoading(false);
						SelectModuleInformation(null);
					});
	
				}else{
					SetPageIsLoading(false);
					SelectModuleInformation(
						{
							allInformation: thisModulesTraining,
							useInformation: findSpecificItemForId
						}
					)
				}
			} )
			.catch( error => {
				console.log('Could not get training content? ');
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch CFA Training Content items. ', error);
				}
				SetPageIsLoading(false);
				SelectModuleInformation(null);
			});
	

			/*
			
			axios.all([
				get_cfa_training_modules(getAuthToken),
				get_cfa_specific_training_content(
					getAuthToken, 
					getId,
					props.match.params.courseId, 
					props.match.params.contentId
				)
			])
			.then( (res) => {
				console.log('Retrieving CFA Online Training Manual Content', res);
				
				if( res[0].status !== 200 || res[1].status !== 200 ) {
					throw res;
				}

				if( res[0].data.data && res[1].data.contentData ) {

					SetPageIsLoading(false);
					SelectModuleInformation(
						{
							allInformation: res[0].data.data,
							useInformation: res[1].data.contentData
						}
					)
					
				}
			} )
			.catch( error => {
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch CFA Training Content items. ', error);
				}
				SetPageIsLoading(false);
				SelectModuleInformation(null);
			});
			*/
		}catch(loginStatusErr) {
			
			console.warn('Problematic Login token. Clearing. ');
			localStorage.removeItem('profact-token');
			props.SetUserState({
				loggedIn: false,
				refreshData: false,
				//preferredLanguage: 'en-US',
				preferredLanguage: 'en-US',
				user:null
			});
			
		}
		
		return () => {
			//on close.
			const myUser = props.UserState;
			if(myUser.loggedIn && myUser.lastRefreshPage !== window.location.pathname){
				return () => {
					//on close.
					myUser.lastRefreshPage = window.location.pathname;
					props.SetUserState(myUser);
				}
			}
		}
	}, [props]);

	const getButtonLinks = (prevContent, nextContent) => {
		//console.log(props);
		if( props.location.pathname.includes('ce-training') ) {
			return (
				
				<div className="button-group">
				{
					prevContent ?
					<Link to={`/ce-training/${prevContent.courseId}/${prevContent.module}/${prevContent.section}`} className="button  primary"><i className="fas fa-carat-left"></i> Previous Section</Link> 
					: <button type="button" className="button medium primary" disabled="disabled"><i className="fas fa-carat-left"></i> Previous Section</button> 
				}

				<Link to={`/ce-training/${moduleInformation.useInformation.courseId}`} className="button  primary"> Table of Contents</Link> 
				
				{
					nextContent ?
					<Link to={`/ce-training/${nextContent.courseId}/${nextContent.module}/${nextContent.section}`} 
						className=" button  primary">Next Section <i className="fas fa-carat-right"></i></Link> 
					: <Link to={`/ce-training/${moduleInformation.useInformation.courseId}`} className=" button  primary"> Finish <i className="fas fa-carat-right"></i></Link> 
				}
			</div>
			);
		}


		return (
			
			<div className="button-group">
			{
				prevContent ?
				<Link to={`/cfa-training/${prevContent.courseId}/${prevContent.module}/${prevContent.section}`} className="button  primary"><i className="fas fa-carat-left"></i> Previous Section</Link> 
				: <button type="button" className="button  primary" disabled="disabled"><i className="fas fa-carat-left"></i> Previous Section</button> 
			}

			<Link to={`/cfa-training/`} className="button  primary"> Table of Contents</Link> 
			
			{
				nextContent ?
				<Link to={`/cfa-training/${nextContent.courseId}/${nextContent.module}/${nextContent.section}`} className=" button  primary">Next Section <i className="fas fa-carat-right"></i></Link> 
				: <Link to={`/cfa-training/`} className=" button  primary"> Finish <i className="fas fa-carat-right"></i></Link> 
			}
		</div>
		);

	}


	const WriteManualPage = () => {
		if( pageIsloading ) {
			console.log('Page is loading: ', moduleInformation);
			return (<>
				<p><i className="fas fa-circle-notch fa-spin"></i></p>
			</>);
		}

		if( !moduleInformation || !moduleInformation.allInformation|| !moduleInformation.useInformation ) {
			return (
				<div className="callout alert">
				  <h5>We're sorry</h5>
				  <p>There was a problem fetching the live online manual for you. Please refresh the page and try again.</p>
				</div>
			);
		}

		let prevContent = null;
		let nextContent = null;
		//console.log('ALL MODULE INFO', moduleInformation);
		//for( let i in moduleInformation.allInformation ){
		for( let i=0;i<moduleInformation.allInformation.length;i++) {
			if( (
				moduleInformation.allInformation[i].courseId === Number(props.match.params.courseId)
				&& moduleInformation.allInformation[i].module === Number(props.match.params.module)
				&& moduleInformation.allInformation[i].section === Number(props.match.params.section)
			) ){
			//moduleInformation.allInformation[i].id === Number.parseInt(props.match.params.contentId)) {
				//console.log('Checking i: ', i, (i>0), (i<moduleInformation.allInformation.length-1))
				if( i > 0  ) {
					prevContent = moduleInformation.allInformation[i-1];
				}
				if( i < moduleInformation.allInformation.length - 1 ) {
					//console.log('CHECKING i????', (i+1), moduleInformation.allInformation[i], moduleInformation.allInformation[(i+1)]);
					nextContent = moduleInformation.allInformation[i+1];
				}
			}
		}

		//console.log(moduleInformation, prevContent, nextContent);


		return (
			<>
				<h2>Module {moduleInformation.useInformation.module}: {moduleInformation.useInformation.moduleTitle}</h2>
				<h3>Section {moduleInformation.useInformation.section}: {moduleInformation.useInformation.sectionTitle}</h3>
				<div>
					{parse(moduleInformation.useInformation.content)}
				</div>
				{getButtonLinks(prevContent, nextContent)}
			</>
		);
	};

	return (
		<main id="main-wrapper" className="">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<div className="small-12 columns">
							<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
							<hr className="slim-hr" />
						</div>
						<div className="small-12 columns">
							<h1>Certified Fertilizer Applicator Online Training Manual</h1>
							{WriteManualPage()}
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default CFATrainingContentPage;