import React
, {useState
	,useEffect
	//, useRef
} 
from 'react';

import Config from '../Config';
import {
	//MANUAL_WRITE
} from '../Api/api';
/*
//import Config from '../Config';

import JoditEditor from 'jodit-react';
//import ReactQuill from 'react-quill';
import QRCode from 'qrcode.react';
//import 'react-quill/dist/quill.snow.css';
import sanitizeHtml from 'sanitize-html';
//var QRCode = require('qrcode.react');
import axios from 'axios';
import Config from '../Config';

import {get_cfa_training_completion, GET_API_COURSE} from '../Api/api';
*/


const PaymentTestPage = (props) => {
	
	document.title = props.title;

	const POST_TO_URL = 'https://payserv.scholarchip.com/Webservices/000901/StoreFront.asmx?wsdl';
	const XML_NS = 'http://payserv.scholarchip.com';

	//TODO: Remove
	const [sUserid, SetProfactUn] = useState(Config.EXTERNAL_SYSTEM.profactUn);
	const [sPassword, SetProfactPw] = useState(Config.EXTERNAL_SYSTEM.profactPw);
	
	const [sToken, Set_sToken] = useState('__NOT_SET__');


	console.log(sUserid, sPassword);

	//Just to silence annoyance warning
	useEffect( () => {
		Set_sToken(sToken);
	}, [sToken])



	const GET_TOKEN = () => {
let xmls=`<?xml version="1.0" encoding="UTF-8"?>
<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/">
	<SOAP-ENV:Body>
	<GetToken xmlns="${XML_NS}">
		<sUserid>${sUserid}</sUserid>
		<sPassword>${sPassword}</sPassword>
	</GetToken>
	</SOAP-ENV:Body>
</SOAP-ENV:Envelope>`;
		//let xmls = '<?xml version="1.0" encoding="UTF-8"?> <SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/"> <SOAP-ENV:Body> <GetToken xmlns="http://payserv.scholarchip.com"> <sUserid>ruprofact</sUserid> <sPassword>jiY$b3644#</sPassword> </GetToken> </SOAP-ENV:Body> </SOAP-ENV:Envelope>';
		fetch(
            POST_TO_URL,
            {   method: 'POST',
                //mode: 'no-cors',
                headers: new Headers(
                  	{
					   "Content-Type": "text/xml",
						"Accept":"text/xml; charset=utf-8"
					}
                ),
                body: xmls
             }
           ).then( response => { 
			   console.log(response); 
			   //const myXML = await response.text();

			   //console.log('MY XML', response.text());
			   return response.text(); 
			})
  			.then(str => {
				  console.log('MY XML in `str`', str);
				  //(new window.DOMParser()).parseFromString(str, "text/xml")
			})
			.catch(err => console.log(err));
			
		
		//console.log(MANUAL_WRITE);
/*
		MANUAL_WRITE()
		.post(
			'https://payserv.scholarchip.com/Webservices/000901/StoreFront.asmx?wsdl',
			xmls,
			{headers:
				{
					'Content-Type': 'text/xml'
				}
			}
		).then(res=>{
			console.log(res);
		}
		).catch(err=>{console.log(err)});
		
		*/
	}


	return (
		<main id="main-wrapper" className="grid-container ">
			<div id="main" className="grid-x grid-margin-x">
				<div id="main-col" className="cell">
					<h1>Payment Test Page</h1>
					<h2 style={{color:'red'}}> You can do potential damage from here. Be careful. In fact, you should probably not see this page. Go home. </h2>
					<div>
						<h3> Get Token </h3>
						<h4> Request </h4>
						<div className="large-4 medium-6 small-12  cell">
							<input type="text" id="sUserid" name="sUserid" placeholder="Username" required="required" 
								value={sUserid}
								onChange={e => SetProfactUn(e.target.value)}
							/>
						</div>
						<div className="large-4 medium-6 small-12  cell">
							<input type="password" id="sPassword" name="sPassword" placeholder="Password" required="required" 
								value={sPassword}
								onChange={e => SetProfactPw(e.target.value)}
							/>
						</div>
						<h4> Submit </h4>
						<div>
							<button type="button" className="button primary" onClick={() => { GET_TOKEN() } }>Submit</button>
						</div>
						<h4> Request </h4>
						<div>
							<ul>
								<li><strong>sToken</strong>: {sToken}</li>
							</ul>
						</div>
					</div>
					<div>
						<h3> Get Inventory </h3>
						<div>
							
						</div>
					</div>

					<hr />
				</div>
			</div>
		</main>
	);

}
/*
					<div>
						<h3> REACT QUILL EDITOR</h3>
						<ReactQuill theme="snow" value={newsItem} onChange={setNewsItem}
							modules={{
								toolbar: [
								  [{ 'header': [1, 2, false] }],
								  ['bold', 'italic', 'underline','strike', 'blockquote'],
								  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
								  ['link', 'image'],
								  ['clean']
								],
							}}
						/>
					</div>*/

export default PaymentTestPage;