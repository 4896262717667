import React from 'react';
import Config from '../../Config';

class RUUnitBar extends React.Component {
	render() {
		return (
			<div id="sebs-bar-wrapper" className="grid-container full">
				<div id="sebs-bar" className="grid-x">
					<div id="sebs-text-area" className="cell small-10 medium-11">
						<h1 id="sebs-text"> {Config.UNIT_NAME} </h1>
					</div>
					<div id="site-search" className="cell small-2 medium-1">
						<button id="site-search-button" className="button" data-open="search-modal"><i className="fas fa-search"><span className="vh">Search This Site</span></i></button>
						<div id="search-modal" className="reveal callout" data-reveal data-animation-in="hinge-in-from-top" data-animation-out="hinge-out-from-top">
							<h2>Search This Site</h2>
							<form action="https://search.rutgers.edu/web.html" method="get" className="search">
								<input type="hidden" name="site" id="site" value={Config.WEB_ADDRESS} />
								<label className="vh" htmlFor="search-text">Search</label>
								<input type="text" className="text" id="search-text" name="q" placeholder="Search This Site" />
								<input type="submit" className="submit button" id="search-submit" value="Go" />
							</form>
							<button className="close-button" data-close aria-label="Close modal" type="button"
								onClick={ () => { document.getElementsByClassName('reveal-overlay')[0].style.display='none'; }}
							><span aria-hidden="true">&times;</span></button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default RUUnitBar;