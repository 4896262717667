import React, {useState, useEffect} from 'react';
import TFATable from './Components/TrainedApplicatorTable';
//import { Link } from 'react-router-dom';

//import tfaList from  '../Api/tfa-list.js';
import {get_tfa_public} from '../Api/api';

//import axios from 'axios';
//import Config from '../Config';
//Copy items from CFA List Page and functionalizing this page.

const TFATrainingPage = ( props ) => {

	document.title = props.title;
	const [tfaList, setTfaList] = useState([]);

	useEffect( () => {
		get_tfa_public()
			.then( (res) => {
				console.log('Retrieving TFA Public List', res);
				if( res.status && res.status !== 200 ) {
					throw res; 
				}

				if( res.data ) {
					setTfaList( res.data.rawTfas );
				}
			} )
			.catch( error => {
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch TFA Public List. ');
				}
				setTfaList(null);
			});
	}, [] );


	return (
		<main id="main-wrapper" className="">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content" className="">
						<h1>Trained Applicators List​</h1>
						<h3>
							The following people are trained applicators approved by Certified Fertilizer Applicator (CFA) to apply fertilizer in the state of 
								New Jersey under direct supervision of a CFA for 2020​. 
							Trained Fertilizer Applicators must receive annual training and pay a registration fee of $40. 
							Note that Trained Fertilizer Applicators are removed from the public list after December 1st of each year.​
						</h3>
						<hr />
						<TFATable TfaList={tfaList} />
					</div>
				</div>
			</div>
		</main>
	);

}

export default TFATrainingPage;