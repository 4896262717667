import React from 'react';


class TemplatePage extends React.Component {

	render() {
		return (
			<main id="main-wrapper" className="grid-container full">
			  <a className="photo-box" href="interior.html">
				<figure>
				  <img src={process.env.PUBLIC_URL + '/ru-sebs/exampleImages/homepage/sample-1-across.png'} alt="Sample  1-across." />
				  <figcaption className="text-box">
					<h2>Clickable Full-Width Image With Text</h2>
					<p className="button">Call to Action!!!</p>
				  </figcaption>
				</figure>
			  </a>
			  <div id="main" className="grid-x grid-margin-x">
					<form action="" className="grid-x grid-margin-x">
					<div className="medium-6 large-5 cell large-offset-1">
						<h3>My Awesome Product</h3>
						<p>Nunc eu ullamcorper orci. Quisque eget odio ac lectus vestibulum faucibus eget in metus. In pellentesque faucibus vestibulum. Nulla at nulla justo, eget luctus tortor. Nulla facilisi. Duis aliquet egestas purus in.</p>
				
						<label>Size
							<select>
								<option value="husker">Small</option>
								<option value="starbuck">Medium</option>
								<option value="hotdog">Large</option>
								<option value="apollo">Yeti</option>
							</select>
						</label>
				
						<div className="grid-x">
							<div className="small-3 cell">
								<label htmlFor="middle-label" className="middle">Quantity</label>
							</div>
							<div className="small-9 cell">
								<input type="text" id="middle-label" placeholder="One fish two fish" />
							</div>
						</div>
				
						<a href="./#" className="button large expanded">Buy Now</a>
				
						<div className="small secondary expanded button-group">
							<a href="./#" className="button">Facebook</a>
							<a href="./#" className="button">Twitter</a>
							<a href="./#" className="button">Yo</a>
						</div>
					</div>

					</form>
				<div id="main-col" className="cell">
				  <div id="content" className="grid-x grid-margin-x">
					<div id="primary-sub-col" className="cell small-12 large-9">
					  <p>This version of the homepage uses one large feature image, but you can use up to five.  If you want text overlayed on the image, you should use just one large image, as seen above.</p>
	
					  <ol className="menu-list">
						<li>Homepage with Horizontal Nav and One Feature Photo (You Are Here)</li>
						<li><a href="/index2.html">Homepage with Horizontal Nav and Three Feature Photos</a></li>
						<li><a href="/index-v.html">Homepage with Vertical Nav and Three Feature Photos</a></li>
						<li><a href="/index-v2.html">Homepage with Vertical Nav and One Feature Photo within the Main Column</a></li>
					  </ol>
	
					  <h2>Subhead</h2>
	
					  <p>Spicy jalapeno bacon ipsum dolor amet turkey hamburger ham kielbasa tenderloin turducken filet mignon kevin short loin porchetta. Short loin beef swine, brisket hamburger bresaola bacon chicken pig capicola pork chop. Pork loin ham hock t-bone corned beef tenderloin shank kielbasa pig chicken ground round cow salami boudin meatloaf venison. Fatback leberkas bresaola, landjaeger pancetta frankfurter doner bacon pork loin beef ribs jowl swine ribeye.</p>
	
					</div>
	
					<div id="secondary-sub-col" className="cell small-12 large-3">
					  <div className="callout">
						<h2>More Info</h2>
	
						<ol className="menu-list">
						  <li><a href="interior.html">Sample Interior Page</a></li>
						  <li>
							<a href="/#">Link B</a>
							<ol className="menu-list">
							  <li><a href="/#">Subink 1</a></li>
							  <li><a href="/#">Sublink 2</a></li>
							</ol>
						  </li>
						  <li><a href="/#">Link C</a></li>
						</ol>
					  </div>
					</div>
	
				  </div>
				</div>
			  </div>
			</main>
		);
	}

}

export default TemplatePage;