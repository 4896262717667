import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
//import axios from 'axios';
//import Config from '../../Config';

import {get_public_news} from '../../Api/api';
//import { Link } from 'react-router-dom';
//import NewsItems from  '../../Api/news-items.js';

const BreakingNews = () => {
	
	const [showPage, setPageItem] = useState( 1 );
	const [newsError, setNewsError] = useState( false );
	const [NewsItems, setNewsItems] = useState( [] );
	
	
	useEffect( ()=> {
		console.log('Swapping page to : ', showPage);
		//setPageItem(  showPage );
	}, [showPage] );
	

	useEffect( () => {
		get_public_news()
			.then( (res) => {
				console.log('Retrieving news from database.');
				if( res.status && res.status !== 200 ) {
					throw res; 
				}
				setNewsItems( res.data );
				//setNewsItems(data);
			} )
			.catch( error => {

				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch news items. ');
				}
				setNewsError(true);
			});
	}, [] );
	

	//console.log(NewsItems);

	if( newsError ) {
		return (
			<aside>
				<div className="panel">
					<h2>Breaking News</h2>
					<div id="breaking-news-content">
						<h3>Could not fetch news from source.</h3>
					</div>
					<nav aria-label="Breaking News Pagination">
						<ul className="pagination">
						</ul>
					</nav>
				</div>
			</aside>
		);
	} else if(NewsItems.length < 1 ) {
		return (
			<aside>
				<div className="panel">
					<h2>Breaking News</h2>
					<div id="breaking-news-content">
						<h3>No news available.</h3>
					</div>
					<nav aria-label="Breaking News Pagination">
						<ul className="pagination">
						</ul>
					</nav>
				</div>
			</aside>
		);
	}



	return (
		<aside>
			<div className="panel">
				<h2>Breaking News</h2>
				<div id="breaking-news-content">
					{NewsItems.map( (newsItem, key)  => {
							//console.log(newsItem, key);
						if( showPage === key+1 ) {
							return (
								<div id={'newsKey_' + newsItem.id} key={newsItem.id}>
									<h3>{newsItem.title}</h3>
									{parse(newsItem.content)}
								</div>
							);
						}
						return (
							<div id={'newsKey_' + newsItem.id} style={{display: 'none'}}  key={newsItem.id}>
								<h3>{newsItem.title}</h3>
								{parse(newsItem.content)}
							</div>
						);
					})}
					
				</div>
				<nav aria-label="Breaking News Pagination">
					<ul className="pagination text-center">
						{
							showPage < 2 ? 
							<li className={"pagination-previous disabled"}>Previous <span className="show-for-sr">page</span></li>
							: <li className={"pagination-previous "}>
								<a href="/#" aria-label="Previous page" onClick={(e) => { e.preventDefault();  e.stopPropagation(); setPageItem(showPage - 1);} }>Previous <span className="show-for-sr">page</span> </a>
							</li>
						}
						
						{NewsItems.map( (newsItem, key)  => {
							return ( <li className={(showPage === (key+1) ? 'current' : '')} key={key}><a href="/#" aria-label={"Page "+ (key+1)} onClick={(e) => { e.preventDefault();  e.stopPropagation(); setPageItem(key+1);}}><span className="show-for-sr">You're on news item</span> {key+1} </a></li> )
						} ) }

						{
							showPage > NewsItems.length-1 ? 
							<li className={"pagination-next disabled"}>Next <span className="show-for-sr">page</span></li>
							: <li className={"pagination-next "}>
								<a href="/#" aria-label="Next page" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setPageItem(showPage + 1); return false; }}>Next <span className="show-for-sr">page</span> </a>
							</li>
						}
					</ul>
				</nav>
			</div>
			</aside>
	);
/*
				<div id="page-1">
					<h3>For information regarding COVID-19 regulations:</h3>
					<ul>
						<li>Golf Course Superintendents Association of New Jersey </li>
						<li>Irrigation Association of New Jersey  </li>
						<li>New Jersey Green Industry Council  </li>
						<li>New Jersey Landscape Contractors Association  </li>
						<li>New Jersey Nursery and Landscape Association  </li>
						<li>Sports Field Managers Association of New Jersey  </li>
					</ul>
				</div>
*/
}

export default BreakingNews;