
import React, { useRef, useState, useEffect } from "react";
import { DECODE_TOKEN, nyeTfaDeactivate } from "../../../Api/api";


import { AXIOS_ERR, AXIOS_LOADING, AXIOS_OK, AXIOS_WAIT } from "../../NewYearsTasksPage";



export default function TfaPrevDeactivate () {
	const popupRef = useRef/*<HTMLDivElement>*/(null);
	const [myPopup, setMyPopup] = useState(null);
	const [tfacount, setTfaCount] = useState/*<number|null>*/(null);
	const [axiosState, setAxiosState] = useState/*<{isLoading: boolean, status: AXIOS_WAIT|AXIOS_OK|AXIOS_ERR|AXIOS_LOADING|> */({
		isLoading: false,
		state: AXIOS_WAIT
	});


	useEffect( () => {
		if( axiosState.state === AXIOS_LOADING ){
			const tokenInfo = DECODE_TOKEN();
			if( tokenInfo.code !== 'TOKEN_FOUND' ) {
				console.error('Did not find appropriate token.');
				setAxiosState({isLoading:false, state: AXIOS_ERR});
				return;
			}

			
			nyeTfaDeactivate( tokenInfo.getAuthToken ) 
			.then( (res) => {		
				if( res && res.data && res.data.code && res.data.code ==='ALL_TFAS_DEACTIVATED') {
					setAxiosState({isLoading:false, state: AXIOS_OK});
					setTfaCount(res.data.deactivatedTfas)
				}else{
					setAxiosState({isLoading:false, state: AXIOS_ERR});
				}
			} )
			.catch( error => {

				console.error('Getting [nyeTfaDeactivate] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not revoke cfas.');
				}
				setAxiosState({isLoading:false, state: AXIOS_ERR});
			});
		}
	}, [axiosState] );

	
	useEffect( () => {
		if( popupRef.current ){
			const myA = new window.Foundation.Reveal( window.jQuery(popupRef.current) );
			setMyPopup(myA)
			//new Foundation.Reveal(myPopup.current)
		}
	}, [popupRef] );


	return <div style={{paddingLeft: '.5rem', paddingRight: '.5rem'}}>
		<div><button type="button" className="button  hollow expanded alert" onClick={()=>{
			myPopup.open()
		}}>Deactivate TFAs </button></div>
		<div ref={popupRef} className="reveal" id="disable-tfas" data-reveal>
			{
				axiosState.state !== AXIOS_OK
				?
				<div>
					<h1>Deactivate All TFAs on the System</h1>
					<p className="lead">This deactivates all TFAs on the system and should only be pressed at the new year.</p>
					<p><em><strong>This action cannot be undone.</strong></em></p>
					{axiosState.state === AXIOS_ERR ? <p><em> There was a problem deactivating these TFAs. </em></p> : <></>}
					<button className="close-button" data-close aria-label="Close modal" type="button"
						onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							
						}}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div>
						<button className="button" onClick={()=>{
							if( myPopup ) { myPopup.close(); }
							}}>
							Nevermind
						</button> <button className="button "
							disabled={axiosState.isLoading}
							onClick={()=>{
								setAxiosState({isLoading:true, state: AXIOS_LOADING});
							}}>
								{axiosState.isLoading ? 'Deactivating...'
							:"Yes, Deactivate All TFAs"
								}
						</button>
					</div>
				</div>
				:
				<div>
					<h1>Revoked {tfacount} TFA(s)</h1>
					<p className="lead">The TFAs were revoked.</p>
					<div>
						<button className="button" onClick={()=>{
							if( myPopup ) { myPopup.close(); }
						}}>
							OK
						</button>
					</div>
				</div>

			}
		</div>	
	</div>
}

