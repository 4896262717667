import React from 'react';
//import Config from '../Config';
import AdminDashboard from './Components/DashboardComponents/AdminDashboard';
import CFADashboard from './Components/DashboardComponents/CFADashboard';


const DashboardPage = (props) =>{

	const userState = props.UserState;
	document.title = props.title;
	
	return (
		<main id="main-wrapper" className="">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						{(
								userState.user.userType === 'A' ?
								<AdminDashboard UserState={userState} />
								: <CFADashboard UserState={userState} />
						)}
					</div>
				</div>
			</div>
		</main>
	);
}
export default DashboardPage;