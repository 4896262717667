import React, 
{ 
	useState,
	useRef,
} from 'react';
import {
	AUTO_DOWNLOAD_CSV,

	DECODE_TOKEN
} from '../Api/api';
//import Config from '../Config';

//import parser from 'html-react-parser';
/*
import { 
	DECODE_TOKEN 
} from '../Api/api';
*/


const AdminDownloadReportsPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	const iframeRef = useRef(null);

	const [customDownloadOpts, SetCustomDownloadOptions] = useState({
		type:'',
		options: {}
	});

	const [downloadAlert, SetDownloadAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});

	const INITIAL_USER_DOWNLOAD = { type: 'cfa', options: {credits: null, examPaid: null} };
	const INITIAL_PAYMENT_DOWNLOAD = { type: 'payment', options: {lastXYears: null, updatedAt: null, payPurpose: null} };
	const INITIAL_EXAM_DOWNLOAD = { type: 'exam', options: {lastXYears: null, createdAt: null, passed: null, spanish: null} };
	const INITIAL_QUESTION_DOWNLOAD = { type: 'question', options: {lastXYears: null, createdAt: null, answerPct: null, spanish: null} };

	const DownloadCsv = (overrideDownloadOptions = null) => {
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		//console.log(customDownloadOpts, overrideDownloadOptions);
		//return true;

		AUTO_DOWNLOAD_CSV(
			tokenInfo.getAuthToken, 
			(overrideDownloadOptions || customDownloadOpts)
		)
		.then( (res) => {
			console.log('Getting information', res);
			return {
				type:res.headers['content-type'],
				info: res.data //blob.text()
			}
			/*
			try {
				//If this is a json object for some reason saying there's no rows, we'll dispaly a message to the suer.
				console.log('Tryi9ng this first...', blob);
				const myJsonMaybe = JSON.parse(blob.text());
				console.log(myJsonMaybe);
				SetDownloadAlert({
					type: (myJsonMaybe.code === 'NOT_OK' ? 'alert' : 'warning'),
					display: 'none',
					title: 'There was a problem downloading this data.',
					message: myJsonMaybe.message
				});
			}catch( error ) {
				const url=window.URL.createObjectURL(blob);
				window.open(url,iframeRef.current);
			}*/

            //iframeRef.current.write(res.data);
		} )
		.then( (blob) => {
			//console.log('My Blob', blob);
			if( blob.type === 'application/json; charset=utf-8' ) {
				blob.info.text().then( (myText) => {
					//console.log('My text: ', myText);
					const myJsonMaybe = JSON.parse(myText);
					//console.log(myJsonMaybe);
					SetDownloadAlert({
						type: (myJsonMaybe.code === 'NOT_OK' ? 'alert' : 'warning'),
						display: 'block',
						title: 'There was a problem downloading this data.',
						message: myJsonMaybe.message
					});
				});
			}else{

				const myBlob = new Blob([blob.info],{type: 'text/csv'});
				const url=window.URL.createObjectURL(myBlob);
				window.open(url,iframeRef.current);
			}
		})
		.catch( error => {
			console.error('Getting specific news item', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Getting specific download item failed.');
			}
			SetDownloadAlert({
				type: 'alert',
				display: 'block',
				title: 'There was a problem downloading this data.',
				message: 'There was a problem generating the query for these selected filters. Please try again..'
			});
		});
	}
	const ChangeCustomReportType = (e_target) => {

		if( e_target.value === 'cfa' ) {
			SetCustomDownloadOptions(INITIAL_USER_DOWNLOAD);
		}else if(e_target.value === 'payment') {
			SetCustomDownloadOptions(INITIAL_PAYMENT_DOWNLOAD);
		}else if(e_target.value === 'exam') {
			SetCustomDownloadOptions(INITIAL_EXAM_DOWNLOAD);
		}else if(e_target.value === 'question') {
			SetCustomDownloadOptions(INITIAL_QUESTION_DOWNLOAD);
		}else{
			SetCustomDownloadOptions({
				...customDownloadOpts,
				type: e_target.value,
				options: {}
			});
		}

		return true;
	}

	const updateCustomOptions = (e_target) => {
		const { name, value } = e_target;
		//console.log(e_target, );
		SetCustomDownloadOptions({
			...customDownloadOpts,
			options: {
				...customDownloadOpts.options,
				[name]: (value === '' ? null : value)
			}
		});
	}
	

	const GET_USER_FILTERS = () => {

		return ( 
			<div className="grid-x">
				<div className="small-12 cell">
					<h3>User Data Filters</h3>
					<p><em>Blank textboxes means &ldquo;ignore filter&rdquo;</em></p>
				</div>
				<div className="medium-5 small-12 cell">
					<label htmlFor="credits" className="top">Show CFAs with Zero Credits</label>
				</div>
				<div className="medium-7 small-12 cell">
					<select id="credits" name="credits" 
						onChange={e => { 
							updateCustomOptions(e.target)
						} }>
							<option value="">Ignore Filter</option>
							<option value={0}>Yes - Show all CFAs with Zero Credits</option>
							<option value={1}>No - Show all CFAs with More Than Zero Credits</option>
					</select>
				</div>
				<div className="medium-5 small-12 cell">
					<label htmlFor="examPaid" className="top">Show CFAs With an Exam Payment Pending or Not Paid?</label>
				</div>
				<div className="medium-7 small-12 cell">
					<select id="examPaid" name="examPaid" 
						onChange={e => { 
							updateCustomOptions(e.target)
						} }>
							<option value="">Ignore Filter</option>
							<option value={0}>Yes - Show Only CFAs with an Exam Payment Pending or Not Paid</option>
							<option value={1}>No - Show Only CFAs that have completed their exam payment.</option>
					</select>
				</div>
			</div>
		);
	}
	const GET_PAYMENT_FILTERS = () => {
/*

				<div className="medium-5 small-12 cell">
					<label htmlFor="lastXYears" className="top">Show data from the last X Years:</label>
				</div>
				<div className="medium-7 small-12 cell">
					<input id="lastXYears" name="lastXYears" 
						type="number"
						min={0}
						step={1}
						placeholder="Ignored Filter"
						onChange={e => { 
							e.target.value= parseInt(e.target.value);
							updateCustomOptions(e.target)
						} } />
				</div>
*/
		return ( 
			
			<div className="grid-x">
				<div className="small-12 cell">
					<h3>Payment Data Filters</h3>
					<p><em>Blank textboxes means &ldquo;ignore filter&rdquo;</em></p>
				</div>
				
				<div className="medium-5 small-12 cell">
					<label htmlFor="updatedAt" className="top">Show data from year:</label>
				</div>
				<div className="medium-7 small-12 cell">
					<input id="updatedAt" name="updatedAt" 
						type="number"
						min={1990}
						step={1}
						placeholder="Ignored Filter"
						onChange={e => { 
							e.target.value= parseInt(e.target.value);
							updateCustomOptions(e.target)
						} } />
				</div>
				<div className="medium-5 small-12 cell">
					<label htmlFor="payPurpose" className="top">Get Specific Payment Purpose?</label>
				</div>
				<div className="medium-7 small-12 cell">
					<select id="payPurpose" name="payPurpose" 
						onChange={e => { 
							updateCustomOptions(e.target)
						} }>
							<option value="">Ignore Filter</option>
							<option value={`exam`}>Exam payments only</option>
							<option value={`tfa`}>TFA payments only</option>
							<option value={`renewal`}>Renewal payments only</option>
							<option value={`course`}>Course payments only</option>
					</select>
				</div>
			</div>
		);
	}
	const GET_EXAM_FILTERS = () => {
/*

				<div className="medium-5 small-12 cell">
					<label htmlFor="lastXYears" className="top">Show data from the last X Years:</label>
				</div>
				<div className="medium-7 small-12 cell">
					<input id="lastXYears" name="lastXYears" 
						type="number"
						min={0}
						step={1}
						placeholder="Ignored Filter"
						onChange={e => { 
							e.target.value= parseInt(e.target.value);
							updateCustomOptions(e.target)
						} } />
				</div>
*/
		return ( 
			
			<div className="grid-x">
				<div className="small-12 cell">
					<h3>Exam Data Filters</h3>
					<p><em>Blank textboxes means &ldquo;ignore filter&rdquo;</em></p>
				</div>
				<div className="medium-5 small-12 cell">
					<label htmlFor="spanish" className="top">Language?</label>
				</div>
				<div className="medium-7 small-12 cell">
					<select id="spanish" name="spanish" 
						onChange={e => { 
							updateCustomOptions(e.target)
						} }>
							<option value="">Ignore Filter </option>
							<option value={`0`}>Only ENGLISH Exams</option>
							<option value={`1`}>Only SPANISH Exams</option>
					</select>
				</div>
				<div className="medium-5 small-12 cell">
					<label htmlFor="createdAt" className="top">Show data from year:</label>
				</div>
				<div className="medium-7 small-12 cell">
					<input id="createdAt" name="createdAt" 
						type="number"
						min={1990}
						step={1}
						placeholder="Ignored Filter"
						onChange={e => { 
							e.target.value= parseInt(e.target.value);
							updateCustomOptions(e.target)
						} } />
				</div>
				<div className="medium-5 small-12 cell">
					<label htmlFor="passed" className="top">Show Passed or Failed Exams?</label>
				</div>
				<div className="medium-7 small-12 cell">
					<select id="passed" name="passed" 
						onChange={e => { 
							updateCustomOptions(e.target)
						} }>
							<option value="">Ignore Filter </option>
							<option value={true}>Only PASSED Exams</option>
							<option value={false}>Only FAILED Exams</option>
					</select>
				</div>
			</div>
		);
	}
	const GET_QUESTION_FILTERS = () => {

		/*
		
				<div className="medium-5 small-12 cell">
					<label htmlFor="lastXYears" className="top">Show data from the last X Years:</label>
				</div>
				<div className="medium-7 small-12 cell">
					<input id="lastXYears" name="lastXYears" 
						type="number"
						min={0}
						step={1}
						placeholder="Ignored Filter"
						onChange={e => { 
							e.target.value= parseInt(e.target.value);
							updateCustomOptions(e.target)
						} } />
				</div>


				
				<div className="medium-5 small-12 cell">
					<label htmlFor="answerPct" className="top">Less than x% answered correctly:</label>
				</div>
				<div className="medium-7 small-12 cell">
					<input id="answerPct" name="answerPct" 
						type="number"
						min={0}
						max={100}
						step={1}
						placeholder="Ignored Filter"
						onChange={e => { 
							e.target.value= parseInt(e.target.value);
							updateCustomOptions(e.target)
						} } />
				</div>
				<div className="medium-5 small-12 cell">
					<label htmlFor="passed" className="top">Language?</label>
				</div>
				<div className="medium-7 small-12 cell">
					<select id="passed" name="passed" 
						onChange={e => { 
							updateCustomOptions(e.target)
						} }>
							<option value="">Ignore Filter </option>
							<option value={`english`}>Only ENGLISH Exams</option>
							<option value={`spanish`}>Only SPANISH Exams</option>
					</select>
				</div>
		*/
		return ( 
			
			<div className="grid-x">
				<div className="small-12 cell">
					<h3>Question Data Filters</h3>
					<p><em>Blank textboxes means &ldquo;ignore filter&rdquo;</em></p>
				</div>
				<div className="medium-5 small-12 cell">
					<label htmlFor="createdAt" className="top">Show data from year:</label>
				</div>
				<div className="medium-7 small-12 cell">
					<input id="createdAt" name="createdAt" 
						type="number"
						min={1990}
						step={1}
						placeholder="Ignored Filter"
						onChange={e => { 
							e.target.value= parseInt(e.target.value);
							updateCustomOptions(e.target)
						} } />
				</div>
			</div>
		);
	}
	const GetCustomControls = () => {
		if( !customDownloadOpts.type ) {
			return(<h4><em>Please select an option on the right.</em></h4>);
		}
		
		switch(customDownloadOpts.type) {
			case 'cfa':
				return GET_USER_FILTERS();
			case 'payment':
				return GET_PAYMENT_FILTERS();
			case 'exam':
				return GET_EXAM_FILTERS();
			case 'question':
				return GET_QUESTION_FILTERS();
			default:
				break;
		}

		return(<h4><em>No Controls found</em></h4>);
	}
	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h2> Download Reports </h2>
						<p><em>Downloads are CSV Format</em></p>
						<p><em>Note: If files are not downloading, please make sure you are not blocking popups. </em></p>
						<div className={"callout " + downloadAlert.type} style={{display:downloadAlert.display}} data-closable>
							<h5>{downloadAlert.title}</h5>
							<p>{downloadAlert.message}</p>
							<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
								window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
									SetDownloadAlert({
										type: 'success',
										display: 'none',
										title: '',
										message: ''
									})
								});
							}}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<h3> Quick Downloads </h3>
						<div className="button-group stacked-for-small button-group-override">
							<button className={`button primary`} onClick={(e) => {
								DownloadCsv(INITIAL_USER_DOWNLOAD);
							}}>User Account Summary</button>
							<button className={`button primary`} onClick={(e) => {
								DownloadCsv(INITIAL_PAYMENT_DOWNLOAD);
							}}>Certified Applicator Payment Report</button>
							<button className={`button primary`} onClick={(e) => {
								DownloadCsv({
									type:'tfa',
									options:[]
								});
							}}>Trained Applicator Report </button>
							<button className={`button primary`} onClick={(e) => {
								DownloadCsv({
									type:'credits',
									options:[]
								});
							}}>Continuing Education Credit Report </button>
							<button className={`button primary`} onClick={(e) => {
								DownloadCsv({
									type:'cfa-status-download',
									options:[]
								});
							}}>Certified Applicator Status Report </button>
							<button className={`button primary`} onClick={(e) => {
								DownloadCsv({
									type:'cfa-recert-download',
									options:[]
								});
							}}>Recertification Cycle Report </button>
							<button className={`button primary`} onClick={(e) => {
								DownloadCsv(INITIAL_EXAM_DOWNLOAD);
							}}>Test Results Report  </button>
							<button className={`button primary`} onClick={(e) => {
								DownloadCsv(INITIAL_QUESTION_DOWNLOAD);
							}}>Test Analysis Report  </button>
						</div>
						<h3> Customized Reports</h3>
						<div className="row">
							<div className="medium-5 columns">
								<div>
									<label htmlFor="customType_cfa" className="">
										<input type="radio" 
											name="customType" id="customType_cfa" 
											value='cfa'
											defaultChecked={(customDownloadOpts.type==='cfa' )}
											onChange={(e)=>{ ChangeCustomReportType(e.target); }}
										/>
										User Data
									</label>
								</div>
								<div>
									<label htmlFor="customType_payment" className="">
										<input type="radio" 
											name="customType" id="customType_payment" 
											value='payment'
											defaultChecked={(customDownloadOpts.type==='payment' )}
											onChange={(e)=>{ ChangeCustomReportType(e.target); }}
										/>
										Payment Data
									</label>
								</div>
								<div>
									<label htmlFor="customType_exam" className="">
										<input type="radio" 
											name="customType" id="customType_exam" 
											value='exam'
											defaultChecked={(customDownloadOpts.type==='exam' )}
											onChange={(e)=>{ ChangeCustomReportType(e.target); }}
										/>
										Exam Data
									</label>
								</div>
								<div>
									<label htmlFor="customType_question" className="">
										<input type="radio" 
											name="customType" id="customType_question" 
											value='question'
											defaultChecked={(customDownloadOpts.type==='question' )}
											onChange={(e)=>{ ChangeCustomReportType(e.target); }}
										/>
										Question Data
									</label>
								</div>
							</div>
							<div className="medium-7 columns">
								{GetCustomControls()}
							</div>
							<div className="small-12 columns text-align-right">
								<button 
									className="button primary medium"
									onClick={(e)=>{ DownloadCsv(); }}
								><i className="fas fa-download"></i> Download</button>
							</div>
						</div>
						<div>
							<iframe style={{display:'none'}} title="DOWNLOAD CSV DATA FOR PROFACTS" ref={iframeRef} id="iframe_for_download" name="iframe_for_download"></iframe>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
	/*
	Old quick download buttons
	
					<div className="button-group stacked-for-small button-group-override">
						<button className={`button primary`} onClick={(e) => {
							DownloadCsv(INITIAL_USER_DOWNLOAD);
						}}>User Data</button>
						<button className={`button primary`} onClick={(e) => {
							DownloadCsv(INITIAL_PAYMENT_DOWNLOAD);
						}}>All Payment Data</button>
						<button className={`button primary`} onClick={(e) => {
							DownloadCsv({
								...INITIAL_PAYMENT_DOWNLOAD,
								options:{
									...INITIAL_PAYMENT_DOWNLOAD.options,
									updatedAt: new Date().getFullYear()
									//lastXYears: 0
								}
							});
						}}>Payments This Year Only</button>
						<button className={`button primary`} onClick={(e) => {
							DownloadCsv(INITIAL_EXAM_DOWNLOAD);
						}}>Exam Statistics</button>
						<button className={`button primary`} onClick={(e) => {
							DownloadCsv(INITIAL_QUESTION_DOWNLOAD);
						}}>Question Statistics</button>
					</div>
	*/
}
export default AdminDownloadReportsPage;