import React, {
	useState, 
	useEffect
} from 'react';
import { 
	admin_get_all_courses,
	//get_course_content,

	DECODE_TOKEN 
} from '../../../Api/api';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
/*
import { 
	Link 
} from 'react-router-dom';
*/
/*
import axios from 'axios';
import Config from '../../../Config';
*/


const CourseList = (props) => {

	const [filterText, filterByText] = useState('');
	const [courseList, setCourseList] = useState({
		cfaExam:{},
		ceCourses: [],
		isLoading: true
	});

	useEffect( () => {
		getCeCourseList();
	}, []);

	const getCeCourseList = () => {
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		//get_course_content(tokenInfo.getAuthToken)
		admin_get_all_courses(tokenInfo.getAuthToken)
		.then( (res) => {
			console.log('COURSE CONTENT: ', res.data, res.data.courseList, res.data.code, res.data.courseList && res.data.code === 'ALL_COURSES');
			if( res.data.courseList && res.data.code === 'ALL_COURSES') {
				//Let's pull out the cfa exam vs. ce courses

				const myCourses = {
					cfaExam:{},
					ceCourses: [],
					isLoading: false
				};
				for( let i=0;i<res.data.courseList.length;i++) {
					if( res.data.courseList[i].courseTitle.toLowerCase() === 'cfa exam' ) {
						myCourses.cfaExam = res.data.courseList[i];
					}else{
						myCourses.ceCourses.push( res.data.courseList[i] );
					}
				}
				console.log('Seriou');
				//
				setCourseList(myCourses);
				//create book
				//setCourseList(res.data.);
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting [ACTIVE_COURSES] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Course List.');
			}
			setCourseList(null);
		});
	}

	const OutputCourseList = () => {
		console.log(courseList.ceCourses);
		
		return courseList.ceCourses
		.filter((courseInfo) => {
			const myFilterText = filterText.toLocaleLowerCase().trim();
			let showInTable = true;

			if (myFilterText.trim() !== '') {
				showInTable = (showInTable && courseInfo.courseTitle.toLocaleLowerCase().includes(myFilterText));
			}
			
			return showInTable;
		})
		.map((courseInfo, key) => {
			/*
			<td> {
				courseInfo.cost ? 
				Number(courseInfo.cost).toLocaleString('en-US', {
					style: 'currency',
					currency: 'USD'
				}) : 'NYI'
			} </td>
			*/
			return (
			<tr key={key}>
				<td> {courseInfo.courseId} </td> 
				<td> { courseInfo.courseTitle }</td>
				<td>{courseInfo.description && courseInfo.description.length > 100 ? courseInfo.description.substr(0,100) + '...' : courseInfo.description }</td>
				<td> { courseInfo.credits} </td>
				<td> {
					moment.tz(courseInfo.beginDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
						.tz(moment.tz.guess())
						.format('L')
				} </td>
				<td><Link to={`manage-courses/${courseInfo.courseId}`} className="no-bottom-margin button warning small"><i className="fas fa-edit"></i></Link> </td>
				<td><button 
					className="no-bottom-margin button alert small" 
					type="button"
					onClick={ (e) => { } }
					><i className="fa fa-trash" /></button>
				</td>
			</tr>
			)
		});
	}
	const handleFilterOnChange = event => {
		filterByText(event.target.value);
	};
	
	//<th>Cost</th>

	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h2> Manage Courses </h2>
						<p><em> Select a course you want to modify here </em></p>
						<div>
							<Link to={`/manage-courses/${courseList.cfaExam.courseId}`} className="button primary"> <i className="fas fa-book"></i> Manage CFA Exam / Training Content </Link>
						</div>
						<h4> Search Continuing Education Courses Courses </h4> 
						<div className="row" >
							<div className="medium-3 small-12 columns" >
								<label htmlFor="middle-label"
									className="label-style-1" > Filter By Text: </label> 
							</div> 
							<div className="medium-5 small-12 no-float columns" >
								<input type="text"
									id="middle-label"
									placeholder="Course title"
									onChange={
										handleFilterOnChange
									}
								/> 
							</div> 
						</div> 
						<div className="table-scroll">
							<table className="table-left-align-all">
								<thead>
									<tr>
										<th>Id</th>
										<th>Title</th>
										<th>Description</th>
										<th>Credits</th>
										<th>Begins on</th>
										<th>Edit</th>
										<th>Remove</th>
									</tr>
								</thead>
								<tbody>
									{OutputCourseList()}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default CourseList;