import React, {
	useEffect, useState
} from 'react';

import {
	get_cfa_list,
	AUTO_admin_bulk_credits,
	DECODE_TOKEN
} from '../../../Api/api';
//import DatePicker from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";
/*

import moment from 'moment-timezone';
import {
	admin_get_all_courses,

	DECODE_TOKEN
} from '../../../Api/api';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
*/

const UpdateCECourseForm = (props) => {

	const [
		selectedCourse,
		cancelEditing,
		confirmEditing
	] = [
		props.selectedCourse,
		props.cancelEditing, 
		props.confirmEditing 
	];
	

	const [disableSubmitBtn, SetDisableSubmitBtn] = useState(false);
	const [cfas, SetCfas] = useState({
		isLoading: true,
		hasError: false,
		filterText: '',
		list: [],
		selectedIds: []
	});
	

	const GetCfaList = (selectedCourse) => {
		if( !selectedCourse ) {
			return ;
		}
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		get_cfa_list(tokenInfo.getAuthToken)
		.then( (res) => {
			console.log('CFA List', res.data);

			if( res.data.code === 'ALL_CFAS' ) {
				const myCfas = res.data.data.map( (cfaApp) => {
					console.log(selectedCourse.cfaCredits);
					const myCreditInfo = selectedCourse.cfaCredits.find( (creditInfo) => { return creditInfo.userId === cfaApp.id })
					console.log('Found my Credit info ', myCreditInfo);
					if( selectedCourse && myCreditInfo ) {
						cfaApp.preSelected=true;
						cfaApp.isSelected=true;
						cfaApp.selectedCreditId = myCreditInfo.id;
					}else{
						//probably a new course or did not find a credit for this user.
						cfaApp.preSelected=false;
						cfaApp.isSelected=false;
						cfaApp.selectedCreditId = null;
					}
					return cfaApp;
				});

				SetCfas({
					isLoading: false,
					hasError: true,
					filterText: '',
					list: myCfas //res.data.data
				});
			}else{
				SetCfas({
					isLoading: false,
					hasError: true,
					filterText: '',
					list: []
				})
			}
			
		} )
		.catch( error => {
			console.error('Getting [Admin Get All CFAs] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch statistics.');
			}
		});
		
	}

	const handleFilterOnChange = event => {
		SetCfas({
			...cfas,
			filterText: event.target.value
		});
	};
	
	
	const ToggleCfa = (cfaId) => {
		console.log('Selecting this CFA', cfaId);
		SetCfas({
			...cfas,
			list: cfas.list.map( (cfaApp) => {
				if( cfaApp.id === cfaId ) {
					cfaApp.isSelected= !cfaApp.isSelected;
				}
				return cfaApp;
			})
		});

	}

	const GetAvailableCfas = () => {

		if( cfas.list.length < 1 ) {
			return( <p><em>No CFAs available to add.</em></p>)
		}
		if( 
			cfas.filterText === '' 
			|| cfas.filterText.length < 3
		) {
			return( <p><em>Please type more letters.</em></p>)
		}

		const unselectedCfas = cfas.list
		.filter((cfaApp) => 
			{

				if( cfas.filterText === '' ) { 
					return !cfaApp.isSelected;
				}
				const myFilterText = cfas.filterText.toLocaleLowerCase().trim();
				return !cfaApp.isSelected && (
					('' + cfaApp.id.toString().padStart(6, '0')).toLocaleLowerCase().includes( myFilterText )
					|| (cfaApp.profactId ? cfaApp.profactId.toLocaleLowerCase().includes( myFilterText ) : false )

					|| cfaApp.lastName.toLocaleLowerCase().includes( myFilterText )
					|| cfaApp.firstName.toLocaleLowerCase().includes( myFilterText )
					|| cfaApp.businessName.toLocaleLowerCase().includes( myFilterText )
					|| ( cfaApp.businessAddress ? 
						cfaApp.businessAddress.street.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.city.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.state.toLocaleLowerCase().includes( myFilterText )
							|| cfaApp.businessAddress.phone.toString().toLocaleLowerCase().includes( myFilterText )
					: false )
				);
		})
		.slice(0,10);
		if( unselectedCfas.length < 1 ) {
			return( <p><em>No CFAs available to be selected with this filter.</em></p>);
		}
		return (
			<ul>
			{unselectedCfas
			.map( (cfaApp, key) => {
				return (<li key={key}>
					<button type="button" 
						onClick={() => { ToggleCfa(cfaApp.id) } }
						className="button primary tiny vertical-align-middle no-bottom-margin">
						<i className="fas fa-plus"></i> Add</button> {cfaApp.firstName} {cfaApp.lastName} [{
							cfaApp.profactId && cfaApp.examPassed ? (
								cfaApp.profactId
							) : `#${cfaApp.id}`
						}]
				</li>);
			})}
			</ul>
		)
	}
	const GetAddedCfaList = () => {
		const mySelectedCfas = cfas.list
			.filter((cfaApp) => {
					return cfaApp.isSelected;
			});
		if( mySelectedCfas.length < 1 ) {
			return( <p><em>No CFAs selected yet.</em></p>)
		}

		return (
			<ul>
			{mySelectedCfas
			.map( (cfaApp, key) => {
				return (<li key={key}>
					<button type="button" 
						onClick={() => { ToggleCfa(cfaApp.id) } }
						className="button success override-sebs tiny vertical-align-middle no-bottom-margin">
						<i className="fas fa-minus"></i> Remove</button> {cfaApp.firstName} {cfaApp.lastName} [{
							cfaApp.profactId && cfaApp.examPassed ? (
								cfaApp.profactId
							) : `#${cfaApp.id}`
						}]
				</li>);
			})}
			</ul>
		)
	}
/*
	useEffect( () => {
		console.log('[ucecf] Temporary so that the compiler stops complaining. ');
		SetCourseInfo(courseInfo);
		//RetrieveCourseList();
	}, [courseInfo, cfas]);
	*/




	
	const submitCfaCourseInfo = (e) => {
		e.preventDefault();
		console.log('Submitting course information...');

		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		SetDisableSubmitBtn(true);
/*
	cfaApp.preSelected=true;
	cfaApp.isSelected=true;
*/
		const newCfas = cfas.list.filter( (cfaApp) => { 
			return cfaApp.preSelected===false && cfaApp.isSelected===true;
		} ).map( (newCfas) => {
			return {
				userId: newCfas.id,
				courseId: selectedCourse.courseId,
				creditEarned: selectedCourse.credits
			}
		} );
		const updateCfas = cfas.list.filter( (cfaApp) => { 
			return cfaApp.preSelected===true && cfaApp.isSelected===true;
		} ).map( (updateCfas) => {
			return {
				userId: updateCfas.id,
				courseId: selectedCourse.courseId,
				creditEarned: selectedCourse.credits
			}
		} );
		const deleteCfas = cfas.list.filter( (cfaApp) => { 
			return cfaApp.preSelected===true && cfaApp.isSelected===false;
		} ).map( (deleteCfas) => {
			return {
				creditId: deleteCfas.selectedCreditId
			}
		} );
		console.log({
			selectedCourse,
			newCfas,
			updateCfas,
			deleteCfas
		});

		//console.log(moment(courseInfo.beginDate).format('L'));
		AUTO_admin_bulk_credits( 
			tokenInfo.getAuthToken, 
			newCfas,
			updateCfas,
			deleteCfas
		)
		.then( (res) => {
			console.log('Adding or updating course list ', res.data);
			confirmEditing();
			SetDisableSubmitBtn(false);
		} )
		.catch( error => {
			console.error('Updating news error', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Updating news error');
			}
			SetDisableSubmitBtn(false);
		});

	}
	
	useEffect( () => {
		GetCfaList(selectedCourse);
	}, [selectedCourse] );


	if( !selectedCourse ){
		return (<>
			<h3> No Course selected. </h3>
		</>)
	}
	return (
		<>
			<h3> Add / Update CFAs for &ldquo;{selectedCourse.courseTitle}&rdquo; </h3>
			<div>
			<form action="" method="post" onSubmit={submitCfaCourseInfo}>
				<h4>Add / Update / Remove CFAs</h4>
				<div className="">
					<div className="medium-6 small-12 columns">
						<h5>Filter CFAs</h5>
						<p> Start typing the name (first name, last name, CFA ID, or business) of the CFA you want to add.</p>
							<div className="rows">
								<div className="medium-3 small-12 columns">
									<label htmlFor="cfaFilter" className="label-style-1">Name: </label>
								</div>
								<div className="medium-5 small-12 columns">
									<input type="text" 
										id="cfaFilter" 
										name="cfaFilter"
										value={cfas.filterText}
										placeholder="CFA Filter" 
										onChange={handleFilterOnChange}
									/>
								</div>
								<div className="small-12 columns">
									<p>Showing top 10 results: </p>
									{GetAvailableCfas()}
								</div>
						</div>
					</div>
					<div className="medium-6 small-12 columns">
						<h5>Added CFAs</h5>
						{GetAddedCfaList()}
					</div>
					<div className="medium-8 small-12 columns" style={{textAlign:'right'}}>
						<button type="button"  className="button primary" onClick={(e) =>{
							cancelEditing();
						}}>Cancel</button> <button type="submit" 
						disabled={disableSubmitBtn}
						className="button success override-sebs" onClick={(e) =>{
							//confirmEditing();
						}}>Add / Update Course Credits</button>
					</div>
				</div>
			</form>
			</div>
		</>
	);
};

export default UpdateCECourseForm;