const njCountiesList = [
	{
		"COUNTY": "ATLANTIC",
		"COUNTY_TITLECASE": "Atlantic"
	},
	{
		"COUNTY": "BERGEN",
		"COUNTY_TITLECASE": "Bergen"
	},
	{
		"COUNTY": "BURLINGTON",
		"COUNTY_TITLECASE": "Burlington"
	},
	{
		"COUNTY": "CAMDEN",
		"COUNTY_TITLECASE": "Camden"
	},
	{
		"COUNTY": "CAPE MAY",
		"COUNTY_TITLECASE": "Cape May"
	},
	{
		"COUNTY": "CUMBERLAND",
		"COUNTY_TITLECASE": "Cumberland"
	},
	{
		"COUNTY": "ESSEX",
		"COUNTY_TITLECASE": "Essex"
	},
	{
		"COUNTY": "GLOUCESTER",
		"COUNTY_TITLECASE": "Gloucester"
	},
	{
		"COUNTY": "HUDSON",
		"COUNTY_TITLECASE": "Hudson"
	},
	{
		"COUNTY": "HUNTERDON",
		"COUNTY_TITLECASE": "Hunterdon"
	},
	{
		"COUNTY": "MERCER",
		"COUNTY_TITLECASE": "Mercer"
	},
	{
		"COUNTY": "MIDDLESEX",
		"COUNTY_TITLECASE": "Middlesex"
	},
	{
		"COUNTY": "MONMOUTH",
		"COUNTY_TITLECASE": "Monmouth"
	},
	{
		"COUNTY": "MORRIS",
		"COUNTY_TITLECASE": "Morris"
	},
	{
		"COUNTY": "OCEAN",
		"COUNTY_TITLECASE": "Ocean"
	},
	{
		"COUNTY": "PASSAIC",
		"COUNTY_TITLECASE": "Passaic"
	},
	{
		"COUNTY": "SALEM",
		"COUNTY_TITLECASE": "Salem"
	},
	{
		"COUNTY": "SOMERSET",
		"COUNTY_TITLECASE": "Somerset"
	},
	{
		"COUNTY": "SUSSEX",
		"COUNTY_TITLECASE": "Sussex"
	},
	{
		"COUNTY": "UNION",
		"COUNTY_TITLECASE": "Union"
	},
	{
		"COUNTY": "WARREN",
		"COUNTY_TITLECASE": "Warren"
	}
];

export default njCountiesList;