import React, {
	useState,
	useEffect
	//,useRef
} from 'react';
import {
	Link
} from 'react-router-dom';
import {
	get_ce_courses_and_cfa_credits,
	register_for_ce_course,
	DECODE_TOKEN
} from '../Api/api';

import moment from 'moment-timezone';
//import Config from '../Config';


const CFAContinuingEducationPage = (props) => {
	//const userState = props.UserState;
	document.title = props.title;

	const [pageIsLoading, setPageIsLoading] = useState(true);
	const [courseList, setCourseList] = useState({
		upcoming: [],
		registered: []
	});
	const [filterText, filterByText] = useState('');
	const [showCoursesByType, setShowCoursesByType] = useState('registered');

	const [courseRegistration, setCourseRegistration] = useState(null);



	const signUpForCourse = (courseId) => {

		const tokenInfo = DECODE_TOKEN();
		if (tokenInfo.code !== 'TOKEN_FOUND') {
			console.error('Did not find appropriate token.');
			return () => { };
		}


		register_for_ce_course(tokenInfo.getAuthToken, tokenInfo.getId.id, courseId)
			.then((res) => {
				//console.log('Registering only for a course: ', res.data);
				if (res.data.credit && res.data.code === 'OK') {
					//They're simply registering, not paying.

					getCourseList()
				} else {
					throw new Error(res.data);
				}
			})
			.catch(error => {
				console.error('Getting [register_for_ce_course] Failed.', error);
				if (error.statusCode && error.statusCode === 500) {
					console.error('Could not register for this course..');
				}
			});

	}
	const getCourseList = () => {
		setPageIsLoading(true);
		setCourseRegistration(null);

		const tokenInfo = DECODE_TOKEN();
		if (tokenInfo.code !== 'TOKEN_FOUND') {
			console.error('Did not find appropriate token.');
			return () => { };
		}

		get_ce_courses_and_cfa_credits(tokenInfo.getAuthToken, tokenInfo.getId.id)
			.then((res) => {
				//console.log('Retrieving CE courses and CFA CE Credit items.', res);
				//const [ courseList, cfaCredits ] = [
				//res[0].data.filter, res[1].data
				//];
				if (res[0].status !== 200 || res[1].status !== 200) {
					throw res;
				}
				if (res[0].data.code !== 'ACTIVE_COURSES' || res[1].data.code !== 'OK') {
					throw res;
				}

				//Remove CFA Exam
				const cfaExam = res[0].data.data.find((courseInfo) => {
					return courseInfo.title.toLowerCase() === 'cfa exam';
				});

				const [courseList, cfaCredits] = [
					//res[0].data.data.find( (courseInfo) => { return courseInfo.title.toLowerCase() === 'cfa exam'; } ),
					res[0].data.data.filter((courseInfo) => {
						return courseInfo.title.toLowerCase() !== 'cfa exam';
					}),
					res[1].data.credits.filter((courseInfo) => {
						return courseInfo.courseId !== cfaExam.courseId;
					}),
				];
				/*
							console.log('??????????', {
								cfaExam,
								courseList,
								cfaCredits
							})*/

				courseList.forEach((myCourseInfo) => {
					const myCfaCreditItem = cfaCredits.find((courseInfo) => {
						return courseInfo.courseId === myCourseInfo.courseId;
					});
					myCourseInfo.creditObj = myCfaCreditItem;
				});

				// I guess we should reverse sync....
				cfaCredits.forEach((myCreditItem) => {
					const myCourseItem = courseList.find((courseInfo) => {
						return courseInfo.courseId === myCreditItem.courseId;
					});
					//myCreditItem.courseAvailable = (myCourseItem ? myCourseItem.beginDate : null);
					myCreditItem.courseAvailable = (myCourseItem ? myCourseItem.beginDate : (
						myCreditItem.courseAvailable ? myCreditItem.courseAvailable : null
					));
				});


				setPageIsLoading(false);
				setCourseList({
					upcoming: courseList,
					registered: cfaCredits //res[1].data.credits
				});

			})
			.catch(error => {

				//console.log('Retrieving CE courses and CFA CE Credit items.', error);

				setPageIsLoading(false);
				setCourseList(null);
			});
	}
	useEffect(() => {
		getCourseList();
	}, []);




	const determineCeCourseButtonState = (courseInfo) => {
		return ( 
			<Link to={
				`/ce-training/${courseInfo.courseId}`
			}
				className="override-sebs button small success" > < i className="fas fa-book" > </i> Course Material</Link>
		);
		/*
		//For now.
		return (<Link to={
			`/ce-training/${courseInfo.courseId}`
		}
			className="button small success" > < i className="fas fa-book" > </i> Online Material</Link>
		);
		*/

	}
	const getCeCourseInfo = () => {
		if (pageIsLoading) {
			return (<tr><td colSpan="10"><i className="fas fa-circle-notch fa-spin" > </i> Loading</td></tr>
			);
		}



		if (showCoursesByType === 'upcoming') {

			if (!courseList.upcoming) {
				return (
					<tr><td colSpan="10"> There was a problem loading the upcoming <strong> Continuing Education </strong> courses.</td></tr>
				);
			}

			if (courseList.upcoming.length < 1) {
				return (
					<tr><td colSpan="10"> No upcoming <strong> Continuing Education </strong> courses are available at this time.</td></tr>
				);
			}
			return (
				courseList.upcoming
					.filter((courseInfo) => {
						const myFilterText = filterText.toLocaleLowerCase().trim();

						if( courseInfo.creditObj ){
							return false;
						}
						let showInTable = true;
						switch (showCoursesByType) {

							case 'all':
							default: {
								break;
							}
						}

						if (myFilterText.trim() !== '') {
							showInTable = (showInTable && courseInfo.title.toLocaleLowerCase().includes(myFilterText));
						}



						return showInTable;
					})
					.map((courseInfo, key) => {
						/*<td> {
								(courseInfo.creditObj && courseInfo.creditObj.creditEarned ? 'Yes' : 'No')
							} </td>
						*/
						return (<tr key={key}>
							<td> {courseInfo.courseId} </td> 
							<td> {
								courseInfo.title
							} </td>
							
							<td> {
								courseInfo.creditObj && courseInfo.creditObj.creditEarned 
								? courseInfo.creditObj.creditEarned
								: courseInfo.credits
							} </td>
							
							<td> {
								Number(courseInfo.cost).toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD'
								})
							} </td><td> {
								moment.tz(courseInfo.beginDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
									.tz(moment.tz.guess())
									.format('L')
							} </td><td> {
								(courseInfo.creditObj && courseInfo.creditObj.payId ? 'Yes' : 'No')
							} </td>
							
							<td> {
								!courseInfo.creditObj ?
									<button
										className="button small no-bottom-margin"
										onClick={
											(e) => {
												//console.log('Clicking btn');
												setCourseRegistration(courseInfo)

											}
										} >
										<i className="fa fa-door-open" > </i> Register</button >
									:
									<Link to={
										`/ce-training/${courseInfo.courseId}`
									}
										className="override-sebs button small success" > < i className="fas fa-book" > </i> Course Material</Link>
							}
							</td></tr>
						)
					})
			);


		}
		//console.log('[registered courses] ', courseList.registered);
		return (
			courseList.registered
				.filter((courseInfo) => {
					const myFilterText = filterText.toLocaleLowerCase().trim();
					let showInTable = true;
					//console.log(courseInfo);
					switch (showCoursesByType) {

						case 'completed': {
							showInTable = showInTable && (courseInfo.creditEarned !== null);
							break;
						}
						case 'registered': {
							showInTable = showInTable && (courseInfo.creditEarned === null);
							break;
						}
						case 'all':
						default: {
							break;
						}
					}

					if (myFilterText.trim() !== '') {
						showInTable = (showInTable && courseInfo.course.title.toLocaleLowerCase().includes(myFilterText));
					}



					return showInTable;
				})
				.map((courseInfo, key) => {
					//console.log(courseInfo.payId, (courseInfo.payId ? 'Yes' : 'No'));
						/*<td> {
							(courseInfo.creditEarned ? 'Yes' : 'No')
						} </td>*/
						console.log('Course Info: ', courseInfo);
					return (<tr key={
						key
					}><td> {
							courseInfo.courseId
						} </td><td> {
							courseInfo.course && courseInfo.course.title  ? courseInfo.course.title : 'N/A'
						} </td><td> {
							courseInfo.creditEarned 
							? courseInfo.creditEarned
							: ( courseInfo.course && courseInfo.course.credits ? courseInfo.course.credits : 'N/A') 
						} </td><td> {
							( courseInfo.course && courseInfo.course.cost ?
								Number(courseInfo.course.cost).toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD'
								})
								: 'N/A'
							)
						} </td><td> {
							(courseInfo.courseAvailable ?
								moment.tz(courseInfo.courseAvailable, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
									.tz(moment.tz.guess())
									.format('L') :
								'--'
							)
						}</td><td> {
							(courseInfo.payId ? 'Yes' : 'No')
						} </td>
						<td> {
							determineCeCourseButtonState(courseInfo)
						} </td></tr>
					)
				})
		);


	}

	const handleFilterOnChange = event => {
		filterByText(event.target.value);
	};




	const getProperCeForm = () => {
		if (courseRegistration) {
			return (<>
				<div>
					<h2> Course Registration Form </h2>
					<div>
						<h4> What you will be registering for: </h4> 
						<ul>
							<li> Course: {courseRegistration.title} </li> 
							<li > Description: {courseRegistration.description || 'N/A'} </li> 
							<li > Cost: {
								Number(courseRegistration.cost).toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD'
								})
							} </li> 
						</ul> 
						<p> After registering, you may head over to the <Link to="/payments"className="button small primary" > payments </Link> page and pay there.</p>
						<div>

							<button className="button small alert"
								onClick={ (e) => {
									setCourseRegistration(null)
								} } >
								<i className="fa fa-backspace"></i> Cancel </button> 
								<button className="button small primary"
									onClick={(e) => {
										signUpForCourse(courseRegistration.courseId)
									} }
								> Register for this course 
							</button> 
						</div> 
					</div> 
				</div>
			</>);
		}

		/*
				<button className={
					"button small primary" 
					+ ( showCoursesByType === 'ready' ? ' ' : ' hollow')
				}
					onClick={(e) =>{ setShowCoursesByType( 'ready' ) }}>
				Ready </button> 
				<button className={
					"button small primary" 
					+ ( showCoursesByType === 'all' ? ' ' : ' hollow')
				}
					onClick={(e) =>{ setShowCoursesByType( 'all' ) }}>
				All </button>
				<button className={
					"button small primary" 
					+ ( showCoursesByType === 'unregistered' ? ' ' : ' hollow')
				}
					onClick={(e) =>{ setShowCoursesByType( 'unregistered' ) }}>
				Unregistered </button>
				<button className={
					"button small primary" 
					+ ( showCoursesByType === 'unpaid' ? ' ' : ' hollow')
				}
					onClick={(e) =>{ setShowCoursesByType( 'unpaid' ) }}>
				Unpaid </button>
				<button className={
					"button small primary" 
					+ ( showCoursesByType === 'completed' ? ' ' : ' hollow')
				}
					onClick={(e) =>{ setShowCoursesByType( 'completed' ) }}>
				Completed </button>
			*/

		return (<>
			<div className="button-group" >  <button className={
					"button small primary" +
					(showCoursesByType === 'registered' ? ' is-selected' : '')
				}
					onClick={
						(e) => {
							setShowCoursesByType('registered')
						}
					} >Registered </button>
					<button className={
						"button small primary" +
						(showCoursesByType === 'upcoming' ? ' is-selected' : '')
					}
						onClick={
							(e) => {
								setShowCoursesByType('upcoming')
							}
						} >
						New Courses </button>  <button className={
						"button small primary" +
						(showCoursesByType === 'completed' ? ' is-selected' : '')
					}
						onClick={
							(e) => {
								setShowCoursesByType('completed')
							}
						} >
					Completed </button>

			</div> 
			<div >
				<h4 > Search Courses </h4> 
				<div className="row" >
					<div className="medium-3 small-12 columns" >
						<label htmlFor="filter-courses"
							className="label-style-1" > Filter By Text: </label> 
					</div> 
					<div className="medium-5 small-12 columns no-float" >
						<input type="text"
							id="filter-courses"
							placeholder="Course title"
							onChange={
								handleFilterOnChange
							}
						/> 
					</div> 
				</div> 
			</div> 
				<div>
				<div className="table-scroll" >
					<table className="table-left-align-all">
						<thead>
							<tr>
								<th> Course Id </th>
								<th> Title </th>
								<th> Credits </th>
								<th> Cost </th>
								<th> Starts </th>
								<th> Paid For </th>
								<th></th>
							</tr>
						</thead> 
									<tbody>{
										getCeCourseInfo()
									}</tbody></table></div> </div>
		</>
		)


	}

	return (
	<main id="main-wrapper" className=" " >
		<div id="main" className="" >
			<div id="main-col" className="" >
				<div id="content">
					<div>
						<Link to="/dashboard" className="no-bottom-margin button primary small"><i className="fas fa-angle-left"></i> Back To Dashboard</Link>
						<hr className="slim-hr" />
					</div>
					<h2> Continuing Education Courses </h2> 
					{getProperCeForm()} 
				</div>
			</div> 
		</div>
	</main>
	);
}
export default CFAContinuingEducationPage;