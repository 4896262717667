import React from 'react';

class RUBarWrapper extends React.Component {
	render() {
		return (
			<div id="rutgers-bar-wrapper" className="grid-container full">
				<div id="rutgers-bar" className="grid-x">
					<div id="rutgers-sebs" className="cell small-12 large-7">
						<a id="rutgers-logo" href="https://www.rutgers.edu/"><img src={process.env.PUBLIC_URL + '/ru-sebs/images/rutgers-logo.png'} alt="Rutgers" /></a>
						<a id="sebs-logotext" href="https://sebs.rutgers.edu"><img src={process.env.PUBLIC_URL + '/ru-sebs/images/sebs-logotext.png'} alt="School of Environmental and Biological Sciences" /></a>
					</div>
					<div id="rutgers-links" className="cell show-for-large large-5">
						<ol className="menu align-right">
							<li><a href="https://newbrunswick.rutgers.edu/">Rutgers&ndash;New Brunswick</a></li>
							<li><a href="https://my.rutgers.edu">myRutgers</a></li>
							<li><a href="https://search.rutgers.edu/web.html">Search Rutgers</a></li>
						</ol>
					</div>
				</div>
			</div>
		);
	}
}
export default RUBarWrapper;