import React, {
	useState, 
	useEffect, 
//	useRef
} from 'react';
import { 
	get_users_in_system,
	get_cfa_by_id,
	get_contact_info,
	get_tfas_by_cfas,

	delete_admin,
	delete_cfa,
	delete_tfa_to_cfa,

	DECODE_TOKEN 
} from '../Api/api';
import moment from 'moment-timezone';

import EditAdminProfile from '../Pages/Components/UserMgmtComponents/EditAdminProfile';
import EditCFARecord from '../Pages/Components/UserMgmtComponents/EditCFARecord';
import EditTFARecord from '../Pages/Components/UserMgmtComponents/EditTFARecord';

import {FormatPhoneNumber, FormatZipcode} from '../Config';

const AdminManageUsersPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	//console.log(props.match);
	const initialCfaId = props.match && props.match.params.cfaId? props.match.params.cfaId : null;
	//console.log('Go to Cfa ID', initialCfaId);


	const [currentSubpage, SetCurrentSubpage] = useState('list');
	const [selectedUser, SetSelectedUser] = useState(null);

	
	const [disableDeleteUserBtn, setDisableDeleteUserBtn] = useState(false);
	
	const [userCategoryFilter, SetUserCategoryFilter] = useState('cfa-only');
	const [tablePagination, SetTablePagination] = useState({
		tablePage: 0,
		count: 25
	});

	const [sortedTable, SetSortedVars] = useState({
		var: 'lastName',
		dir: 'asc'
	});
	const [filterText, filterByText] = useState(initialCfaId || '');
	const [userList, SetUserList] = useState({
		isLoading: true,
		loaded: {
			admin:false,
			cfa:false,
		},
		list: [] //type of object depends on user.
	});

	const STOCK_TFA = {

		tfaId: null,

		firstName: '',
		lastName: '',
		email:'',
		homeAddressId: '',
		cfaId: null,
		spanishPref: 0,
		payId: null,
		activeYear: null, //new Date().getFullYear(),

		//Address 
		homeStreet: '',
		homeCity: '',
		homeState: 'NJ',
		homeZip: '',
		homePhone: '',
		homeCounty: '',

		confirmTfa: false,
	};

/*

	useEffect( () => {
		if( initialCfaId ) {
			editCFAInfo(initialCfaId);
		}
	}, [initialCfaId, userList.list])
	*/
	useEffect( () => {
		GetUserList();
	}, [] );

	const updateSortable = (newVar, newDir) => {
		SetSortedVars({
			var: newVar,
			dir: newDir
		})
		return true;
	}
	const GetUserList = () => {
		console.log('Getting Users List');
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}


		get_users_in_system(tokenInfo.getAuthToken)
		.then( (res) => {
			const [ cfaResp, adminResp] = [ res[0], res[1] ];
			console.log('COURSE CONTENT: ', cfaResp, adminResp);

			const myUserList = {
				isLoading:false,
				loaded:{
					cfa: cfaResp.data.code === 'ALL_CFAS',
					admin: adminResp.data.code === 'ALL_ADMINS',
				},
				list: [],
			};
			

			if( !myUserList.loaded.cfa || !myUserList.loaded.admin ) {
				throw new Error(res);
			}

			//Time to do big loopies
			adminResp.data.data.forEach( (adminObj) => {
				myUserList.list.push({
					...adminObj,
					userType: 'admin'
				});
			});
			
			cfaResp.data.data.forEach( (cfaObj) => {
				myUserList.list.push({
					...cfaObj,
					userType: 'cfa'
				});
				cfaObj.tfas.forEach( (tfaObj) => { 
					myUserList.list.push({
						...tfaObj,
						cfaId: cfaObj.id,
						cfaPId: cfaObj.profactId,
						userType: 'tfa'
					});
				});
			});

			SetUserList(myUserList);
			

		} )
		.catch( error => {
			console.error('Getting [User_list] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Users List or Admin User List.');
			}
			SetUserList({
				isLoading:false,
				loaded: {
					admin:false,
					cfa:false,
				},
				list: null
			});
		});
	}

	const confirmEditing = (gotoForm='cfa-only') => {
		SetSelectedUser(null);
		SetCurrentSubpage('list');
		SetUserCategoryFilter(gotoForm);
		GetUserList();
	}
	const cancelEditing = (gotoForm='cfa-only') => {
		SetSelectedUser(null);
		SetCurrentSubpage('list');
		SetUserCategoryFilter(gotoForm);
		GetUserList();
	}

	const editAdminInfo = (adminId) => {
		console.log('Editing admin info: ', adminId );
		const myUser = userList.list
		.filter((userInfo) => {
			return userInfo.userType === 'admin'
		})
		.find( (userInfo) => { return userInfo.adminId === adminId })
		;


		console.log('Found admin: ', myUser);
		if( myUser ) {
			SetSelectedUser(myUser);
			SetCurrentSubpage('admin-form');
		}
		return ;
	}
	const editCFAInfo = (id) => {
		
		const foundMyUser = userList.list
		.filter((userInfo) => {
			return userInfo.userType === 'cfa'
		})
		.find( (userInfo) => { return userInfo.id === id })
		;
		
		if( !foundMyUser ) {
			return ;
		}

		console.log('Editing cfa info: ', id, foundMyUser, userList.list );

		const tokenInfo = DECODE_TOKEN();

		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		
		get_cfa_by_id(tokenInfo.getAuthToken, id)
		.then( (res) => {
			
  
			if( res.data.code === 'ONE_CFA' && res.data.data ) {
				//console.log('Here is the data form the login: ', res.data, getAuthToken);
				const myUser = res.data.data;
				console.log('Found cfa: ', myUser);
				if( myUser ) {
					SetSelectedUser({
						...res.data.data,
						tfas: foundMyUser.tfas,
						userType: 'cfa'
					});
					SetCurrentSubpage('cfa-form');
				}

				
			  }

		} )
		.catch( error => {
			console.error('Getting [User_list] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Users List or Admin User List.');
			}
		});


	}
	const editTFAInfo = (cfaId, tfaId) => {
		console.log('Editing tfa info: ', cfaId, tfaId, userList.list );

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		get_tfas_by_cfas(tokenInfo.getAuthToken, cfaId)
		.then( (res) => {
			
			console.log('Found all TFAS under this cfa', res.data);
			const findTfa = res.data.data.find( (userItem) => {
				return (userItem.id === tfaId );
			});

			console.log('FOUND PERSON:', findTfa);
			if(!findTfa) {
				throw new Error(res);
			}


			const myTfaInfo = {
				...STOCK_TFA,
				tfaId: tfaId,
				cfaId: cfaId,
				firstName: findTfa.firstName,
				lastName: findTfa.lastName,
				email: findTfa.email,
				homeAddressId: findTfa.homeAddressId,
				spanishPref: (findTfa.spanishPref === true || Number(findTfa.spanishPref) === 1 ? 1 : 0 ),
				payId: findTfa.payId,
				activeYear: findTfa.activeYear,

				homeStreet: '',
				homeCity: '',
				homeState: '',
				homeZip: '',
				homePhone: '',
				homeCounty: ''
			};



			if( findTfa.homeAddressId ) {
				console.log('Edit tfa info', findTfa.homeAddressId)
				get_contact_info( tokenInfo.getAuthToken, findTfa.homeAddressId )
				.then( (cinfo) => {
					//console.log('Editing a TFA: ', res.data);
					if( cinfo.data.data 
						//&& res.data.code === 'ONE_TFA'
						&& cinfo.data.code === 'ONE_CONTACT'
					) {
						//find tfa in current list
						
	
						//console.log(foundTfaInfo);
						myTfaInfo.homeStreet= cinfo.data.data.street;
						myTfaInfo.homeCity= cinfo.data.data.city;
						myTfaInfo.homeState= cinfo.data.data.state;
						myTfaInfo.homeZip= FormatZipcode( cinfo.data.data.zip);
						myTfaInfo.homePhone= FormatPhoneNumber(cinfo.data.data.phone);
						myTfaInfo.homeCounty= cinfo.data.data.county;

						//Let's pull out the cfa exam vs. ce courses
						
						SetSelectedUser(myTfaInfo);

						//console.log('HELLO???????????', myTfaInfo);
						SetCurrentSubpage('tfa-form');
					}else{
						throw new Error(res.data);
					}
				} )
				.catch( error => {
					console.error('Getting [get_contact] Failed.', error);
					if( error.statusCode && error.statusCode === 500) {
						console.error('Could not fetch TFAs for this CFA.');
					}
				});
			}else{
				SetSelectedUser(myTfaInfo);
				SetCurrentSubpage('tfa-form');
			}



			
			

		} )
		.catch( error => {
			console.error('Getting [User_list] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Users List or Admin User List.');
			}
		});
		
	}


	const deleteCFAInfo = (id) => {
		console.log('Deleting cfa info: ', id );
		
		const foundMyUser = userList.list
		.filter((userInfo) => {
			return userInfo.userType === 'cfa'
		})
		.find( (userInfo) => { return userInfo.id === id })
		;
		
		console.log('Editing cfa info: ', id, foundMyUser, userList.list );

		const tokenInfo = DECODE_TOKEN();

		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		
		get_cfa_by_id(tokenInfo.getAuthToken, id)
		.then( (res) => {
			
  
			if( res.data.code === 'ONE_CFA' && res.data.data ) {
				//console.log('Here is the data form the login: ', res.data, getAuthToken);
				const myUser = res.data.data;
				console.log('Found cfa: ', myUser);
				if( myUser ) {
					SetSelectedUser({
						...res.data.data,
						tfas: foundMyUser.tfas,
						userType: 'cfa'
					});
					SetCurrentSubpage('delete-user-form');
				}

				
			  }

		} )
		.catch( error => {
			console.error('Getting [User_list] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Users List or Admin User List.');
			}
		});


	}
	const deleteAdminInfo = (adminId) => {
		console.log('Deleting admin info: ', adminId );
		const myUser = userList.list
		.filter((userInfo) => {
			return userInfo.userType === 'admin'
		})
		.find( (userInfo) => { return userInfo.adminId === adminId })
		;


		console.log('Found admin: ', myUser);
		if( myUser ) {
			SetSelectedUser(myUser);
			SetCurrentSubpage('delete-user-form');
		}
	}
	const deleteTFAInfo = (cfaId, tfaId) => {
		console.log('Delete tfa info: ', cfaId, tfaId, userList.list );

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		get_tfas_by_cfas(tokenInfo.getAuthToken, cfaId)
		.then( (res) => {
			
			console.log('Found all TFAS under this cfa', res.data);
			const findTfa = res.data.data.find( (userItem) => {
				return (userItem.id === tfaId );
			});

			console.log('FOUND PERSON:', findTfa);
			if(!findTfa) {
				throw new Error(res);
			}

			if( findTfa.homeAddressId ) {
				get_contact_info( tokenInfo.getAuthToken, findTfa.homeAddressId )
				.then( (cinfo) => {
					//console.log('Editing a TFA: ', res.data);
					if( cinfo.data.data 
						//&& res.data.code === 'ONE_TFA'
						&& cinfo.data.code === 'ONE_CONTACT'
					) {
						//find tfa in current list
						
	
						//console.log(foundTfaInfo);
						const myTfaInfo = {
							...STOCK_TFA,
							tfaId: tfaId,
							cfaId: cfaId,
							firstName: findTfa.firstName,
							lastName: findTfa.lastName,
							email: findTfa.email,
							homeAddressId: findTfa.homeAddressId,
							spanishPref: (findTfa.spanishPref === true || Number(findTfa.spanishPref) === 1 ? 1 : 0 ),
							payId: findTfa.payId,
							activeYear: findTfa.activeYear,
							
							homeStreet: cinfo.data.data.street,
							homeCity: cinfo.data.data.city,
							homeState: cinfo.data.data.state,
							homeZip: FormatZipcode( cinfo.data.data.zip),
							homePhone: FormatPhoneNumber(cinfo.data.data.phone),
							homeCounty: cinfo.data.data.county,
							userType:'tfa'
						};
	
						//Let's pull out the cfa exam vs. ce courses
						
						SetSelectedUser(myTfaInfo);
						SetCurrentSubpage('delete-user-form');
					}else{
						throw new Error(res.data);
					}
				} )
				.catch( error => {
					console.error('Getting [get_contact] Failed.', error);
					if( error.statusCode && error.statusCode === 500) {
						console.error('Could not fetch TFAs for this CFA.');
					}
				});

			}else{
				const myTfaInfo = {
					...STOCK_TFA,
					tfaId: tfaId,
					cfaId: cfaId,
					firstName: findTfa.firstName,
					lastName: findTfa.lastName,
					email: findTfa.email,
					homeAddressId: findTfa.homeAddressId,
					spanishPref: (findTfa.spanishPref === true || Number(findTfa.spanishPref) === 1 ? 1 : 0 ),
					payId: findTfa.payId,
					activeYear: findTfa.activeYear,
					
					homeStreet: '',
					homeCity: '',
					homeState: '',
					homeZip: '',
					homePhone: '',
					homeCounty: '',

					userType:'tfa'
				};

				//Let's pull out the cfa exam vs. ce courses
				
				SetSelectedUser(myTfaInfo);
				SetCurrentSubpage('delete-user-form');
			}

		} )
		.catch( error => {
			console.error('Getting [User_list] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Users List or Admin User List.');
			}
		});
		
	}

	const ActuallyRemoveUser = () => {
		console.log(selectedUser);
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		setDisableDeleteUserBtn(true);
		if( selectedUser.userType === 'tfa' ) {
			
			delete_tfa_to_cfa( tokenInfo.getAuthToken, selectedUser.tfaId )
			.then( (res) => {
				console.log('Deleting TFA: ', res.data);
				confirmEditing();
				setDisableDeleteUserBtn(false);
			} )
			.catch( error => {
				console.error('[Delete TFA] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add TFA for this CFA.');
				}
				setDisableDeleteUserBtn(false);
			});
		}else if( selectedUser.userType === 'cfa' ) {
			
			delete_cfa( tokenInfo.getAuthToken, selectedUser.id )
			.then( (res) => {
				console.log('Deleting CFA: ', res.data);
				confirmEditing();
				setDisableDeleteUserBtn(false);
			} )
			.catch( error => {
				console.error('[Delete CFA] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add TFA for this CFA.');
				}
				setDisableDeleteUserBtn(false);
			});
		}else if( selectedUser.userType === 'admin' ) {
			
			delete_admin( tokenInfo.getAuthToken, selectedUser.adminId )
			.then( (res) => {
				console.log('Deleting Admin: ', res.data);
				confirmEditing();
				setDisableDeleteUserBtn(false);
			} )
			.catch( error => {
				console.error('[Delete CFA] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add TFA for this CFA.');
				}
				setDisableDeleteUserBtn(false);
			});
		}

		console.log('Removed!');
	}

	const DeleteUserForm = () => {
		if(!selectedUser) {
			return;
		}

		const EXTRA_INFO = () => {
			console.log(selectedUser);
			if( selectedUser.userType === 'cfa' ) {
				return ( <>
					<dt>Phone Number</dt>
						{selectedUser.homeAddress ?
						<dd>{FormatPhoneNumber(selectedUser.homeAddress.phone)}</dd>
						: <dd>N/A</dd>
						}
					<dt>Business Name:</dt>
						<dd>{selectedUser.businessName}</dd>
					<dt>Business Phone</dt>
						{selectedUser.businessAddress ?
						<dd>{FormatPhoneNumber(selectedUser.businessAddress.phone)}</dd>
						: <dd>N/A</dd>
						}
					<dt>Business Address</dt>
						{selectedUser.businessAddress ?
						<dd>
							<p className="no-bottom-margin">{selectedUser.businessAddress.street}</p>
							<p className="no-bottom-margin">{selectedUser.businessAddress.city}, {selectedUser.businessAddress.state} {FormatZipcode(selectedUser.businessAddress.zip)} </p>
						</dd>
						: <dd>N/A</dd>
						}
					<dt>TFAs: </dt>
						<dd>
							{selectedUser.tfas.length} People
							<ul style={{marginLeft:'2em'}}>
								{selectedUser.tfas.map( (tfaInfo) => { return( <li> {!tfaInfo.activeYear ? '[Inactive]' : '' } {tfaInfo.firstName} {tfaInfo.lastName} </li>)})}
							</ul>
						</dd> 
					
				</> )
			}else if( selectedUser.userType === 'admin' ) {
				return (<>
				
				</>)
			}else if( selectedUser.userType === 'tfa' ) {
				return (<>
				
				</>)
			}
			return null;
		}

		return (
			<div className="cell small-12">
				<h5> User Information: </h5>
				<div>
					<dl>
						<dt>Name</dt>
							<dd>{selectedUser.firstName} {selectedUser.lastName}</dd>
						<dt>Email</dt>
							<dd>{selectedUser.email}</dd>
						{EXTRA_INFO()}	
					</dl>
				</div>
				<h5> <strong>NOTICE: </strong> This action cannot be undone.</h5>
				<div>
					<button onClick={ (e) => { 
						cancelEditing();
					} } 
						className="override-sebs button primary success" type="button">
						Cancel (DO NOT DELETE) </button>  
						<button disabled={disableDeleteUserBtn} onClick={ (e) => { 
							ActuallyRemoveUser();
						} } 
						className="button primary alert" type="button">
						REMOVE THIS USER FROM THE DB </button>
				</div>
			</div>
		);
	}


	const GetPageComponent = () => {
		if( currentSubpage === 'list' ) {
			return GetUserListComp();
		}else if( currentSubpage === 'admin-form' ) {
			return ( <EditAdminProfile selectedUser={selectedUser} confirmEditing={confirmEditing} cancelEditing={cancelEditing} />);
		}else if( currentSubpage === 'cfa-form' ) {
			return ( <EditCFARecord selectedUser={selectedUser} confirmEditing={confirmEditing} cancelEditing={cancelEditing} />);
		}else if( currentSubpage === 'tfa-form' ) {
			return ( <EditTFARecord selectedUser={selectedUser} cfaId={selectedUser.cfaId} confirmEditing={confirmEditing} cancelEditing={cancelEditing} />);
		}else if( currentSubpage === 'delete-user-form' ) {
			return DeleteUserForm();
		}
			

		console.log('Current subpage: ', currentSubpage);

		return null;
	}

	
	const handleFilterOnChange = event => {
		filterByText(event.target.value);
		SetTablePagination({
			...tablePagination,
			tablePage: 0
		});
	};

	const _GetTablePagination = () => {
		const myCount = userList.list
		.filter((userInfo) => {
			const myFilterText = filterText.toLocaleLowerCase().trim();
			let showInTable = true;

			if( userCategoryFilter === 'admin-only') {
				showInTable = (showInTable && userInfo.userType === 'admin');
			}else if( userCategoryFilter === 'cfa-only') {
				showInTable = (showInTable && userInfo.userType === 'cfa');
			}else if( userCategoryFilter === 'tfa-only') {
				showInTable = (showInTable && userInfo.userType === 'tfa');
			}
//All users should have lastName, firstName
//console.log(userInfo);
			if (myFilterText.trim() !== '') {
				showInTable = (showInTable && (
					userInfo.lastName.toLocaleLowerCase().includes(myFilterText)
					|| userInfo.firstName.toLocaleLowerCase().includes(myFilterText)
					|| (userInfo.email && userInfo.email.toLocaleLowerCase().includes(myFilterText) )
					|| (
						userInfo.userType==='cfa'
						? 
						( userInfo.profactId ? userInfo.profactId.toLocaleLowerCase().includes( myFilterText ) : false )
						|| ('' + userInfo.id.toString().padStart(6, '0')).toLocaleLowerCase().includes( myFilterText )
						|| userInfo.email.toLocaleLowerCase().includes(myFilterText)
						:false
					)
				));
			}
			
			return showInTable;
		}).length;

		const maxPages = Math.ceil( myCount / tablePagination.count);
		/*
		console.log('Pagination: ', {
			listCount: myCount,
			tablePage: tablePagination.tablePage,
			count: tablePagination.count,
			maxPages
		});
		*/

		if( maxPages < 2 ) {
			return (<></>);
		}
		const getPageBtns = [];

		let trimFrontPages = false;
		let trimBackPages = false;

		if( maxPages > 10 ) {
			if(tablePagination.tablePage < 5 ) {
				trimBackPages =true;
				for(let i=0;i<6;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}else if(tablePagination.tablePage > maxPages-6 ) {
				trimFrontPages =true;
				for(let i=maxPages-6;i<maxPages;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}else{
				trimBackPages =true;
				trimFrontPages =true;
				for(let i=tablePagination.tablePage-2;i<tablePagination.tablePage+3;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}
		}else{
			for(let i=0;i<maxPages;i++){
				getPageBtns.push( 
					<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
						<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
									e.preventDefault();  
									e.stopPropagation(); 
									SetTablePagination({
										...tablePagination,
										tablePage: i
									});
									return false; 
								}}>
							<span className="show-for-sr">You're on table page</span> {i+1} 
						</a>
					</li>
				);
			}
		}

		return (
			<nav aria-label="Table Pagination">
				<ul className="pagination">{
						tablePagination.tablePage < 1 ? 
						<li className={"pagination-previous disabled"}>Previous <span className="show-for-sr">page</span></li>
						: <li className={"pagination-previous"}>
							<a href="/#" aria-label="Previous page" onClick={(e) => { 
								e.preventDefault();  
								e.stopPropagation(); 
								SetTablePagination({
									...tablePagination,
									tablePage: tablePagination.tablePage-1
								});
								return false; 
							} }>Previous <span className="show-for-sr">page</span> </a>
						</li>
					}
					{trimFrontPages ? <>
						<li className={(tablePagination.tablePage === (0) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (0+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: 0
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {0+1} 
							</a>
						</li>
						<li className={"disabled"}>... <span className="show-for-sr">trimmed front pages</span></li> 
					</>: <></>}
					{getPageBtns}
					{trimBackPages ? <>
						<li className={"disabled"}>... <span className="show-for-sr">trimmed back pages</span></li> 
						<li className={(tablePagination.tablePage === (maxPages-1) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (maxPages)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: maxPages-1
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {maxPages} 
							</a>
						</li>
					</> : <></>}
					{
						tablePagination.tablePage >= maxPages-1 ? 
						<li className={"pagination-next disabled"}>Next <span className="show-for-sr">page</span></li>
						: <li className={"pagination-next"}>
							<a href="/#" aria-label="Next page" onClick={(e) => { 
								e.preventDefault(); 
								e.stopPropagation(); 
								SetTablePagination({
									...tablePagination,
									tablePage: tablePagination.tablePage + 1
								});
								return false; 
							}}>Next <span className="show-for-sr">page</span> </a>
						</li>
					}
				</ul>
			</nav>
		);
	}
	const _OutputUserList = () => {
		//console.log(userList, filterText);

		if( userList.isLoading ) {
			return (
				<tr><td colSpan="11"><i className="fas fa-circle-notch fa-spin"></i> Loading</td></tr>
			);
		}
		
		if( !userList.list ) {
			return (
				<tr><td colSpan="11">There was a problem loading the users data</td></tr>
			);
		}
		
		const tokenInfo = DECODE_TOKEN();

		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		//console.log(tokenInfo.getId.id);

		console.log('Generating table with: ', tablePagination);

		return userList.list
		.filter((userInfo) => {
			
			const myFilterText = filterText.toLocaleLowerCase().trim();
			let showInTable = true;

			if( userCategoryFilter === 'admin-only') {
				showInTable = (showInTable && userInfo.userType === 'admin');
			}else if( userCategoryFilter === 'cfa-only') {
				showInTable = (showInTable && userInfo.userType === 'cfa');
			}else if( userCategoryFilter === 'tfa-only') {
				showInTable = (showInTable && userInfo.userType === 'tfa');
				
			}
//console.log(userInfo);

//All users should have lastName, firstName
			if (myFilterText.trim() !== '') {
				showInTable = (showInTable && (
					userInfo.lastName.toLocaleLowerCase().includes(myFilterText)
					|| userInfo.firstName.toLocaleLowerCase().includes(myFilterText)
					|| (userInfo.email && userInfo.email.toLocaleLowerCase().includes(myFilterText) )
					|| (
						userInfo.userType==='cfa'
						? 
						( userInfo.profactId ? userInfo.profactId.toLocaleLowerCase().includes( myFilterText ) : false )
						|| ('' + userInfo.id.toString().padStart(6, '0')).toLocaleLowerCase().includes( myFilterText )
						|| userInfo.email.toLocaleLowerCase().includes(myFilterText)
						:false
					)|| (
						userInfo.userType==='tfa'
						? 
						( userInfo.cfaPId ? userInfo.cfaPId.toLocaleLowerCase().includes( myFilterText ) : false )
						|| (('T' + userInfo.tfaId.toString().padStart(6, '0').toLocaleLowerCase()).includes( myFilterText ))
						:false
					)
				));
			}
			
			return showInTable;
		})
		.sort( ( a, b ) =>{


			const aVar = a[ sortedTable.var ] ? a[ sortedTable.var ].toLocaleLowerCase() : '';
			const bVar = b[ sortedTable.var ] ? b[ sortedTable.var ].toLocaleLowerCase() : '';

			if( sortedTable.dir === 'asc' ) {
				return aVar.localeCompare(bVar);
			}else  if( sortedTable.dir === 'desc' ) {
				return (aVar.localeCompare(bVar) * -1);
			}

			return 0;
		})
		.slice(
			tablePagination.tablePage * tablePagination.count,
			(tablePagination.tablePage * tablePagination.count) + tablePagination.count
		)
		.map((userInfo, key) => {
			if( userInfo.userType === 'admin') {
				/*
						<td>{userInfo.userType}</td>
						<td>--</td>
						<td>--</td>
						<td>--</td>
						<td>--</td>
				*/
				return (
					<tr key={key}>
						<td>{userInfo.lastName}</td>
						<td>{userInfo.firstName}</td>
						<td>{userInfo.email}</td>
						<td><button type="button" className="no-bottom-margin button warning small"
							onClick={ (e) => {
								editAdminInfo(userInfo.adminId);
							}}
						><i className="fas fa-edit"></i></button></td>
						
						{
							userInfo.adminId === tokenInfo.getId.id 
							? <td></td> : (
								<td><button type="button" className="no-bottom-margin button alert small"
									onClick={ (e) => {
										deleteAdminInfo(userInfo.adminId);
									}}><i className="fas fa-trash"></i></button></td>)
						}
						
					</tr>
				);
			}else if( userInfo.userType === 'cfa') {
				//activeYear >= current year, say yes
				return (
					<tr key={key}>
						<td>{
							userInfo.profactId && userInfo.examPassed ? (
								userInfo.profactId
							)
							: `#${userInfo.id}`
						}</td>
						<td>{userInfo.lastName}</td>
						<td>{userInfo.firstName}</td>
						<td>{userInfo.email}</td>
						<td>{
							userInfo.certDate ?
							moment(userInfo.certDate,'YYYY-MM-DDTHH:mm:ss.SSSZ')
							.format('L')
							: '--'
						}</td>
						<td>{userInfo.cycleYear}</td>
						<td>{userInfo.credits}</td>
						{userInfo.examPaid ? <td>Yes</td>: ( <td style={{backgroundColor:'lightgray'}}><strong>No</strong></td>)}
						<td><button type="button" className="no-bottom-margin button warning small"
							onClick={ (e) => {
								editCFAInfo(userInfo.id);
							}}
						><i className="fas fa-edit"></i></button></td>
						<td><button type="button" className="no-bottom-margin button alert small"
							onClick={ (e) => {
								deleteCFAInfo(userInfo.id);
							}}><i className="fas fa-trash"></i></button></td>
					</tr>
				);
			}else if( userInfo.userType === 'tfa') {
				//console.log('tfa', userInfo);
				return (
					<tr key={key}>
						<td>{('T' + userInfo.tfaId.toString().padStart(6, '0'))}</td>
						<td>{//('C' + userInfo.cfaId.toString().padStart(6, '0'))
							userInfo.cfaPId ? userInfo.cfaPId : ''
						}</td>
						<td>{userInfo.lastName}</td>
						<td>{userInfo.firstName}</td>
						<td>{userInfo.email || 'N/A'}</td>
						
						{userInfo.activeYear ? <td>Yes</td>: ( <td style={{backgroundColor:'lightgray'}}><strong>No</strong></td>)}
						<td><button type="button" className="no-bottom-margin button warning small"
							onClick={ (e) => {
								editTFAInfo(userInfo.cfaId, userInfo.tfaId);
							}}
						><i className="fas fa-edit"></i></button></td>
						<td><button type="button" className="no-bottom-margin button alert small"
							onClick={ (e) => {
								deleteTFAInfo(userInfo.cfaId, userInfo.tfaId);
							}}><i className="fas fa-trash"></i></button></td>
					</tr>
				);
			}
			
			return (
				<tr key={key}> 
					<td colSpan="11"> {(userInfo.id || userInfo.tfaId || userInfo.adminId)} User's type for this row not found...</td>
				</tr>
			);
		});
	}
	const GetUserListComp = () => {
		
		return (
			<div className="small-12 columns">
				<h4> Search Users </h4> 
				<div className="" >
					<div className="row">
						<div className="medium-3 small-12 columns" >
							<label htmlFor="middle-label"
								className="label-style-1" > Filter By Text: </label> 
						</div> 
						<div className="medium-5 small-12 no-float columns" >
							<input type="text"
								id="middle-label"
								value={filterText}
								placeholder=" CFA ID, User Name"
								onChange={
									handleFilterOnChange
								}
							/> 
						</div> 
					</div>
					<div className="row">
						<div className="medium-3 small-12 columns" >
							<label htmlFor="tablePaginationRows"
								className="label-style-1" > How Many Rows Per Page: </label> 
						</div> 
						<div className="medium-5 small-12 no-float columns" >
							<select 
								id="tablePaginationRows"
								name="tablePaginationRows"
								value={tablePagination.count}
								onChange={e => { 
									SetTablePagination({
										...tablePagination,
										tablePage:0,
										count: Number(e.target.value)
									});
								} }>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="200">200</option>
							</select> 
						</div> 
					</div>
					<div className="small-12 button-group stacked-for-small button-group-override">
						<button className={
							`button small primary ${userCategoryFilter === 'tfa-only' ? ' is-selected' : ''}`
						}
							onClick={(e) =>{ 
								SetTablePagination({
									...tablePagination,
									tablePage:0,
								});
								SetUserCategoryFilter('tfa-only') 
							}}
						> Show TFAs</button>
						<button className={
							`button small primary ${userCategoryFilter === 'cfa-only' ? ' is-selected' : ''}`
						}
							onClick={(e) =>{ 
								SetTablePagination({
									...tablePagination,
									tablePage:0,
								});
								SetUserCategoryFilter('cfa-only') 
							}}
						> Show CFAs</button>
						<button className={
							`button small primary ${userCategoryFilter === 'admin-only' ? ' is-selected' : ''}`
						}
							onClick={(e) =>{ 
								SetTablePagination({
									...tablePagination,
									tablePage:0,
								});
								SetUserCategoryFilter('admin-only') 
							}}
						> Show Admins </button>
						<button 
						style={{display:'none'}}
						className={
							`button small primary ${userCategoryFilter === 'all-users' ? ' is-selected' : ''}`
						} 
							onClick={(e) =>{ SetUserCategoryFilter('all-users') }}
						> Show All </button>
					</div>
					<p><em><strong>&ldquo;Paid&rdquo;</strong>: 
						For CFAs, paid means they are active this year. For TFAs, paid means they were paid for by their parent CFA.</em></p>
				</div> 
				{_GetTablePagination()}
				<div className="table-scroll">
					<table className="table-left-align-all">
						<thead>
							<tr>{
									 (userCategoryFilter === 'cfa-only')
									 ?(
										 <>
										 <th>CFA ID</th>
										 </>
									 ) : null
								}{
									(userCategoryFilter === 'tfa-only')
									?(
										<>
										<th>TFA ID</th>
										<th>CFA ID</th>
										</>
									) : null
							   }
								<th>
									<button className="sortable-btn"
										onClick={()=>{
											updateSortable('lastName', (
												sortedTable.var==='lastName' && sortedTable.dir==='asc' 
												? 'desc' : 'asc'
											));
										}}
									>
										<span>Last Name</span> {(
											sortedTable.var==='lastName' ?
											<i className={`fas fa-${(
												sortedTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
											)}`}></i>
											: <i className="fas fa-sort"></i>
										)}
									</button>
								</th>
								<th>First Name</th>
								<th>
									<button className="sortable-btn"
										onClick={()=>{
											updateSortable('email', (
												sortedTable.var==='email' && sortedTable.dir==='asc' 
												? 'desc' : 'asc'
											));
										}}
									>
										<span>Email</span> {(
											sortedTable.var==='email' ?
											<i className={`fas fa-${(
												sortedTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
											)}`}></i>
											: <i className="fas fa-sort"></i>
										)}
									</button>
								</th>
								{
									 (userCategoryFilter === 'cfa-only')
									 ?(
										 <>
										 <th>Cert. Date</th>
										 <th>Cert. Cycle</th>
										 <th>CE Credits</th>
										<th>Paid For Exam</th>
										 </>
									 ) : null
								}{
									(userCategoryFilter === 'admin-only')
									?(
										<>
										</>
									) : null
							   }{
								(userCategoryFilter === 'tfa-only')
								?(
									<>
									<th>Is Paid For</th>
									</>
								) : null
						   }
								<th>Edit / View</th>
								<th>Remove</th>
							</tr>
						</thead>
						<tbody>
							{_OutputUserList()}
						</tbody>
					</table>
				</div>
				{_GetTablePagination()}
			</div>
		);
	}

	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<div className="">
							<h2> Manage Users In System </h2>
							<p><em> You can update CFA or Other Admins Here </em></p>
							<div className="button-group stacked-for-small button-group-override">
								<button className={
									`button small primary ${currentSubpage === 'list' ? ' is-selected' : ''}`
								}
									onClick={(e) =>{ cancelEditing(); SetCurrentSubpage( 'list' ) }}
								><i className="fas fa-table" ></i> Show User List</button>
								<button className={
									`button small primary ${currentSubpage === 'admin-form' ? ' is-selected' : ''}`
								}
									onClick={(e) =>{ cancelEditing(); SetCurrentSubpage( 'admin-form' ) }}
								><i className="fas fa-plus" ></i> Add / Update Admin</button>
							</div>
						</div>
						{GetPageComponent()}
					</div>
				</div>
			</div>
		</main>
	);
}
export default AdminManageUsersPage;