import React, {useState, 
	//useEffect
} from 'react';
import {user_forgot_password} from '../Api/api';
import { Link } from 'react-router-dom';

//import axios from 'axios';
import Config from '../Config';
/*
const languageLabels = {
	'en-US': {
		firstName: 'Name',
	},
	'es': {
		firstName: 'Nombre'
	}

};
*/

const ForgottenPasswordPage = (props) => {
	document.title = props.title;
	//const [recoveryToken, setRecoveryToken] = useState(false);


	//const [preferredLanguage, setPreferredLanguage] = useState(props.UserState.preferredLanguage || 'en-US');

	//console.log(preferredLanguage);

	const [emailAddress, setEmailAddress] = useState('');
	const [showMessage, setShowMessage] = useState('recover-form');
	const [disableRegBtn, setDisableRegBtn] = useState(false);
	const [forgotPwAlert, setForgotPwAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	


	const handleForgotPwEvent = (event) => {
		event.preventDefault();
		//console.log('Registration Submit using <form>', event);
		//console.log('Getting [new FormData()] ', );

		setDisableRegBtn(true);

		user_forgot_password(emailAddress)
			.then( (res) => {
				console.log('Email recovery start', res);

				if( res.status !== 200 || res.data.code !== 'OK' ) {
					throw new Error(res);
				}

				setShowMessage('thank-you-message');
				setDisableRegBtn(false);
			} )
			.catch( error => {
				console.log(error);
				if( error.response && error.response.data) { 
					if( error.response.data.message === 'Email is unregistered.' ) {
						setForgotPwAlert({
							type: 'alert',
							display: 'block',
							title: 'Incorrect Email Address',
							message: `We're sorry, but we could not find this email address in our system. Please register your account or use the link above to contact us.`
						})
					}else{
						setForgotPwAlert({
							type: 'alert',
							display: 'block',
							title: 'Error',
							message: error.response.data.message
						})
					}
				}else{
					setForgotPwAlert({
						type: 'alert',
						display: 'block',
						title: 'Error',
						message: 'There was a problem creating a token for you. Please try again.'
					})

				}
				setDisableRegBtn(false);
			});

		return true;
	}


	const showProperForm = () => {
		if( showMessage === 'recover-form' ) {
			return registerForm();
		}
		if( showMessage === 'thank-you-message' ) {
			return thankYouMessage();
		}
		return (<><p>There was a problem loading this form... please contact an administrator.</p></>)
	}

	const registerForm = () => {
		return( 
			<div id="primary-sub-col" className="margin-top-1 large-10 large-offset-1 medium-12 small-12 columns">
				<h1>Password Recovery</h1>	
				<form action="" method="post" onSubmit={handleForgotPwEvent}>
					<div className="callout primary ">
						<p>
							We&rsquo;re sorry. Let&rsquo;s help you recover your account. We will first need your email that you used to register on the site.
						</p>
					</div>
					<div className="callout secondary ">
						<p>
							<strong>NOTE</strong>: This is a new website system as of 2021.  
							Usernames and passwords from the old system will not work on the new site.  
							Create a new account and your information from the old site will be imported to the new site.  
						</p>
						<p>If you have any questions, please contact {Config.UNIT_EMAIL}.</p>
					</div>
					<div className={"callout " + forgotPwAlert.type} style={{display:forgotPwAlert.display}} data-closable>
						<h5>{forgotPwAlert.title}</h5>
						<p>{forgotPwAlert.message}</p>
						<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
							window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
								setForgotPwAlert({
									type: 'success',
									display: 'none',
									title: '',
									message: ''
								})
							});
						}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="">
						<input type="email" id="email" name="email" placeholder="name@example.com" required="required"
							value={emailAddress}
							onChange={e => setEmailAddress(e.target.value)}
						/>
					</div>
					<div className="text-right">
						<button  className="button" type="submit" disabled={disableRegBtn}>Start Password Recovery</button>
					</div>
				</form>
			</div>
		);
	}
	
	const thankYouMessage = () => {
		return( 
			<div id="primary-sub-col" className="margin-top-1 cell large-10 large-offset-1 medium-12 small-12">
				<h1>Password Recovery</h1>	
				<div className="callout primary large-12 medium-12 small-12 cell">
					<p>
						We have sent an email to the address specified. Please check your email soon. If you have not received an email, please try this process again or contact an administrator.
					</p>
					<p>
						<Link to="/login" className="button small primary"> Go back to Login </Link>
					</p>
				</div>
			</div>
		);
	}
	

	return (
		<>
		<main id="main-wrapper" className="">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content" className="">
						{showProperForm()}
					</div>
				</div>
			</div>
		</main>
		</>
	);

}

export default ForgottenPasswordPage;